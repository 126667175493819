import { ReactElement } from "react";
import { SubState } from "Asset_Specification";
import {
  AccordionInput,
  FormGrid,
  SetAssetParameterInput,
} from "@form-components";
import { Path, UseFormGetValues } from "react-hook-form";
import { defaultSetAssetParameter } from "@utils/setAssetParameterUtils";

export const actionSetAssetParameterName: Path<SubState> =
  "actionSetAssetParameter";

const ActionSetAssetParameterTabContent = (): ReactElement => {
  return (
    <FormGrid>
      <AccordionInput<SubState, "actionSetAssetParameter">
        heading="Action Set Asset Parameters"
        name={actionSetAssetParameterName}
        defaultValue={{ ...defaultSetAssetParameter }}
        getItemLabel={(index: number, getValues: UseFormGetValues<SubState>) =>
          getValues(`${actionSetAssetParameterName}.${index}.@assetId`) ?? ""
        }
        itemRenderer={({ itemKey, name }) => (
          <SetAssetParameterInput key={itemKey} name={name} />
        )}
      />
    </FormGrid>
  );
};

export default ActionSetAssetParameterTabContent;
