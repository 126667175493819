import { Node } from "reactflow";
import { isStepNodeData } from "../../components/SequentialFlowEditor/components/nodes/nodeUtils";
import { StepNodeData } from "../../components/SequentialFlowEditor/types";

export const migrateStepNodeData = (nodes: Node[]): Node[] => {
  nodes.forEach((node: Node) => {
    if (node.type !== "stepNode" || isStepNodeData(node.data)) {
      return;
    }

    const stepNodeData: StepNodeData = {
      step: {
        stepId: node.data.stepId,
        actionOperatingScheme: node.data.actionOperatingScheme,
        actionOperatingSchemeCommand: node.data.actionOperatingSchemeCommand,
        actionSetAssetParameter: node.data.actionSetAssetParameter,
        actionSetParameter: node.data.actionSetParameter,
        actionOperatorMessage: node.data.actionOperatorMessage,
        actionSnapShot: node.data.actionSnapShot,
        actionAlarmingWarning: node.data.actionAlarmingWarning,
        actionRefreshParameter: node.data.actionRefreshParameter,
        actionCalculation: node.data.actionCalculation,
        branching: node.data.branching,
        "@value": node.data["@value"],
        "@description": node.data["@description"],
        "@id": node.data["@id"],
        "@pauseStep": node.data["@pauseStep"],
        "@name": node.data["@name"],
      },
    };
    node.data = stepNodeData;
  });
  return nodes;
};
