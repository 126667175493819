import { AssetSpecification } from "../../Asset_Specification";
import { toArray } from "../../utils";
import { isUndefinedOrEmptyOrWhitespace } from "../../utils/stringUtils";
import { IAssetFormatterService } from "./IAssetFormatterService";
import { FormatTemplate, FormatTemplateType } from "./FormatTemplate";

/**
 * A concrete service which allows for formatting assets.
 * @see IAssetFormatterService
 */
export class AssetFormatterService implements IAssetFormatterService {
  private objectTemplate: FormatTemplate;

  constructor(objectTemplate: FormatTemplate) {
    this.objectTemplate = objectTemplate;
  }

  public format(object: { [key: string]: any }): AssetSpecification {
    return this.formatObjectToTemplate(
      object,
      this.objectTemplate,
      false
    ) as AssetSpecification;
  }

  private formatObjectToTemplate(
    object: { [key: string]: any } | undefined,
    template: FormatTemplate,
    canBeUndefined: boolean
  ): { [key: string]: any } | undefined {
    const formattedObject: { [key: string]: any } = {};
    if (object === undefined && canBeUndefined) {
      return undefined;
    }

    const templateEntries: [string, FormatTemplateType][] =
      Object.entries(template);
    templateEntries.forEach(([propertyName, formatType]) => {
      const propertyValue = object?.[propertyName];
      switch (formatType.type) {
        case "string": {
          formattedObject[propertyName] = this.formatStringToTemplate(
            propertyValue,
            formatType.canBeUndefined
          );
          break;
        }
        case "boolean": {
          formattedObject[propertyName] = this.formatBooleanToTemplate(
            propertyValue,
            formatType.canBeUndefined
          );
          break;
        }
        case "array": {
          formattedObject[propertyName] = this.formatArrayToTemplate(
            toArray(propertyValue),
            formatType,
            template
          );
          break;
        }
        case "object": {
          const formattedObjectProperty = this.formatObjectToTemplate(
            propertyValue,
            formatType.propertyTemplate,
            formatType.canBeUndefined
          );
          formattedObject[propertyName] =
            formatType.canBeUndefined &&
            (formattedObjectProperty === undefined ||
              Object.keys(formattedObjectProperty).length < 1)
              ? undefined
              : formattedObjectProperty;
          break;
        }
      }
    });
    return formattedObject;
  }

  private formatStringToTemplate(
    value: string | undefined,
    canBeUndefined: boolean
  ): string | undefined {
    if (isUndefinedOrEmptyOrWhitespace(value)) {
      return canBeUndefined ? undefined : "";
    }

    return value;
  }

  private formatBooleanToTemplate(
    value: boolean | undefined,
    canBeUndefined: boolean
  ): boolean | undefined {
    if (canBeUndefined) {
      return !value ? undefined : value;
    }

    return value !== undefined ? value : false;
  }

  private formatArrayToTemplate(
    array: any[],
    formatType: {
      type: "array";
      canBeUndefined: boolean;
      propertyTemplate?: FormatTemplate | "self";
    },
    parentTemplate: FormatTemplate
  ): any[] | undefined {
    if (array.length < 1) {
      return formatType.canBeUndefined ? undefined : array;
    }

    if (formatType.propertyTemplate === undefined) {
      return array;
    }

    const propertyTemplate: FormatTemplate =
      formatType.propertyTemplate === "self"
        ? parentTemplate
        : formatType.propertyTemplate;

    const newArray: { [propertyName: string]: any }[] = [];
    array.forEach((value: { [propertyName: string]: any }) => {
      const formattedValue = this.formatObjectToTemplate(
        value,
        propertyTemplate,
        formatType.canBeUndefined
      );
      if (formattedValue !== undefined) {
        newArray.push(formattedValue);
      }
    });
    return newArray;
  }
}
