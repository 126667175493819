import { FormatTemplate } from "../FormatTemplate";

const plcParameterTemplate: FormatTemplate = {
  "@id": {
    type: "string",
    canBeUndefined: false,
  },
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@type": {
    type: "string",
    canBeUndefined: false,
  },
  "@block": {
    type: "string",
    canBeUndefined: true,
  },
  "@description": {
    type: "string",
    canBeUndefined: true,
  },
};

export default plcParameterTemplate;
