import { Parameter } from "../../../../Asset_Specification";

export const defaultParameter: Parameter = {
  label: [
    {
      "@lang": "en_US.utf8",
      "@value": "",
    },
    {
      "@lang": "de_DE.utf8",
      "@value": "",
    },
  ],
  value: {
    "@description": "",
    "@defaultValue": "",
    "@min": "",
    "@max": "",
  },
  engineeringUnit: {
    "@description": "Engineering unit",
    "@value": "",
  },
  displayFormat: {
    "@description": "Display format",
    "@value": "",
  },
  mfcs: undefined,
  "@alias": "",
  "@type": "Process",
  "@description": "",
  "@dataType": "real",
  "@id": "",
  "@name": "",
};
