import { requiredRuleErrorMessage } from "@components/form-components/utils/rulesUtils";
import { FieldError } from "react-hook-form";
import { maybeWarnAboutMissingErrorMessage } from "../../utils/errorUtils";

/**
 * Defines the properties of the {@link useErrorMessage} hook.
 */
interface UseErrorMessageProps {
  name: string;
  required: boolean;
  error: FieldError | undefined;
}

/**
 * Defines the return value of the {@link useErrorMessage} hook.
 */
interface UseErrorMessageReturn {
  errorMessage: string | undefined;
}

/**
 * A hook for getting the error message for the numeric field component.
 * @param props the properties to inject into the hook
 * @returns {UseErrorMessageReturn}
 */
const useErrorMessage = ({
  name,
  required,
  error,
}: UseErrorMessageProps): UseErrorMessageReturn => {
  if (!error) {
    return { errorMessage: undefined };
  }

  if (required && error.type === "required") {
    return { errorMessage: requiredRuleErrorMessage };
  }

  maybeWarnAboutMissingErrorMessage(name, error);
  return { errorMessage: error.message };
};

export default useErrorMessage;
