import { isEmptyOrWhitespace } from "../../utils/stringUtils";

export const validateRegexInput = (input: string, regExpPattern: string) => {
  const regExp = new RegExp(regExpPattern);
  const matches = input.match(regExp);
  return matches !== null;
};

export const alphanumericRegexpCharacters = "A-Za-z0-9";
export const alphanumericRegexpValidationMessage =
  "This should consist of only alphanumeric characters (A-z, 0-9)";

export const ansiRegexCharacters = " -ÿ";
export const ansiRegexValidationMessage =
  "Text should consist of ANSI characters";

export const getRegexpFromCharacters = (characters: string) => {
  return `^[${characters}]+$`;
};

export const processChangedOptionalValue = (
  value: string
): string | undefined => {
  return isEmptyOrWhitespace(value) ? undefined : value;
};

export const processOptionalValue = (value?: string): string => {
  return value ?? "";
};
