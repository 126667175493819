import { useCallback, useContext } from "react";
import List from "../../../../GenericInputs/List/List";
import useSpecificationStateNames from "../../../hooks/useSpecificationStateNames";
import {
  AppDispatchContext,
  AppStateContext,
} from "../../../../../state/Context";

const StatesList = () => {
  const { selectedState } = useContext(AppStateContext);
  const dispatch = useContext(AppDispatchContext);
  const states: string[] = useSpecificationStateNames();

  const onListItemClick = useCallback(
    (stateName: string) => {
      dispatch({
        type: "set-selected-state",
        selectedState: stateName,
      });
    },
    [dispatch]
  );

  return (
    <List
      id="states"
      items={states}
      itemClicked={onListItemClick}
      selectedItem={selectedState}
    />
  );
};

export default StatesList;
