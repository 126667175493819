import { useEffect, useState } from "react";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import { toArray } from "utils";

/**
 * Defines the properties for the {@link useError} hook.
 */
interface UseErrorProps<TInputs extends FieldValues> {
  names: Path<TInputs> | Path<TInputs>[];
}

/**
 * Hook for getting the error for a field, or several fields.
 * @template TInputs - the type defining the inputs/fields for the form.
 * @returns whether the fields are invalid or not
 */
export default function useError<TInputs extends FieldValues>({
  names,
}: UseErrorProps<TInputs>): boolean {
  const { getFieldState, formState } = useFormContext<TInputs>();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const newError: boolean = toArray(names)
      .map((name) => getFieldState(name, formState))
      .some((fieldState) => fieldState.invalid);
    setError(newError);
  }, [formState, getFieldState, names]);

  return error;
}
