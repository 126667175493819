import { useContext, useEffect, useState } from "react";
import {
  AppDispatchContext,
  AppStateContext,
  ModalContext,
} from "../../state/Context";
import { CalculationDefinition } from "../../Asset_Specification";
import EditList from "../EditList/EditList";
import {
  getItemDictionary,
  onDeleteItemCallback,
  onSaveItemCallback,
} from "../EditList/utils/editListUtils";
import EditCalculationsModal from "./Modal/EditCalculationsModal";

const addFormHeading = "Add Calculation Definition";
const editFormHeading = "Edit Calculation Definition";
const calculationDefinitionUniqueProperty = "@id";

const EditCalculations = () => {
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const dispatch = useContext(AppDispatchContext);
  const { specificationService } = useContext(AppStateContext);
  const [calculationDefinitions, setCalculationDefinitions] = useState<{
    [key: string]: CalculationDefinition;
  }>({});

  const setCalculationsCallback = (newCalculationDefinitions: {
    [key: string]: CalculationDefinition;
  }) => {
    setCalculationDefinitions(newCalculationDefinitions);
    dispatch({
      type: "set-calculations",
      calculations: Object.values(newCalculationDefinitions),
    });
  };

  const openEditCalculationDefinitionModal = (
    heading: string,
    calculationDefinitionEntry?: [string, CalculationDefinition]
  ) => {
    dispatchModal({
      type: "show",
      content: (
        <EditCalculationsModal
          modalHeading={heading}
          onSaveItemCallback={onSaveCalculationDefinition}
          onCancelCallback={() => dispatchModal({ type: "close" })}
          itemEntry={calculationDefinitionEntry}
        />
      ),
    });
  };

  const onSaveCalculationDefinition = (
    calculationDefinition: CalculationDefinition,
    calculationDefinitionId?: string
  ) => {
    onSaveItemCallback(
      calculationDefinitions,
      calculationDefinition,
      setCalculationsCallback,
      dispatchModal,
      calculationDefinitionId
    );
  };

  useEffect(() => {
    const calculationsArray: CalculationDefinition[] =
      specificationService?.getCalculations() ?? [];
    setCalculationDefinitions(getItemDictionary(calculationsArray));
  }, [specificationService]);

  return (
    <EditList
      itemDictionary={calculationDefinitions}
      heading={"Calculations"}
      getItemLabel={(item) =>
        (item as CalculationDefinition)[calculationDefinitionUniqueProperty]
      }
      onAddItemButtonClick={() =>
        openEditCalculationDefinitionModal(addFormHeading)
      }
      onEditItemCallback={(calculationDefinitionId) =>
        openEditCalculationDefinitionModal(editFormHeading, [
          calculationDefinitionId,
          calculationDefinitions[calculationDefinitionId],
        ])
      }
      onDeleteItemCallback={(calculationDefinitionId) =>
        onDeleteItemCallback(
          calculationDefinitions,
          calculationDefinitionId,
          setCalculationsCallback
        )
      }
    />
  );
};

export default EditCalculations;
