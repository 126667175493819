import { Handle, NodeProps, Position } from "reactflow";
import { StateLinkNodeData } from "../../../types";
import { ReactElement, useCallback } from "react";
import { HandleTypes } from "@components/SequentialFlowEditor/utils/types";
import NodeButtonContainer from "../../NodeButtonContainer/NodeButtonContainer";
import EditNodeModalService from "@components/SequentialFlowEditor/services/interfaces/EditNodeModalService";
import useEditStateLinkNodeDataModal from "./hooks/useEditStateLinkNodeDataModal";
import "./StateLinkNode.css";

const StateLinkNode = ({
  id,
  data,
}: NodeProps<StateLinkNodeData>): ReactElement<
  NodeProps<StateLinkNodeData>
> => {
  const editStateLinkNodeModalService: EditNodeModalService<StateLinkNodeData> =
    useEditStateLinkNodeDataModal();

  const onEditCallback = useCallback(() => {
    editStateLinkNodeModalService.openEditModal(id, data);
  }, [editStateLinkNodeModalService, id, data]);

  return (
    <div className="stateLink-node">
      <Handle
        type="target"
        id={HandleTypes.stateLinkTarget}
        position={Position.Top}
      />
      <div className="stateLink-node-labels-wrapper">
        <label className="stateLink-node-label">continue</label>
        <label className="stateLink-node-substate-label">
          <span>{data.substateId}</span>
          <span className="stateLink-node-state-label">({data.state})</span>
        </label>
        <NodeButtonContainer
          className="stateLink-node-button-container"
          nodeId={id}
          onEditCallback={onEditCallback}
        />
      </div>
    </div>
  );
};

export default StateLinkNode;
