import { PLCParameter } from "../../../../Asset_Specification";
import PLCParameterForm from "../interfaces/PLCParameterForm";

export const defaultPLCParameterForm: PLCParameterForm = {
  "@type": ["static", true],
  "@block": [undefined, true],
  "@description": [undefined, true],
  "@id": ["", false],
  "@name": ["", false]
}

export const getPLCParameterFormFromPLCParameter = (plcParameter?: PLCParameter): PLCParameterForm => {
  return plcParameter !== undefined
    ? {
      "@type": [plcParameter["@type"], true],
      "@block": [plcParameter["@block"], true],
      "@description": [plcParameter["@description"], true],
      "@id": [plcParameter["@id"], plcParameter["@id"].length > 0],
      "@name": [plcParameter["@name"], plcParameter["@name"].length > 0]
    } : defaultPLCParameterForm;
}

export const getPLCParameterFromPLCParameterForm = (plcParameterForm: PLCParameterForm): PLCParameter => {
  return {
    "@type": plcParameterForm["@type"][0],
    "@block": plcParameterForm["@block"][0],
    "@description": plcParameterForm["@description"][0],
    "@id": plcParameterForm["@id"][0],
    "@name": plcParameterForm["@name"][0]
  };
}

export const isValidPlcParameter = (plcParameter: PLCParameter): boolean => {
  return plcParameter["@id"].length > 0 && plcParameter["@name"].length > 0;
}
