import { FormatTemplate } from "../FormatTemplate";
import conditionTemplate from "./conditionTemplate";

const setOperatingSchemeTemplate: FormatTemplate = {
  condition: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: conditionTemplate,
  },
  "@operatingScheme": {
    type: "string",
    canBeUndefined: false,
  },
};

export default setOperatingSchemeTemplate;
