import { FormatTemplate } from "../FormatTemplate";

const validationRuleTemplate: FormatTemplate = {
  "@operator": {
    type: "string",
    canBeUndefined: false,
  },
  "@parameterId": {
    type: "string",
    canBeUndefined: false,
  },
};

export default validationRuleTemplate;
