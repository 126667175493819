import { forwardRef, useEffect, useRef, useState } from "react";
import TextAreaInput from "../TextAreaInput";
import { CommonInputProps } from "../InputProps";
import {
  defaultTextBlockFormValue,
  generateTextBlockArrayChecksum,
  getTextBlockFormFromTextBlock,
  getTextBlockFromFormArray,
  getValidityFromTextBlockFormArray,
} from "./utils/textBlockInputUtils";
import AccordionInput from "../AccordionInput/AccordionInput";

interface TextBlockInputProps
  extends CommonInputProps<string | string[] | undefined> {
  textBlock?: string | string[];
  hideAddButton?: boolean;
  regexp?: string;
  setValueOnChange?: boolean; // For inputs which don't need to be updated everytime the underlying input value changes; needed to fix AP-158895
}

const TextBlockInput = forwardRef((props: TextBlockInputProps, ref) => {
  const {
    id,
    textBlock,
    onChangeCallback,
    hideAddButton,
    regexp,
    setValueOnChange,
  } = {
    ...props,
  };

  const textBlockChecksum = useRef<number | null>(null);
  if (textBlockChecksum.current == null) {
    // Avoids uncessary function calls each render
    textBlockChecksum.current = generateTextBlockArrayChecksum(textBlock);
  }

  const [textBlocks, setTextBlocks] = useState<
    { key: string; value: string; valid: boolean }[]
  >(() => getTextBlockFormFromTextBlock(textBlock));

  const onTextBlockChanged = (
    newTextBlocks: { key: string; value: string; valid: boolean }[]
  ) => {
    setTextBlocks(newTextBlocks);
    const newTextBlock = getTextBlockFromFormArray(newTextBlocks);
    const valid = getValidityFromTextBlockFormArray(newTextBlocks);
    textBlockChecksum.current = generateTextBlockArrayChecksum(newTextBlock);
    onChangeCallback?.(newTextBlock, valid);
  };

  useEffect(() => {
    // If any changes to the textBlock occur outside of this component, e.g. creating a new spec, then we always use the prop
    // as the single source of truth. The checksum comparison achieves this.
    const propTextBlockChecksum = generateTextBlockArrayChecksum(textBlock);
    if (propTextBlockChecksum === textBlockChecksum.current) {
      return;
    }

    setTextBlocks(getTextBlockFormFromTextBlock(textBlock));
    textBlockChecksum.current = propTextBlockChecksum;
  }, [textBlock]);

  return (
    <AccordionInput
      id={id}
      accordionInputRef={ref}
      items={textBlocks}
      defaultItemEntry={defaultTextBlockFormValue}
      itemRenderer={(id, key, value, onItemChangeCallback) => (
        <TextAreaInput
          id={id}
          key={key}
          value={value}
          onChangeCallback={onItemChangeCallback}
          required
          maxLength={-1}
          regexp={regexp}
          setValueOnChange={setValueOnChange}
        />
      )}
      getItemLabel={(value: string) => value}
      onChangeCallback={onTextBlockChanged}
      hideAddButton={hideAddButton}
    />
  );
});

export default TextBlockInput;
