import { FormGrid, FormTab, MultiLabelInput } from "@form-components";
import { Parameter } from "Asset_Specification";
import { Path } from "react-hook-form";

const labelNames: Path<Parameter>[] = ["label", "label.0", "label.1"];

const LabelTab = () => {
  return (
    <FormTab heading="Label" names={labelNames}>
      <FormGrid>
        <MultiLabelInput name="label" required />
      </FormGrid>
    </FormTab>
  );
};

export default LabelTab;
