import CalculationFormProps from "./interfaces/CalculationFormProps";
import FormTabs from "../../EditForm/tabs/FormTabs";
import NameInput from "./inputs/NameInput";
import DescriptionInput from "./inputs/DescriptionInput";
import IdInput from "./inputs/IdInput";
import ParameterIdInput from "./inputs/ParameterIdInput";
import ParameterInput from "./inputs/ParameterInput";
import EditForm from "../../EditForm/EditForm";
import CodeInput from "./inputs/CodeInput/CodeInput";
import FormTab from "../../EditForm/tabs/FormTab";
import PlcParameterInput from "./inputs/PlcParameterInput/PlcParameterInput";
import DocumentationInput from "./inputs/DocumentationInput/DocumentationInput";
import { SuiFormSegment } from "../../SuiFormSegment";
import CalculationForm from "./interfaces/CalculationForm";

const formId: string = "EditCalculationsForm";

interface EditCalculationsFormProps
  extends Omit<CalculationFormProps, "formId"> {
  calculationDefinitionForm: CalculationForm;
}

const attributesInError = (errors: { [key: string]: boolean }): boolean => {
  return (
    errors["@name"] ||
    errors["@description"] ||
    errors["@id"] ||
    errors["@parameterId"] ||
    errors["@parameter"]
  );
};

const EditCalculationsForm = (props: EditCalculationsFormProps) => {
  const { calculationDefinitionForm } = { ...props };
  const errors: { [key: string]: boolean } = Object.fromEntries(
    Object.entries(calculationDefinitionForm).map((property) => [
      property[0],
      !property[1][1],
    ])
  );

  const formProps: CalculationFormProps = {
    formId: formId,
    setCalculationFormCallback: props.setCalculationFormCallback,
  };
  return (
    <EditForm>
      <FormTabs>
        <FormTab heading="Attributes" error={attributesInError(errors)}>
          <SuiFormSegment>
            <NameInput
              name={calculationDefinitionForm["@name"][0]}
              {...formProps}
            />
            <DescriptionInput
              description={calculationDefinitionForm["@description"][0]}
              {...formProps}
            />
            <IdInput id={calculationDefinitionForm["@id"][0]} {...formProps} />
            <ParameterIdInput
              parameterId={calculationDefinitionForm["@parameterId"][0]}
              {...formProps}
            />
            <ParameterInput
              parameter={calculationDefinitionForm["@parameter"][0]}
              {...formProps}
            />
          </SuiFormSegment>
        </FormTab>
        <FormTab heading="Code" error={errors.code}>
          <CodeInput code={calculationDefinitionForm.code[0]} {...formProps} />
        </FormTab>
        <FormTab heading="PLC Parameter" error={errors.plcParameter}>
          <PlcParameterInput
            plcParameter={calculationDefinitionForm.plcParameter[0]}
            {...formProps}
          />
        </FormTab>
        <FormTab heading="Documentation" error={errors.documentation}>
          <DocumentationInput
            documentation={calculationDefinitionForm.documentation[0]}
            {...formProps}
          />
        </FormTab>
      </FormTabs>
    </EditForm>
  );
};

export default EditCalculationsForm;
