import { MutableRefObject, useCallback } from "react";

/**
 * Defines the return values for the {@link useFormSubmit} hook.
 */
interface UseFormSubmitReturn {
  dispatchSubmitEvent: (
    formRef: MutableRefObject<HTMLFormElement | null>
  ) => void;
}

/**
 * A hook for returning a reusable method for submitting forms.
 * @returns {UseFormSubmitReturn}
 */
const useFormSubmit = (): UseFormSubmitReturn => {
  const dispatchSubmitEvent = useCallback(
    (formRef: MutableRefObject<HTMLFormElement | null>) => {
      formRef.current?.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    },
    []
  );

  return { dispatchSubmitEvent };
};

export default useFormSubmit;
