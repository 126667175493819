import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import {
  AnsiTextField,
  FormGrid,
  MultiLabelInput,
  RequiredIdTextField,
  RequiredNonEmptyNameTextField,
  getAlreadyExistingValueRule,
} from "@form-components";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";

/**
 * Defines the properties for the {@link ValueEnumInput} component.
 */
interface ValueEnumInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {
  excludedValues?: string[];
}

/**
 * The input component for specifying Value Enum values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<ValueEnumInputProps<TInputs>>}
 */
export default function ValueEnumInput<TInputs extends FieldValues>({
  disabled,
  columnSpan,
  name,
  excludedValues,
}: ValueEnumInputProps<TInputs>): ReactElement<ValueEnumInputProps<TInputs>> {
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <RequiredIdTextField name={`${name}.@id`} fullWidth />

      <RequiredNonEmptyNameTextField
        name={`${name}.@name` as Path<TInputs>}
        fullWidth
      />

      <AnsiTextField
        name={`${name}.@value` as Path<TInputs>}
        label="Value"
        rules={{
          required: true,
          validate: excludedValues
            ? getAlreadyExistingValueRule(
                excludedValues,
                "This value is already used in another value enum!"
              )
            : undefined,
        }}
        disabled={disabled}
        fullWidth
      />

      <AnsiTextField
        name={`${name}.@description` as Path<TInputs>}
        label="Description"
        fullWidth
      />

      <MultiLabelInput name={`${name}.label` as Path<TInputs>} required />
    </FormGrid>
  );
}
