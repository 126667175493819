import { ReactElement } from "react";
import { SuiTab } from "../../SuiTabs";
import { InputProps } from "../interfaces/InputProps";
import { Rules } from "../interfaces/Rules";
import { FormGridProps } from "../grid/FormGrid";
import useError from "../hooks/useError";
import { FieldValues, Path } from "react-hook-form";

/**
 * Defines the properties for the {@link FormTab} component.
 * @template TInputs - the type defining the inputs for the form.
 * @param names - the array of field names which collectively make up the total state of the tab; this is used
 * to calculate whether the tab contains any fields in error or not.
 * @param control - the {@link Control} for the form.
 * @param children - the child {@link ReactElement } input components which make up the tabs content.
 */
export interface FormTabProps<TInputs extends FieldValues> {
  names: Path<TInputs> | Path<TInputs>[];
  heading: string;
  children:
    | ReactElement<InputProps<any, Rules> | FormGridProps>
    | ReactElement<InputProps<any, Rules> | FormGridProps>[];
}

/**
 * A component which presents a portion of a forms data as a single tab.
 * @template TInputs - the type defining the inputs for the form.
 * @param {FormTabProps} props - the properties to pass into the component.
 * @returns {ReactElement}
 */
function FormTab<TInputs extends FieldValues>({
  names,
  heading,
  children,
}: FormTabProps<TInputs>): ReactElement<FormTabProps<TInputs>> {
  const error: boolean = useError({ names });
  const internalHeading: string = !error ? heading : `${heading} \u26a0`;
  return (
    <SuiTab heading={internalHeading} error={error}>
      {children}
    </SuiTab>
  );
}

export default FormTab;

