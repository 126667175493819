import { ReactElement, useEffect } from "react";
import { Path, useFormContext } from "react-hook-form";
import { SubState } from "Asset_Specification";
import {
  AnsiTextField,
  FormGrid,
  NumericField,
  RequiredIdTextField,
  RequiredNonEmptyNameTextField,
} from "@form-components";

export const attributeNames: Path<SubState>[] = [
  "@id",
  "@name",
  "@value",
  "@description",
  "@pauseStep",
];

const AttributesTabContent = (): ReactElement => {
  const { setValue, watch } = useFormContext<SubState>();
  const value = watch("@value");

  useEffect(() => {
    setValue("@id", `S${value.toString().padStart(3, "0")}`);
  }, [setValue, value]);

  return (
    <FormGrid>
      <RequiredIdTextField name="@id" fullWidth disabled />
      <RequiredNonEmptyNameTextField name="@name" fullWidth />
      <NumericField
        name="@value"
        label="Value"
        rules={{
          min: { value: 0, message: "Must be greater or equal to 0!" },
          required: true,
        }}
        isInteger
        fullWidth
      />
      <AnsiTextField
        name="@description"
        label="Description"
        rules={{ required: true }}
        fullWidth
      />
      <AnsiTextField
        name="@pauseStep"
        label="Pause Step"
        rules={{ required: false }}
        fullWidth
      />
    </FormGrid>
  );
};

export default AttributesTabContent;
