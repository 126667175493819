import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import AnsiTextField from "../../basic/AnsiTextField";
import { Checkbox, FormGrid, getStylesFromColumnSpan } from "@form-components";

import "./AwParameterInput.css";

/**
 * Defines the properties for the {@link AwParameterInputProps} component.
 */
interface AwParameterInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "fullWidth"> {}

/**
 * The input component for specifying AwParameter values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<AwParameterInput<TInputs>>}
 */
export default function AwParameterInput<TInputs extends FieldValues>({
  disabled,
  name,
  label,
  columnSpan,
}: AwParameterInputProps<TInputs>): ReactElement<
  AwParameterInputProps<TInputs>
> {
  return (
    <FormGrid
      heading={label}
      className="aw-parameter-container"
      style={getStylesFromColumnSpan(columnSpan)}
    >
      <AnsiTextField
        name={`${name}.@value` as Path<TInputs>}
        label="Value"
        disabled={disabled}
        fullWidth
      />

      <Checkbox<TInputs>
        name={`${name}.@enable` as Path<TInputs>}
        label="Enable"
        disabled={disabled}
      />
    </FormGrid>
  );
}
