import EditNodeModalService from "@components/SequentialFlowEditor/services/interfaces/EditNodeModalService";
import IEditNodeService from "@components/SequentialFlowEditor/services/interfaces/IEditNodeService";
import { StateLinkNodeData } from "@components/SequentialFlowEditor/types";
import { Dispatch, ReactElement } from "react";
import { Node } from "reactflow";
import { AppReducerAction } from "state/reducers/appReducer";
import { ModalReducerAction } from "state/reducers/modalReducer";
import { EditStateLinkNodeDataModalType } from "../enums/EditStateLinkNodeDataModalType";
import EditStateLinkNodeDataModal from "../modals/EditStateLinkNodeDataModal";
import AddNodeModalService from "@components/SequentialFlowEditor/services/interfaces/AddNodeModalService";

export interface IEditStateLinkNodeDataModalService
  extends EditNodeModalService<StateLinkNodeData>,
    AddNodeModalService<StateLinkNodeData> {}

export default class EditStateLinkNodeDataModalService
  implements IEditStateLinkNodeDataModalService
{
  private appDispatch: Dispatch<AppReducerAction>;
  private modalDispatch: Dispatch<ModalReducerAction>;
  private editNodeService: IEditNodeService;

  constructor(
    appDispatch: Dispatch<AppReducerAction>,
    modalDispatch: Dispatch<ModalReducerAction>,
    editNodeService: IEditNodeService
  ) {
    this.appDispatch = appDispatch;
    this.modalDispatch = modalDispatch;
    this.editNodeService = editNodeService;
  }

  public openAddModal(node: Node<StateLinkNodeData>): void {
    this.modalDispatch({
      type: "show",
      content: this.getModalContent(
        EditStateLinkNodeDataModalType.Add,
        node.data,
        (data: StateLinkNodeData) => this.saveOnAdd(node, data)
      ),
    });
  }

  public openEditModal(nodeId: string, nodeData: StateLinkNodeData): void {
    this.modalDispatch({
      type: "show",
      content: this.getModalContent(
        EditStateLinkNodeDataModalType.Edit,
        nodeData,
        (data: StateLinkNodeData) => this.saveOnEdit(nodeId, data)
      ),
    });
  }

  private getModalContent(
    type: EditStateLinkNodeDataModalType,
    data: StateLinkNodeData,
    onSaveCallback: (data: StateLinkNodeData) => void
  ): ReactElement {
    return (
      <EditStateLinkNodeDataModal
        type={type}
        data={data}
        onSaveCallback={(data: StateLinkNodeData) => {
          onSaveCallback(data);
          this.modalDispatch({ type: "close" });
        }}
        onCancelCallback={() => this.modalDispatch({ type: "close" })}
      />
    );
  }

  private saveOnAdd(node: Node, data: StateLinkNodeData): void {
    const nodeToAdd: Node<StateLinkNodeData> = {
      ...node,
      data: {
        ...data,
      },
    };
    this.appDispatch({ type: "add-node", node: nodeToAdd });
  }

  private saveOnEdit(nodeId: string, data: StateLinkNodeData): void {
    this.editNodeService.editNode(nodeId, data);
  }
}
