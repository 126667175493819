import { ReactElement, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";

import "./../node_modules/@umetrics/sartorius-ui-style/dist/css/variables.css";
import "./../node_modules/@umetrics/sartorius-ui-style/dist/css/typography.css";

import "./App.css";
import { Header } from "@umetrics/sartorius-ui-header";
import { Menu, MenuItem } from "@umetrics/sartorius-ui-menu";
import { Button } from "@umetrics/sartorius-ui-button";
import { Icon } from "@umetrics/sartorius-ui-icon";
import { PanelTemplate } from "@umetrics/sartorius-ui-panel-template";
import { Breadcrumb } from "@umetrics/sartorius-ui-breadcrumb";
import { TextField } from "@umetrics/sartorius-ui-text-field";
import { TextArea } from "@umetrics/sartorius-ui-text-area";
import { Table } from "@umetrics/sartorius-ui-table";
import { Spinner } from "@umetrics/sartorius-ui-spinner";
import { Checkbox, CheckboxGroup } from "@umetrics/sartorius-ui-checkbox";
import { FileSelector } from "@umetrics/sartorius-ui-file-selector";
import { List, ListItem } from "@umetrics/sartorius-ui-list";
import { Tabs, Tab } from "@umetrics/sartorius-ui-tabs";
import { Tree } from "@umetrics/sartorius-ui-tree";
import {
  Select,
  SelectOption,
  SelectGroup,
} from "@umetrics/sartorius-ui-select";
import { Modal } from "@umetrics/sartorius-ui-modal";
import { Accordion, AccordionItem } from "@umetrics/sartorius-ui-accordion";
import { Collapse } from "@umetrics/sartorius-ui-collapse";
import "./App.css";
import "./../node_modules/@umetrics/sartorius-ui-style/dist/css/style.css";
import { appReducer, initializer } from "./state/reducers/appReducer";
import {
  AppDispatchContext,
  AppStateContext,
  ModalContext,
} from "./state/Context";
import { modalReducer, initialModalState } from "./state/reducers/modalReducer";
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { InteractionStatus } from "@azure/msal-browser";
import { FormSegment } from "@umetrics/sartorius-ui-form-segment";
import { Divider } from "@umetrics/sartorius-ui-divider";
import { Scrollbar } from "@umetrics/sartorius-ui-scrollbar";
import { FeedbackMessage } from "@umetrics/sartorius-ui-feedback-message";
import { Coder } from "@umetrics/sartorius-ui-coder";
import { Error as SuiError } from "@umetrics/sartorius-ui-error";
import { useErrorBoundary } from "react-error-boundary";
import AppLayout from "layouts/AppLayout";
import { initialState } from "state/utils";

Accordion.register("");
AccordionItem.register("");
Header.register("");
Menu.register("");
MenuItem.register("");
Button.register("");
Icon.register("");
PanelTemplate.register("");
Breadcrumb.register("");
TextField.register("");
TextArea.register("");
Table.registerTable("");
Spinner.register("");
Checkbox.register("");
CheckboxGroup.register("");
FileSelector.register("");
List.register("");
ListItem.register("");
Select.register("");
SelectOption.register("");
SelectGroup.register("");
Modal.register("");
Tabs.register("");
Tab.register("");
Tree.register("");
FormSegment.register("");
Divider.register("");
Scrollbar.register("");
FeedbackMessage.register("");
Coder.register("");
Collapse.register("");
SuiError.register("");

export interface AppRoute {
  path: string;
  label: string;
  element: ReactElement;
}

function App() {
  const [state, dispatch] = useReducer(appReducer, initialState, initializer);
  const [modalState, dispatchModal] = useReducer(
    modalReducer,
    initialModalState
  );
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch((e) => {
        showBoundary({ error: e, errorCode: "401" });
      });
    }
  }, [instance, isAuthenticated, inProgress, showBoundary]);

  useEffect(() => {
    localStorage.setItem(
      "localState",
      JSON.stringify({
        specification: state.specificationService?.Specification,
        nodesAndEdges: state.nodesAndEdges,
        selectedState: state.selectedState,
      })
    );
  }, [state]);

  useEffect(() => {
    dispatchModal({
      type: "set-on-close",
      func: () => dispatchModal({ type: "close" }),
    });
  }, []);

  return (
    <AuthenticatedTemplate>
      <AppStateContext.Provider value={state}>
        <AppDispatchContext.Provider value={dispatch}>
          <ModalContext.Provider
            value={{ state: modalState, dispatch: dispatchModal }}
          >
            <AppLayout>
              <Outlet />
            </AppLayout>
          </ModalContext.Provider>
        </AppDispatchContext.Provider>
      </AppStateContext.Provider>
    </AuthenticatedTemplate>
  );
}

export default App;

