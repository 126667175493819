import { DescriptionValueInput, FormGrid, FormTab } from "@form-components";
import { Parameter } from "Asset_Specification";
import { Path } from "react-hook-form";

const displayFormatNames: Path<Parameter>[] = [
  "displayFormat.@description",
  "displayFormat.@value",
];

const DisplayFormatTab = () => {
  return (
    <FormTab heading="Display Format" names={displayFormatNames}>
      <FormGrid>
        <DescriptionValueInput name="displayFormat" />
      </FormGrid>
    </FormTab>
  );
};

export default DisplayFormatTab;
