import { FormatTemplate } from "../FormatTemplate";
import faceplateElementPropertyTemplate from "./faceplateElementPropertyTemplate";
import validationRuleListTemplate from "./validationRuleListTemplate";

const faceplateElementTemplate: FormatTemplate = {
  property: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: faceplateElementPropertyTemplate,
  },
  valueValidation: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: validationRuleListTemplate,
  },
  "@controlType": {
    type: "string",
    canBeUndefined: false,
  },
  "@parameterId": {
    type: "string",
    canBeUndefined: false,
  },
};

export default faceplateElementTemplate;
