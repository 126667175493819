import { Node, XYPosition } from "reactflow";
import { Step } from "../types";
import {
  BranchingNodeData,
  CaseNodeData,
  MessageNodeData,
  NodeData,
  NodeType,
  StepNodeData,
  StateLinkNodeData,
} from "../components/SequentialFlowEditor/types";
import {
  ActionOperatorMessage,
  Branching,
  Case,
  OperatorMessageDefinition,
} from "../Asset_Specification";
import { toArray } from "../utils";

export const ySpaceBetweenStartAndStep: number = 100;
export const ySpaceBetweenSteps: number = 200;
export const ySpaceBetweenStepAndBranch: number = 150;
export const yOffsetForOperatorMessage: number = -15;

export const xOffsetForOperatorMessage: number = 150;

export const caseXInitialOffset: number = 10;
export const caseXOffset: number = 100 + xOffsetForOperatorMessage;
export const caseYInitialOffset: number = 35;
export const defaultCaseNodeWidth: number = 72;

export const unconnectedNodeXPosition: number = -200;

export const defaultNodePosition: XYPosition = { x: 0, y: 0 };

export interface BranchingNodes {
  branchingNode: Node<BranchingNodeData>;
  caseNodes: Node<CaseNodeData>[];
}

export const createNewNode = (
  id: string,
  type: NodeType,
  position?: XYPosition
): Node => {
  return {
    id,
    position: position ?? { ...defaultNodePosition },
    type,
    data: {},
  };
};

export const createStepNode = (
  id: string,
  step: Step,
  position: XYPosition | undefined
): Node<StepNodeData> => {
  return {
    id: id,
    type: NodeType.step,
    position: position ? { ...position } : { ...defaultNodePosition },
    data: { step: step },
  };
};

export const createStartNode = (
  id: string,
  stateName: string
): Node<NodeData> => {
  return {
    id: id,
    type: NodeType.start,
    position: { ...defaultNodePosition },
    data: { label: stateName },
  };
};

export const createMessageNode = (
  id: string,
  stepNode: Node<StepNodeData>,
  actionOperatorMessage: ActionOperatorMessage,
  messageDefinition: OperatorMessageDefinition
): Node<MessageNodeData> => {
  return {
    id: id,
    position: {
      y: stepNode.position.y + yOffsetForOperatorMessage,
      x: stepNode.position.x + xOffsetForOperatorMessage,
    },
    data: {
      actionMessage: { ...actionOperatorMessage },
      messageDefinition: { ...messageDefinition },
      stepNodeId: stepNode.id,
    },
    type: NodeType.message,
  };
};

export const createCaseNode = (
  branchingNodeId: string,
  caseValue: Case,
  allNodes: Node[]
): Node<CaseNodeData> => {
  const caseNodesInBranching = allNodes.filter(
    (node) => node.parentNode === branchingNodeId
  ).length;
  return {
    id: createNewNodeId(allNodes).toString(),
    position: {
      x: caseXInitialOffset + caseXOffset * caseNodesInBranching,
      y: caseYInitialOffset,
    },
    type: NodeType.case,
    data: {
      case: caseValue,
      branchingNodeId: branchingNodeId,
    },
    parentNode: branchingNodeId,
    extent: "parent",
    draggable: false,
    width: defaultCaseNodeWidth,
  };
};

export const createCaseNodes = (
  branchingNodeId: string,
  cases: Case[],
  allNodes?: Node[]
): Node<CaseNodeData>[] => {
  const newNodes: Node<CaseNodeData>[] = [];
  for (const branchingCase of cases) {
    const node: Node<CaseNodeData> = createCaseNode(
      branchingNodeId,
      branchingCase,
      [...(allNodes ? allNodes : []), ...newNodes]
    );

    newNodes.push(node);
  }

  return newNodes;
};

export const createBranchingNode = (
  stepNode: Node<StepNodeData>,
  transitionORStepId: string | undefined,
  allNodes: Node[]
): Node<BranchingNodeData> => {
  const position: XYPosition = {
    x: stepNode.position.x,
    y: stepNode.position.y + ySpaceBetweenStepAndBranch,
  };
  return createBranchingNodeWithPosition(
    createNewNodeId(allNodes).toString(),
    position,
    stepNode.id,
    transitionORStepId
  );
};

export const createBranchingNodeWithPosition = (
  id: string,
  position: XYPosition | undefined,
  stepNodeId?: string,
  transitionORStepId?: string
): Node<BranchingNodeData> => ({
  id,
  position: position
    ? {
        ...position,
      }
    : { ...defaultNodePosition },
  data: {
    transitionORStepId,
    stepNodeId,
    caseNodeIds: [],
  },
  type: NodeType.branching,
});

export const createBranchingAndCaseNodes = (
  stepNode: Node<StepNodeData>,
  branching: Branching,
  allNodes: Node[]
): BranchingNodes => {
  const branchingNode: Node<BranchingNodeData> = createBranchingNode(
    stepNode,
    branching["@transitionORStepId"],
    allNodes
  );
  const caseNodes = createCaseNodes(branchingNode.id, toArray(branching.case), [
    ...allNodes,
    branchingNode,
  ]);

  branchingNode.data.caseNodeIds = caseNodes.map((node) => node.id);

  return {
    branchingNode,
    caseNodes,
  };
};

export const createNewNodeId = (nodes: Node[]): number => {
  return nodes.length < 1
    ? 1
    : Math.max(...nodes.map((node) => Number(node.id))) + 1;
};

export const createStateLinkNode = (
  id: string,
  position?: XYPosition,
  substateId?: string,
  state?: string
): Node<StateLinkNodeData> => {
  const node: Node = createNewNode(id, NodeType.stateLink, position);
  const data: StateLinkNodeData = {
    substateId: substateId ?? "",
    state: state ?? "",
  };
  node.data = data;
  return node;
};

export const getAbsoluteCaseNodePosition = (
  caseNodeRelativePosition: XYPosition,
  parentBranchNodePosition: XYPosition
): XYPosition => {
  return {
    x:
      caseNodeRelativePosition.x +
      parentBranchNodePosition.x -
      caseXInitialOffset,
    y: caseNodeRelativePosition.y + parentBranchNodePosition.y,
  };
};
