import { FormatTemplate } from "../FormatTemplate";
import conditionTemplate from "./conditionTemplate";

const faceplateElementPropertyTemplate: FormatTemplate = {
  condition: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: conditionTemplate,
  },
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
};

export default faceplateElementPropertyTemplate;
