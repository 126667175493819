/**
 * Returns the label and value to use for a particular select option, returned as a object.
 * @param option the select option
 * @returns The label and value for the select option
 */
export const getLabelAndValueForOption = (
  option: string | { label: string; value: string }
): { label: string; value: string } => {
  return typeof option === "string" ? { label: option, value: option } : option;
};
