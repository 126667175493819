import useSpecificationStateNames from "@components/SequentialFlowEditor/hooks/useSpecificationStateNames";
import { useContext, useMemo } from "react";
import { AppStateContext } from "state/Context";

interface UseStateOptionsReturn {
  stateOptions: string[];
}

const useStateOptions = (): UseStateOptionsReturn => {
  const { specificationService } = useContext(AppStateContext);
  const currentState: string | undefined =
    specificationService?.getSelectedStateName();
  const allStateOptions: string[] = useSpecificationStateNames();
  const stateOptions: string[] = useMemo(
    () =>
      allStateOptions.filter(
        (stateOption: string) => stateOption !== currentState
      ),
    [allStateOptions, currentState]
  );
  return { stateOptions };
};

export default useStateOptions;
