import { DescriptionValueInput, FormGrid, FormTab } from "@form-components";
import { Parameter } from "Asset_Specification";
import { Path } from "react-hook-form";

const engineeringUnitNames: Path<Parameter>[] = [
  "engineeringUnit.@description",
  "engineeringUnit.@value",
];

const EngineeringUnitTab = () => {
  return (
    <FormTab heading="Engineering Unit" names={engineeringUnitNames}>
      <FormGrid>
        <DescriptionValueInput name="engineeringUnit" />
      </FormGrid>
    </FormTab>
  );
};

export default EngineeringUnitTab;
