import { isEmptyOrWhitespace } from "../../utils/stringUtils";
import { SuiTextField } from "../SuiTextField";
import { AdditionalRegexpInputProps } from "./InputProps";
import {
  alphanumericRegexpCharacters,
  alphanumericRegexpValidationMessage,
  getRegexpFromCharacters,
  validateRegexInput,
} from "./inputUtils";

const AlphanumericInput = (props: AdditionalRegexpInputProps) => {
  const {
    onChangeCallback,
    required,
    additionalCharactersRegex,
    additionalErrorMessage,
    maxLength,
    ...remainingProps
  } = { ...props };
  const regexp = getRegexpFromCharacters(
    `${alphanumericRegexpCharacters}${additionalCharactersRegex ?? ""}`
  );

  const onInput = ({ detail: { value } }: any) => {
    let valid = validateRegexInput(value, regexp);
    if (required && isEmptyOrWhitespace(value)) {
      valid = false;
    }

    onChangeCallback?.(value, valid);
  };

  return (
    <SuiTextField
      {...remainingProps}
      onInput={onInput}
      required={required}
      regexp={regexp}
      maxLength={maxLength}
    >
      <span slot="validation-empty">This is a mandatory field!</span>
      <span slot="validation-custom">{`${alphanumericRegexpValidationMessage} ${
        additionalErrorMessage ?? ""
      }`}</span>
    </SuiTextField>
  );
};

export default AlphanumericInput;
