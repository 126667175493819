import { inequalities } from "@utils/operatorUtils";
import { ConditionOperator } from "Asset_Specification";

export interface OperatorOption {
  label: string;
  value: ConditionOperator;
  multiOpCondition: boolean;
}

const operatorOptions: OperatorOption[] = [
  {
    label: "AND (&)",
    value: "and",
    multiOpCondition: false,
  },
  {
    label: "OR (\u2228)",
    value: "or",
    multiOpCondition: false,
  },
  {
    label: "true",
    value: "true",
    multiOpCondition: false,
  },
  {
    label: "false",
    value: "false",
    multiOpCondition: false,
  },
  { label: "OM", value: "OM", multiOpCondition: false },
  {
    label: "snapshot",
    value: "snapshot",
    multiOpCondition: false,
  },
  {
    label: "steptimer",
    value: "steptimer",
    multiOpCondition: false,
  },
  {
    label: "valueChange",
    value: "valueChange",
    multiOpCondition: false,
  },
  {
    label: "stepId",
    value: "stepId",
    multiOpCondition: false,
  },
  {
    label: "= (eq)",
    value: "eq",
    multiOpCondition: true,
  },
  {
    label: "≠ (ne)",
    value: "ne",
    multiOpCondition: true,
  },
  ...inequalities.map(({ label, value }) => ({
    label,
    value: value as ConditionOperator,
    multiOpCondition: true,
  })),
];

export const getOperatorLabel = (logicalOperator: "and" | "or"): string => {
  return logicalOperator === "and" ? "&" : "\u2228";
};

export const getOperatorOptions = (
  operatorsToExclude?: ConditionOperator[]
): OperatorOption[] => {
  const excludedOperators: ConditionOperator[] = operatorsToExclude ?? [];
  return operatorOptions.filter(
    (operatorOption: OperatorOption) =>
      !excludedOperators.some(
        (excludedOperator: ConditionOperator) =>
          operatorOption.value === excludedOperator
      )
  );
};
