import { FormatTemplate } from "../FormatTemplate";

const descriptionValueTemplate: FormatTemplate = {
  "@description": {
    type: "string",
    canBeUndefined: false,
  },
  "@value": {
    type: "string",
    canBeUndefined: false,
  },
};

export default descriptionValueTemplate;
