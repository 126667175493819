import { FormatTemplate } from "../FormatTemplate";
import caseTemplate from "./caseTemplate";

const branchingTemplate: FormatTemplate = {
  case: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: caseTemplate,
  },
  "@transitionORStepId": {
    type: "string",
    canBeUndefined: true,
  },
};

export default branchingTemplate;
