import { Handle, NodeProps, Position } from "reactflow";
import "./CaseNode.css";
import "../BranchingNode/BranchingNode.css";
import { CaseNodeData } from "../../../types";
import { HandleTypes } from "../../../utils/types";
import NodeButtonContainer from "../../NodeButtonContainer/NodeButtonContainer";
import useEditCaseNodeDataModal from "./hooks/useEditCaseNodeDataModal";
import { useCallback } from "react";
import { formatConditionToHighLevelLabel } from "../../../../../utils/conditionLabelUtils";

const CaseNode = (props: NodeProps<CaseNodeData>) => {
  const { id, data } = { ...props };
  const editModalService = useEditCaseNodeDataModal();

  const editCallback = useCallback(
    () => editModalService.openEditModal(id, data),
    [id, data, editModalService]
  );

  return (
    <div className="case-node-container">
      <p className="branching-node-label">
        {formatConditionToHighLevelLabel(data.case.condition)}
      </p>
      <NodeButtonContainer
        className="case-node-button-container"
        onEditCallback={editCallback}
        nodeId={id}
      />
      <Handle
        position={Position.Bottom}
        type="source"
        id={HandleTypes.branchingCaseSource}
      />
    </div>
  );
};

export default CaseNode;
