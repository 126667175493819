import { useCallback, useContext, useEffect, useState } from "react";
import {
  AppDispatchContext,
  AppStateContext,
  ModalContext,
} from "../../state/Context";
import { Parameter } from "../../Asset_Specification";
import EditParametersModal from "./Modal/EditParameterModal";
import EditList from "../EditList/EditList";
import {
  onDeleteItemCallback,
  onSaveItemCallback,
} from "../EditList/utils/editListUtils";

const addFormHeading = "Add Parameter";
const editFormHeading = "Edit Parameter";
const parameterUniqueKey = "@id";

const EditParameters = () => {
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const dispatch = useContext(AppDispatchContext);
  const { specificationService } = useContext(AppStateContext);
  const [parameters, setParameters] = useState<{ [type: string]: Parameter }>(
    {}
  );

  const getParametersFromService = useCallback((): {
    [type: string]: Parameter;
  } => {
    return specificationService?.getParameters() ?? {};
  }, [specificationService]);

  const setParametersCallback = (newParameters: {
    [type: string]: Parameter;
  }) => {
    setParameters(newParameters);
    dispatch({
      type: "set-parameters",
      parameters: Object.values(newParameters),
    });
  };

  const openEditParametersModal = (
    heading: string,
    parameterEntry?: [string, Parameter]
  ) => {
    dispatchModal({
      type: "show",
      content: (
        <EditParametersModal
          modalHeading={heading}
          onSaveItemCallback={(parameter: Parameter, parameterKey?: string) =>
            onSaveParameter(parameter, parameterKey)
          }
          onCancelCallback={() => dispatchModal({ type: "close" })}
          itemEntry={parameterEntry}
        />
      ),
    });
  };

  const onSaveParameter = (parameter: Parameter, parameterKey?: string) => {
    onSaveItemCallback(
      parameters,
      parameter,
      setParametersCallback,
      dispatchModal,
      parameterKey
    );
  };

  useEffect(() => {
    setParameters(getParametersFromService);
  }, [getParametersFromService]);

  return (
    <EditList
      itemDictionary={parameters}
      heading={"Parameters"}
      getItemLabel={(item) => (item as Parameter)[parameterUniqueKey]}
      onAddItemButtonClick={() => openEditParametersModal(addFormHeading)}
      onEditItemCallback={(parameterId) =>
        openEditParametersModal(editFormHeading, [
          parameterId,
          parameters[parameterId],
        ])
      }
      onDeleteItemCallback={(parameterId) =>
        onDeleteItemCallback(parameters, parameterId, setParametersCallback)
      }
    />
  );
};

export default EditParameters;
