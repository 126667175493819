import { FormatTemplate } from "../FormatTemplate";
import conditionTemplate from "./conditionTemplate";
import subStateTemplate from "./subStateTemplate";

const isa88StateTemplate: FormatTemplate = {
  condition: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: conditionTemplate,
  },
  subState: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: subStateTemplate,
  },
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@description": {
    type: "string",
    canBeUndefined: false,
  },
};

export default isa88StateTemplate;
