import { Path, UseFormGetValues } from "react-hook-form";
import { SubState } from "Asset_Specification";
import {
  AccordionInput,
  ActionOperatingSchemeCommandInput,
  FormGrid,
} from "@form-components";
import { defaultActionOperatingSchemeCommand } from "@utils/actionOperatingSchemeCommandUtils";

export const actionOperatingSchemeCommandName: Path<SubState> =
  "actionOperatingSchemeCommand";

const ActionOperatingSchemeCommandTabContent = () => {
  return (
    <FormGrid>
      <AccordionInput<SubState, "actionOperatingSchemeCommand">
        heading="Action Operating Scheme Commands"
        name={actionOperatingSchemeCommandName}
        defaultValue={{ ...defaultActionOperatingSchemeCommand }}
        getItemLabel={(index: number, getValues: UseFormGetValues<SubState>) =>
          getValues(
            `${actionOperatingSchemeCommandName}.${index}.@controlModuleId`
          ) ?? ""
        }
        itemRenderer={({ itemKey, name }) => (
          <ActionOperatingSchemeCommandInput<SubState>
            key={itemKey}
            name={name}
          />
        )}
      />
    </FormGrid>
  );
};

export default ActionOperatingSchemeCommandTabContent;
