import { MutableRefObject, ReactElement, useEffect, useRef } from "react";
import {
  ArrayPath,
  FieldArray,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
} from "react-hook-form";
import { ConditionInputEntryProps } from "./ConditionInputEntry";
import { ArrayInputItemProps } from "../../../array/ArrayInput";
import useArrayInput from "../../../array/hooks/useArrayInput";
import AddConditionButton from "./buttons/AddConditionButton";
import { defaultCondition } from "@utils/conditionUtils";
import DeleteConditionButton from "./buttons/DeleteConditionButton";
import { Condition } from "Asset_Specification";
import { toArray } from "utils";
import SelectConditionOperator from "./inputs/SelectConditionOperator";
import ConditionInputGroupItem from "./ConditionInputGroupItem";

/**
 * Defines the properties of the {@link ConditionInputGroup} component
 */
export interface ConditionInputGroupProps<TInputs extends FieldValues>
  extends ConditionInputEntryProps<TInputs> {
  logicalOperator: "and" | "or";
}

const minNumberOfConditions: number = 2;

/**
 * The component representing a group of conditions.
 * @param props the properties to inject into the component
 * @returns {ReactElement<ConditionInputGroupProps<Inputs>>}
 */
export default function ConditionInputGroup<TInputs extends FieldValues>({
  name,
  disabled,
  onDelete,
  deleteBtnDisabled,
  logicalOperator,
  operatorsToExclude,
}: ConditionInputGroupProps<TInputs>): ReactElement<
  ConditionInputGroupProps<TInputs>
> {
  const { setValue, watch } = useFormContext<TInputs>();
  const condition: Condition = watch(name);
  const firstRender: MutableRefObject<boolean> = useRef(true);
  const { arrayInput, count, append, remove } = useArrayInput({
    name: `${name}.condition` as ArrayPath<TInputs>,
    itemRenderer: ({
      itemKey,
      name,
      index,
    }: ArrayInputItemProps<TInputs, ArrayPath<TInputs>>) => (
      <ConditionInputGroupItem
        key={itemKey}
        index={index}
        count={count}
        name={name as Path<TInputs>}
        logicalOperator={logicalOperator}
        disabled={disabled}
        deleteBtnDisabled={count < 3}
        onDelete={() => remove(index)}
        operatorsToExclude={operatorsToExclude}
      />
    ),
  });

  useEffect(() => {
    if (!firstRender.current) {
      return;
    }

    firstRender.current = false;
    const conditionsInGroup: Condition[] = toArray(condition.condition);
    const missingNumberOfConditions: number =
      minNumberOfConditions - conditionsInGroup.length;
    for (let i = 0; i < missingNumberOfConditions; i++) {
      conditionsInGroup.push({ ...defaultCondition });
    }

    const newCondition: Condition = {
      "@operator": condition["@operator"],
    };
    setValue(name, newCondition as PathValue<TInputs, Path<TInputs>>);
    setValue(
      `${name}.condition` as Path<TInputs>,
      conditionsInGroup as PathValue<TInputs, Path<TInputs>>
    );
  }, [condition, name, setValue]);

  return (
    <div className="condition-group-condition">
      <div className="condition-group-toolbar">
        <div className="condition-group-toolbar-operator">
          <SelectConditionOperator
            name={name}
            operatorsToExclude={operatorsToExclude}
          />
        </div>

        <div className="condition-group-delete-button-container">
          <DeleteConditionButton
            onDelete={onDelete}
            disabled={disabled || deleteBtnDisabled}
          />
        </div>
      </div>
      <div className="condition-group-items">{arrayInput}</div>
      <div className="condition-group-add-button-container">
        <AddConditionButton
          onAdd={() =>
            append({ ...defaultCondition } as FieldArray<
              TInputs,
              ArrayPath<TInputs>
            >)
          }
          disabled={disabled}
        />
      </div>
    </div>
  );
}
