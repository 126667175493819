import { FormatTemplate } from "../FormatTemplate";
import startHoldSuspendTemplate from "./startHoldSuspendTemplate";

const holdConditionsTemplate: FormatTemplate = {
  holdCondition: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: startHoldSuspendTemplate,
  },
};

export default holdConditionsTemplate;
