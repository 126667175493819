import { CoderProperties } from "@umetrics/sartorius-ui-coder";
import { CommonInputProps } from "../InputProps";
import { SuiCoder } from "../../SuiCoder";
import { isUndefinedOrEmptyOrWhitespace } from "../../../utils/stringUtils";
import "./CoderInput.css";
import { SuiFeedbackMessage } from "../../SuiFeedbackMessage";
import { FeedbackMessageType } from "@umetrics/sartorius-ui-feedback-message";
import {
  replaceNewLineCharsForInput,
  replaceNewLineCharsForOutput,
} from "./coderInputUtils";
import { useState } from "react";
import InputLabel from "../InputLabel/InputLabel";

const validationMessage: string = "This is mandatory field!";

interface CoderInputProps
  extends CommonInputProps<string>,
    Omit<CoderProperties, "onCodeUpdated" | "indent"> {
  required?: boolean;
  label?: string;
  className?: string;
}

const CoderInput = (props: CoderInputProps) => {
  const {
    id,
    code,
    label,
    onChangeCallback,
    required,
    className,
    ...remainingProps
  } = {
    ...props,
  };

  const [internalCode, setInternalCode] = useState<string>(
    () => replaceNewLineCharsForInput(code) ?? ""
  );
  const invalid = isUndefinedOrEmptyOrWhitespace(code) && required;

  const onCodeUpdated = ({ detail: { detail } }: any) => {
    const valid = !(required && isUndefinedOrEmptyOrWhitespace(detail));
    const newInternalCode = replaceNewLineCharsForOutput(detail) ?? "";
    setInternalCode(newInternalCode);
    onChangeCallback?.(newInternalCode, valid);
  };

  const getFullClassName = (): string => {
    let fullClassName = className ?? "";
    if (invalid) {
      fullClassName += " code-input-border-invalid";
    }

    return fullClassName;
  };

  return (
    <InputLabel label={label} required={required} htmlFor={id}>
      <>
        <SuiCoder
          id={id}
          className={getFullClassName()}
          code={internalCode}
          onCodeUpdated={onCodeUpdated}
          indent={"\t"}
          {...remainingProps}
        />
        {invalid && (
          <SuiFeedbackMessage feedbackType={FeedbackMessageType.Failure}>
            {validationMessage}
          </SuiFeedbackMessage>
        )}
      </>
    </InputLabel>
  );
};

export default CoderInput;
