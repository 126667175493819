import CollapseSegment from "@components/GenericInputs/CollapseSegment/CollapseSegment";
import {
  AnsiTextField,
  Checkbox,
  ColumnSpan,
  FormGrid,
  InputPropsWithoutRules,
  MultiLabelInput,
  OptionalInput,
  getStylesFromColumnSpan,
} from "@form-components";
import { defaultLabel } from "@utils/labelUtils";
import { ConditionOperator } from "Asset_Specification";
import {
  FieldError,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
} from "react-hook-form";
import DeleteConditionButton from "./buttons/DeleteConditionButton";
import SelectConditionOperator from "./inputs/SelectConditionOperator";
import {
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { OperatorOption } from "../utils/operatorOptions";

/**
 * Defines the properties for the {@link ConditionInputEntry} component.
 */
export interface ConditionInputNonGroupProps<TInputs extends FieldValues>
  extends Omit<
    InputPropsWithoutRules<TInputs>,
    "label" | "columnSpan" | "fullWidth"
  > {
  onDelete: () => void;
  deleteBtnDisabled?: boolean;
  operatorsToExclude?: ConditionOperator[];
  selectedOperatorOption: OperatorOption;
}

const columnSpan: ColumnSpan = {
  columnSpanDesktop: 1,
  columnSpanMobile: 1,
  columnSpanTablet: 1,
  gridColumnSpan: 1,
};

const textFieldColumnSpan: ColumnSpan = {
  columnSpanDesktop: 3,
  columnSpanMobile: 3,
  columnSpanTablet: 3,
  gridColumnSpan: 3,
};

/**
 * A component representing a single Condition in the specification. This is not for groups
 * of conditions.
 * @template TInputs the type of the form object
 * @param props the properties injected into the component
 * @returns {ReactElement<ConditionInputEntryProps<TInputs>>}
 */
export default function ConditionInputNonGroup<TInputs extends FieldValues>({
  name,
  disabled,
  onDelete,
  deleteBtnDisabled,
  operatorsToExclude,
  selectedOperatorOption,
}: ConditionInputNonGroupProps<TInputs>): ReactElement<
  ConditionInputNonGroupProps<TInputs>
> {
  const firstRender: MutableRefObject<boolean> = useRef<boolean>(true);
  const { watch, unregister, getFieldState, formState } =
    useFormContext<TInputs>();
  const labelName: Path<TInputs> = `${name}.label` as Path<TInputs>;
  const labelValue: PathValue<TInputs, Path<TInputs>> = watch(labelName);
  const [hasLabel, setHasLabel] = useState<boolean>(labelValue !== undefined);
  const labelHasError: FieldError | undefined = getFieldState(
    labelName,
    formState
  ).error;

  useEffect(() => {
    if (!firstRender.current) {
      return;
    }

    firstRender.current = false;
    unregister(`${name}.condition` as Path<TInputs>);
  }, [name, unregister]);

  return (
    <div className="condition-input-entry">
      <FormGrid>
        <AnsiTextField
          name={`${name}.@op1`}
          label="op1"
          disabled={disabled}
          fullWidth
          columnSpan={textFieldColumnSpan}
        />

        <SelectConditionOperator
          name={name}
          fullWidth
          columnSpan={columnSpan}
          operatorsToExclude={operatorsToExclude}
        />

        <div
          style={{
            visibility: selectedOperatorOption.multiOpCondition
              ? "visible"
              : "hidden",
            ...getStylesFromColumnSpan(textFieldColumnSpan),
          }}
        >
          <AnsiTextField
            name={`${name}.@op2`}
            label="op2"
            disabled={disabled || !selectedOperatorOption.multiOpCondition}
            excludeValueFromSubmissionOnDisabled
            fullWidth
          />
        </div>

        <AnsiTextField
          name={`${name}.@timer`}
          label="timer"
          disabled={disabled}
          fullWidth
          columnSpan={textFieldColumnSpan}
        />

        <div
          className="condition-input-entry-checkboxes"
          style={getStylesFromColumnSpan(columnSpan)}
        >
          <Checkbox
            name={`${name}.@once`}
            label="Once"
            disabled={disabled}
            columnSpan={columnSpan}
          />

          <Checkbox
            name={`${name}.@freeze`}
            label="Freeze"
            disabled={disabled}
            columnSpan={columnSpan}
          />
        </div>

        <div
          className="condition-input-entry-button"
          style={getStylesFromColumnSpan(columnSpan)}
        >
          <DeleteConditionButton
            disabled={disabled || deleteBtnDisabled}
            onDelete={onDelete}
          />
        </div>

        <OptionalInput
          name={labelName}
          defaultValue={[...defaultLabel] as PathValue<TInputs, Path<TInputs>>}
          checkboxProps={{ label: "Has Labels?" }}
          checked={hasLabel}
          onCheckedChangeCallback={setHasLabel}
        >
          <CollapseSegment
            buttonLabel="Labels"
            error={labelHasError !== undefined}
          >
            <div className="condition-input-entry-labels-container">
              <MultiLabelInput
                name={labelName}
                required // To ensure that a user doesn't leave the labels empty if they specify it should be set
                disabled={disabled}
              />
            </div>
          </CollapseSegment>
        </OptionalInput>
      </FormGrid>
    </div>
  );
}
