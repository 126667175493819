import { ReactElement } from "react";
import { Path, UseFormGetValues } from "react-hook-form";
import { ExchangeAsset } from "Asset_Specification";
import { AccordionInput, EnumerationInput, FormGrid } from "@form-components";
import { defaultEnumeration } from "@utils/enumerationUtils";

export const enumerationName: Path<ExchangeAsset> = "enumeration";

const EnumerationTabContent = (): ReactElement => {
  return (
    <FormGrid>
      <AccordionInput<ExchangeAsset, "enumeration">
        heading="Enumeration"
        name={enumerationName}
        defaultValue={{ ...defaultEnumeration }}
        getItemLabel={(index: number, _: UseFormGetValues<ExchangeAsset>) =>
          `Enumeration - ${index}`
        }
        itemRenderer={({ itemKey, name }) => (
          <EnumerationInput key={itemKey} name={name} />
        )}
      />
    </FormGrid>
  );
};

export default EnumerationTabContent;
