import { generateSimpleNonCryptoHash } from "./hashingUtils";
import { isRecordObject } from "./objectUtils";

export const generateChecksum = (value: any): number => {
  const combinedValue: string = serializeToString(value);
  return generateSimpleNonCryptoHash(combinedValue);
};

const serializeToString = (value: any): string => {
  if (Array.isArray(value)) {
    return JSON.stringify(value.map((element) => serializeToString(element)));
  }

  if (isRecordObject(value)) {
    return Object.keys(value)
      .map((key) => `${key}:${serializeToString(value[key])}`)
      .join("|");
  }

  return value;
};
