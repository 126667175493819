import { FallbackProps } from "react-error-boundary";
import { SuiError } from "../../components/SuiError";
import { SuiButton } from "../../components/SuiButton";
import CopyToClipboardButton from "../../components/CopyToClipboardButton/CopyToClipboardButton";
import "./ErrorPage.css";
import { ErrorSlots, asErrorWithCode } from "./utils/utils";

const ErrorPage = (props: FallbackProps) => {
  const { error, errorCode } = asErrorWithCode(props.error);

  return (
    <SuiError>
      <label slot={ErrorSlots.header}>Something went wrong!</label>
      {error && (
        <label
          slot={ErrorSlots.description}
        >{`${error.name}: ${error.message}\n `}</label>
      )}
      {error && <label slot={ErrorSlots.information}>{error.stack}</label>}
      <label slot={ErrorSlots.errorCode}>{errorCode}</label>
      <div slot={ErrorSlots.button} className="error-buttons-container">
        <SuiButton onClick={props.resetErrorBoundary}>Go back</SuiButton>
        <CopyToClipboardButton
          textToCopy={`${error.stack}${
            error.cause ? `\nCause: ${error.cause}` : ""
          }`}
        />
      </div>
    </SuiError>
  );
};

export default ErrorPage;
