import { ReactElement } from "react";
import { AccordionInput, FormGrid, ValueEnumInput } from "@form-components";
import { defaultValueEnum } from "@utils/valueEnumUtils";
import {
  ArrayPath,
  FieldArray,
  FieldValues,
  Path,
  UseFormGetValues,
  useFormContext,
} from "react-hook-form";
import { ValueEnum } from "Asset_Specification";

/**
 * Defines the properties for the {@link ValueEnumsInput} component.
 */
interface ValueEnumsInputProps<TInputs extends FieldValues> {
  name: ArrayPath<TInputs>;
}

function getExcludedValues(itemIndex: number, values: ValueEnum[]) {
  const valuesCopy = [...values].map((valueEnum) => valueEnum["@value"]);
  valuesCopy.splice(itemIndex, 1);

  return valuesCopy;
}

/**
 * The input component for specifying valueEnumsInput values in the substate.
 * @param props the properties to inject into the component
 * @returns {ReactElement<ValueEnumsInputProps<TInputs>> }
 */
function ValueEnumsInput<TInputs extends FieldValues>({
  name,
}: ValueEnumsInputProps<TInputs>): ReactElement<ValueEnumsInputProps<TInputs>> {
  const { getValues } = useFormContext();
  const valueEnums = getValues(name);

  return (
    <FormGrid>
      <AccordionInput<TInputs, ArrayPath<TInputs>>
        heading="Value Enums"
        name={name}
        defaultValue={
          { ...defaultValueEnum } as FieldArray<TInputs, ArrayPath<TInputs>>
        }
        getItemLabel={(index: number, getValues: UseFormGetValues<TInputs>) =>
          getValues(`${name}.${index}.@id` as Path<TInputs>)?.toString() ?? ""
        }
        itemRenderer={({ itemKey, name: fieldName, index }) => (
          <ValueEnumInput<TInputs>
            key={itemKey}
            name={fieldName as Path<TInputs>}
            excludedValues={getExcludedValues(index, valueEnums)}
          />
        )}
      />
    </FormGrid>
  );
}

export default ValueEnumsInput;
