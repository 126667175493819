import { ReactElement, useEffect, useState } from "react";
import { Path, useFormContext } from "react-hook-form";
import {
  FormGrid,
  MultiLabelInput,
  NumericField,
  RequiredIdTextField,
  RequiredNonEmptyNameTextField,
  Select,
  getStylesFromColumnSpan,
  threeColumnsSpan,
} from "@form-components";
import {
  messageButtons,
  messageTypes,
} from "../../../utils/operatorMessageUtils";
import useOperatorMessageDefinitionValidationRules from "../hooks/useOperatorMessageDefinitionValidationRules";
import { SuiButton } from "@components/SuiButton";
import { SuiIcon } from "@components/SuiIcon";
import editIcon from "@umetrics/sartorius-ui-icon/dist/icons/edit";
import closeIcon from "@umetrics/sartorius-ui-icon/dist/icons/cancel";
import "./DefinitionTabContent.css";
import SubstateIdsInput from "../inputs/SubstateIdsInput";
import { MessageNodeDataForm } from "../interfaces";

export const definitionName: Path<MessageNodeDataForm> = "messageDefinition";
const messageDefinitionIdName: Path<MessageNodeDataForm> = `${definitionName}.@id`;

interface DefinitionTabContentProps {
  defaultId: string | undefined;
}

const DefinitionTabContent = ({
  defaultId,
}: DefinitionTabContentProps): ReactElement<DefinitionTabContentProps> => {
  const { trigger } = useFormContext<MessageNodeDataForm>();
  const { existingOperatorMessageIds, validateOperatorMessageIdUniqueness } =
    useOperatorMessageDefinitionValidationRules({ defaultId });
  const [editId, setEditId] = useState<boolean>(false);

  useEffect(() => {
    trigger(messageDefinitionIdName);
  }, [editId, trigger]);

  return (
    <FormGrid>
      <div
        className="om-definition-id"
        style={getStylesFromColumnSpan(threeColumnsSpan)}
      >
        <div className="om-definition-id-control-wrapper">
          <RequiredIdTextField<MessageNodeDataForm>
            name={messageDefinitionIdName}
            rules={{
              validate: (id: string) =>
                validateOperatorMessageIdUniqueness(id) ||
                `Operator Message Id is not unique! Other ids include: ${existingOperatorMessageIds.join(
                  ", "
                )}`,
            }}
            fullWidth
            excludeValueFromSubmissionOnDisabled
            disabled={!editId}
          />
        </div>
        <div className="om-definition-edit-id-btn-wrapper">
          <SuiButton type="secondary" onClick={() => setEditId(!editId)}>
            <SuiIcon
              icon={!editId ? editIcon : closeIcon}
              slot="icon-slot"
              size="large"
            />
          </SuiButton>
        </div>
      </div>
      <RequiredNonEmptyNameTextField<MessageNodeDataForm>
        name={`${definitionName}.@name`}
        columnSpan={threeColumnsSpan}
        includeWhitespace
        fullWidth
      />
      <NumericField<MessageNodeDataForm>
        name={`${definitionName}.@messageNumber`}
        columnSpan={threeColumnsSpan}
        label="Message Number"
        rules={{
          min: { value: 0, message: "Must be greater or equal to 0!" },
          required: true,
        }}
        isInteger
        fullWidth
      />
      <NumericField<MessageNodeDataForm>
        name={`${definitionName}.@viewValues`}
        columnSpan={threeColumnsSpan}
        label="View Values"
        rules={{
          min: { value: 0, message: "Must be greater or equal to 0!" },
          required: true,
        }}
        isInteger
        fullWidth
      />
      <NumericField<MessageNodeDataForm>
        name={`${definitionName}.@presetValues`}
        columnSpan={threeColumnsSpan}
        label="Preset Values"
        rules={{
          min: { value: 0, message: "Must be greater or equal to 0!" },
          required: true,
        }}
        isInteger
        fullWidth
      />
      <Select<MessageNodeDataForm>
        name={`${definitionName}.@messageType`}
        columnSpan={threeColumnsSpan}
        label="Message Type"
        rules={{ required: true }}
        options={messageTypes}
        fixedDropdown
        fullWidth
      />
      <MultiLabelInput<MessageNodeDataForm>
        name={`${definitionName}.label`}
        required
      />
      <Select<MessageNodeDataForm>
        name={`${definitionName}.@buttonSet`}
        columnSpan={threeColumnsSpan}
        label="Button Set"
        rules={{ required: true }}
        options={messageButtons}
        fixedDropdown
        fullWidth
      />
      <SubstateIdsInput />
    </FormGrid>
  );
};

export default DefinitionTabContent;
