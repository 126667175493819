import { useCallback } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { MessageNodeData } from "../../../types";
import useEditOperatorMessageNodeDataModal from "./hooks/useEditOperatorMessageNodeDataModal";
import { HandleTypes } from "../../../utils/types";
import NodeButtonContainer from "../../NodeButtonContainer/NodeButtonContainer";
import "./MessageNode.css";
import EditNodeModalService from "../../../services/interfaces/EditNodeModalService";

function MessageNode(props: NodeProps<MessageNodeData>) {
  const operatorMessageModalService: EditNodeModalService<MessageNodeData> =
    useEditOperatorMessageNodeDataModal();

  const onEditCallback = useCallback(() => {
    operatorMessageModalService.openEditModal(props.id, props.data);
  }, [operatorMessageModalService, props.data, props.id]);

  return (
    <div className="message-node">
      <Handle
        position={Position.Left}
        type="target"
        id={HandleTypes.operatorMessage}
      />
      <p className="message-label-top">Operator message</p>
      <div className="message-content-bottom">
        <p className="message-label">{props.data.messageDefinition?.["@id"]}</p>
        <NodeButtonContainer
          className="message-node-button-container"
          nodeId={props.id}
          onEditCallback={onEditCallback}
        />
      </div>
    </div>
  );
}

export default MessageNode;
