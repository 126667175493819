import {
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import ArrayInput, { ArrayInputItemProps } from "../ArrayInput";
import { SuiAccordion, SuiAccordionItem } from "../../SuiAccordion";
import { SIcon, SuiIcon } from "../../SuiIcon";
import { SuiButton } from "../../SuiButton";
import { SuiScrollbar } from "../../SuiScrollbar";
import { isUndefinedOrEmptyOrWhitespace } from "../../../utils/stringUtils";
import deleteIcon from "@umetrics/sartorius-ui-icon/dist/icons/deleteAction";
import errorIcon from "@umetrics/sartorius-ui-icon/dist/icons/error";
import plus from "@umetrics/sartorius-ui-icon/dist/icons/add";
import { maybeTruncateLabel } from "./utils/accordionInputUtils";
import BaseAccordionInputProps from "./interfaces/BaseAccordionInputProps";
import "./AccordionInput.css";

/**
 * @deprecated use the equivalent from form-components
 */
interface AccordionInputProps<T> extends BaseAccordionInputProps<T> {
  hideAddButton?: boolean;
  maxLength?: number;
}

/**
 * @deprecated use the equivalent from form-components
 */
function AccordionInput<T>(
  props: AccordionInputProps<T> & { accordionInputRef?: Ref<unknown> }
) {
  const {
    id,
    items,
    defaultItemEntry,
    itemRenderer,
    getItemLabel,
    onChangeCallback,
    accordionInputRef,
    hideAddButton,
    maxLength,
  } = { ...props };

  const accordionRef = useRef<HTMLElement | null>(null);
  const openIndexRef = useRef<number | undefined>(undefined);

  const handleAccordionToggle = useCallback((event: Event) => {
    event.stopPropagation(); // Prevents a parent accordion from closing when event keeps bubbling upwards
    const item: any = event.target;
    const items: HTMLCollection = item.parentElement.children;
    const itemIndex = Array.from(items).indexOf(item);

    openIndexRef.current = openIndexRef.current !== itemIndex ? itemIndex : -1; // -1 effectively closes the accordion item
  }, []);

  const onAddItem = () => {
    if (defaultItemEntry === undefined) {
      return;
    }

    const itemsCopy = [...items, defaultItemEntry];
    openIndexRef.current = itemsCopy.length - 1;
    onChangeCallback(itemsCopy);
  };

  useImperativeHandle(accordionInputRef, () => ({
    onAddItem,
  }));

  useEffect(() => {
    const element = accordionRef.current;
    if (!element) {
      return;
    }

    element.addEventListener("accordion-toggle", handleAccordionToggle);
    return () =>
      element.removeEventListener("accordion-toggle", handleAccordionToggle);
  }, [handleAccordionToggle]);

  useEffect(() => {
    const accordionElement = accordionRef.current;
    if (
      openIndexRef.current === undefined ||
      openIndexRef.current < 0 ||
      accordionElement === null
    ) {
      return;
    }

    const accordionItems: Element[] = Array.from(accordionElement.children);
    if (accordionItems.length <= openIndexRef.current) {
      return;
    }

    accordionItems[openIndexRef.current].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  });

  return (
    <div className="ai-input-container">
      <SuiAccordion className="ai-accordion" singleOpen ref={accordionRef}>
        <ArrayInput<T>
          id={id}
          items={items}
          itemRenderer={({
            id,
            key,
            value,
            valid,
            onItemChangeCallback,
            onDeleteItem,
            index,
          }: ArrayInputItemProps<T>) => {
            const label = maybeTruncateLabel(getItemLabel(value, index));
            return (
              <SuiAccordionItem
                className={valid ? undefined : "input-error"}
                key={id}
                open={index === openIndexRef.current}
              >
                <span slot="label-slot">
                  {!valid && isUndefinedOrEmptyOrWhitespace(label) && (
                    <SIcon icon={errorIcon} />
                  )}
                  {label}
                </span>
                <SuiButton
                  type="tertiary"
                  compact
                  slot="action-slot"
                  onClick={onDeleteItem}
                >
                  <SIcon
                    className={valid ? undefined : "input-error"}
                    icon={deleteIcon}
                    slot="icon-slot"
                  />
                </SuiButton>
                <SuiScrollbar horiHide>
                  {itemRenderer(id, key, value, onItemChangeCallback, index)}
                </SuiScrollbar>
              </SuiAccordionItem>
            );
          }}
          onChangeCallback={onChangeCallback}
        />
      </SuiAccordion>
      {!hideAddButton && (
        <div className="ai-item-buttons">
          <SuiButton
            type="primary"
            onClick={onAddItem}
            disabled={
              defaultItemEntry === undefined ||
              (maxLength !== undefined && items.length >= maxLength)
            }
          >
            <SuiIcon icon={plus} />
          </SuiButton>
        </div>
      )}
    </div>
  );
}

export default AccordionInput;
