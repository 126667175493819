import {
  FaceplateElement,
  FaceplateElementControlType,
  FaceplateElementProperty,
} from "Asset_Specification";

export const controlTypes: FaceplateElementControlType[] = [
  "Dropdown",
  "LabelledInput",
  "ReadOnlyLabel",
  "RadioButton",
  "Checkbox",
  "LabelComment",
  "LabelledInputCombined",
];

export const defaultFaceplateElementProperty: FaceplateElementProperty = {
  condition: { "@operator": "true" },
  "@name": "Visibility",
};

export const defaultFaceplateElement: FaceplateElement = {
  "@controlType": "Dropdown",
  "@parameterId": "",
};

export const faceplateElementPropertyNames: (
  | "Visibility"
  | "ReadOnly"
  | "Editable"
)[] = ["Visibility", "ReadOnly", "Editable"];

export const getDefaultFaceplateElementProperty = (
  namesToExclude: string[]
): FaceplateElementProperty => {
  if (namesToExclude.length < 1) {
    return { ...defaultFaceplateElementProperty };
  }

  const nameToUse: "Visibility" | "ReadOnly" | "Editable" =
    faceplateElementPropertyNames.find(
      (name: string) => !namesToExclude.includes(name)
    ) ?? "Visibility";
  return { ...defaultFaceplateElementProperty, "@name": nameToUse };
};
