import {
  DescriptionValueEnhancedInput,
  FormGrid,
  FormTab,
} from "@form-components";
import { Parameter } from "Asset_Specification";
import { Path } from "react-hook-form";

const valueNames: Path<Parameter>[] = [
  "value.@defaultValue",
  "value.@description",
  "value.@max",
  "value.@min",
  "value.valueEnum",
];

const ValueTab = () => {
  return (
    <FormTab heading="Value" names={valueNames}>
      <FormGrid>
        <DescriptionValueEnhancedInput name="value" heading="Value" />
      </FormGrid>
    </FormTab>
  );
};

export default ValueTab;
