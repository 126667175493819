import { ReactElement } from "react";
import { SuiTab } from "../../SuiTabs";

/**
 * @deprecated use {@link ../../FormComponents/Tabs/FormTab} instead
 */
export interface FormTabProps {
  heading: string;
  children: ReactElement | ReactElement[];
  error?: boolean;
}

/**
 * @deprecated use {@link ../../FormComponents/Tabs/FormTab} instead
 */
const FormTab = (props: FormTabProps) => {
  const { heading, error, children } = { ...props };
  const internalHeading: string = !error ? heading : `${heading} \u26a0`;
  return (
    <SuiTab heading={internalHeading} error={error}>
      {children}
    </SuiTab>
  );
};

export default FormTab;
