import { ReactElement } from "react";
import {
  ArrayPath,
  FieldArray,
  FieldValues,
  Path,
  UseFormGetValues,
} from "react-hook-form";
import CollapseSegment from "@components/GenericInputs/CollapseSegment/CollapseSegment";
import FormGrid from "@components/form-components/grid/FormGrid";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import {
  AccordionInput,
  ConditionInput,
  SelectAssetId,
  SetOperatingSchemeCommandInput,
  SetParameterInput,
} from "@form-components";
import { defaultSetParameter } from "@utils/setParameterUtils";
import { defaultSetOperatingSchemeCommand } from "@utils/setOperatingSchemeCommandUtils";
import useError from "@components/form-components/hooks/useError";

/**
 * Defines the properties for the {@link ActionOperatingSchemeCommandInput} component
 */
interface ActionOperatingSchemeCommandInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {}

export default function ActionOperatingSchemeCommandInput<
  TInputs extends FieldValues,
>({
  disabled,
  columnSpan,
  name,
}: ActionOperatingSchemeCommandInputProps<TInputs>): ReactElement<
  ActionOperatingSchemeCommandInputProps<TInputs>
> {
  const conditionName: Path<TInputs> = `${name}.condition` as Path<TInputs>;
  const conditionError: boolean = useError({ names: conditionName });
  const setParameterName: Path<TInputs> =
    `${name}.setParameter` as Path<TInputs>;
  const setParameterError: boolean = useError({ names: setParameterName });
  const setOperatingSchemeCommandName: Path<TInputs> =
    `${name}.setOperatingSchemeCommand` as Path<TInputs>;
  const setOperatingSchemeCommandError: boolean = useError({
    names: setOperatingSchemeCommandName,
  });
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <SelectAssetId
        name={`${name}.@controlModuleId` as Path<TInputs>}
        label="Control Module Id"
        rules={{ required: true }}
        disabled={disabled}
        fixedDropdown
        fullWidth
      />

      <CollapseSegment buttonLabel="Condition" error={conditionError}>
        <ConditionInput
          name={conditionName}
          label="Condition"
          disabled={disabled}
        />
      </CollapseSegment>

      <CollapseSegment buttonLabel="Set Parameter" error={setParameterError}>
        <AccordionInput<TInputs, ArrayPath<TInputs>>
          heading="Set Parameters"
          name={setParameterName as ArrayPath<TInputs>}
          defaultValue={
            { ...defaultSetParameter } as FieldArray<
              TInputs,
              ArrayPath<TInputs>
            >
          }
          getItemLabel={(index: number, getValues: UseFormGetValues<TInputs>) =>
            getValues(
              `${setParameterName}.${index}.@parameter` as Path<TInputs>
            ) ?? ""
          }
          itemRenderer={({ itemKey, name }) => (
            <SetParameterInput
              key={itemKey}
              name={name as Path<TInputs>}
              disabled={disabled}
            />
          )}
        />
      </CollapseSegment>

      <CollapseSegment
        buttonLabel="Set Operating Command Scheme"
        error={setOperatingSchemeCommandError}
      >
        <AccordionInput<TInputs, ArrayPath<TInputs>>
          heading="Set Operating Scheme Commands"
          name={setOperatingSchemeCommandName as ArrayPath<TInputs>}
          defaultValue={
            { ...defaultSetOperatingSchemeCommand } as FieldArray<
              TInputs,
              ArrayPath<TInputs>
            >
          }
          getItemLabel={(index: number, getValues: UseFormGetValues<TInputs>) =>
            getValues(
              `${setOperatingSchemeCommandName}.${index}.@operatingSchemeCommand` as Path<TInputs>
            ) ?? ""
          }
          itemRenderer={({ itemKey, name }) => (
            <SetOperatingSchemeCommandInput
              key={itemKey}
              name={name as Path<TInputs>}
              disabled={disabled}
            />
          )}
        />
      </CollapseSegment>
    </FormGrid>
  );
}
