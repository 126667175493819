import { Condition, SubState } from "./Asset_Specification";
import { Node, Edge } from "reactflow";

export interface Step extends SubState {
  stepId: string;
}

export enum PhaseSubtype {
  Control = "Control",
  Process = "Process",
  Initialization = "Initialization",
  Calibration = "Calibration",
  System = "System",
  Structure = "Structure",
  Guidance = "Guidance",
}

export interface EPHInfo {
  name: string;
  subtype: PhaseSubtype;
  description: string;
}

export type TransitionCondition = {
  id: string;
  name: string;
  english: string;
  german: string;
  conditions?: Condition;
  transBackConditions?: Condition;
};

export type ConditionType = "START" | "HOLD" | "SUSPEND";

export type Operator =
  | "true"
  | "false"
  | "le"
  | "lt"
  | "ge"
  | "gt"
  | "eq"
  | "ne"
  | "OM"
  | "snapshot"
  | "steptimer"
  | "valueChange"
  | "stepId"
  | "and"
  | "or";

export const lang = {
  eng: "en_US.utf8" as "en_US.utf8",
  ger: "de_DE.utf8" as "de_DE.utf8",
};

export type Lang = "en_US.utf8" | "de_DE.utf8";

export enum FaceplateElementPropertyNameType {
  Visibility = "Visibility",
  ReadOnly = "ReadOnly",
  Editable = "Editable",
}

export type StateFlows = {
  [stateName: string]: { nodes: Node[]; edges: Edge[] };
};
