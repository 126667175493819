import { FormatTemplate } from "../FormatTemplate";

const mfcsTemplate: FormatTemplate = {
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@min": {
    type: "string",
    canBeUndefined: true,
  },
  "@max": {
    type: "string",
    canBeUndefined: true,
  },
};

export default mfcsTemplate;
