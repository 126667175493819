import { FormatTemplate } from "../FormatTemplate";

const docCalculationTemplate: FormatTemplate = {
  textBlock: {
    type: "array",
    canBeUndefined: true,
  },
};

export default docCalculationTemplate;
