import { Label } from "Asset_Specification";

export const defaultMultiLangValue: Label[] = [
  {
    "@lang": "en_US.utf8",
    "@value": "",
  },
  {
    "@lang": "de_DE.utf8",
    "@value": "",
  },
];
