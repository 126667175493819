import { v4 as uuid } from "uuid";
import { toArray } from "../../../../utils";
import { generateSimpleNonCryptoHash } from "../../../../utils/hashingUtils";

export const defaultTextBlockFormValue: {
  key: string;
  value: string;
  valid: boolean;
} = { key: uuid(), value: "", valid: false };

export const getTextBlockFromFormArray = (
  textBlockFormArray: {
    key: string;
    value: string;
    valid: boolean;
  }[]
): string | string[] | undefined => {
  const textBlockArray: string[] = textBlockFormArray.map(
    (textBlockForm) => textBlockForm.value
  );
  if (textBlockArray.length < 1) {
    return undefined;
  }

  return textBlockArray.length > 1 ? textBlockArray : textBlockArray[0];
};

export const getValidityFromTextBlockFormArray = (
  textBlockFormArray: {
    value: string;
    valid: boolean;
  }[]
): boolean => {
  return textBlockFormArray.every((textBlockForm) => textBlockForm.valid);
};

export const getTextBlockFormFromTextBlock = (
  textBlock: string | string[] | undefined
): {
  key: string;
  value: string;
  valid: boolean;
}[] => {
  return toArray(textBlock).map((textBlock) => ({
    key: uuid(),
    value: textBlock,
    valid: textBlock.length > 0,
  }));
};

export const generateTextBlockArrayChecksum = (
  textBlock: string | string[] | undefined
): number => {
  const combinedTextBlockValue: string = toArray(textBlock).reduce(
    (previousValue, currentValue) => `${previousValue}|${currentValue}`,
    ""
  );
  return generateSimpleNonCryptoHash(combinedTextBlockValue);
};

