import { reactifySartoriusUiComponent } from "@umetrics/sartorius-ui-reactify";
import {
  FileSelectorChangeEvent,
  FileSelectorProperties,
} from "@umetrics/sartorius-ui-file-selector";


export const SuiFileSelector = reactifySartoriusUiComponent<
  | FileSelectorProperties
  | { onFileSelectionChanged: (event: FileSelectorChangeEvent) => void }
>("sartorius-ui-file-selector");
