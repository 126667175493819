// REGULAR EXPRESSION DEFINITIONS //
export const ansiiRegexp: RegExp = /^[ -ÿ]+$/;
export const alphanumericWithSeparatorsRegexp: RegExp = /^[a-zA-Z0-9_-]+$/;
export const alphanumericWithSeparatorsAndWhitespaceRegexp: RegExp =
  /^[\sa-zA-Z0-9_-]+$/;

// REGULAR EXPRESSION ERROR MESSAGES //
export const ansiRegexpMessage: string =
  "Only characters in the ANSI set are allowed!";
export const alphanumericWithSeparatorsRegexpMessage: string =
  "Only alphanumeric (a-z A-z 0-9) and the special characters '_', '-' are allowed!";
export const alphanumericWithSeparatorsAndWhitespaceRegexpMessage: string =
  "Only alphanumeric (a-z A-z 0-9), whitespace and the special characters '_', '-' are allowed!";
