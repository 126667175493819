import { FormatTemplate } from "../FormatTemplate";
import omValueTemplate from "./omValueTemplate";

const presetValuesTemplate: FormatTemplate = {
  presetValue: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: omValueTemplate,
  },
};

export default presetValuesTemplate;
