import {
  FormGrid,
  InputPropsWithoutRules,
  RequiredIdTextField,
  SelectParameterId,
  twoColumnsSpan,
} from "@form-components";
import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";

/**
 * Defines the properties to inject into the {@link OMValueInput} component.
 */
interface OMValueInputProps<TInputs extends FieldValues>
  extends Omit<
    InputPropsWithoutRules<TInputs>,
    "columnSpan" | "label" | "fullWidth"
  > {
  fixedDropdown?: boolean;
}

/**
 * The react component for a OMValue specification input.
 * @param props the properties to inject into the component
 * @returns a {@link ReactElement} representing the OMValue.
 */
export default function OMValueInput<TInputs extends FieldValues>({
  disabled,
  name,
  fixedDropdown,
}: OMValueInputProps<TInputs>): ReactElement<OMValueInputProps<TInputs>> {
  return (
    <FormGrid>
      <RequiredIdTextField<TInputs>
        name={`${name}.@id` as Path<TInputs>}
        fullWidth
        columnSpan={twoColumnsSpan}
        disabled={disabled}
      />
      <SelectParameterId<TInputs>
        name={`${name}.@value` as Path<TInputs>}
        label="Value"
        fixedDropdown={fixedDropdown}
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
        columnSpan={twoColumnsSpan}
      />
    </FormGrid>
  );
}
