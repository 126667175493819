import { FormatTemplate } from "../FormatTemplate";
import awParameterTemplate from "./awParameterTemplate";
import conditionTemplate from "./conditionTemplate";

const actionAlarmingWarningTemplate: FormatTemplate = {
  limitHH: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: awParameterTemplate,
  },
  limitH: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: awParameterTemplate,
  },
  limitL: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: awParameterTemplate,
  },
  limitLL: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: awParameterTemplate,
  },
  condition: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: conditionTemplate,
  },
  "@assetId": {
    type: "string",
    canBeUndefined: false,
  },
};

export default actionAlarmingWarningTemplate;
