import { useRef, useState } from "react";
import { FaceplateElement } from "../../../Asset_Specification";
import EditModalProps from "../../EditModal/interfaces/EditModalProps";
import EditModal from "../../EditModal/EditModal";
import EditFaceplateElementForm from "../Form/EditFaceplateElementForm";
import { defaultFaceplateElement } from "@utils/faceplateElementUtils";

const formId: string = "EditFaceplateElementForm";

const EditFaceplateElementModal = ({
  modalHeading,
  onSaveItemCallback,
  onCancelCallback,
  itemEntry,
}: EditModalProps<FaceplateElement>) => {
  const formRef: React.MutableRefObject<HTMLFormElement | null> =
    useRef<HTMLFormElement>(null);
  const [valid, setValid] = useState<boolean>(itemEntry !== undefined);
  return (
    <EditModal
      modalHeading={modalHeading}
      valid={valid}
      onCancelCallback={onCancelCallback}
      formRef={formRef}
    >
      <EditFaceplateElementForm
        id={formId}
        faceplateElement={itemEntry?.[1] ?? defaultFaceplateElement}
        onFaceplateElementSubmitted={(newFaceplateElement: FaceplateElement) =>
          onSaveItemCallback(newFaceplateElement, itemEntry?.[0])
        }
        formRef={formRef}
        onValidityChange={setValid}
      />
    </EditModal>
  );
};

export default EditFaceplateElementModal;
