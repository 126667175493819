import { TextField } from "@form-components";
import { ReactElement } from "react";
import { FieldValues } from "react-hook-form";
import { TextFieldProps } from "../basic/TextField/TextField";
import { RegexpRules } from "@components/form-components/interfaces/Rules";
import { constructRulesWithRegExp } from "@components/form-components/utils/rulesUtils";

/**
 * Defines the properties to inject into the {@link SubstateIdInput}.
 */
interface SubstateIdInputProps<TInputs extends FieldValues>
  extends Omit<TextFieldProps<TInputs, RegexpRules>, "label"> {
  label?: string;
}

const substateIdRegexp: RegExp = /^S[0-9]{3,}$/;
const substateIdRegexpMessage: string =
  "A substate id must have the prefix 'S' followed by at least 3 numbers!";

/**
 * An input for a substate id in a specification.
 * @param props the properties to inject into the component
 * @returns a react component.
 */
export default function SubstateIdInput<TInputs extends FieldValues>({
  label,
  rules,
  ...remainingProps
}: SubstateIdInputProps<TInputs>): ReactElement<SubstateIdInputProps<TInputs>> {
  return (
    <TextField<TInputs>
      label={label ?? "Substate Id"}
      rules={constructRulesWithRegExp(
        substateIdRegexp,
        substateIdRegexpMessage,
        rules
      )}
      {...remainingProps}
    />
  );
}
