import { FormatTemplate } from "../FormatTemplate";
import byPassTemplate from "./byPassTemplate";
import conditionTemplate from "./conditionTemplate";
import enumerationTemplate from "./enumerationTemplate";
import mfcsTemplate from "./mfcsTemplate";

const exchangeAssetTemplate: FormatTemplate = {
  condition: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: conditionTemplate,
  },
  byPass: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: byPassTemplate,
  },
  enumeration: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: enumerationTemplate,
  },
  mfcs: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: mfcsTemplate,
  },
  "@id": {
    type: "string",
    canBeUndefined: false,
  },
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@allocName": {
    type: "string",
    canBeUndefined: true,
  },
  "@alias": {
    type: "string",
    canBeUndefined: false,
  },
  "@type": {
    type: "string",
    canBeUndefined: false,
  },
  "@subtype": {
    type: "string",
    canBeUndefined: false,
  },
  "@description": {
    type: "string",
    canBeUndefined: false,
  },
};

export default exchangeAssetTemplate;
