import { FormatTemplate } from "../FormatTemplate";
import conditionTemplate from "./conditionTemplate";
import labelTemplate from "./labelTemplate";

const suspendConditionTemplate: FormatTemplate = {
  label: {
    type: "array",
    canBeUndefined: false,
    propertyTemplate: labelTemplate,
  },
  suspend: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: conditionTemplate,
  },
  unSuspend: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: conditionTemplate,
  },
  "@id": {
    type: "string",
    canBeUndefined: false,
  },
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@description": {
    type: "string",
    canBeUndefined: true,
  },
};

export default suspendConditionTemplate;
