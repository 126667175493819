import FlowchartGenerator from "services/FlowchartGenerator";
import { SpecificationService } from "services/SpecificationService";
import AppState from "state/interfaces/AppState";
import { StateFlows } from "types";

export const copyAppState = (state: AppState): AppState => {
  if (!state.specificationService || !state.selectedState) {
    return { ...state };
  }
  const specificationServiceCopy = new SpecificationService(
    structuredClone(state.specificationService.Specification),
    state.selectedState
  );
  const flowchartGeneratorCopy = new FlowchartGenerator(
    specificationServiceCopy
  );

  return {
    ...state,
    flowchartGenerator: flowchartGeneratorCopy,
    nodesAndEdges: copyNodesAndEdges(state.nodesAndEdges),
    specificationService: specificationServiceCopy,
  };
};

const copyNodesAndEdges = (nodesAndEdges: StateFlows): StateFlows => {
  let edgesAndNodesCopy: StateFlows = {};

  Object.entries(nodesAndEdges).forEach((entry) => {
    // what is worth to remember - structuredClone does not handle copying functions
    edgesAndNodesCopy[entry[0]] = structuredClone(entry[1]);
  });

  return edgesAndNodesCopy;
};

