import { FormatTemplate } from "../../FormatTemplate";

const commonSpecificationTemplate: FormatTemplate = {
  "?xml": {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: {
      "@version": {
        type: "string",
        canBeUndefined: false,
      },
      "@encoding": {
        type: "string",
        canBeUndefined: false,
      },
    },
  },
  "?xml-model": {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: {
      "@href": {
        type: "string",
        canBeUndefined: false,
      },
    },
  },
};

export default commonSpecificationTemplate;
