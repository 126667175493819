import { FormatTemplate } from "../FormatTemplate";
import exchangeAssetTemplate from "./exchangeAssetTemplate";

const exchangeTemplate: FormatTemplate = {
  asset: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: exchangeAssetTemplate,
  },
};

export default exchangeTemplate;
