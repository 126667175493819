import { useContext, useMemo } from "react";
import { AppStateContext } from "state/Context";

interface UseSubstateIdOptionsReturn {
  substateIdOptions: string[];
}

interface UseSubstateIdOptionsProps {
  state: string | undefined;
}

const useSubstateIdOptions = ({
  state,
}: UseSubstateIdOptionsProps): UseSubstateIdOptionsReturn => {
  const { specificationService } = useContext(AppStateContext);

  const substateIdOptions: string[] = useMemo(() => {
    if (!specificationService || !state) {
      return [];
    }

    return specificationService.getSubstateIdsForState(state);
  }, [specificationService, state]);

  return { substateIdOptions };
};

export default useSubstateIdOptions;
