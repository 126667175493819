import AnsiCharInput from "../../AnsiCharInput";
import { PLCParameterRequiredPropertyInputProps } from "../interfaces/PLCParameterPropertyInputProps";

const IdInput = (props: PLCParameterRequiredPropertyInputProps) => {
  const { id, value, onPropertyChanged } = { ...props };

  return (
    <AnsiCharInput
      id={`${id}-Id`}
      label="Id"
      value={value}
      maxLength={47}
      onChangeCallback={(value: string, valid: boolean) =>
        onPropertyChanged(value, valid)
      }
      fullWidth
      required
    />
  );
};

export default IdInput;

