import { ReactElement } from "react";
import { RegexpRules } from "../../interfaces/Rules";
import { ansiRegexpMessage, ansiiRegexp } from "../../utils/regexpUtils";
import TextField, { TextFieldProps } from "./TextField/TextField";
import { constructRulesWithRegExp } from "../../utils/rulesUtils";
import { FieldValues } from "react-hook-form";

/**
 * Defines the properties for the {@link AnsiTextField} component.
 * @interface
 * @extends TextFieldProps
 */
export interface AnsiTextFieldProps<TInputs extends FieldValues>
  extends TextFieldProps<TInputs, RegexpRules> {}

/**
 * A text field component which validates the text to the ANSI character set
 * @param {AnsiTextFieldProps} props - the properties to pass into the component.
 * @returns {ReactElement}
 */
function AnsiTextField<TInputs extends FieldValues>({
  rules,
  ...remainingProps
}: AnsiTextFieldProps<TInputs>): ReactElement<AnsiTextFieldProps<TInputs>> {
  return (
    <TextField<TInputs>
      rules={constructRulesWithRegExp(ansiiRegexp, ansiRegexpMessage, rules)}
      {...remainingProps}
    />
  );
}

export default AnsiTextField;
