import { ReactElement } from "react";
import { FieldValues } from "react-hook-form";
import Select, { SelectProps } from "../basic/Select/Select";
import { ExchangeAssetSubtype } from "Asset_Specification";

/**
 * Defines the properties for the {@link SelectExchangeAssetSubtype}.
 */
interface SelectExchangeAssetSubtypeProps<TInputs extends FieldValues>
  extends Omit<SelectProps<TInputs>, "options"> {}

const options: ExchangeAssetSubtype[] = [
  "Driver",
  "Actuator",
  "Function",
  "Sensor",
  "Controller",
  "Profile",
  "System",
  "EquipmentModule",
];

/**
 * The component for selecting exchange asset subtype values.
 * @param props the properties to inject into the component
 * @returns {ReactElement<SelectExchangeAssetSubtypeProps<TInputs>>}
 */
export default function SelectExchangeAssetSubtype<TInputs extends FieldValues>(
  props: SelectExchangeAssetSubtypeProps<TInputs>
): ReactElement<SelectExchangeAssetSubtypeProps<TInputs>> {
  return <Select<TInputs> options={options} {...props} />;
}
