import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import { FormGrid, Select, SelectParameterId } from "@form-components";
import { inequalities } from "@utils/operatorUtils";

/**
 * Defines the properties for the {@link ValidationRuleInput} component.
 */
interface ValidationRuleInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {}

/**
 * The input component for specifying ValidationRule values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<ValidationRuleInputProps<TInputs>>}
 */
export default function ValidationRuleInput<TInputs extends FieldValues>({
  disabled,
  columnSpan,
  name,
}: ValidationRuleInputProps<TInputs>): ReactElement<
  ValidationRuleInputProps<TInputs>
> {
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <SelectParameterId
        name={`${name}.@parameterId` as Path<TInputs>}
        label="Parameter Id"
        disabled={disabled}
        fullWidth
        fixedDropdown
      />

      <Select<TInputs>
        name={`${name}.@operator` as Path<TInputs>}
        label="Operator"
        disabled={disabled}
        options={inequalities}
        fullWidth
        fixedDropdown
      />
    </FormGrid>
  );
}
