import AnsiCharInput from "../../../GenericInputs/AnsiCharInput";
import {
  processChangedOptionalValue,
  processOptionalValue,
} from "../../../GenericInputs/inputUtils";
import CalculationFormProps from "../interfaces/CalculationFormProps";

interface ParameterInputProps extends CalculationFormProps {
  parameter: string | undefined;
}

const ParameterInput = (props: ParameterInputProps) => {
  const { parameter, formId, setCalculationFormCallback } = { ...props };

  return (
    <AnsiCharInput
      id={`${formId}-Parameter`}
      label="Parameter"
      value={processOptionalValue(parameter)}
      maxLength={47}
      onChangeCallback={(value: string, valid: boolean) =>
        setCalculationFormCallback({
          type: "set-prop",
          payload: {
            key: "@parameter",
            value: [processChangedOptionalValue(value), valid],
          },
        })
      }
      fullWidth
    />
  );
};

export default ParameterInput;

