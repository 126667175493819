import { File } from "../../../Asset_Specification";
import { SuiFileSelector } from "../../SuiFileSelector";
import InputLabel from "../InputLabel/InputLabel";
import { CommonInputProps } from "../InputProps";
import { defaultFile, isValidFile } from "../../../utils/fileUtils";
import { FileSelectorChangeEvent } from "@umetrics/sartorius-ui-file-selector";
import "./FileInput.css";
import { isEmptyOrWhitespace } from "../../../utils/stringUtils";
import { SuiButton } from "../../SuiButton";
import { SIcon } from "../../SuiIcon";
import reset from "@umetrics/sartorius-ui-icon/dist/icons/reset";
import AnsiCharInput from "../AnsiCharInput";

interface FileInputProps extends CommonInputProps<File> {
  label: string;
  file?: File;
  required?: boolean;
  acceptedFileExtensions?: string[];
}

const FileInput = (props: FileInputProps) => {
  const {
    id,
    label,
    file,
    required,
    acceptedFileExtensions,
    onChangeCallback,
  } = { ...props };

  const internalFile = file ?? defaultFile;
  const emptyFileName =
    !internalFile["@fileName"] ||
    isEmptyOrWhitespace(internalFile["@fileName"]);

  const onFileChanged = (fileName: string) => {
    const newFile: File = {
      "@fileName": fileName,
    };
    onChangeCallback?.(newFile, isValidFile(newFile));
  };

  const onFileSelectionChanged = (event: FileSelectorChangeEvent) => {
    const files = event.target.selectedFiles;
    const fileName: string = !files || files.length !== 1 ? "" : files[0].name;
    onFileChanged(fileName);
  };

  const onClearFileSelection = () => {
    const newFile = defaultFile;
    const valid = required ? isValidFile(defaultFile) : true;
    onChangeCallback?.(newFile, valid);
  };

  return (
    <InputLabel label={label} required={required} htmlFor={id}>
      <div className="file-input-container">
        <div className="file-input-container-text-input">
          <AnsiCharInput
            id={id}
            value={internalFile["@fileName"]}
            placeholder="No file selected."
            required={required}
            onChangeCallback={onFileChanged}
            fullWidth
          />
        </div>
        <div className="file-input-button-group">
          <SuiButton
            type="secondary"
            disabled={emptyFileName}
            onClick={onClearFileSelection}
          >
            <SIcon slot="icon-slot" icon={reset} />
          </SuiButton>
          <div className="file-input-selector-wrapper">
            <SuiFileSelector
              dragDropLabel=""
              browseButtonLabel="Choose File"
              hideLabel
              multiple={false}
              onFileSelectionChanged={onFileSelectionChanged}
              acceptedFileExtensions={acceptedFileExtensions}
            />
          </div>
        </div>
      </div>
    </InputLabel>
  );
};

export default FileInput;

