import { ReactElement } from "react";
import { FieldValues } from "react-hook-form";
import Select, { SelectProps } from "../../basic/Select/Select";
import useParameterIds from "./hooks/useParameterIds";

/**
 * Defines the properties for the {@link SelectParameterId}.
 */
interface SelectParameterIdProps<TInputs extends FieldValues>
  extends Omit<SelectProps<TInputs>, "options"> {}

/**
 * The component for selecting parameter ids from those defined in the underlying specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<SelectParameterIdProps<TInputs>>}
 */
export default function SelectParameterId<TInputs extends FieldValues>(
  props: SelectParameterIdProps<TInputs>
): ReactElement<SelectParameterIdProps<TInputs>> {
  const { parameterIdOptions } = useParameterIds();
  return <Select<TInputs> options={parameterIdOptions} {...props} />;
}
