import { FormatTemplate } from "../FormatTemplate";

const displayInformationTemplate: FormatTemplate = {
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@description": {
    type: "string",
    canBeUndefined: false,
  },
};

export default displayInformationTemplate;
