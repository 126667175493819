import CoderInput from "../../../../GenericInputs/CoderInput/CoderInput";
import CalculationFormProps from "../../interfaces/CalculationFormProps";
import "./CodeInput.css";

interface CodeInputProps extends CalculationFormProps {
  code: string;
}

const CodeInput = (props: CodeInputProps) => {
  const { code, formId, setCalculationFormCallback } = { ...props };

  return (
    <CoderInput
      id={`${formId}-Code`}
      className="calculations-code-input"
      label="Code"
      code={code}
      onChangeCallback={(value: string, valid: boolean) =>
        setCalculationFormCallback({
          type: "set-prop",
          payload: { key: "code", value: [value, valid] },
        })
      }
      required
      language="none"
      lines
    />
  );
};

export default CodeInput;
