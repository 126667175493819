import { FormatTemplate } from "../FormatTemplate";
import valueEnumTemplate from "./valueEnumTemplate";

const enumerationTemplate: FormatTemplate = {
  valueEnum: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: valueEnumTemplate,
  },
  "@dataType": {
    type: "string",
    canBeUndefined: false,
  },
  "@description": {
    type: "string",
    canBeUndefined: true,
  },
};

export default enumerationTemplate;
