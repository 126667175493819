import {
  BranchingNodeData,
  CaseNodeData,
  StepNodeData,
  StateLinkNodeData,
} from "@components/SequentialFlowEditor/types";
import { Edge, Node, XYPosition } from "reactflow";
import ISpecificationService from "services/interfaces/ISpecificationService";
import { Step } from "types";
import { toObjectOrArray } from "./objectUtils";
import { getBranchingCases } from "./branchingUtils";
import {
  isCaseNode,
  isStepNode,
  isStateLinkNode,
} from "@components/SequentialFlowEditor/components/nodes/nodeUtils";
import { connectWithEdge } from "./edgeUtils";
import {
  createNewNodeId,
  createStepNode,
  createStateLinkNode,
  getAbsoluteCaseNodePosition,
  ySpaceBetweenSteps,
} from "./nodeGenerationUtils";

export const onUpdateCaseNode = (
  caseNodeId: string,
  caseNodeData: CaseNodeData,
  nodesAndEdgesOfCurrentState: { nodes: Node[]; edges: Edge[] },
  specificationService: ISpecificationService
): { nodes: Node[]; edges: Edge[] } => {
  const { nodes: nodesWithUpdatedCase, edges } =
    updateCaseNodeAndRemoveExistingEdge(
      caseNodeId,
      caseNodeData,
      nodesAndEdgesOfCurrentState
    );
  const { newNodes: nodesWithUpdatedCaseAndBranch, parentBranchingNode } =
    updateParentBranchingNode(
      caseNodeData.branchingNodeId,
      nodesWithUpdatedCase,
      specificationService
    );
  return addCaseToSubstateNodesAndEdges(
    caseNodeId,
    caseNodeData.case["@subStateId"],
    nodesWithUpdatedCaseAndBranch,
    edges,
    specificationService,
    parentBranchingNode.position
  );
};

const updateCaseNodeAndRemoveExistingEdge = (
  caseNodeId: string,
  caseNodeData: CaseNodeData,
  nodesAndEdgesOfCurrentState: { nodes: Node[]; edges: Edge[] }
): { nodes: Node[]; edges: Edge[] } => {
  const connectedEdgeToCaseId: string | undefined =
    nodesAndEdgesOfCurrentState.edges.find(
      (edge: Edge) => edge.source === caseNodeId
    )?.id;
  const nodes: Node[] = [...nodesAndEdgesOfCurrentState.nodes].map(
    (node: Node) =>
      node.id === caseNodeId
        ? { ...node, data: { ...caseNodeData } }
        : { ...node }
  );
  const edges: Edge[] = [...nodesAndEdgesOfCurrentState.edges].filter(
    (edge: Edge) => edge.id !== connectedEdgeToCaseId
  );
  return { nodes, edges };
};

const updateParentBranchingNode = (
  branchingNodeId: string,
  nodes: Node[],
  specificationService: ISpecificationService
): { newNodes: Node[]; parentBranchingNode: Node<BranchingNodeData> } => {
  const parentBranchingNode: Node<BranchingNodeData> | undefined = nodes.find(
    (node) => node.id === branchingNodeId
  );
  if (!parentBranchingNode) {
    throw new Error("Case node has no parent branching node!");
  }

  const branchingStepNode: Node<StepNodeData> | undefined = nodes.find(
    (node: Node) => node.id === parentBranchingNode.data.stepNodeId
  );
  if (!branchingStepNode || !branchingStepNode.data.step.branching) {
    return { newNodes: [...nodes], parentBranchingNode };
  }

  branchingStepNode.data.step.branching.case = toObjectOrArray(
    getBranchingCases(parentBranchingNode.data.caseNodeIds, nodes)
  );

  specificationService.updateStep(branchingStepNode.data.step);
  const newNodes: Node[] = [...nodes].map((node: Node) =>
    node.id === branchingNodeId
      ? { ...node, data: { ...parentBranchingNode.data } }
      : { ...node }
  );
  return { newNodes, parentBranchingNode };
};

const addCaseToSubstateNodesAndEdges = (
  caseNodeId: string,
  substateId: string,
  currentStateNodes: Node[],
  currentStateEdges: Edge[],
  specificationService: ISpecificationService,
  parentBranchingNodePosition: XYPosition
): { nodes: Node[]; edges: Edge[] } => {
  const newNodes: Node[] = [...currentStateNodes];
  const newEdges: Edge[] = [...currentStateEdges];

  const caseNode: Node<CaseNodeData> | undefined = currentStateNodes
    .filter(isCaseNode)
    .find((caseNode: Node<CaseNodeData>) => caseNode.id === caseNodeId);
  if (!substateId || !caseNode) {
    return {
      nodes: newNodes,
      edges: newEdges,
    };
  }

  const absoluteCaseNodePosition: XYPosition = getAbsoluteCaseNodePosition(
    caseNode.position,
    parentBranchingNodePosition
  );
  const { connectingNode, isNewNode } = getConnectingNode(
    substateId,
    currentStateNodes,
    specificationService,
    absoluteCaseNodePosition
  );
  const connectingEdge: Edge | undefined = connectWithEdge(
    caseNode,
    connectingNode
  );
  if (connectingEdge) {
    newEdges.push(connectingEdge);
  }

  if (isNewNode) {
    newNodes.push(connectingNode);
  }

  return {
    nodes: newNodes,
    edges: newEdges,
  };
};

const getConnectingNode = (
  substateId: string,
  currentStateNodes: Node[],
  specificationService: ISpecificationService,
  caseNodePosition: XYPosition
): { connectingNode: Node; isNewNode: boolean } => {
  const stepNodeInCurrentState: Node<StepNodeData> | undefined =
    currentStateNodes
      .filter(isStepNode)
      .find((node: Node<StepNodeData>) => node.data.step["@id"] === substateId);
  if (stepNodeInCurrentState) {
    return { connectingNode: stepNodeInCurrentState, isNewNode: false };
  }

  const newNodeId: string = createNewNodeId(currentStateNodes).toString();
  const newPosition: XYPosition = {
    x: caseNodePosition.x,
    y: caseNodePosition.y + ySpaceBetweenSteps,
  };
  const stepNodeState: string | undefined =
    specificationService.getStateOfSubstate(substateId);
  if (stepNodeState) {
    return getOrCreateStateLinkNode(
      newNodeId,
      newPosition,
      substateId,
      stepNodeState,
      currentStateNodes
    );
  }

  const step: Step = specificationService.createStep({
    id: substateId,
    name: "Name",
    description: "Description",
  });
  const newStep: Step | undefined = specificationService.addStep(step);
  if (!newStep) {
    throw new Error("Unable to add new step to the specification!");
  }

  const newStepNode: Node<StepNodeData> = createStepNode(
    newNodeId,
    newStep,
    newPosition
  );
  return { connectingNode: newStepNode, isNewNode: true };
};

const getOrCreateStateLinkNode = (
  nodeId: string,
  position: XYPosition,
  substateId: string,
  stepNodeState: string,
  currentStateNodes: Node[]
): { connectingNode: Node<StateLinkNodeData>; isNewNode: boolean } => {
  const existingStateLinkNode: Node<StateLinkNodeData> | undefined =
    currentStateNodes
      .filter(isStateLinkNode)
      .find(
        (node: Node<StateLinkNodeData>) =>
          node.data.state === stepNodeState &&
          node.data.substateId === substateId
      );
  if (existingStateLinkNode) {
    return { connectingNode: existingStateLinkNode, isNewNode: false };
  }

  const newStateLinkNode: Node<StateLinkNodeData> = createStateLinkNode(
    nodeId,
    position,
    substateId,
    stepNodeState
  );
  return { connectingNode: newStateLinkNode, isNewNode: true };
};
