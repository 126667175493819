import { StateLinkNodeData } from "@components/SequentialFlowEditor/types";
import { FormGrid, Select, twoColumnsSpan } from "@form-components";
import { ReactElement, useEffect } from "react";
import useSubstateIdOptions from "./hooks/useSubstateIdOptions";
import { Path, useFormContext } from "react-hook-form";
import useStateOptions from "./hooks/useStateOptions";

const stateName: Path<StateLinkNodeData> = "state";
const substateIdName: Path<StateLinkNodeData> = "substateId";

const EditStateLinkNodeDataFormContent = (): ReactElement => {
  const { watch, setValue } = useFormContext<StateLinkNodeData>();
  const state: string | undefined = watch(stateName);
  const { stateOptions } = useStateOptions();
  const { substateIdOptions } = useSubstateIdOptions({ state });

  useEffect(() => {
    setValue(substateIdName, "");
  }, [setValue, state]);

  return (
    <FormGrid>
      <Select<StateLinkNodeData>
        name={stateName}
        label="State"
        options={stateOptions}
        fixedDropdown
        columnSpan={twoColumnsSpan}
      />
      <Select<StateLinkNodeData>
        name={substateIdName}
        label="Substate Id"
        options={substateIdOptions}
        fixedDropdown
        disabled={!state}
        columnSpan={twoColumnsSpan}
      />
    </FormGrid>
  );
};

export default EditStateLinkNodeDataFormContent;
