import { ReactElement } from "react";
import { FormGrid, Select, SelectParameterId } from "@form-components";
import { Path } from "react-hook-form";
import { FaceplateElement } from "Asset_Specification";
import { controlTypes } from "@utils/faceplateElementUtils";

export const attributeNames: Path<FaceplateElement>[] = [
  "@controlType",
  "@parameterId",
];

const AttributesTabContent = (): ReactElement => {
  return (
    <FormGrid>
      <SelectParameterId
        name="@parameterId"
        label="Parameter Id"
        rules={{ required: true }}
        fixedDropdown
      />

      <Select<FaceplateElement>
        name="@controlType"
        label="Control Type"
        rules={{ required: true }}
        options={controlTypes}
        fixedDropdown
      />
    </FormGrid>
  );
};

export default AttributesTabContent;
