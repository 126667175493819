import { useContext, useMemo } from "react";
import { Edge } from "reactflow";
import { AppStateContext } from "../../../state/Context";

const useSelectedStateEdges = (): Edge[] => {
  const { nodesAndEdges, selectedState } = useContext(AppStateContext);
  const selectedEdges: Edge[] = useMemo(
    () => (selectedState ? nodesAndEdges[selectedState].edges : []),
    [selectedState, nodesAndEdges]
  );
  return selectedEdges;
};

export default useSelectedStateEdges;
