import { FormatTemplate } from "../FormatTemplate";
import docCalculationTemplate from "./docCalculationTemplate";
import plcParameterTemplate from "./plcParameterTemplate";

const calculationDefinitionTemplate: FormatTemplate = {
  documentation: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: docCalculationTemplate,
  },
  plcParameter: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: plcParameterTemplate,
  },
  code: {
    type: "string",
    canBeUndefined: false,
  },
  "@id": {
    type: "string",
    canBeUndefined: false,
  },
  "@parameterId": {
    type: "string",
    canBeUndefined: true,
  },
  "@parameter": {
    type: "string",
    canBeUndefined: true,
  },
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@description": {
    type: "string",
    canBeUndefined: false,
  },
};

export default calculationDefinitionTemplate;
