import "./EnviromentBanner.css"

const EnviromentBanner = () => {
  const releaseEnviroment: string | undefined = process.env.REACT_APP_RELEASE_ENVIROMENT;
  if (!releaseEnviroment) {
    return null;
  }

  return(
    <div className="banner">
      <span className="banner-text">{releaseEnviroment} Release</span>
    </div>
  );
}

export default EnviromentBanner;