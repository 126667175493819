import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import { ConditionInput, FormGrid, Select } from "@form-components";
import { faceplateElementPropertyNames } from "@utils/faceplateElementUtils";

/**
 * Defines the properties for the {@link FaceplateElementPropertyInput} component.
 */
interface FaceplateElementPropertyInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {
  namesToExclude: string[];
}

/**
 * The input component for specifying FaceplateElementProperty values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<ValidationRuleInputProps<TInputs>>}
 */
export default function FaceplateElementPropertyInput<
  TInputs extends FieldValues,
>({
  disabled,
  columnSpan,
  name,
  namesToExclude,
}: FaceplateElementPropertyInputProps<TInputs>): ReactElement<
  FaceplateElementPropertyInputProps<TInputs>
> {
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <Select<TInputs>
        name={`${name}.@name` as Path<TInputs>}
        label="Name"
        disabled={disabled}
        options={faceplateElementPropertyNames}
        rules={{
          required: true,
          validate: (value: string) => !namesToExclude.includes(value),
        }}
        fullWidth
        fixedDropdown
      />

      <ConditionInput
        name={`${name}.condition` as Path<TInputs>}
        label="Condition"
        disabled={disabled}
        rules={{
          required: true,
        }}
      />
    </FormGrid>
  );
}
