import { ReactElement } from "react";
import { FieldValues } from "react-hook-form";
import { ConditionInputGroupProps } from "./ConditionInputGroup";
import ConditionInputEntry from "./ConditionInputEntry";
import { getOperatorLabel } from "../utils/operatorOptions";

interface ConditionInputGroupItemProps<TInputs extends FieldValues>
  extends ConditionInputGroupProps<TInputs> {
  index: number;
  count: number;
}

export default function ConditionInputGroupItem<TInputs extends FieldValues>({
  name,
  logicalOperator,
  disabled,
  deleteBtnDisabled,
  index,
  count,
  onDelete,
  operatorsToExclude,
}: ConditionInputGroupItemProps<TInputs>): ReactElement<
  ConditionInputGroupItemProps<TInputs>
> {
  return (
    <div className="condition-group-item">
      <ConditionInputEntry
        name={name}
        disabled={disabled}
        deleteBtnDisabled={deleteBtnDisabled}
        onDelete={onDelete}
        operatorsToExclude={operatorsToExclude}
      />
      <h1
        style={{
          visibility: index < count - 1 ? "visible" : "hidden",
        }}
      >
        {getOperatorLabel(logicalOperator)}
      </h1>
    </div>
  );
}
