import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import { Select } from "@form-components";
import { getOperatorOptions } from "../../utils/operatorOptions";
import { ColumnSpan } from "@components/form-components/interfaces/InputProps";
import { ConditionOperator } from "Asset_Specification";

/**
 * Defines the properties for the {@link SelectConditionOperator} component.
 */
interface SelectConditionOperatorProps<TInputs extends FieldValues> {
  name: Path<TInputs>;
  fullWidth?: boolean;
  columnSpan?: ColumnSpan;
  operatorsToExclude?: ConditionOperator[];
}

/**
 * The input component for selecting a particular operator in a condition,
 * @param props the properties to inject into the component
 * @returns {ReactElement<SelectConditionOperatorProps<TInputs>>}
 */
export default function SelectConditionOperator<TInputs extends FieldValues>({
  name,
  fullWidth,
  columnSpan,
  operatorsToExclude,
}: SelectConditionOperatorProps<TInputs>): ReactElement<
  SelectConditionOperatorProps<TInputs>
> {
  return (
    <Select
      name={`${name}.@operator`}
      label="Operator"
      rules={{ required: true }}
      options={getOperatorOptions(operatorsToExclude)}
      fixedDropdown
      fullWidth={fullWidth}
      columnSpan={columnSpan}
    />
  );
}
