import { FormatTemplate } from "../FormatTemplate";
import faceplateElementTemplate from "./faceplateElementTemplate";

const dialogContentTemplate: FormatTemplate = {
  faceplateElement: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: faceplateElementTemplate,
  },
};

export default dialogContentTemplate;
