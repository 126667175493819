import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { AnsiTextField, ConditionInput, FormGrid } from "@form-components";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";

/**
 * Defines the properties for the {@link SetOperatingSchemeCommandInput} component.
 */
interface SetOperatingSchemeCommandInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {}

/**
 * The input component for specifying SetOperatingSchemeCommand values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<SetOperatingSchemeCommandInputProps<TInputs>>}
 */
export default function SetOperatingSchemeCommandInput<
  TInputs extends FieldValues,
>({
  disabled,
  columnSpan,
  name,
}: SetOperatingSchemeCommandInputProps<TInputs>): ReactElement<
  SetOperatingSchemeCommandInputProps<TInputs>
> {
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <AnsiTextField
        name={`${name}.@operatingSchemeCommand` as Path<TInputs>}
        label="Operating Scheme Command"
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
      />

      <ConditionInput
        name={`${name}.condition` as Path<TInputs>}
        label="Condition"
        disabled={disabled}
      />
    </FormGrid>
  );
}
