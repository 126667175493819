import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import {
  AnsiTextField,
  FormGrid,
  RequiredIdTextField,
  RequiredNonEmptyNameTextField,
  SelectExchangeAssetSubtype,
  TextField,
} from "@form-components";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import {
  alphanumericWithSeparatorsRegexp,
  alphanumericWithSeparatorsRegexpMessage,
} from "@components/form-components/utils/regexpUtils";

/**
 * Defines the properties for the {@link BypassInput} component.
 */
interface BypassInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {}

/**
 * The input component for specifying Bypass values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<BypassInputProps<TInputs>>}
 */
export default function BypassInput<TInputs extends FieldValues>({
  disabled,
  columnSpan,
  name,
}: BypassInputProps<TInputs>): ReactElement<BypassInputProps<TInputs>> {
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <RequiredIdTextField name={`${name}.@id`} fullWidth disabled={disabled} />

      <RequiredNonEmptyNameTextField
        name={`${name}.@name` as Path<TInputs>}
        disabled={disabled}
        fullWidth
      />

      <TextField
        name={`${name}.@nameInParentAsset` as Path<TInputs>}
        label="Name in Parent Asset"
        rules={{
          pattern: {
            message: alphanumericWithSeparatorsRegexpMessage,
            value: alphanumericWithSeparatorsRegexp,
          },
        }}
        disabled={disabled}
        fullWidth
      />

      <AnsiTextField
        name={`${name}.@alias` as Path<TInputs>}
        label="Alias"
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
      />

      <SelectExchangeAssetSubtype
        name={`${name}.@subtype` as Path<TInputs>}
        label="Subtype"
        rules={{ required: true }}
        fullWidth
        disabled={disabled}
        fixedDropdown
      />

      <AnsiTextField
        name={`${name}.@type` as Path<TInputs>}
        label="Type"
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
      />

      <AnsiTextField
        name={`${name}.@description` as Path<TInputs>}
        label="Description"
        disabled={disabled}
        fullWidth
      />
    </FormGrid>
  );
}
