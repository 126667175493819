import {
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  RefAttributes,
} from "react";
import { isEmptyOrWhitespace } from "../../utils/stringUtils";
import { SuiTextArea } from "../SuiTextArea";
import { TextAreaInputProps } from "./InputProps";
import {
  ansiRegexCharacters,
  ansiRegexValidationMessage,
  getRegexpFromCharacters,
  validateRegexInput,
} from "./inputUtils";
import { TextAreaProperties } from "@umetrics/sartorius-ui-text-area";

const defaultRegexp = getRegexpFromCharacters(ansiRegexCharacters); // Matches all ANSI characters

const TextAreaInput = (props: TextAreaInputProps) => {
  const {
    onChangeCallback,
    required,
    regexp,
    customValidationMessage,
    setValueOnChange,
    ...remainingProps
  } = {
    ...props,
  };
  const onValueChange = ({ detail: { value } }: any) => {
    let valid = validateRegexInput(value, regexp ?? defaultRegexp);
    if (required && isEmptyOrWhitespace(value)) {
      valid = false;
    }

    onChangeCallback?.(value, valid);
  };

  const textAreaProperties: PropsWithChildren<
    TextAreaProperties & {
      children?: ReactNode;
    } & RefAttributes<HTMLElement> &
      HTMLAttributes<HTMLElement>
  > = {
    ...remainingProps,
    required,
    regexp: regexp ?? defaultRegexp,
    ...(setValueOnChange
      ? { onChange: onValueChange }
      : { onInput: onValueChange }),
  };

  return (
    <SuiTextArea {...textAreaProperties}>
      <span slot="validation-empty">This is a mandatory field!</span>
      <span slot="validation-custom">
        {customValidationMessage ?? ansiRegexValidationMessage}!
      </span>
    </SuiTextArea>
  );
};

export default TextAreaInput;
