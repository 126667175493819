import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import { ConditionInput, FormGrid, SelectParameterId } from "@form-components";

/**
 * Defines the properties for the {@link ActionRefreshParameterInput} component.
 */
interface ActionRefreshParameterInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {}

/**
 * The input component for specifying actionRefreshParameter values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<ActionRefreshParameterInputProps<TInputs>>}
 */
export default function ActionRefreshParameterInput<
  TInputs extends FieldValues,
>({
  disabled,
  columnSpan,
  name,
}: ActionRefreshParameterInputProps<TInputs>): ReactElement<
  ActionRefreshParameterInputProps<TInputs>
> {
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <SelectParameterId
        name={`${name}.@parameterId` as Path<TInputs>}
        label={"Parameter Id"}
        rules={{ required: true }}
        disabled={disabled}
        fixedDropdown
      />
      <ConditionInput
        name={`${name}.condition` as Path<TInputs>}
        label="Condition"
        disabled={disabled}
      />
    </FormGrid>
  );
}
