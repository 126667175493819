import { useContext, useMemo } from "react";
import { AppStateContext } from "../../../state/Context";

const useSpecificationStateNames = (): string[] => {
  const { specificationService } = useContext(AppStateContext);
  const stateNames: string[] = useMemo(
    () => specificationService?.getStateNames() ?? [],
    [specificationService]
  );
  return stateNames;
};

export default useSpecificationStateNames;
