/**
 * Generates a simple non crypto hash from a string value.
 * @param {string} str - the string value to generate the hash for.
 * @returns {string} the hash value.
 * @see {@link https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript}
 */
export const generateSimpleNonCryptoHash = (str: string): number => {
  let hash: number = 0;
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
};
