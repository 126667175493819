import { TabNotification } from "@umetrics/sartorius-ui-tabs";
import { SuiTab } from "../../SuiTabs";
import TabProps from "./interfaces/TabProps";
import EditSnapshots from "../../EditSnapshots/EditSnapshots";

const SnapshotsTab = (props: TabProps) => {
  return(
    <SuiTab
      heading="Snapshots"
      notificationType={TabNotification.None}
      { ...props }
    >
      <EditSnapshots />
    </SuiTab>
  );
}

export default SnapshotsTab;