import CalculationFormProps from "../../interfaces/CalculationFormProps";
import { DocCalculation } from "../../../../../Asset_Specification";
import { getDocCalculationToSetFromArray } from "./utils/documentationInputUtils";
import TextBlockInput from "../../../../GenericInputs/TextBlockInput/TextBlockInput";

interface DocumentationInputProps extends CalculationFormProps {
  documentation: DocCalculation;
}

const DocumentationInput = (props: DocumentationInputProps) => {
  const { documentation, formId, setCalculationFormCallback } = { ...props };

  const onDocumentationChanged = (
    newTextBlocks: string | string[] | undefined,
    valid: boolean
  ) => {
    setCalculationFormCallback({
      type: "set-prop",
      payload: {
        key: "documentation",
        value: [getDocCalculationToSetFromArray(newTextBlocks), valid],
      },
    });
  };

  return (
    <TextBlockInput
      id={`${formId}-Documentation`}
      textBlock={documentation.textBlock}
      onChangeCallback={onDocumentationChanged}
      regexp=""
    />
  );
};

export default DocumentationInput;
