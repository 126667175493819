import { Node, Connection, Edge } from "reactflow";
import { AppReducerAction } from "../../../state/reducers/appReducer";
import { BranchingNodeData, StepNodeData } from "../types";
import { updateStepCasesForBranchingNode } from "./utils";

export const removeStepBranching = (
  connection: Connection | Edge,
  allNodes: Node[],
  dispatch: (action: AppReducerAction) => void
) => {
  const nodes = allNodes.map((node) => ({ ...node }));
  const branchingNode: Node<BranchingNodeData> | undefined = nodes.find(
    (node) => node.id === connection.target
  );
  const stepNode: Node<StepNodeData> | undefined = nodes.find(
    (node) => node.id === connection.source
  );

  if (!branchingNode || !stepNode) {
    return;
  }

  const newBranchingNodeData = {
    ...branchingNode.data,
    stepNodeId: undefined,
  };

  branchingNode.data = newBranchingNodeData;
  stepNode.data = {
    ...stepNode.data,
    step: {
      ...stepNode.data.step,
      branching: undefined,
    },
  };

  dispatch({
    type: "update-step",
    newStepValue: {
      ...stepNode.data.step,
      branching: undefined,
    },
  });

  dispatch({
    type: "set-nodes",
    nodes,
  });
};

export const removeBranchingCase = (
  connection: Connection | Edge,
  allNodes: Node[],
  dispatch: (action: AppReducerAction) => void
) => {
  const nodes = allNodes.map((node) => ({ ...node }));
  const caseNode = nodes.find((node) => node.id === connection.source);
  const branchingNode = nodes.find(
    (node) => node.id === caseNode?.data.branchingNodeId
  );

  if (!caseNode || !branchingNode) {
    return;
  }
  caseNode.data.case["@subStateId"] = "";

  const { updatedStepNode, updatedNodes } = updateStepCasesForBranchingNode(
    branchingNode,
    nodes
  );
  if (updatedStepNode) {
    dispatch({
      type: "update-step",
      newStepValue: updatedStepNode.data.step,
    });
  }

  dispatch({ type: "set-nodes", nodes: updatedNodes });
};
