import { Dispatch } from "react";
import { Edge, Node } from "reactflow";
import IEditNodeService from "./interfaces/IEditNodeService";
import { AppReducerAction } from "../../../state/reducers/appReducer";
import {
  BranchingNodeData,
  CaseNodeData,
  StateLinkNodeData,
  StepNodeData,
} from "../types";
import {
  isBranchingNodeData,
  isCaseNode,
  isCaseNodeData,
  isStateLinkNodeData,
  isStepNodeData,
} from "../components/nodes/nodeUtils";

export default class EditNodeService implements IEditNodeService {
  private readonly appDispatch: Dispatch<AppReducerAction>;
  private readonly nodes: Node[];
  private readonly edges: Edge[];

  constructor(
    appDispatch: Dispatch<AppReducerAction>,
    nodes: Node[],
    edges: Edge[]
  ) {
    this.appDispatch = appDispatch;
    this.nodes = nodes;
    this.edges = edges;
  }

  public editNode(nodeId: string, data: any): void {
    if (isCaseNodeData(data)) {
      this.appDispatch({
        type: "update-case-node",
        nodeId,
        data,
      });
      return;
    }

    this.onNodeDataChange(nodeId, data);
    this.appDispatch({
      type: "update-node",
      payload: { id: nodeId, data: data },
    });
  }

  private onNodeDataChange(nodeId: string, data: any): void {
    if (isStepNodeData(data)) {
      return this.onStepNodeDataChange(nodeId, data);
    }

    if (isBranchingNodeData(data)) {
      return this.onBranchingNodeDataChange(data);
    }

    if (isStateLinkNodeData(data)) {
      return this.onStateLinkNodeDataChange(nodeId, data);
    }
  }

  private onStepNodeDataChange(nodeId: string, data: StepNodeData): void {
    this.appDispatch({
      type: "update-step",
      newStepValue: data.step,
    });
    this.updateConnectedCaseNodes(nodeId, data.step["@id"]);
  }

  private onBranchingNodeDataChange(data: BranchingNodeData): void {
    if (!data.stepNodeId) {
      return;
    }

    const stepNode = this.nodes.find((node) => node.id === data.stepNodeId);
    if (!stepNode) {
      return;
    }

    if (stepNode.data.step.branching === undefined) {
      stepNode.data.step.branching = {};
    }

    stepNode.data.step.branching["@transitionORStepId"] =
      data.transitionORStepId;
    this.appDispatch({
      type: "update-node",
      payload: { id: stepNode.id, data: { ...stepNode.data } },
    });
    this.appDispatch({
      type: "update-step",
      newStepValue: stepNode.data.step,
    });
  }

  private onStateLinkNodeDataChange(
    nodeId: string,
    data: StateLinkNodeData
  ): void {
    this.updateConnectedCaseNodes(nodeId, data.substateId);
  }

  private updateConnectedCaseNodes(
    nodeId: string,
    newSubstateId: string
  ): void {
    const connectedNodeIds: string[] = this.edges
      .filter((edge: Edge) => edge.target === nodeId)
      .map((edge: Edge) => edge.source);
    const connectedCaseNodes: Node<CaseNodeData>[] = this.nodes.filter(
      (node: Node) => isCaseNode(node) && connectedNodeIds.includes(node.id)
    );
    for (const connectedCaseNode of connectedCaseNodes) {
      const newCaseNodeData: CaseNodeData = {
        ...connectedCaseNode.data,
        case: {
          ...connectedCaseNode.data.case,
          "@subStateId": newSubstateId,
        },
      };
      this.appDispatch({
        type: "update-node",
        payload: { id: connectedCaseNode.id, data: newCaseNodeData },
      });
    }
  }
}
