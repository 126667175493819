import { FormatTemplate } from "../FormatTemplate";
import suspendConditionTemplate from "./suspendConditionTemplate";

const suspendConditionsTemplate: FormatTemplate = {
  suspendCondition: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: suspendConditionTemplate,
  },
};

export default suspendConditionsTemplate;
