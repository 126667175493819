import { StateLinkNodeData } from "@components/SequentialFlowEditor/types";
import { EditStateLinkNodeDataModalType } from "../enums/EditStateLinkNodeDataModalType";
import { ReactElement, useRef, useState } from "react";
import { SuiButton } from "@components/SuiButton";
import { FormSuiButton } from "@form-components";
import EditStateLinkNodeDataForm from "./form/EditStateLinkNodeDataForm";
import ModalContent from "@components/ModalContent/ModalContent";

interface EditStateLinkNodeDataModalProps {
  type: EditStateLinkNodeDataModalType;
  data: StateLinkNodeData;
  onSaveCallback: (data: StateLinkNodeData) => void;
  onCancelCallback: () => void;
}

const formId: string = "state-link-node-data-form";

const EditStateLinkNodeDataModal = ({
  type,
  data,
  onSaveCallback,
  onCancelCallback,
}: EditStateLinkNodeDataModalProps): ReactElement<EditStateLinkNodeDataModalProps> => {
  const formRef: React.MutableRefObject<HTMLFormElement | null> =
    useRef<HTMLFormElement>(null);
  const [valid, setValid] = useState<boolean>();
  return (
    <ModalContent
      renderTopSlotContent={() => <h1>{type}</h1>}
      renderMainContent={() => (
        <EditStateLinkNodeDataForm
          id={formId}
          formRef={formRef}
          data={data}
          onDataSubmitted={(data: StateLinkNodeData) => onSaveCallback(data)}
          onValidityChange={setValid}
        />
      )}
      renderBottomRightSlotContent={() => (
        <FormSuiButton
          formRef={formRef}
          label="Save"
          type="primary"
          disabled={!valid}
        />
      )}
      renderBottomLeftSlotContent={() => (
        <SuiButton type="secondary" onClick={onCancelCallback}>
          Cancel
        </SuiButton>
      )}
    />
  );
};

export default EditStateLinkNodeDataModal;
