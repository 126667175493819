import { FormSuiButton } from "@form-components";
import ModalContent from "../ModalContent/ModalContent";
import { SuiButton } from "../SuiButton";
import { EditModalMinimalProps } from "./interfaces/EditModalProps";

const EditModal = ({
  modalHeading,
  valid,
  onSaveItemCallback,
  onCancelCallback,
  children,
  formRef,
}: EditModalMinimalProps) => {
  return (
    <ModalContent
      renderTopSlotContent={() => <h1>{modalHeading}</h1>}
      renderMainContent={() => children}
      renderBottomRightSlotContent={() =>
        // TODO: this conditionally rendering is temp until we refactor all forms to use new form-components/react-hook-form
        formRef ? (
          <FormSuiButton
            formRef={formRef}
            label="Save"
            type="primary"
            disabled={!valid}
          />
        ) : (
          <SuiButton
            type="primary"
            onClick={onSaveItemCallback}
            disabled={!valid}
          >
            Save
          </SuiButton>
        )
      }
      renderBottomLeftSlotContent={() => (
        <SuiButton type="secondary" onClick={onCancelCallback}>
          Cancel
        </SuiButton>
      )}
    />
  );
};

export default EditModal;
