import { AssetSpecification } from "./Asset_Specification";
import { PhaseSubtype } from "./types";

export const toArray = <T>(items: T | T[] | undefined): T[] => {
  if (items === undefined || items === null) {
    return [];
  }

  if (Array.isArray(items)) {
    return items.filter((item) => item !== undefined);
  }

  return [items];
};

export const toValueValidPair = <T>(
  value: T | undefined,
  isValid?: (value: T) => boolean
): { value: T; valid: boolean } | undefined => {
  if (value === undefined) {
    return undefined;
  }

  const valid = isValid ? isValid(value) : true;
  return { value, valid };
};

export const GeneratePhaseInfo = (asset: AssetSpecification | undefined) => {
  return {
    name: asset?.equipmentPhase?.["@name"] ?? "",
    subtype: PhaseSubtype[asset?.equipmentPhase?.["@subtype"] ?? "Control"],
    description:
      asset?.equipmentPhase?.displayInformation?.["@description"] ?? "",
  };
};
