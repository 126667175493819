import { Dispatch } from "react";
import { v4 as uuid } from "uuid";
import { ModalReducerAction } from "../../../state/reducers/modalReducer";

export const getItemDictionary = <T>(items: T[]): { [key: string]: T } => {
  return Object.fromEntries(items.map((item) => [uuid(), item]));
};

export const onDeleteItemCallback = <T>(
  itemDictionary: { [key: string]: T },
  key: string,
  setItemsCallback: (newItemDictionary: { [key: string]: T }) => void
): void => {
  const itemDictionaryCopy = { ...itemDictionary };
  delete itemDictionaryCopy[key];
  setItemsCallback(itemDictionaryCopy);
};

export const onSaveItemCallback = <T extends { [property: string]: any }>(
  itemDictionary: { [key: string]: T },
  itemToSave: T,
  setItemsCallback: (newItemDictionary: { [key: string]: T }) => void,
  dispatchModal: Dispatch<ModalReducerAction>,
  itemKey?: string
): void => {
  const itemDictionaryCopy = { ...itemDictionary };
  const actualItemKey = itemKey ?? uuid();
  itemDictionaryCopy[actualItemKey] = itemToSave;
  setItemsCallback(itemDictionaryCopy);
  dispatchModal({ type: "close" });
};
