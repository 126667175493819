import { FormatTemplate } from "../../../FormatTemplate";
import actionTableTemplate from "../../actionTableTemplate";
import assetTemplate from "../../assetTemplate";
import calculationsTemplate from "../../calculationsTemplate";
import documentationPHTemplate from "../../documentationPHTemplate";
import exchangeTemplate from "../../exchangeTemplate";
import holdConditionsTemplate from "../../holdConditionsTemplate";
import operatorMessagesTemplate from "../../operatorMessagesTemplate";
import snapShotsTemplate from "../../snapShotsTemplate";
import startConditionsTemplate from "../../startConditionsTemplate";
import suspendConditionsTemplate from "../../suspendConditionsTemplate";

const equipmentPhaseTemplate: FormatTemplate = {
  ...assetTemplate,
  exchange: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: exchangeTemplate,
  },
  startConditions: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: startConditionsTemplate,
  },
  holdConditions: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: holdConditionsTemplate,
  },
  suspendConditions: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: suspendConditionsTemplate,
  },
  actionTable: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: actionTableTemplate,
  },
  operatorMessages: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: operatorMessagesTemplate,
  },
  snapShots: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: snapShotsTemplate,
  },
  calculations: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: calculationsTemplate,
  },
  documentation: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: documentationPHTemplate,
  },
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@typeIdentifier": {
    type: "string",
    canBeUndefined: false,
  },
  "@subtype": {
    type: "string",
    canBeUndefined: false,
  },
  "@typeId": {
    type: "string",
    canBeUndefined: false,
  },
};

export default equipmentPhaseTemplate;
