import { ReactElement } from "react";
import { FieldValues, useFormContext } from "react-hook-form";
import InputLabel from "@components/GenericInputs/InputLabel/InputLabel";
import { InputProps } from "@components/form-components/interfaces/InputProps";
import { Rules } from "@components/form-components/interfaces/Rules";
import {
  getRuleValue,
  requiredRuleErrorMessage,
} from "@components/form-components/utils/rulesUtils";
import { SuiFeedbackMessage } from "@components/SuiFeedbackMessage";
import { FeedbackMessageType } from "@umetrics/sartorius-ui-feedback-message";
import ConditionInputContent from "./components/ConditionInputContent";
import { ConditionOperator } from "Asset_Specification";
import "./ConditionInput.css";

/**
 * Defines the properties for the {@link ConditionInput}
 * @template TInputs - the value type for the input.
 * @see InputProps
 */
interface ConditionInputProps<TInputs extends FieldValues>
  extends Omit<
    InputProps<TInputs, Rules>,
    "columnSpan" | "fullWidth" | "label"
  > {
  operatorsToExclude?: ConditionOperator[];
  label?: string;
}

/**
 * The input component for Conditions in an asset specification.
 *
 * @param props the properties to inject into the component
 * @returns {ReactElement<ConditionInputProps<TInputs>>}
 */
export default function ConditionInput<TInputs extends FieldValues>({
  rules,
  name,
  label,
  disabled,
  operatorsToExclude,
}: ConditionInputProps<TInputs>): ReactElement<ConditionInputProps<TInputs>> {
  const { formState, register } = useFormContext<TInputs>();

  const required: boolean | undefined = getRuleValue(rules?.required);

  register(name, {
    disabled: disabled,
    required: required,
  }); // Ensures that a required condition is validated properly

  return (
    <>
      <InputLabel label={label} required={required}>
        <ConditionInputContent
          name={name}
          disabled={disabled}
          operatorsToExclude={operatorsToExclude}
        />
      </InputLabel>
      {formState.errors[name]?.type === "required" && (
        <SuiFeedbackMessage feedbackType={FeedbackMessageType.Failure}>
          {requiredRuleErrorMessage}
        </SuiFeedbackMessage>
      )}
    </>
  );
}
