import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { AnsiTextField, ConditionInput, FormGrid } from "@form-components";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";

/**
 * Defines the properties for the {@link SetOperatingSchemeInput} component.
 */
interface SetOperatingSchemeInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {}

/**
 * The input component for specifying SetOperatingScheme values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<SetOperatingSchemeInputProps<TInputs>>}
 */
export default function SetOperatingSchemeInput<TInputs extends FieldValues>({
  disabled,
  columnSpan,
  name,
}: SetOperatingSchemeInputProps<TInputs>): ReactElement<
  SetOperatingSchemeInputProps<TInputs>
> {
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <AnsiTextField
        name={`${name}.@operatingScheme` as Path<TInputs>}
        label="Operating Scheme"
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
      />

      <ConditionInput
        name={`${name}.condition` as Path<TInputs>}
        label="Condition"
        disabled={disabled}
      />
    </FormGrid>
  );
}
