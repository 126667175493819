import { FormatTemplate } from "../FormatTemplate";

const processDescriptionTemplate: FormatTemplate = {
  textBlock: {
    type: "array",
    canBeUndefined: true,
  },
};

export default processDescriptionTemplate;
