import { useContext, useMemo } from "react";
import { Node } from "reactflow";
import { AppStateContext } from "../../../state/Context";

const useSelectedStateNodes = (): Node[] => {
  const { nodesAndEdges, selectedState } = useContext(AppStateContext);
  const selectedNodes: Node[] = useMemo(
    () => (selectedState ? nodesAndEdges[selectedState].nodes : []),
    [selectedState, nodesAndEdges]
  );
  return selectedNodes;
};

export default useSelectedStateNodes;
