import { FormatTemplate } from "../FormatTemplate";
import valueEnumTemplate from "./valueEnumTemplate";

const descriptionValueEnhancedTemplate: FormatTemplate = {
  valueEnum: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: valueEnumTemplate,
  },
  "@description": {
    type: "string",
    canBeUndefined: false,
  },
  "@defaultValue": {
    type: "string",
    canBeUndefined: false,
  },
  "@min": {
    type: "string",
    canBeUndefined: false,
  },
  "@max": {
    type: "string",
    canBeUndefined: false,
  },
};

export default descriptionValueEnhancedTemplate;
