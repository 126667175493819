import { SpecificationService } from "../../../../../../services/SpecificationService";
import { MessageNodeData } from "../../../../types";

export const getNewMessageNodeData = (
  stepNodeId: string,
  specificationService: SpecificationService
): MessageNodeData => {
  return {
    messageDefinition: specificationService.getEmptyOperatorMessageDefinition(),
    actionMessage: { "@messageId": "" },
    stepNodeId,
  };
};
