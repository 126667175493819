import { FormatTemplate } from "../FormatTemplate";
import documentationCoreTemplate from "./documentationCoreTemplate";
import fileTemplate from "./fileTemplate";

const documentationPHTemplate: FormatTemplate = {
  ...documentationCoreTemplate,
  sequentalFlowChart: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: fileTemplate,
  },
  processFlowDiagram: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: fileTemplate,
  },
};

export default documentationPHTemplate;
