import AnsiCharInput from "../../AnsiCharInput";
import { PLCParameterRequiredPropertyInputProps } from "../interfaces/PLCParameterPropertyInputProps";

const NameInput = (props: PLCParameterRequiredPropertyInputProps) => {
  const { id, value, onPropertyChanged } = { ...props };

  return (
    <AnsiCharInput
      id={`${id}-Name`}
      label="Name"
      value={value}
      maxLength={47}
      onChangeCallback={(value: string, valid: boolean) =>
        onPropertyChanged(value, valid)
      }
      fullWidth
      required
    />
  );
};

export default NameInput;

