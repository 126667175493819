import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import { AnsiTextField, ConditionInput, FormGrid } from "@form-components";

/**
 * Defines the properties for the {@link SetParameterBypassInput} component.
 */
interface SetParameterBypassInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {}

/**
 * The input component for specifying SetParameterBypass values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<SetParameterBypassInputProps<TInputs>>}
 */
export default function SetParameterBypassInput<TInputs extends FieldValues>({
  disabled,
  columnSpan,
  name,
}: SetParameterBypassInputProps<TInputs>): ReactElement<
  SetParameterBypassInputProps<TInputs>
> {
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <AnsiTextField
        name={`${name}.@assetId` as Path<TInputs>}
        label="Asset Id"
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
      />

      <AnsiTextField
        name={`${name}.@parameter` as Path<TInputs>}
        label="Parameter"
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
      />

      <AnsiTextField
        name={`${name}.@value` as Path<TInputs>}
        label="Value"
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
      />

      <ConditionInput
        name={`${name}.condition` as Path<TInputs>}
        label="Condition"
        disabled={disabled}
      />
    </FormGrid>
  );
}
