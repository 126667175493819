import { ReactElement } from "react";
import "./InputLabel.css";

interface InputLabelWrapperProps {
  children: ReactElement | ReactElement[];
  label?: string;
  required?: boolean;
  htmlFor?: string;
}

const InputLabel = (props: InputLabelWrapperProps) => {
  const { children, label, required, htmlFor } = { ...props };
  return (
    <div className="input-label-container">
      {label && (
        <div className="input-label-wrapper">
          <label className="input-label" htmlFor={htmlFor}>
            {`${label} ${required ? "*" : ""}`}
          </label>
        </div>
      )}
      {children}
    </div>
  );
};

export default InputLabel;
