import { useContext, useMemo } from "react";
import { Node } from "reactflow";
import { AppDispatchContext, AppStateContext } from "../../../state/Context";
import { BranchingNodeData, StateLinkNodeData, StepNodeData } from "../types";
import useEditBranchingNodeDataModal from "../components/nodes/BranchingNode/hooks/useEditBranchingNodeDataModal";
import useEditStepNodeDataModal from "../components/nodes/StepNode/hooks/useEditStepNodeDataModal";
import IAddNodeService from "../services/interfaces/IAddNodeService";
import AddNodeService from "../services/AddNodeService";
import useSelectedStateNodes from "./useSelectedStateNodes";
import useEditStateLinkNodeDataModal from "../components/nodes/StateLinkNode/hooks/useEditStateLinkNodeDataModal";
import AddNodeModalService from "../services/interfaces/AddNodeModalService";

const useAddNode = (): IAddNodeService => {
  const selectedStateNodes: Node[] = useSelectedStateNodes();
  const { specificationService } = useContext(AppStateContext);
  const appDispatch = useContext(AppDispatchContext);
  const addStepNodeModalService: AddNodeModalService<StepNodeData> =
    useEditStepNodeDataModal();
  const addBranchingNodeDataModalService: AddNodeModalService<BranchingNodeData> =
    useEditBranchingNodeDataModal();
  const addStateLinkNodeModalService: AddNodeModalService<StateLinkNodeData> =
    useEditStateLinkNodeDataModal();

  const addNodeService = useMemo(
    () =>
      new AddNodeService(
        appDispatch,
        addBranchingNodeDataModalService,
        addStepNodeModalService,
        addStateLinkNodeModalService,
        selectedStateNodes,
        specificationService
      ),
    [
      appDispatch,
      addBranchingNodeDataModalService,
      addStateLinkNodeModalService,
      addStepNodeModalService,
      selectedStateNodes,
      specificationService,
    ]
  );

  return addNodeService;
};

export default useAddNode;
