import { ReactElement } from "react";
import { Form, FormTab, FormTabs } from "@form-components";
import { FaceplateElement } from "Asset_Specification";
import AttributesTabContent, {
  attributeNames,
} from "./TabContent/AttributesTabContent";
import ValueValidationTabContent, {
  valueValidationName,
} from "./TabContent/ValueValidationTabContent";
import PropertyTabContent, {
  propertyName,
} from "./TabContent/PropertyTabContent";

interface EditFaceplateElementFormProps {
  id: string;
  faceplateElement: FaceplateElement;
  onFaceplateElementSubmitted: (faceplateElement: FaceplateElement) => void;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onValidityChange: (isValid: boolean) => void;
}

const EditFaceplateElementForm = ({
  id,
  faceplateElement,
  onFaceplateElementSubmitted,
  formRef,
  onValidityChange,
}: EditFaceplateElementFormProps): ReactElement<EditFaceplateElementFormProps> => {
  return (
    <Form<FaceplateElement>
      id={id}
      formRef={formRef}
      onSubmit={onFaceplateElementSubmitted}
      defaultValues={faceplateElement}
      mode="onChange"
      onValidityChange={onValidityChange}
    >
      <FormTabs>
        <FormTab heading="Attributes" names={attributeNames}>
          <AttributesTabContent />
        </FormTab>
        <FormTab heading="Property" names={propertyName}>
          <PropertyTabContent />
        </FormTab>
        <FormTab heading="Value Validation" names={valueValidationName}>
          <ValueValidationTabContent />
        </FormTab>
      </FormTabs>
    </Form>
  );
};

export default EditFaceplateElementForm;
