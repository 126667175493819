import { FormatTemplate } from "../FormatTemplate";
import parameterTemplate from "./parameterTemplate";

const parameterListTemplate: FormatTemplate = {
  parameter: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: parameterTemplate,
  },
};

export default parameterListTemplate;
