import {reactifySartoriusUiComponent} from "@umetrics/sartorius-ui-reactify";
import {CheckboxProperties} from "@umetrics/sartorius-ui-checkbox";
import { withShadowDOM } from "./withShadowDOM";

interface CheckboxGroupProperties2  {
    selected?: string;
    checkboxesGroup?: Array<string>;
}

const SCheckbox = reactifySartoriusUiComponent<CheckboxProperties>("sartorius-ui-checkbox");
const SCheckboxGroup = reactifySartoriusUiComponent<CheckboxGroupProperties2>("sartorius-ui-checkbox-group");

export const SuiCheckbox = withShadowDOM(SCheckbox);
export const SuiCheckboxGroup = withShadowDOM(SCheckboxGroup);
