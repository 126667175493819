import { AppReducerAction } from "../../../state/reducers/appReducer";
import { Edge, Node } from "reactflow";
import {
  BranchingNodeData,
  CaseNodeData,
  MessageNodeData,
  StepNodeData,
  StateLinkNodeData,
} from "../types";
import { getBranchingCases } from "../../../utils/branchingUtils";
import { recalculateCaseNodePositions } from "./editorFunctions";
import { Dispatch } from "react";
import {
  isBranchNode,
  isCaseNode,
  isStepNode,
} from "../components/nodes/nodeUtils";
import { OperatorMessageDefinition } from "Asset_Specification";
import { Step } from "types";

export const deleteStepNode = (
  stepNode: Node<StepNodeData>,
  allNodes: Node[],
  allEdges: Edge[],
  dispatch: Dispatch<AppReducerAction>
): void => {
  let nodes = allNodes.filter((node: Node) => node.id !== stepNode.id);
  const operatorMessageNode = nodes.find(
    (opNode) =>
      opNode.data.messageDefinition &&
      opNode.data.messageDefinition["@id"] ===
        stepNode.data.step.actionOperatorMessage?.["@messageId"]
  );

  if (operatorMessageNode) {
    nodes = nodes.filter((node: Node) => node.id !== operatorMessageNode.id);
    dispatch({
      type: "remove-operator-message",
      messageId: operatorMessageNode.data.messageDefinition["@id"],
    });
  }

  const connectedNodeIds: string[] = allEdges
    .filter((edge: Edge) => edge.target === stepNode.id)
    .map((edge: Edge) => edge.source);
  updateConnectedCaseNodes(nodes, connectedNodeIds, dispatch);

  dispatch({ type: "set-nodes", nodes });
  dispatch({ type: "remove-step", step: stepNode.data.step });
};

export const deleteMessageOperatorNode = (
  messageNode: Node<MessageNodeData>,
  allNodes: Node[],
  allEdges: Edge[],
  dispatch: Dispatch<AppReducerAction>
): void => {
  const nodesWithoutMessageNode: Node[] = allNodes.filter(
    (node: Node) => node.id !== messageNode.id
  );
  const messageDefinition: OperatorMessageDefinition =
    messageNode.data.messageDefinition;

  const { nodes, edges } = updateNodesAndEdgesOnDeletingMessageNode(
    messageDefinition,
    nodesWithoutMessageNode,
    allEdges,
    dispatch
  );

  dispatch({
    type: "remove-operator-message",
    messageId: messageDefinition["@id"] as string,
  });
  dispatch({
    type: "set-nodes",
    nodes,
  });
  dispatch({
    type: "set-edges",
    edges,
  });
};

const updateNodesAndEdgesOnDeletingMessageNode = (
  messageDefinition: OperatorMessageDefinition,
  nodes: Node[],
  edges: Edge[],
  dispatch: Dispatch<AppReducerAction>
): { nodes: Node[]; edges: Edge[] } => {
  const connectedStepNode: Node<StepNodeData> | undefined = nodes.find(
    (node: Node) =>
      isStepNode(node) &&
      node.data.step.actionOperatorMessage &&
      node.data.step.actionOperatorMessage["@messageId"] ===
        messageDefinition["@id"]
  );
  if (!connectedStepNode) {
    return { nodes, edges };
  }

  const newStep: Step = {
    ...connectedStepNode.data.step,
    actionOperatorMessage: undefined,
  };
  connectedStepNode.data = {
    ...connectedStepNode.data,
    step: {
      ...newStep,
    },
  };

  if (!newStep.branching) {
    return { nodes, edges };
  }

  const caseNodeIds: string[] =
    nodes.find(
      (node: Node) =>
        isBranchNode(node) && node.data.stepNodeId === connectedStepNode.id
    )?.data.caseNodeIds ?? [];

  if (caseNodeIds.length < 1) {
    return { nodes, edges };
  }

  let newNodes: Node[] = [...nodes];

  caseNodeIds.forEach((caseNodeId: string) => {
    const caseNodeToDelete: Node<CaseNodeData> | undefined = newNodes.find(
      (node: Node) =>
        isCaseNode(node) &&
        node.id === caseNodeId &&
        node.data.case.condition["@operator"] === "OM"
    );
    if (!caseNodeToDelete) {
      return;
    }

    newNodes = deleteCaseNodeFromNodes(caseNodeToDelete, newNodes, dispatch);
  });
  const newEdges = edges.filter(
    (edge: Edge) =>
      !caseNodeIds.includes(edge.source) && !caseNodeIds.includes(edge.target)
  );

  return { nodes: newNodes, edges: newEdges };
};

export const deleteBranchingNode = (
  branchingNode: Node<BranchingNodeData>,
  allNodes: Node[],
  dispatch: Dispatch<AppReducerAction>
): void => {
  const caseNodeIds = branchingNode.data.caseNodeIds;
  const nodes = allNodes.filter(
    (node) => node.id !== branchingNode.id && !caseNodeIds.includes(node.id)
  );
  const stepNode: Node<StepNodeData> | undefined = nodes.find(
    (node) => node.id === branchingNode.data.stepNodeId
  );

  if (stepNode) {
    stepNode.data.step.branching = undefined;
    dispatch({ type: "update-step", newStepValue: stepNode.data.step });
  }

  dispatch({ type: "set-nodes", nodes });
};

export const deleteCaseNode = (
  caseNode: Node<CaseNodeData>,
  allNodes: Node[],
  dispatch: Dispatch<AppReducerAction>
): void => {
  const nodes = deleteCaseNodeFromNodes(caseNode, allNodes, dispatch);
  dispatch({
    type: "set-nodes",
    nodes,
  });
};

const deleteCaseNodeFromNodes = (
  caseNode: Node<CaseNodeData>,
  allNodes: Node[],
  dispatch: Dispatch<AppReducerAction>
): Node[] => {
  const nodes = allNodes.filter((node) => node.id !== caseNode.id);
  const branchingNode: Node<BranchingNodeData> | undefined = nodes.find(
    (node) => node.id === caseNode.data.branchingNodeId
  );

  if (!branchingNode) {
    return nodes;
  }

  branchingNode.data.caseNodeIds = branchingNode.data.caseNodeIds.filter(
    (nodeId) => nodeId !== caseNode.id
  );
  updateBranchingAndCaseNodes(nodes, branchingNode, dispatch);
  return recalculateCaseNodePositions(nodes, branchingNode.data.caseNodeIds);
};

export const deleteStateLinkNode = (
  stateLinkNode: Node<StateLinkNodeData>,
  allNodes: Node[],
  allEdges: Edge[],
  dispatch: Dispatch<AppReducerAction>
): void => {
  const nodes: Node[] = allNodes.filter(
    (node: Node) => node.id !== stateLinkNode.id
  );
  const connectedNodeIds: string[] = allEdges
    .filter((edge: Edge) => edge.target === stateLinkNode.id)
    .map((edge: Edge) => edge.source);
  updateConnectedCaseNodes(nodes, connectedNodeIds, dispatch);
  dispatch({
    type: "set-nodes",
    nodes,
  });
};

const updateConnectedCaseNodes = (
  nodes: Node[],
  connectedNodeIds: string[],
  dispatch: Dispatch<AppReducerAction>
): void => {
  const branchingNodesToUpdate: Node<BranchingNodeData>[] = [];
  for (const node of nodes) {
    if (!connectedNodeIds.includes(node.id) || !isCaseNode(node)) {
      continue;
    }

    node.data.case["@subStateId"] = "";
    const branchingNode: Node<BranchingNodeData> | undefined = nodes.find(
      (otherNode: Node) => otherNode.id === node.data.branchingNodeId
    );
    if (branchingNode && !branchingNodesToUpdate.includes(branchingNode)) {
      branchingNodesToUpdate.push(branchingNode);
    }
  }

  for (const branchingNode of branchingNodesToUpdate) {
    updateBranchingAndCaseNodes(nodes, branchingNode, dispatch);
  }
};

const updateBranchingAndCaseNodes = (
  nodes: Node[],
  branchingNode: Node<BranchingNodeData> | undefined,
  dispatch: Dispatch<AppReducerAction>
): void => {
  if (!branchingNode) {
    return;
  }

  const branchingStepNode: Node<StepNodeData> | undefined = nodes.find(
    (node) => node.id === branchingNode.data.stepNodeId
  );

  if (!branchingStepNode?.data.step.branching) {
    return;
  }

  const cases = getBranchingCases(branchingNode.data.caseNodeIds, nodes);
  branchingStepNode.data.step.branching.case = cases;

  dispatch({
    type: "update-step",
    newStepValue: branchingStepNode.data.step,
  });
};
