import { ReactElement } from "react";
import { Path, UseFormGetValues } from "react-hook-form";
import { Case } from "Asset_Specification";
import {
  AccordionInput,
  ActionRefreshParameterInput,
  FormGrid,
  FormTab,
} from "@form-components";
import { defaultActionRefreshParameter } from "@utils/actionRefreshParameterUtils";

const refreshParameterName: Path<Case> = "refreshParameter";

const RefreshParameterTab = (): ReactElement => {
  return (
    <FormTab heading="Refresh Parameters" names={refreshParameterName}>
      <FormGrid>
        <AccordionInput<Case, "refreshParameter">
          heading="Refresh Parameters"
          name={refreshParameterName}
          defaultValue={{ ...defaultActionRefreshParameter }}
          getItemLabel={(index: number, getValues: UseFormGetValues<Case>) =>
            getValues(`${refreshParameterName}.${index}.@parameterId`) ?? ""
          }
          itemRenderer={({ itemKey, name }) => (
            <ActionRefreshParameterInput name={name} key={itemKey} />
          )}
        />
      </FormGrid>
    </FormTab>
  );
};

export default RefreshParameterTab;
