import { ReactElement } from "react";
import { FieldValues } from "react-hook-form";
import Select, { SelectProps } from "../../basic/Select/Select";
import useSnapShotIds from "./hooks/useSnapShotIds";

/**
 * Defines the properties for the {@link SelectSnapShotId}.
 */
interface SelectSnapShotIdProps<TInputs extends FieldValues>
  extends Omit<SelectProps<TInputs>, "options"> {}

/**
 * The component for selecting snapshot ids from those defined in the underlying specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<SelectSnapShotIdProps<TInputs>>}
 */
export default function SelectSnapShotId<TInputs extends FieldValues>(
  props: SelectSnapShotIdProps<TInputs>
): ReactElement<SelectSnapShotIdProps<TInputs>> {
  const { snapShotIdOptions } = useSnapShotIds();
  return <Select<TInputs> options={snapShotIdOptions} {...props} />;
}
