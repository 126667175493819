import ErrorPage from "pages/ErrorPage/ErrorPage";
import { ReactElement } from "react";
import { NavigateFunction, useNavigate, useRouteError } from "react-router-dom";

const RouterErrorPage = (): ReactElement => {
  const error: unknown = useRouteError();
  const navigate: NavigateFunction = useNavigate();

  return (
    <ErrorPage
      error={error}
      resetErrorBoundary={
        () => navigate(0) // Refresh page
      }
    />
  );
};

export default RouterErrorPage;
