import { FieldValues } from "react-hook-form";
import Select, { SelectProps } from "../../basic/Select/Select";
import useAssetIds from "./hooks/useAssetIds";
import { ReactElement } from "react";

/**
 * Defines the properties for the {@link SelectAssetId}.
 */
interface SelectAssetIdProps<TInputs extends FieldValues>
  extends Omit<SelectProps<TInputs>, "options"> {}

/**
 * A component for selecting an asset id.
 * @param props the properties to inject into the component
 * @returns {ReactElement<SelectAssetIdProps<TInputs>>}
 */
export default function SelectAssetId<TInputs extends FieldValues>({
  ...selectProps
}: SelectAssetIdProps<TInputs>): ReactElement<SelectAssetIdProps<TInputs>> {
  const { assetIdOptions } = useAssetIds();
  return <Select<TInputs> options={assetIdOptions} {...selectProps} />;
}
