import { FormatTemplate } from "../FormatTemplate";
import descriptionValueEnhancedTemplate from "./descriptionValueEnhancedTemplate";
import descriptionValueTemplate from "./descriptionValueTemplate";
import labelTemplate from "./labelTemplate";

const reportListTemplate: FormatTemplate = {
  label: {
    type: "array",
    canBeUndefined: false,
    propertyTemplate: labelTemplate,
  },
  value: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: descriptionValueEnhancedTemplate,
  },
  engineeringUnit: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: descriptionValueTemplate,
  },
  displayFormat: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: descriptionValueTemplate,
  },
  "@id": {
    type: "string",
    canBeUndefined: false,
  },
  "@alias": {
    type: "string",
    canBeUndefined: false,
  },
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@allocName": {
    type: "string",
    canBeUndefined: true,
  },
  "@description": {
    type: "string",
    canBeUndefined: false,
  },
  "@dataType": {
    type: "string",
    canBeUndefined: false,
  },
};

export default reportListTemplate;
