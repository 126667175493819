import { FormatTemplate } from "../FormatTemplate";

const omValueTemplate: FormatTemplate = {
  "@id": {
    type: "string",
    canBeUndefined: false,
  },
  "@value": {
    type: "string",
    canBeUndefined: false,
  },
};

export default omValueTemplate;
