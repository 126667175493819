import { FormatTemplate } from "../FormatTemplate";
import conditionTemplate from "./conditionTemplate";

const byPassTemplate: FormatTemplate = {
  condition: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: conditionTemplate,
  },
  "@id": {
    type: "string",
    canBeUndefined: false,
  },
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@nameInParentAsset": {
    type: "string",
    canBeUndefined: true,
  },
  "@type": {
    type: "string",
    canBeUndefined: false,
  },
  "@subtype": {
    type: "string",
    canBeUndefined: false,
  },
  "@alias": {
    type: "string",
    canBeUndefined: false,
  },
  "@description": {
    type: "string",
    canBeUndefined: true,
  },
};

export default byPassTemplate;
