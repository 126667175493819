import { AssetSpecification } from "Asset_Specification";
import { useContext, useEffect, useState } from "react";
import { AppStateContext } from "state/Context";

interface UseAssetSpecificationReturn {
  asset?: AssetSpecification;
}

const useAssetSpecification = (): UseAssetSpecificationReturn => {
  const { specificationService } = useContext(AppStateContext);
  const [asset, setAsset] = useState<AssetSpecification>();

  useEffect(() => {
    setAsset(specificationService?.Specification);
  }, [specificationService]);

  return { asset };
};

export default useAssetSpecification;
