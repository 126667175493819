import { useContext } from "react";
import { Node } from "reactflow";
import {
  AppDispatchContext,
  ModalContext,
} from "../../../../../../state/Context";
import {
  EditOperatorMessageNodeDataModalService,
  IEditOperatorMessageNodeDataModalService,
} from "../services/EditOperatorMessageNodeDataModalService";
import useSelectedStateNodes from "@components/SequentialFlowEditor/hooks/useSelectedStateNodes";

const useEditOperatorMessageNodeDataModal =
  (): IEditOperatorMessageNodeDataModalService => {
    const { dispatch: modalDispatch } = useContext(ModalContext);
    const appDispatch = useContext(AppDispatchContext);
    const selectedNodes: Node[] = useSelectedStateNodes();
    const operatorMessageModalService: IEditOperatorMessageNodeDataModalService =
      new EditOperatorMessageNodeDataModalService(
        appDispatch,
        modalDispatch,
        selectedNodes
      );
    return operatorMessageModalService;
  };

export default useEditOperatorMessageNodeDataModal;
