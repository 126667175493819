import { Edge, Node } from "reactflow";
import applyNodeMigrations from "./applyNodeMigrations";
import applyEdgeMigrations from "./applyEdgeMigrations";

const applyNodeAndEdgeMigrations = (
  nodesAndEdges:
    | { [stateName: string]: { nodes: Node[]; edges: Edge[] } }
    | undefined,
  selectedState: string | undefined,
  nodes: Node[] | undefined, // Historical node format
  edges: Edge[] | undefined // Historical edge format
): { [stateName: string]: { nodes: Node[]; edges: Edge[] } } => {
  if (nodesAndEdges) {
    return nodesAndEdges;
  }

  if (!selectedState) {
    return {};
  }

  const migratedNodes: Node[] = applyNodeMigrations(nodes);
  const migratedEdges: Edge[] = applyEdgeMigrations(edges);
  return { [selectedState]: { nodes: migratedNodes, edges: migratedEdges } };
};

export default applyNodeAndEdgeMigrations;
