export const inequalities: { label: string; value: string }[] = [
  { label: "≤ (le)", value: "le" },
  { label: "< (lt)", value: "lt" },
  { label: "≥ (ge)", value: "ge" },
  { label: "> (gt)", value: "gt" },
];

export const isInequalityOperator = (
  operator: string
): operator is "le" | "lt" | "ge" | "gt" => {
  return (
    operator === "le" ||
    operator === "lt" ||
    operator === "ge" ||
    operator === "gt"
  );
};

export const isComparisonOperator = (
  operator: string
): operator is "le" | "lt" | "ge" | "gt" | "eq" | "ne" => {
  return (
    isInequalityOperator(operator) || operator === "eq" || operator === "ne"
  );
};

export const isLogicalOperator = (
  operator: string
): operator is "and" | "or" => {
  return operator === "and" || operator === "or";
};

export const getComparisonOperatorSymbol = (
  comparisonOperator: "le" | "lt" | "ge" | "gt" | "eq" | "ne"
): string => {
  switch (comparisonOperator) {
    case "le":
      return "≤";
    case "lt":
      return "<";
    case "ge":
      return "≥";
    case "gt":
      return ">";
    case "eq":
      return "=";
    case "ne":
      return "≠";
  }
};

