import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import {
  AccordionInput,
  FormGrid,
  InputPropsWithoutRules,
  OMValueInput,
} from "@form-components";
import { defaultOMValue } from "@utils/utilsForOMValue";
import { ReactElement } from "react";
import {
  ArrayPath,
  FieldArray,
  FieldValues,
  Path,
  UseFormGetValues,
} from "react-hook-form";

/**
 * Defines the properties to inject into the {@link MultiOMValueInput} component.
 */
interface MultiOMValueInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "fullWidth"> {
  fixedDropdown?: boolean;
}

/**
 * The react component for a MultiOMValue specification input.
 * @param props the properties to inject into the component
 * @returns a {@link ReactElement} representing the MultiOMValue.
 */
export default function MultiOMValueInput<TInputs extends FieldValues>({
  disabled,
  label,
  columnSpan,
  name,
  fixedDropdown,
}: MultiOMValueInputProps<TInputs>): ReactElement<
  MultiOMValueInputProps<TInputs>
> {
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <AccordionInput<TInputs, ArrayPath<TInputs>>
        heading={label}
        name={name as ArrayPath<TInputs>}
        defaultValue={
          { ...defaultOMValue } as FieldArray<TInputs, ArrayPath<TInputs>>
        }
        getItemLabel={(index: number, getValues: UseFormGetValues<TInputs>) =>
          getValues(`${name}.${index}.@id` as Path<TInputs>) ?? ""
        }
        itemRenderer={({ itemKey, name }) => (
          <OMValueInput<TInputs>
            key={itemKey}
            name={name as Path<TInputs>}
            disabled={disabled}
            fixedDropdown={fixedDropdown}
          />
        )}
        maxLength={4}
      />
    </FormGrid>
  );
}
