import {
  FormGrid,
  FormTab,
  FormTabs,
  MultiOMValueInput,
} from "@form-components";
import { ReactElement } from "react";
import { Path } from "react-hook-form";
import "./ActionTabContent.css";
import { MessageNodeDataForm } from "../../interfaces";

export const actionName: Path<MessageNodeDataForm> = "actionMessage";

interface MultiOMValuesConfig {
  name: Path<MessageNodeDataForm>;
  label: string;
}

const multiOMValuesConfigs: MultiOMValuesConfig[] = [
  {
    name: `${actionName}.viewValues.viewValue`,
    label: "View Values",
  },
  {
    name: `${actionName}.presetValues.presetValue`,
    label: "Preset Values",
  },
  {
    name: `${actionName}.returnValues.returnValue`,
    label: "Return Values",
  },
];

const ActionTabContent = (): ReactElement => {
  return (
    <FormGrid className="action-tab-content-container">
      <FormTabs>
        {multiOMValuesConfigs.map(({ name, label }: MultiOMValuesConfig) => (
          <FormTab<MessageNodeDataForm> key={name} heading={label} names={name}>
            <MultiOMValueInput<MessageNodeDataForm>
              name={name}
              label={label}
              fixedDropdown
            />
          </FormTab>
        ))}
      </FormTabs>
    </FormGrid>
  );
};

export default ActionTabContent;
