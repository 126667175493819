import {
  ValidationRule,
  ValidationValue,
  ValidationValueMessage,
} from "react-hook-form";
import { isObject } from "../../../utils/objectUtils";
import { RegexpRules, Rules, StringRules } from "../interfaces/Rules";

export const requiredRuleErrorMessage: string = "This is a mandatory field!";

/**
 * A utility function for getting the value of a validation rule.
 * @param rule - The validation rule to get the value of
 * @returns - The rule value, or undefined.
 */
export function getRuleValue<TValidationValue extends ValidationValue>(
  rule: ValidationRule<TValidationValue> | undefined
): TValidationValue | undefined {
  return rule !== undefined && isValidationValueMessage(rule)
    ? rule.value
    : rule;
}

/**
 * Constructs the {@link StringRules} object with a pattern field using the provided {@link RegExp}
 * @param {RegexpRules} rules the existing rules without a regexp pattern
 * @param {string} message the message to display when the regexp pattern is not matched by the input
 * @param {RegExp} regExp the {@link RegExp} to use
 * @returns {StringRules} the constructed rules
 */
export const constructRulesWithRegExp = (
  regExp: RegExp,
  message: string,
  rules?: RegexpRules
): StringRules => {
  const initialRules: RegexpRules = rules ?? {};
  return { ...initialRules, pattern: { value: regExp, message: message } };
};

/**
 * Constructs the {@link Rules} object with the required field set to true
 * @param {RegexpRules} rules - the existing rules to set the required field for
 * @returns {Rules} - the constructed rules
 */
export const constructRulesWithRequired = (rules: Rules): Rules => {
  return { ...rules, required: true };
};

function isValidationValueMessage<TValidationValue extends ValidationValue>(
  rule: TValidationValue | ValidationValueMessage<TValidationValue>
): rule is ValidationValueMessage<TValidationValue> {
  return isObject(rule) && "value" in rule && "message" in rule;
}

export const getAlreadyExistingValueRule = <T>(
  values: T[],
  message?: string
) => ({
  alreadyExisitingValueRule: (value: T) => {
    if (values.includes(value)) {
      return message ?? "Provided value must be unique!";
    }

    return true;
  },
});
