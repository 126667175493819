import { useReducer, useState } from "react";
import { CalculationDefinition } from "../../../Asset_Specification";
import EditModalProps from "../../EditModal/interfaces/EditModalProps";
import EditModal from "../../EditModal/EditModal";
import {
  getCalculationDefinitionFromCalculationForm,
  getCalculationFormFromCalculationDefinition,
} from "../Form/utils/calculationFormUtils";
import EditCalculationsForm from "../Form/EditCalculationsForm";
import { ReducerAction, createReducer } from "../../../state/reducers/reducer";
import CalculationForm from "../Form/interfaces/CalculationForm";

const EditCalculationsModal = (
  props: EditModalProps<CalculationDefinition>
) => {
  const { modalHeading, onSaveItemCallback, onCancelCallback, itemEntry } = {
    ...props,
  };

  const [valid, setValid] = useState<boolean>(itemEntry !== undefined);
  const [calculationsFormData, setCalculationsFormData] = useReducer(
    createReducer<CalculationForm>(),
    itemEntry?.[1],
    getCalculationFormFromCalculationDefinition
  );

  const onSaveCalculationDefinitionButtonClick = (_: React.MouseEvent) => {
    const calculationDefinitionToSave: CalculationDefinition =
      getCalculationDefinitionFromCalculationForm(calculationsFormData);
    onSaveItemCallback(calculationDefinitionToSave, itemEntry?.[0]);
  };

  const onSetCalculationFormCallback = (
    action: ReducerAction<CalculationForm>
  ) => {
    setCalculationsFormData(action);

    const newCalculationsFormData =
      action.type === "set-prop"
        ? {
            ...calculationsFormData,
            [action.payload.key]: action.payload.value,
          }
        : (action.payload as CalculationForm);
    const newValid = Object.values(newCalculationsFormData).every(
      (calculationPropertyPair) => calculationPropertyPair[1]
    );
    setValid(newValid);
  };

  return (
    <EditModal
      modalHeading={modalHeading}
      valid={valid}
      onSaveItemCallback={onSaveCalculationDefinitionButtonClick}
      onCancelCallback={onCancelCallback}
    >
      <EditCalculationsForm
        calculationDefinitionForm={calculationsFormData}
        setCalculationFormCallback={onSetCalculationFormCallback}
      />
    </EditModal>
  );
};

export default EditCalculationsModal;
