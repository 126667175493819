import { Handle, NodeProps, Position } from "reactflow";
import { BranchingNodeData, NodeType } from "../../../types";
import { HandleTypes } from "../../../utils/types";
import NodeButtonContainer from "../../NodeButtonContainer/NodeButtonContainer";
import add from "@umetrics/sartorius-ui-icon/dist/icons/add";
import EditNodeModalService from "../../../services/interfaces/EditNodeModalService";
import useEditBranchingNodeDataModal from "./hooks/useEditBranchingNodeDataModal";
import useAddNode from "../../../hooks/useAddNode";
import IAddNodeService from "../../../services/interfaces/IAddNodeService";
import "./BranchingNode.css";
import { AddBranchingNodeData } from "../../../services/interfaces/AddNodeData";
import useBranchingNodeWidth from "./hooks/useBranchingNodeWidth";

const BranchingNode = (props: NodeProps<BranchingNodeData>) => {
  const addNodeService: IAddNodeService = useAddNode();
  const editBranchingNodeDataModalService: EditNodeModalService<BranchingNodeData> =
    useEditBranchingNodeDataModal();
  const branchingNodeWidth = useBranchingNodeWidth(props.data.caseNodeIds);

  const addNewCase = (): void => {
    const addBranchingNodeData: AddBranchingNodeData = {
      branchingNodeId: props.id,
    };
    addNodeService.addNode(NodeType.case, undefined, addBranchingNodeData);
  };

  const onEditCallback = (): void => {
    editBranchingNodeDataModalService.openEditModal(props.id, props.data);
  };

  return (
    <div
      className="branching-node-container"
      style={{ width: `${branchingNodeWidth}px` }}
    >
      <p className="branching-node-label">Branching</p>
      <NodeButtonContainer
        className="branching-node-button-container"
        nodeId={props.id}
        onEditCallback={onEditCallback}
        additionalBtns={[
          {
            title: "Add Case",
            icon: add,
            onClick: (_) => addNewCase(),
          },
        ]}
      />
      <Handle
        position={Position.Top}
        type="target"
        id={HandleTypes.branchingTarget}
      />
    </div>
  );
};

export default BranchingNode;
