import { useContext, useMemo } from "react";
import {
  AppDispatchContext,
  ModalContext,
} from "../../../../../../state/Context";
import EditBranchingNodeDataModalService, {
  IEditBranchingNodeDataModalService,
} from "../services/EditBranchingNodeDataModalService";
import useEditNode from "../../../../hooks/useEditNode";
import IEditNodeService from "../../../../services/interfaces/IEditNodeService";

const useEditBranchingNodeDataModal =
  (): IEditBranchingNodeDataModalService => {
    const { dispatch: modalDispatch } = useContext(ModalContext);
    const appDispatch = useContext(AppDispatchContext);
    const editNodeService: IEditNodeService = useEditNode();
    const editBranchingNodeDataModalService: IEditBranchingNodeDataModalService =
      useMemo(
        () =>
          new EditBranchingNodeDataModalService(
            appDispatch,
            modalDispatch,
            editNodeService
          ),
        [appDispatch, modalDispatch, editNodeService]
      );
    return editBranchingNodeDataModalService;
  };

export default useEditBranchingNodeDataModal;

