import {
  MouseEvent,
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  ArrayPath,
  FieldArray,
  FieldValues,
  UseFieldArrayReturn,
  UseFormGetValues,
} from "react-hook-form";
import { ArrayInputItemProps, ArrayInputProps } from "../ArrayInput";
import useArrayInput from "../hooks/useArrayInput";
import { SuiButton } from "@components/SuiButton";
import ContainerWithHeader from "@components/ContainerWithHeader/ContainerWithHeader";
import { SuiIcon } from "@components/SuiIcon";
import addIcon from "@umetrics/sartorius-ui-icon/dist/icons/add";
import { SuiAccordion } from "@components/SuiAccordion";
import AccordionInputItem from "./AccordionInputItem";
import "./AccordionInput.css";

/**
 * Defines the properties of the {@link AccordionInput} component.
 */
interface AccordionInputProps<
  TInputs extends FieldValues,
  TArrayPath extends ArrayPath<TInputs>,
> extends Omit<ArrayInputProps<TInputs, TArrayPath>, "fields"> {
  heading: string;
  defaultValue: FieldArray<TInputs, TArrayPath>;
  getItemLabel: (index: number, getValues: UseFormGetValues<TInputs>) => string;
  maxLength?: number;
  shouldUnregister?: boolean;
}

/**
 * The input component for form fields which are arrays and presented as an accordion.
 * @param props the properties to inject into the component
 * @returns {ReactElement<AccordionInputProps<TInputs>>}
 */
export default function AccordionInput<
  TInputs extends FieldValues,
  TArrayPath extends ArrayPath<TInputs> = ArrayPath<TInputs>,
>({
  heading,
  name,
  defaultValue,
  itemRenderer,
  getItemLabel,
  minLength,
  maxLength,
  shouldUnregister,
}: AccordionInputProps<TInputs, TArrayPath>): ReactElement<
  AccordionInputProps<TInputs, TArrayPath>
> {
  const accordionRef: MutableRefObject<HTMLElement | null> =
    useRef<HTMLElement | null>(null);
  const openIndexRef: MutableRefObject<number | undefined> = useRef<
    number | undefined
  >(undefined);
  const scrollIntoViewRef: MutableRefObject<boolean> = useRef<boolean>(false);

  const { arrayInput, count, append } = useArrayInput({
    name,
    minLength,
    shouldUnregister,
    itemRenderer: ({
      itemKey,
      name,
      index,
      remove,
    }: ArrayInputItemProps<TInputs, TArrayPath> &
      Omit<UseFieldArrayReturn<TInputs, TArrayPath>, "fields">) => (
      <AccordionInputItem
        key={itemKey}
        itemKey={itemKey}
        getItemLabel={(getValues: UseFormGetValues<TInputs>) =>
          getItemLabel(index, getValues)
        }
        open={openIndexRef.current === index}
        onDeleteItem={(_: MouseEvent) => remove(index)}
        index={index}
        name={name}
        itemRenderer={itemRenderer}
      />
    ),
  });

  const onAddItemButtonClick = (_: MouseEvent) => {
    append(defaultValue);
    openIndexRef.current = count;
    scrollIntoViewRef.current = true;
  };

  const handleAccordionToggle = useCallback((event: Event) => {
    event.stopPropagation(); // Prevents a parent accordion from closing when event keeps bubbling upwards
    const item: any = event.target;
    const items: HTMLCollection = item.parentElement.children;
    const itemIndex = Array.from(items).indexOf(item);

    openIndexRef.current = openIndexRef.current !== itemIndex ? itemIndex : -1; // -1 effectively closes the accordion item
  }, []);

  useEffect(() => {
    const element = accordionRef.current;
    if (!element) {
      return;
    }

    element.addEventListener("accordion-toggle", handleAccordionToggle);
    return () =>
      element.removeEventListener("accordion-toggle", handleAccordionToggle);
  }, [handleAccordionToggle]);

  useEffect(() => {
    const accordionElement = accordionRef.current;
    if (
      !scrollIntoViewRef.current ||
      openIndexRef.current === undefined ||
      openIndexRef.current < 0 ||
      accordionElement === null
    ) {
      return;
    }

    scrollIntoViewRef.current = false;
    const accordionItems: Element[] = Array.from(accordionElement.children);
    if (accordionItems.length <= openIndexRef.current) {
      return;
    }

    accordionItems[openIndexRef.current].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  });

  return (
    <ContainerWithHeader
      heading={heading}
      additionalHeading={
        <>
          <h1 className="accordion-input-heading-count">({count})</h1>
          {minLength && count < minLength && (
            <h2 className="input-error">{`Must have at least ${minLength} items!`}</h2>
          )}
        </>
      }
      headingButtons={
        <SuiButton
          type="primary"
          onClick={onAddItemButtonClick}
          disabled={maxLength !== undefined && count >= maxLength}
          fullHeight
          fullWidth
        >
          <SuiIcon icon={addIcon} slot="icon-slot" size="large" />
        </SuiButton>
      }
    >
      <SuiAccordion
        className="accordion-input-accordion"
        singleOpen
        ref={accordionRef}
      >
        {arrayInput}
      </SuiAccordion>
    </ContainerWithHeader>
  );
}
