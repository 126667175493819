import { ReactElement } from "react";
import { SuiIcon } from "@components/SuiIcon";
import { SuiButton } from "@components/SuiButton";
import plus from "@umetrics/sartorius-ui-icon/dist/icons/add";

/**
 * Defines the properties for the {@link AddConditionButton} component.
 */
interface AddConditionButtonProps {
  onAdd: () => void;
  disabled?: boolean;
}

/**
 * A button component used for adding conditions
 * @param props the properties to inject into the component
 * @returns {ReactElement<AddConditionButtonProps>}
 */
const AddConditionButton = ({
  onAdd,
  disabled,
}: AddConditionButtonProps): ReactElement<AddConditionButtonProps> => {
  return (
    <SuiButton type="primary" onClick={onAdd} disabled={disabled}>
      <SuiIcon icon={plus} />
    </SuiButton>
  );
};

export default AddConditionButton;
