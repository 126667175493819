import { MutableRefObject, useRef, useState } from "react";
import { ExchangeAsset } from "Asset_Specification";
import ExchangeEditorForm from "../Form/ExchangeEditorForm";
import EditModal from "../../EditModal/EditModal";
import EditModalProps from "../../EditModal/interfaces/EditModalProps";
import { defaultExchangeAsset } from "@utils/exchangeAssetUtils";

const formId: string = "EditExchangeAssetForm";

const ExchangeModal = ({
  modalHeading,
  onSaveItemCallback,
  onCancelCallback,
  itemEntry,
}: EditModalProps<ExchangeAsset>) => {
  const formRef: MutableRefObject<HTMLFormElement | null> =
    useRef<HTMLFormElement>(null);
  const [isValid, setIsValid] = useState(itemEntry !== undefined);

  return (
    <EditModal
      modalHeading={modalHeading}
      valid={isValid}
      onCancelCallback={onCancelCallback}
      formRef={formRef}
    >
      <ExchangeEditorForm
        id={formId}
        exchangeAsset={itemEntry?.[1] ?? defaultExchangeAsset}
        onExchangeAssetSubmitted={(newExchangeAsset: ExchangeAsset) =>
          onSaveItemCallback(newExchangeAsset, itemEntry?.[0])
        }
        formRef={formRef}
        onValidityChange={setIsValid}
      />
    </EditModal>
  );
};

export default ExchangeModal;
