import { FormatTemplate } from "../FormatTemplate";
import calculationDefinitionTemplate from "./calculationDefinitionTemplate";

const calculationsTemplate: FormatTemplate = {
  calculationDefinition: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: calculationDefinitionTemplate,
  },
};

export default calculationsTemplate;
