import { CSSProperties, ReactElement } from "react";

interface FormColumnProps {
  children: ReactElement | ReactElement[];
  columnSpanDesktop?: number;
  columnSpanTablet?: number;
  columnSpanMobile?: number;
}

/**
 * A component representing a column within a SuiFormSegment.
 * @param props The properties to pass into the componet {@see FormColumnProps}
 * @returns A react component representing a column within a SuiFormSegment
 * @deprecated - the column spanning API has been integrated into each form input. See {@link ../FormComponents}
 */
const FormColumn = (props: FormColumnProps) => {
  const { children, columnSpanDesktop, columnSpanTablet, columnSpanMobile } = {
    ...props,
  };

  return (
    <div
      style={
        {
          "--column-span": `span ${columnSpanDesktop ?? 12}`,
          "--column-span-tablet": `span ${columnSpanTablet ?? 12}`,
          "--column-span-mobile": `span ${columnSpanMobile ?? 12}`,
        } as CSSProperties
      }
    >
      {children}
    </div>
  );
};

export default FormColumn;
