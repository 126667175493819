import { Node } from "reactflow";
import { BranchingNodeData, StepNodeData } from "../types";
import { getBranchingCases } from "../../../utils/branchingUtils";
import { Branching } from "../../../Asset_Specification";
import AddNodeData, {
  AddBranchingNodeData,
} from "../services/interfaces/AddNodeData";

export const updateStepCasesForBranchingNode = (
  branchingNode: Node<BranchingNodeData> | undefined,
  allNodes: Node[]
): {
  updatedStepNode: Node<StepNodeData> | undefined;
  updatedNodes: Node[];
} => {
  const nodes = [...allNodes];
  const branchingStepNodeIndex = nodes.findIndex(
    (node) => node.id === branchingNode?.data.stepNodeId
  );

  if (!branchingNode || branchingStepNodeIndex === -1) {
    return { updatedStepNode: undefined, updatedNodes: nodes };
  }

  const branchingStepNode = { ...nodes[branchingStepNodeIndex] };
  const newBranchingValue: Branching = {
    "@transitionORStepId": branchingNode.data.transitionORStepId,
    case: getBranchingCases(branchingNode.data.caseNodeIds, nodes),
  };

  branchingStepNode.data = {
    ...branchingStepNode.data,
    step: {
      ...branchingStepNode.data.step,
      branching: newBranchingValue,
    },
  };
  nodes[branchingStepNodeIndex] = branchingStepNode;

  return { updatedStepNode: branchingStepNode, updatedNodes: nodes };
};

export const isAddBranchingNodeData = (
  addNodeData: AddNodeData
): addNodeData is AddBranchingNodeData => {
  return "branchingNodeId" in addNodeData;
};
