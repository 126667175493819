import { useContext, useMemo } from "react";
import { ModalContext } from "../../../../../../state/Context";
import EditNodeModalService from "../../../../services/interfaces/EditNodeModalService";
import { CaseNodeData } from "../../../../types";
import useEditNode from "../../../../hooks/useEditNode";
import IEditNodeService from "../../../../services/interfaces/IEditNodeService";
import EditCaseNodeDataModalService from "../services/EditCaseNodeDataModalService";

const useEditCaseNodeDataModal = (): EditNodeModalService<CaseNodeData> => {
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const editNodeService: IEditNodeService = useEditNode();
  const editNodeDataModalService: EditNodeModalService<CaseNodeData> = useMemo(
    () => new EditCaseNodeDataModalService(modalDispatch, editNodeService),
    [modalDispatch, editNodeService]
  );
  return editNodeDataModalService;
};

export default useEditCaseNodeDataModal;

