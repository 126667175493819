import { ReactElement } from "react";
import { Path } from "react-hook-form";
import {
  ConditionInput,
  FormGrid,
  FormTab,
  SubstateIdInput,
} from "@form-components";
import { Case } from "Asset_Specification";

const substateIdName: Path<Case> = "@subStateId";
const conditionName: Path<Case> = "condition";

const SubstateIdAndConditionTab = (): ReactElement => {
  return (
    <FormTab heading="Substate Id & Condition" names={conditionName}>
      <FormGrid>
        <SubstateIdInput<Case> name={substateIdName} fullWidth />

        <ConditionInput<Case>
          label="Condition"
          name={conditionName}
          rules={{ required: true }}
          operatorsToExclude={["stepId"]}
        />
      </FormGrid>
    </FormTab>
  );
};

export default SubstateIdAndConditionTab;
