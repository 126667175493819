export const messageTypes: string[] = [
  "PlainView",
  "LabelledView",
  "Input",
  "PlainInput",
];

export const messageButtons: string[] = [
  "OK",
  "Save",
  "YesNo",
  "YesNoDefaultYes",
  "YesNoDefaultNo",
  "Empty",
];
