import { ReactElement } from "react";
import { SubState } from "Asset_Specification";
import AttributesTabContent, {
  attributeNames,
} from "./TabContent/AttributesTabContent";
import { Form, FormTab, FormTabs } from "@form-components";
import ActionSetParametersTabContent, {
  actionSetParameterName,
} from "./TabContent/ActionSetParametersTabContent";
import ActionCalculationTabContent, {
  actionCalculationName,
} from "./TabContent/ActionCalculationTabContent";
import ActionRefreshParameterTabContent, {
  actionRefreshParameterName,
} from "./TabContent/ActionRefreshParameterTabContent";
import ActionOperatingSchemeTabContent, {
  actionOperatingSchemeName,
} from "./TabContent/ActionOperatingSchemeTabContent";
import ActionOperatingSchemeCommandTabContent, {
  actionOperatingSchemeCommandName,
} from "./TabContent/ActionOperatingSchemeCommandTabContent";
import ActionSnapShotTabContent, {
  actionSnapshotName,
} from "./TabContent/ActionSnapShotTabContent";
import ActionSetAssetParameterTabContent, {
  actionSetAssetParameterName,
} from "./TabContent/ActionSetAssetParameterTabContent";
import ActionAlarmWarningTabContent, {
  actionAlarmingWarningName,
} from "./TabContent/ActionAlarmingWarningTabContent";

interface EditSubstateFormProps {
  id: string;
  substate: SubState;
  onSubstateSubmitted: (substate: SubState) => void;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onValidityChange: (isValid: boolean) => void;
}

const EditSubstateForm = ({
  id,
  substate,
  onSubstateSubmitted,
  formRef,
  onValidityChange,
}: EditSubstateFormProps): ReactElement<EditSubstateFormProps> => {
  return (
    <Form<SubState>
      id={id}
      formRef={formRef}
      onSubmit={onSubstateSubmitted}
      defaultValues={substate}
      mode="onChange"
      onValidityChange={onValidityChange}
    >
      <FormTabs>
        <FormTab heading="Attributes" names={attributeNames}>
          <AttributesTabContent />
        </FormTab>

        <FormTab heading="Set Parameters" names={actionSetParameterName}>
          <ActionSetParametersTabContent />
        </FormTab>

        <FormTab heading="Calculations" names={actionCalculationName}>
          <ActionCalculationTabContent />
        </FormTab>

        <FormTab
          heading="Refresh Parameters"
          names={actionRefreshParameterName}
        >
          <ActionRefreshParameterTabContent />
        </FormTab>

        <FormTab
          heading="Operating Scheme Commands"
          names={actionOperatingSchemeCommandName}
        >
          <ActionOperatingSchemeCommandTabContent />
        </FormTab>

        <FormTab heading="Operating Schemes" names={actionOperatingSchemeName}>
          <ActionOperatingSchemeTabContent />
        </FormTab>

        <FormTab heading="Snapshots" names={actionSnapshotName}>
          <ActionSnapShotTabContent />
        </FormTab>

        <FormTab
          heading="Set Asset Parameters"
          names={actionSetAssetParameterName}
        >
          <ActionSetAssetParameterTabContent />
        </FormTab>

        <FormTab heading="Alarming Warnings" names={actionAlarmingWarningName}>
          <ActionAlarmWarningTabContent />
        </FormTab>
      </FormTabs>
    </Form>
  );
};

export default EditSubstateForm;
