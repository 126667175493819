import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import { FormGrid, SelectCalculationId } from "@form-components";

/**
 * Defines the properties for the {@link ActionCalculationInput} component.
 */
interface ActionCalculationInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {}

/**
 * The input component for specifying actionCalculation values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<ActionCalculationInputProps<TInputs>>}
 */
export default function ActionCalculationInput<TInputs extends FieldValues>({
  disabled,
  columnSpan,
  name,
}: ActionCalculationInputProps<TInputs>): ReactElement<
  ActionCalculationInputProps<TInputs>
> {
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <SelectCalculationId
        name={`${name}.@calculationId` as Path<TInputs>}
        label="Calculation Id"
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
        fixedDropdown
      />
    </FormGrid>
  );
}
