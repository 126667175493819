import { useContext, useMemo } from "react";
import { AppStateContext } from "state/Context";

/**
 * Defines the return value from the {@link useCalculationIds} hook.
 */
interface UseCalculationIdsReturn {
  calculationIdOptions: { label: string; value: string }[];
}

/**
 * A hook for fetching the ids of parameters for the select parameter id component
 * @returns {UseParameterIdsReturn}
 */
const useCalculationIds = (): UseCalculationIdsReturn => {
  const { specificationService } = useContext(AppStateContext);
  const calculationIdOptions: { label: string; value: string }[] = useMemo(
    () =>
      Object.values(specificationService?.getCalculations() ?? {}).map(
        (calculationDefinition) => ({
          label: `${calculationDefinition["@name"]} (${calculationDefinition["@id"]})`,
          value: calculationDefinition["@id"],
        })
      ),
    [specificationService]
  );

  return { calculationIdOptions };
};

export default useCalculationIds;
