import { MutableRefObject, ReactElement, useEffect, useRef } from "react";
import {
  ArrayInputItemProps,
  SubstateIdInput,
  threeColumnsSpan,
  useArrayInput,
} from "@form-components";
import { Path, UseFieldArrayReturn, useFormContext } from "react-hook-form";
import { OperatorMessageButtonSet } from "Asset_Specification";
import { MessageNodeDataForm } from "../interfaces";
import { OperatorMessageCase } from "@utils/branchingUtils";

const isYesNoButtonSet = (buttonSet: OperatorMessageButtonSet): boolean => {
  return (
    buttonSet === "YesNo" ||
    buttonSet === "YesNoDefaultNo" ||
    buttonSet === "YesNoDefaultYes"
  );
};

const SubstateIdsInput = (): ReactElement => {
  const { watch, getValues } = useFormContext<MessageNodeDataForm>();
  const { arrayInput, append, remove } = useArrayInput<
    MessageNodeDataForm,
    "caseSubstateIds"
  >({
    name: "caseSubstateIds",
    itemRenderer: ({
      itemKey,
      name,
    }: ArrayInputItemProps<MessageNodeDataForm, "caseSubstateIds"> &
      Omit<
        UseFieldArrayReturn<MessageNodeDataForm, "caseSubstateIds">,
        "fields"
      >) => {
      const operatorMessageCase: OperatorMessageCase = getValues(
        `${name}.operatorMessageCase` as `caseSubstateIds.${number}.operatorMessageCase`
      );
      const label: string = `Substate Id for Operator '${operatorMessageCase}'`;
      return (
        <SubstateIdInput<MessageNodeDataForm>
          key={itemKey}
          name={`${name}.substateId` as Path<MessageNodeDataForm>}
          label={label}
          columnSpan={threeColumnsSpan}
          fullWidth
        />
      );
    },
  });
  const buttonSet: OperatorMessageButtonSet = watch(
    "messageDefinition.@buttonSet"
  );
  const previousButtonSet: MutableRefObject<OperatorMessageButtonSet> =
    useRef<OperatorMessageButtonSet>(buttonSet);

  useEffect(() => {
    const previousButtonSetIsYesNo: boolean = isYesNoButtonSet(
      previousButtonSet.current
    );
    const currentButtonSetIsYesNo: boolean = isYesNoButtonSet(buttonSet);
    previousButtonSet.current = buttonSet;
    if (previousButtonSetIsYesNo && currentButtonSetIsYesNo) {
      return;
    }

    remove(); // Removes all items
    const substateId: string = "";
    switch (buttonSet) {
      case "Empty":
        return;
      case "OK":
        return append({
          operatorMessageCase: OperatorMessageCase.Ok,
          substateId,
        });
      case "Save":
        return append({
          operatorMessageCase: OperatorMessageCase.Save,
          substateId,
        });
      case "YesNo":
      case "YesNoDefaultNo":
      case "YesNoDefaultYes":
        return append([
          {
            operatorMessageCase: OperatorMessageCase.Yes,
            substateId,
          },
          {
            operatorMessageCase: OperatorMessageCase.No,
            substateId,
          },
        ]);
    }
  }, [append, buttonSet, remove]);

  return <>{arrayInput}</>;
};

export default SubstateIdsInput;
