import { NodeTypes } from "reactflow";
import {
  ActionOperatorMessage,
  Branching,
  Case,
  OperatorMessageDefinition,
} from "../../Asset_Specification";
import { Step } from "../../types";
import BranchingNode from "./components/nodes/BranchingNode/BranchingNode";
import CaseNode from "./components/nodes/CaseNode/CaseNode";
import MessageNode from "./components/nodes/MessageNode/MessageNode";
import StartNode from "./components/nodes/StartNode/StartNode";
import StepNode from "./components/nodes/StepNode/StepNode";
import StateLinkNode from "./components/nodes/StateLinkNode/StateLinkNode";

export enum NodeType {
  step = "stepNode",
  start = "startNode",
  message = "messageNode",
  branching = "branchingNode",
  case = "caseNode",
  stateLink = "stateLinkNode",
}

export const nodeTypes: NodeTypes = {
  messageNode: MessageNode,
  startNode: StartNode,
  stepNode: StepNode,
  branchingNode: BranchingNode,
  caseNode: CaseNode,
  stateLinkNode: StateLinkNode,
};

export interface NodeData {
  label?: string;
}

export interface ConditionNodeData extends NodeData {
  branching: Branching;
  stepNodeId: string;
}

export interface BranchingNodeData extends NodeData {
  caseNodeIds: string[];
  stepNodeId?: string;
  transitionORStepId?: string;
}

export interface CaseNodeData extends NodeData {
  branchingNodeId: string;
  case: Case;
}

export interface StepNodeData extends NodeData {
  step: Step;
}

export interface MessageNodeData {
  actionMessage?: ActionOperatorMessage;
  messageDefinition: OperatorMessageDefinition;
  stepNodeId: string;
}

export interface StateLinkNodeData extends NodeData {
  substateId: string;
  state: string;
}
