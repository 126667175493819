import { FieldError } from "react-hook-form";

/**
 * A utility function which will log a warning message if no message is found on the field error object passed in.
 * This is for helping developers debug when using the input in forms and they forget to set a message for a rule.
 * @param name the name/path of the field which has an error.
 * @param error the error for the field.
 */
export const maybeWarnAboutMissingErrorMessage = (
  name: string,
  error: FieldError | undefined
): void => {
  if (error && error.type !== "required" && !error.message) {
    console.warn(`No error message was set for the input with name '${name}'!`);
  }
};
