import {
  AllocNameTextField,
  AnsiTextField,
  FormGrid,
  FormTab,
  RequiredIdTextField,
  RequiredNonEmptyNameTextField,
  Select,
  SelectDataType,
  twoColumnsSpan,
} from "@form-components";
import { Parameter, ParameterType } from "Asset_Specification";
import { ReactElement } from "react";
import { Path } from "react-hook-form";

export const attributeNames: Path<Parameter>[] = [
  "@alias",
  "@type",
  "@description",
  "@dataType",
  "@id",
  "@name",
];

const parameterTypeOptions: ParameterType[] = [
  "Process",
  "Config",
  "Memory",
  "Allocation",
];

const AttributesTab = (): ReactElement => {
  return (
    <FormTab heading="Attributes" names={attributeNames}>
      <FormGrid>
        <RequiredIdTextField name="@id" fullWidth columnSpan={twoColumnsSpan} />
        <AnsiTextField
          name="@description"
          label="Description"
          rules={{ required: true }}
          fullWidth
          columnSpan={twoColumnsSpan}
        />
        <RequiredNonEmptyNameTextField
          name="@name"
          fullWidth
          columnSpan={twoColumnsSpan}
        />
        <Select
          name="@type"
          label="Type"
          rules={{ required: true }}
          options={parameterTypeOptions}
          fullWidth
          columnSpan={twoColumnsSpan}
        />
        <AllocNameTextField
          name="@allocName"
          fullWidth
          columnSpan={twoColumnsSpan}
        />
        <SelectDataType
          name="@dataType"
          label="Data Type"
          rules={{ required: true }}
          fullWidth
          columnSpan={twoColumnsSpan}
        />
        <AnsiTextField
          name="@alias"
          label="Alias"
          rules={{ required: true }}
          fullWidth
          columnSpan={twoColumnsSpan}
        />
      </FormGrid>
    </FormTab>
  );
};

export default AttributesTab;
