export const swapKeyValues = (obj: { [key: string]: any }) => {
  return Object.entries(obj).reduce(
    (ret: { [key: string]: any }, entry: [string, any]) => {
      const [key, value] = entry;
      ret[value] = key;
      return ret;
    },
    {}
  );
};

export const isObject = (value: any): value is object => {
  return typeof value === "object" && value !== null && !Array.isArray(value);
};

export const isRecordObject = (value: any): value is Record<string, any> => {
  return isObject(value);
};

export const toObjectOrArray = <T>(
  value: T[] | undefined
): T | T[] | undefined => {
  if (!value || value.length === 0) {
    return undefined;
  }

  if (value.length === 1) {
    return value[0];
  }

  return value;
};
