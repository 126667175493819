import { Condition } from "../Asset_Specification";
import { toArray } from "../utils";
import {
  getComparisonOperatorSymbol,
  isComparisonOperator,
  isInequalityOperator,
  isLogicalOperator,
} from "./operatorUtils";

export const invalidFormattedCondition: string = "Invalid or Unknown Condition";
export const newLineForCondition: string = "\n";

const formattedAndOperator: string = "AND";
const formattedOrOperator: string = "OR";

export const formatConditionToHighLevelLabel = (
  condition: Condition
): string => {
  switch (condition["@operator"]) {
    case "true":
    case "false":
    case "OM":
    case "and":
    case "or":
      return condition["@operator"].toUpperCase();
    case "le":
    case "lt":
    case "ge":
    case "gt":
    case "eq":
    case "ne":
      return getComparisonOperatorSymbol(condition["@operator"]);
    case "snapshot":
      return "Snapshot";
    case "steptimer":
      return "Timer";
    case "valueChange":
      return "Value Change";
    case "stepId":
      return "Step Id";
  }
};

export const formatConditionToHumanReadableLabel = (
  condition: Condition
): string => {
  switch (condition["@operator"]) {
    case "true":
    case "false":
      return formatConditionOnOperatorBoolean(condition);
    case "le":
    case "lt":
    case "ge":
    case "gt":
    case "eq":
    case "ne":
      return formatConditionOnOperatorComparison(condition);
    case "OM":
      return formatConditionOnOperatorOM(condition);
    case "snapshot":
      return formatConditionOnOperatorSnapshot(condition);
    case "steptimer":
      return formatConditionOnOperatorStepTimer(condition);
    case "and":
    case "or":
      return formatConditionOnOperatorLogicalCondition(condition);
    case "valueChange":
      return formatConditionOnOperatorValueChange(condition);
    case "stepId":
      return formatConditionOnOperatorStepId(condition);
  }
};

/// BEGIN: FORMAT CONDITION ON BOOLEAN ///

const formatConditionOnOperatorBoolean = (condition: Condition): string => {
  return condition["@op1"]
    ? `${condition["@op1"]} is ${condition["@operator"]}`
    : condition["@operator"];
};

/// END: FORMAT CONDITION ON BOOLEAN ///

/// BEGIN: FORMAT CONDITION ON COMPARISON ///

const formatConditionOnOperatorComparison = (condition: Condition): string => {
  if (
    condition["@op1"] === undefined ||
    condition["@op2"] === undefined ||
    !isComparisonOperator(condition["@operator"])
  ) {
    return invalidFormattedCondition;
  }

  const comparisonOperatorSymbol = getComparisonOperatorSymbol(
    condition["@operator"]
  );
  return `${condition["@op1"]} ${comparisonOperatorSymbol} ${condition["@op2"]}`;
};

/// END: FORMAT CONDITION ON COMPARISON ///

/// BEGIN: FORMAT CONDITION ON OM ///

const formatConditionOnOperatorOM = (condition: Condition): string => {
  return condition["@op1"] !== undefined
    ? `${condition["@op1"]} from operator`
    : invalidFormattedCondition;
};

/// END: FORMAT CONDITION ON OM ///

/// BEGIN: FORMAT CONDITION ON SNAPSHOT ///

const formatConditionOnOperatorSnapshot = (condition: Condition): string => {
  return condition["@op1"] !== undefined
    ? `Snapshot number is ${condition["@op1"]}`
    : invalidFormattedCondition;
};

/// END: FORMAT CONDITION ON SNAPSHOT ///

/// BEGIN: FORMAT CONDITION ON STEP TIMER ///

const formatConditionOnOperatorStepTimer = (condition: Condition): string => {
  if (condition["@timer"] === undefined) {
    return invalidFormattedCondition;
  }

  const formattedTimeElapsed: string = formatTimeElapsed(condition["@timer"]);
  return `Timer ${formattedTimeElapsed} elapsed`;
};

const formatTimeElapsed = (timeElapsed: string): string => {
  const timeElapsedAsNumber: number = parseInt(timeElapsed);
  return isNaN(timeElapsedAsNumber) ? timeElapsed : `${timeElapsedAsNumber}s`;
};

/// END: FORMAT CONDITION ON STEP TIMER ///

/// BEGIN: FORMAT CONDITION ON LOGICAL CONDITION ///

const formatConditionOnOperatorLogicalCondition = (
  logicalCondition: Condition
): string => {
  const conditionArray: Condition[] = toArray(logicalCondition.condition);
  if (
    !isLogicalOperator(logicalCondition["@operator"]) ||
    conditionArray.length < 2
  ) {
    return invalidFormattedCondition;
  }

  const formattedInequalityRangeCondition: string | undefined =
    formatConditionOnOperatorLogicalConditionInequalityRange(
      logicalCondition["@operator"],
      conditionArray
    );
  return formattedInequalityRangeCondition !== undefined
    ? formattedInequalityRangeCondition
    : formatConditionOnOperatorLogicalConditionDefault(
        logicalCondition["@operator"],
        conditionArray
      );
};

const getSymbolForConditionOperatorInInqualityRange = (
  operator: string
): string => {
  if (!isComparisonOperator(operator)) {
    return "";
  }

  // Need to invert the symbol for greater than symbols as they appear on the left of the range, instead of the right as written in the condition.
  if (operator === "ge") {
    return getComparisonOperatorSymbol("le");
  }

  if (operator === "gt") {
    return getComparisonOperatorSymbol("lt");
  }

  return getComparisonOperatorSymbol(operator);
};

const getValueAndSymbolForConditionInInequalityRange = (
  condition: Condition
): { value: string; symbol: string } => {
  return {
    value: condition["@op2"] ?? "",
    symbol: getSymbolForConditionOperatorInInqualityRange(
      condition["@operator"]
    ),
  };
};

const getValuesAndSymbolsForInequalityRange = (
  condition1: Condition,
  condition2: Condition
): {
  lessThanValue: { value: string; symbol: string };
  greaterThanValue: { value: string; symbol: string };
} => {
  const condition1ValueAndSymbol: { value: string; symbol: string } =
    getValueAndSymbolForConditionInInequalityRange(condition1);
  const condition2ValueAndSymbol: { value: string; symbol: string } =
    getValueAndSymbolForConditionInInequalityRange(condition2);

  const condition1IsLessThanValue: boolean =
    condition1["@operator"] === "le" || condition1["@operator"] === "lt";
  const lessThanValue: { value: string; symbol: string } =
    condition1IsLessThanValue
      ? condition1ValueAndSymbol
      : condition2ValueAndSymbol;
  const greaterThanValue: { value: string; symbol: string } =
    condition1IsLessThanValue
      ? condition2ValueAndSymbol
      : condition1ValueAndSymbol;

  return {
    lessThanValue: lessThanValue,
    greaterThanValue: greaterThanValue,
  };
};

const validInequalityRange = (
  condition1: Condition,
  condition2: Condition
): boolean => {
  if (
    condition1["@operator"] === condition2["@operator"] ||
    condition1["@op1"] === undefined ||
    condition2["@op1"] === undefined ||
    condition1["@op2"] === undefined ||
    condition2["@op2"] === undefined ||
    condition1["@op1"] !== condition2["@op1"]
  ) {
    return false;
  }

  if (condition1["@operator"] === "le") {
    return condition2["@operator"] !== "lt";
  }

  if (condition1["@operator"] === "lt") {
    return condition2["@operator"] !== "le";
  }

  return (
    condition2["@operator"] !== (condition1["@operator"] === "gt" ? "ge" : "gt")
  );
};

const formatConditionOnOperatorLogicalConditionInequalityRange = (
  operator: "and" | "or",
  conditions: Condition[]
): string | undefined => {
  if (
    operator !== "and" ||
    conditions.length !== 2 ||
    !conditions.every((condition: Condition) =>
      isInequalityOperator(condition["@operator"])
    )
  ) {
    return undefined;
  }

  const condition1: Condition = conditions[0];
  const condition2: Condition = conditions[1];
  if (!validInequalityRange(condition1, condition2)) {
    return undefined;
  }

  const { lessThanValue, greaterThanValue } = {
    ...getValuesAndSymbolsForInequalityRange(condition1, condition2),
  };

  return `${greaterThanValue.value} ${greaterThanValue.symbol} ${condition1["@op1"]} ${lessThanValue.symbol} ${lessThanValue.value}`;
};

const formatConditionOnOperatorLogicalConditionDefault = (
  operator: "and" | "or",
  conditions: Condition[]
): string => {
  const formattedLogicalOperator =
    operator === "and" ? formattedAndOperator : formattedOrOperator;
  const formattedConditions: string[] = conditions.map(
    formatConditionToHumanReadableLabel
  );
  if (formattedConditions.includes(invalidFormattedCondition)) {
    return invalidFormattedCondition;
  }

  formattedConditions.forEach(
    (
      formattedCondition: string,
      index: number,
      formattedConditions: string[]
    ) => {
      if (
        !formattedCondition.includes(formattedAndOperator) &&
        !formattedCondition.includes(formattedOrOperator)
      ) {
        return;
      }

      const formattedConditionWithBrackets = `[${formattedCondition}]`;
      formattedConditions[index] = formattedConditionWithBrackets;
    }
  );

  return formattedConditions.join(
    ` ${formattedLogicalOperator}${newLineForCondition}`
  );
};

/// END: FORMAT CONDITION ON LOGICAL CONDITION ///

/// BEGIN: FORMAT CONDITION ON VALUE CHANGE ///

const formatConditionOnOperatorValueChange = (condition: Condition): string => {
  return condition["@op1"] !== undefined
    ? `${condition["@op1"]} changed`
    : invalidFormattedCondition;
};

/// END: FORMAT CONDITION ON VALUE CHANGE ///

/// BEGIN: FORMAT CONDITION ON STEP ID ///

const formatConditionOnOperatorStepId = (condition: Condition): string => {
  return condition["@op1"] !== undefined
    ? `Step id is ${condition["@op1"]}`
    : invalidFormattedCondition;
};

/// END: FORMAT CONDITION ON STEP ID ///
