import { FormatTemplate } from "../FormatTemplate";

const fileTemplate: FormatTemplate = {
  "@fileName": {
    type: "string",
    canBeUndefined: false,
  },
};

export default fileTemplate;
