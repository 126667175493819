import { ConditionType } from "../types";

const transitionConditionTypeFactory = (state: string): ConditionType[] => {
    switch (state) {
        //TODO: what conditions are available for each state? 
        // case "IDLE": 
        //     return ["START", "HOLD"];
        // case "HELD":
        //     return ["START", "SUSPEND"];
        default: 
            return ["START", "HOLD", "SUSPEND"];
    }
}

export default transitionConditionTypeFactory;