import { useCallback, useState } from "react";
import { SuiButton } from "../../SuiButton";
import { SuiList, SuiListItem } from "../../SuiList";
import { SuiIcon } from "../../SuiIcon";
import deleteIcon from "@umetrics/sartorius-ui-icon/dist/icons/deleteAction";
import plus from "@umetrics/sartorius-ui-icon/dist/icons/add";
import { SuiTextField } from "../../SuiTextField";
import "./List.css";

interface ListProps {
  id: string;
  items: string[];
  itemClicked: (action: string) => void;
  selectedItem?: string;
  editable?: boolean;
  /** if function returns true - field item text field will be cleared out */
  onItemAdded?: (item: string) => boolean | void;
  onItemRemoved?: (item: string) => void;
  /** this function is called after each new item text field change. The return value will be displayed as the error message */
  onValidateNewItem?: (item: string) => string | undefined;
}

export default function List(props: ListProps) {
  const {
    id,
    items,
    itemClicked,
    selectedItem,
    editable,
    onItemAdded,
    onItemRemoved,
    onValidateNewItem,
  } = props;
  const [newItem, setNewItem] = useState("");
  const [newItemValidationMessage, setNewItemValidationMessage] = useState("");

  const onRemoveItemClick = useCallback(
    (item: string | undefined) => {
      if (onItemRemoved && item) {
        onItemRemoved(item);
      }
    },
    [onItemRemoved]
  );

  const onNewItemInputChange = useCallback(
    (event: any) => {
      const {
        detail: { value },
      } = event;
      setNewItem(value);
      if (onValidateNewItem) {
        const validationMessage = onValidateNewItem(value) ?? "";
        setNewItemValidationMessage(validationMessage);
      }
    },
    [setNewItem, onValidateNewItem]
  );

  const OnNewItemAdded = useCallback(
    (_: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onItemAdded && onItemAdded(newItem)) {
        setNewItem("");
      }
    },
    [newItem, onItemAdded, setNewItem]
  );

  return (
    <>
      <SuiList>
        {items.map((item, index) => (
          <SuiListItem
            key={`${id}_list_item_${index}`}
            onClick={(_) => itemClicked(item)}
            itemText={item}
            clickable
            selected={item === selectedItem}
          >
            <span slot="item-text-slot">{item}</span>
          </SuiListItem>
        ))}
      </SuiList>
      {editable && (
        <>
          <div className="list-edit list-align-end">
            <SuiButton
              type="secondary"
              style={{ marginTop: "7px" }}
              onClick={(_) => onRemoveItemClick(selectedItem)}
            >
              <SuiIcon icon={deleteIcon} />
            </SuiButton>
            {newItemValidationMessage.length > 0 && (
              <span className="list-edit-label">
                {newItemValidationMessage}
              </span>
            )}
          </div>
          <div className="list-edit list-top-margin">
            <SuiButton
              type="primary"
              onClick={OnNewItemAdded}
              disabled={
                newItemValidationMessage?.length !== 0 || newItem.length === 0
              }
            >
              <SuiIcon icon={plus} />
            </SuiButton>
            <SuiTextField onInput={onNewItemInputChange} value={newItem} />
          </div>
        </>
      )}
    </>
  );
}
