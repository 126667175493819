import { v4 as uuid } from "uuid";
import { useState } from "react";
import { PLCParameter } from "../../../../../Asset_Specification";
import CalculationFormProps from "../../interfaces/CalculationFormProps";
import { toArray } from "../../../../../utils";
import PLCParameterInput from "../../../../GenericInputs/PLCParameterInput/PLCParameterInput";
import { getPlcParameterToSetFromArray } from "./utils/plcParameterInputUtils";
import { defaultPlcParameter } from "../../../../../utils/plcParameterUtils";
import { isValidPlcParameter } from "../../../../GenericInputs/PLCParameterInput/utils/plcParameterInputUtils";
import AccordionInput from "../../../../GenericInputs/AccordionInput/AccordionInput";

interface PlcParameterInputProps extends CalculationFormProps {
  plcParameter: PLCParameter | PLCParameter[] | undefined;
}

const PlcParameterInput = (props: PlcParameterInputProps) => {
  const { plcParameter, formId, setCalculationFormCallback } = { ...props };

  const id = `${formId}-PlcParameter`;
  const [plcParameters, setPlcParameters] = useState<
    { key: string; value: PLCParameter; valid: boolean }[]
  >(() =>
    toArray(plcParameter).map((plcParameter) => ({
      key: uuid(),
      value: plcParameter,
      valid: isValidPlcParameter(plcParameter),
    }))
  );

  const onPlcParametersChanged = (
    newPlcParameters: { key: string; value: PLCParameter; valid: boolean }[]
  ) => {
    setPlcParameters(newPlcParameters);
    const newPlcParametersArray = newPlcParameters.map(
      (plcParameter) => plcParameter.value
    );
    const valid = newPlcParameters
      .map((plcParameter) => plcParameter.valid)
      .every((plcParameterValid) => plcParameterValid);
    setCalculationFormCallback({
      type: "set-prop",
      payload: {
        key: "plcParameter",
        value: [getPlcParameterToSetFromArray(newPlcParametersArray), valid],
      },
    });
  };

  return (
    <AccordionInput<PLCParameter>
      id={id}
      items={plcParameters}
      defaultItemEntry={{
        key: uuid(),
        value: defaultPlcParameter,
        valid: false,
      }}
      itemRenderer={(id, key, value, onItemChangeCallback) => (
        <PLCParameterInput
          id={id}
          key={key}
          plcParameter={value}
          onChangeCallback={onItemChangeCallback}
        />
      )}
      getItemLabel={(value: PLCParameter) => value["@id"]}
      onChangeCallback={onPlcParametersChanged}
    />
  );
};

export default PlcParameterInput;
