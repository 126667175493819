import { useContext, useMemo } from "react";
import { ExchangeAsset } from "Asset_Specification";
import { AppStateContext } from "state/Context";

/**
 * Defines the return value from the {@link useAssetIds} hook.
 */
interface UseAssetIdsReturn {
  assetIdOptions: { label: string; value: string }[];
}

/**
 * A hook for fetching the ids of assets for the select asset id component
 * @param props the properties to inject into the hook.
 * @returns {UseParameterIdsReturn}
 */
const useAssetIds = (): UseAssetIdsReturn => {
  const { specificationService } = useContext(AppStateContext);
  const assetIdOptions: { label: string; value: string }[] = useMemo(() => {
    if (!specificationService) {
      return [];
    }

    const assets: ExchangeAsset[] = specificationService.getExchangeAssets();
    return assets.map((exchangeAsset: ExchangeAsset) => ({
      label: `${exchangeAsset["@name"]} (${exchangeAsset["@id"]})`,
      value: exchangeAsset["@id"],
    }));
  }, [specificationService]);
  return { assetIdOptions };
};

export default useAssetIds;
