import { FormatTemplate } from "../FormatTemplate";
import labelTemplate from "./labelTemplate";

const valueEnumTemplate: FormatTemplate = {
  label: {
    type: "array",
    canBeUndefined: false,
    propertyTemplate: labelTemplate,
  },
  "@id": {
    type: "string",
    canBeUndefined: false,
  },
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@value": {
    type: "string",
    canBeUndefined: false,
  },
  "@description": {
    type: "string",
    canBeUndefined: true,
  },
};

export default valueEnumTemplate;
