import { ReactElement, useEffect, useState } from "react";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { FormGrid, NumericField } from "@form-components";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import AnsiTextField from "../basic/AnsiTextField";

/**
 * Defines the properties for the {@link MfcsInput} component.
 */
interface MfcsInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "fullWidth"> {}

const mfcsIntegerCutoff: number = 9007199254740991;

/**
 * The input component for specifying MFCS values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<MfcsInput<TInputs>>}
 */
export default function MfcsInput<TInputs extends FieldValues>({
  disabled,
  label,
  columnSpan,
  name,
}: MfcsInputProps<TInputs>): ReactElement<MfcsInputProps<TInputs>> {
  const { watch, trigger } = useFormContext();
  const minName: Path<TInputs> = `${name}.@min` as Path<TInputs>;
  const maxName: Path<TInputs> = `${name}.@max` as Path<TInputs>;
  const minWatchValue: number = watch(minName);
  const maxWatchValue: number = watch(maxName);
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);
  useEffect(() => {
    setMin(Math.max(minWatchValue, 0));
  }, [minWatchValue]);

  useEffect(() => {
    setMax(Math.min(maxWatchValue, mfcsIntegerCutoff));
  }, [maxWatchValue]);

  useEffect(() => {
    trigger(name);
  }, [name, trigger, min, max]);

  return (
    <FormGrid heading={label} style={getStylesFromColumnSpan(columnSpan)}>
      <AnsiTextField
        name={`${name}.@name` as Path<TInputs>}
        label="Name"
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
      />

      <NumericField
        name={minName}
        isInteger
        label="Min"
        rules={{
          min: {
            value: 0,
            message: "Must be a greater than or equal to 0!",
          },
          max: {
            value: max,
            message: `Must be less than ${max}!`,
          },
        }}
        disabled={disabled}
        fullWidth
      />

      <NumericField
        name={maxName}
        isInteger
        label="Max"
        rules={{
          min: {
            value: min,
            message: `Must be a greater than or equal to ${min}!`,
          },
          max: {
            value: mfcsIntegerCutoff,
            message: `Must be less than ${mfcsIntegerCutoff}!`,
          },
        }}
        disabled={disabled}
        fullWidth
      />
    </FormGrid>
  );
}
