import { useMemo } from "react";
import { Node } from "reactflow";
import { caseXOffset } from "../../../../../../utils/nodeGenerationUtils";
import useSelectedStateNodes from "../../../../hooks/useSelectedStateNodes";

const useBranchingNodeWidth = (caseNodeIds: string[]): number => {
  const selectedStateNodes: Node[] = useSelectedStateNodes();
  const branchingNodeWidth: number = useMemo(() => {
    if (caseNodeIds.length < 1) {
      return 0;
    }

    const finalCaseNodeWidth: number =
      selectedStateNodes.find(
        (node: Node) => node.id === caseNodeIds[caseNodeIds.length - 1]
      )?.width ?? 0;
    return (caseNodeIds.length - 1) * caseXOffset + finalCaseNodeWidth;
  }, [caseNodeIds, selectedStateNodes]);

  return branchingNodeWidth;
};

export default useBranchingNodeWidth;
