import { FormatTemplate } from "../FormatTemplate";
import conditionTemplate from "./conditionTemplate";
import setOperatingSchemeTemplate from "./setOperatingSchemeTemplate";
import setParameterBypassTemplate from "./setParameterBypassTemplate";
import setParameterTemplate from "./setParameterTemplate";

const actionOperatingSchemeTemplate: FormatTemplate = {
  setOperatingScheme: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: setOperatingSchemeTemplate,
  },
  setParameter: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: setParameterTemplate,
  },
  setParameterBypass: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: setParameterBypassTemplate,
  },
  condition: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: conditionTemplate,
  },
  "@equipmentModuleId": {
    type: "string",
    canBeUndefined: false,
  },
};

export default actionOperatingSchemeTemplate;
