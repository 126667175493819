export type ReducerAction<T> = {
  type: "set-prop";
  payload: {
    key: string;
    value: any;
  };
} | {
  type: "set-state";
  payload: T
};

export const createReducer =
  <T>() =>
  (state: T, action: ReducerAction<T>): T => {
    switch (action.type) {
      case "set-prop":
        return { ...state, [action.payload.key]: action.payload.value };
      case "set-state":
        return { ...action.payload };
    }
  };
