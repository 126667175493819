import { ExchangeAsset } from "Asset_Specification";

export const defaultExchangeAsset: ExchangeAsset = {
  "@alias": "",
  "@type": "",
  "@subtype": "Driver",
  "@description": "",
  "@id": "",
  "@name": "",
};
