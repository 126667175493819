import { DragEvent } from "react";
import { NodeType } from "../../types";
import useAddNode from "../../hooks/useAddNode";
import IAddNodeService from "../../services/interfaces/IAddNodeService";
import "./AddTool.css";

const AddTool = () => {
  const addNodeService: IAddNodeService = useAddNode();

  const onDragStart = (
    event: DragEvent<HTMLDivElement>,
    nodeType: NodeType
  ) => {
    event.dataTransfer.setData("text/plain", nodeType);
    event.dataTransfer.dropEffect = "copy";
  };

  const addToolItems: { nodeType: NodeType; image: string; alt: string }[] = [
    {
      nodeType: NodeType.step,
      image: "images/SFE/Regular.png",
      alt: "Regular Element",
    },
    {
      nodeType: NodeType.branching,
      image: "images/SFE/Branching.png",
      alt: "Branching Element",
    },
    {
      nodeType: NodeType.stateLink,
      image: "images/SFE/StateLink.png",
      alt: "State Link Element",
    },
  ];

  return (
    <div className="AddToolGrid">
      {addToolItems.map((item, index) => (
        <div
          key={`add-tool-item-${index}`}
          className="AddToolItem"
          onClick={(_) => addNodeService.addNode(item.nodeType)}
          draggable="true"
          onDragStart={(event) => onDragStart(event, item.nodeType)}
        >
          <img src={item.image} alt={item.alt} />
        </div>
      ))}
    </div>
  );
};

export default AddTool;
