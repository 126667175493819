import { MutableRefObject, ReactElement } from "react";
import { TransitionConditions } from "./types/TransitionConditions";
import { Form } from "@form-components";
import TransitionConditionsFormTabs from "./TransitionConditionsFormTabs";
import useTransitionConditions from "./hooks/useTransitionConditions";

interface TransitionConditionsFormProps {
  id: string;
  onTransitionConditionSubmitted: (
    transitionConditions: TransitionConditions
  ) => void;
  formRef: MutableRefObject<HTMLFormElement | null>;
  onValidityChange: (isValid: boolean) => void;
}

const TransitionConditionsForm = ({
  id,
  onTransitionConditionSubmitted,
  formRef,
  onValidityChange,
}: TransitionConditionsFormProps): ReactElement<TransitionConditionsFormProps> => {
  const transitionConditions: TransitionConditions = useTransitionConditions();
  return (
    <Form<TransitionConditions>
      id={id}
      formRef={formRef}
      onSubmit={onTransitionConditionSubmitted}
      defaultValues={transitionConditions}
      mode="onChange"
      onValidityChange={onValidityChange}
    >
      <TransitionConditionsFormTabs />
    </Form>
  );
};

export default TransitionConditionsForm;
