import { FormatTemplate } from "../FormatTemplate";
import labelTemplate from "./labelTemplate";

const conditionTemplate: FormatTemplate = {
  label: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: labelTemplate,
  },
  condition: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: "self",
  },
  "@operator": {
    type: "string",
    canBeUndefined: false,
  },
  "@op1": {
    type: "string",
    canBeUndefined: true,
  },
  "@op2": {
    type: "string",
    canBeUndefined: true,
  },
  "@timer": {
    type: "string",
    canBeUndefined: true,
  },
  "@once": {
    type: "boolean",
    canBeUndefined: false, // Differs from actual spec where it can be undefined; app should always export it as false
  },
  "@freeze": {
    type: "boolean",
    canBeUndefined: false, // Differs from actual spec where it can be undefined; app should always export it as false
  },
};

export default conditionTemplate;
