import { TabNotification } from "@umetrics/sartorius-ui-tabs";
import { SuiTab } from "../../SuiTabs";
import ExchangeEditor from "../../ExchangeEditor/ExchangeEditor";
import TabProps from "./interfaces/TabProps";

const ExchangeTab = (props: TabProps) => {
  return (
    <SuiTab
      heading="Exchange Assets"
      notificationType={TabNotification.None}
      {...props}
    >
      <ExchangeEditor />
    </SuiTab>
  );
};

export default ExchangeTab;
