import { ReactElement } from "react";
import { SuiDivider } from "../SuiDivider";
import "./ContainerWithHeader.css";
import { toArray } from "../../utils";
import { ButtonProperties } from "@umetrics/sartorius-ui-button";

interface ContainerWithHeaderProps {
  heading: string;
  children: ReactElement | ReactElement[];
  additionalHeading?: ReactElement;
  headingButtons?:
    | ReactElement<ButtonProperties>
    | ReactElement<ButtonProperties>[];
}

const ContainerWithHeader = (props: ContainerWithHeaderProps) => {
  const { heading, children, additionalHeading, headingButtons } = { ...props };
  const internalHeadingButtons = toArray(headingButtons);

  return (
    <div className="cwh-container">
      <div className="cwh-header">
        <div className="cwh-heading-container">
          <h1>{heading}</h1>
          {additionalHeading}
        </div>

        {internalHeadingButtons.length > 0 && (
          <div className="cwh-buttons-container">{headingButtons}</div>
        )}
      </div>

      <SuiDivider />

      <div className="cwh-children">{children}</div>
    </div>
  );
};

export default ContainerWithHeader;
