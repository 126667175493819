import { ReactElement } from "react";
import { Path } from "react-hook-form";
import { ExchangeAsset } from "Asset_Specification";
import {
  AllocNameTextField,
  AnsiTextField,
  FormGrid,
  RequiredIdTextField,
  RequiredNonEmptyNameTextField,
  SelectExchangeAssetSubtype,
  TextField,
  twoColumnsSpan,
} from "@form-components";

export const attributeNames: Path<ExchangeAsset>[] = [
  "@alias",
  "@type",
  "@subtype",
  "@description",
  "@id",
  "@name",
];

const AttributesTabContent = (): ReactElement => {
  return (
    <FormGrid>
      <RequiredIdTextField name="@id" fullWidth columnSpan={twoColumnsSpan} />
      <RequiredNonEmptyNameTextField
        name="@name"
        fullWidth
        columnSpan={twoColumnsSpan}
      />
      <AllocNameTextField
        name="@allocName"
        fullWidth
        columnSpan={twoColumnsSpan}
      />
      <AnsiTextField
        name="@alias"
        label="Alias"
        rules={{ required: true }}
        fullWidth
        columnSpan={twoColumnsSpan}
      />
      <TextField
        name="@type"
        label="Type"
        rules={{
          required: true,
          pattern: {
            value: /^[EC]M_[\d\w]+$/,
            message:
              "Must be prefixed with EM_ or CM_ and contain only alphanumeric characters or '_'.",
          },
        }}
        fullWidth
        columnSpan={twoColumnsSpan}
      />
      <SelectExchangeAssetSubtype
        name="@subtype"
        label="Subtype"
        rules={{ required: true }}
        fixedDropdown
        columnSpan={twoColumnsSpan}
      />
      <AnsiTextField
        name="@description"
        label="Description"
        rules={{ required: true }}
        fullWidth
      />
    </FormGrid>
  );
};

export default AttributesTabContent;
