import { useMemo } from "react";
import { Node } from "reactflow";
import useSelectedStateNodes from "../../../../hooks/useSelectedStateNodes";
import {
  caseToStepEdgeColorWheel,
  defaulCaseToStepEdgeColor,
  getColourWheelIndex,
} from "../utils/caseToStepEdgeUtils";

const useCaseToStepEdgeLineColor = (caseNodeId: string): string => {
  const selectedStateNodes: Node[] = useSelectedStateNodes();
  const lineColor: string = useMemo(() => {
    const colorWheelIndex: number = getColourWheelIndex(
      caseNodeId,
      selectedStateNodes
    );
    return colorWheelIndex >= 0
      ? caseToStepEdgeColorWheel[colorWheelIndex]
      : defaulCaseToStepEdgeColor;
  }, [caseNodeId, selectedStateNodes]);

  return lineColor;
};

export default useCaseToStepEdgeLineColor;
