import { useCallback, useContext, useEffect, useState } from "react";
import {
  AppDispatchContext,
  AppStateContext,
  ModalContext,
} from "../../state/Context";
import { SnapShotDefinition } from "../../Asset_Specification";
import {
  getItemDictionary,
  onDeleteItemCallback,
  onSaveItemCallback,
} from "../EditList/utils/editListUtils";
import { toArray } from "../../utils";
import EditList from "../EditList/EditList";
import EditSnapshotsModal from "./Modal/EditSnapshotsModal";

const addFormHeading = "Add Snapshot Definition";
const editFormHeading = "Edit Snapshot Definition";

const EditSnapshots = () => {
  const { specificationService } = useContext(AppStateContext);
  const dispatchApp = useContext(AppDispatchContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const [snapshots, setSnapshots] = useState<{
    [key: string]: SnapShotDefinition;
  }>({});

  const setSnapshotsCallback = useCallback(
    (newSnapshots: { [key: string]: SnapShotDefinition }) => {
      setSnapshots(newSnapshots);
      dispatchApp({
        type: "set-snapshots",
        snapshots: Object.values(newSnapshots),
      });
    },
    [dispatchApp]
  );

  const onSaveSnapshot = useCallback(
    (snapshot: SnapShotDefinition, snapshotId?: string) => {
      onSaveItemCallback(
        snapshots,
        snapshot,
        setSnapshotsCallback,
        dispatchModal,
        snapshotId
      );
    },
    [dispatchModal, setSnapshotsCallback, snapshots]
  );

  const openEditSnapshotModal = useCallback(
    (heading: string, snapshotEntry?: [string, SnapShotDefinition]) =>
      dispatchModal({
        type: "show",
        content: (
          <EditSnapshotsModal
            modalHeading={heading}
            itemEntry={snapshotEntry}
            onSaveItemCallback={onSaveSnapshot}
            onCancelCallback={() => dispatchModal({ type: "close" })}
            snapshotIds={Object.values(snapshots)
              .map((snapshot) => snapshot["@id"])
              .filter((snapshotId) => snapshotId !== snapshotEntry?.[1]["@id"])}
          />
        ),
      }),
    [dispatchModal, onSaveSnapshot, snapshots]
  );

  useEffect(() => {
    const snapshotsArray: SnapShotDefinition[] = toArray(
      specificationService?.Specification.equipmentPhase?.snapShots
        ?.snapShotDefinition
    );
    setSnapshots(getItemDictionary(snapshotsArray));
  }, [specificationService]);

  return (
    <EditList
      itemDictionary={snapshots}
      heading="Snapshot definitions"
      getItemLabel={(item) => (item as SnapShotDefinition)["@id"]}
      onAddItemButtonClick={() => openEditSnapshotModal(addFormHeading)}
      onEditItemCallback={(snapshotId) =>
        openEditSnapshotModal(editFormHeading, [
          snapshotId,
          snapshots[snapshotId],
        ])
      }
      onDeleteItemCallback={(snapshotId) =>
        onDeleteItemCallback(snapshots, snapshotId, setSnapshotsCallback)
      }
    />
  );
};

export default EditSnapshots;
