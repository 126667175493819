import { PropsWithChildren, useCallback, useState } from "react";
import { SuiCollapse } from "../../SuiCollapse";
import { SuiButton } from "../../SuiButton";
import { ButtonType } from "@umetrics/sartorius-ui-button";
import { SIcon } from "../../SuiIcon";
import downIcon from "@umetrics/sartorius-ui-icon/dist/icons/down";
import "./CollapseSegment.css";

interface CollapseSegmentProps {
  buttonLabel?: string;
  error?: boolean;
}

const CollapseSegment = (props: PropsWithChildren<CollapseSegmentProps>) => {
  const { buttonLabel, error } = { ...props };
  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapse = useCallback(
    () => setIsOpen((open) => !open),
    [setIsOpen]
  );

  return (
    <div>
      <SuiButton
        onClick={toggleCollapse}
        type={isOpen ? ButtonType.Primary : ButtonType.Secondary}
        className={error ? "input-error" : ""}
      >
        <label className={error ? "input-error" : ""}>{buttonLabel}</label>
        <SIcon
          slot="icon-slot"
          icon={downIcon}
          className={`collapse-segment-icon ${
            isOpen ? "collapse-segment-icon-open" : ""
          }${error ? " input-error" : ""}`}
        />
      </SuiButton>
      <SuiCollapse open={isOpen}>
        <div className="collapse-segment-content">{props.children}</div>
        <hr className="collapse-segment-rule" />
      </SuiCollapse>
    </div>
  );
};

export default CollapseSegment;
