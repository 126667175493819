import { FormatTemplate } from "../FormatTemplate";
import snapShotDefinitionTemplate from "./snapShotDefinitionTemplate";

const snapShotsTemplate: FormatTemplate = {
  snapShotDefinition: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: snapShotDefinitionTemplate,
  },
};

export default snapShotsTemplate;
