import { FormatTemplate } from "../FormatTemplate";

const awParameterTemplate: FormatTemplate = {
  "@value": {
    type: "string",
    canBeUndefined: false,
  },
  "@enable": {
    type: "boolean",
    canBeUndefined: false,
  },
};

export default awParameterTemplate;
