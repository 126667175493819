import { FormatTemplate } from "../FormatTemplate";

const actionCalculationTemplate: FormatTemplate = {
  "@calculationId": {
    type: "string",
    canBeUndefined: false,
  },
};

export default actionCalculationTemplate;
