import { ReactElement, useEffect } from "react";
import {
  ArrayPath,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
} from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { FormGrid } from "@form-components";
import SingleLabelInput from "./SingleLabelInput";
import { Lang, lang } from "types";
import useArrayInput from "../../array/hooks/useArrayInput";
import { ArrayInputItemProps } from "../../array/ArrayInput";
import { Label } from "Asset_Specification";
import { toArray } from "utils";

/**
 * Defines the properties for the {@link MultiLabelInput} component.
 */
interface MultiLabelInputProps<TInputs extends FieldValues>
  extends Omit<
    InputPropsWithoutRules<TInputs>,
    "columnSpan" | "label" | "fullWidth"
  > {
  required?: boolean;
}

const isMissingLangValue = (labels: Label[], lang: Lang): boolean => {
  return !labels.some((label: Label) => label["@lang"] === lang);
};

/**
 * The input component for specifying multi label values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<MultiLabelInputProps<TInputs>>}
 */
export default function MultiLabelInput<TInputs extends FieldValues>({
  disabled,
  name,
  required,
}: MultiLabelInputProps<TInputs>): ReactElement<MultiLabelInputProps<TInputs>> {
  const { watch, setValue } = useFormContext<TInputs>();
  const labels: Label[] | undefined = watch(name);
  const { arrayInput, count } = useArrayInput({
    name: name as ArrayPath<TInputs>,
    itemRenderer: ({
      itemKey,
      name,
    }: ArrayInputItemProps<TInputs, ArrayPath<TInputs>>) => (
      <SingleLabelInput
        key={itemKey}
        name={name as Path<TInputs>}
        rules={{ required: required }}
        disabled={disabled}
        fullWidth
      />
    ),
  });

  useEffect(() => {
    if (count > 1) {
      return;
    }

    const labelsCopy: Label[] = toArray(labels);
    const missingLangValues: Lang[] = [];
    if (isMissingLangValue(labelsCopy, lang.eng)) {
      missingLangValues.push(lang.eng);
    }

    if (isMissingLangValue(labelsCopy, lang.ger)) {
      missingLangValues.push(lang.ger);
    }

    missingLangValues.forEach((lang: Lang) =>
      labelsCopy.push({
        "@lang": lang,
        "@value": "",
      })
    );
    setValue(name, labelsCopy as PathValue<TInputs, Path<TInputs>>);
  }, [count, labels, name, setValue]);

  return <FormGrid>{arrayInput}</FormGrid>;
}
