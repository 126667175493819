import { PropsWithChildren, ReactElement } from "react";
import { AppHeader } from "./AppHeader";
import "./AppLayout.css";

const AppLayout = ({
  children,
}: PropsWithChildren): ReactElement<PropsWithChildren> => {
  return (
    <div className="app-layout">
      <AppHeader />
      {children}
    </div>
  );
};

export default AppLayout;
