import { ReactElement, useMemo } from "react";
import {
  ArrayPath,
  FieldValues,
  UseFieldArrayReturn,
  useFieldArray,
} from "react-hook-form";
import ArrayInput, {
  ArrayInputItemProps,
  ArrayInputProps,
} from "../ArrayInput";

/**
 * Defines the properties for the {@link useArrayInput} hook.
 */
interface UseArrayInputProps<
  TInputs extends FieldValues,
  TArrayPath extends ArrayPath<TInputs>,
> extends Omit<
    ArrayInputProps<TInputs, TArrayPath>,
    "fields" | "itemRenderer"
  > {
  itemRenderer: (
    itemProps: ArrayInputItemProps<TInputs, TArrayPath> &
      Omit<UseFieldArrayReturn<TInputs, TArrayPath>, "fields">
  ) => ReactElement;
  shouldUnregister?: boolean;
}

/**
 * Defines the return value for the {@link useArrayInput} hook.
 */
interface UseArrayInputReturn<
  TInputs extends FieldValues,
  TArrayPath extends ArrayPath<TInputs>,
> extends Omit<UseFieldArrayReturn<TInputs, TArrayPath>, "fields"> {
  arrayInput: ReactElement<ArrayInputProps<TInputs, TArrayPath>>;
  count: number;
}

/**
 * Defines the props used by the item renderer for array items using this hook.
 */
export interface UseArrayInputItemRendererProps<
  TInputs extends FieldValues,
  TArrayPath extends ArrayPath<TInputs>,
> extends ArrayInputItemProps<TInputs, TArrayPath>,
    Omit<UseFieldArrayReturn<TInputs, TArrayPath>, "fields"> {}

/**
 * A hook for getting an {@link ArrayInput} instance along with functions to manipulate the array
 * @param props the properties to inject into the hook
 * @returns a {@link UseArrayInputReturn} object
 */
export default function useArrayInput<
  TInputs extends FieldValues,
  TArrayPath extends ArrayPath<TInputs>,
>({
  name,
  minLength,
  itemRenderer,
  shouldUnregister,
}: UseArrayInputProps<TInputs, TArrayPath>): UseArrayInputReturn<
  TInputs,
  TArrayPath
> {
  const { fields, ...remainingFieldArrayProps } = useFieldArray<
    TInputs,
    TArrayPath
  >({
    name,
    rules: {
      required: minLength ? true : false,
      minLength: minLength,
    },
    shouldUnregister,
  });

  return useMemo(
    () => ({
      ...remainingFieldArrayProps,
      arrayInput: (
        <ArrayInput<TInputs, TArrayPath>
          fields={fields}
          name={name}
          itemRenderer={({ itemKey, name, index }) =>
            itemRenderer({
              itemKey,
              name,
              index,
              ...remainingFieldArrayProps,
            })
          }
        />
      ),
      count: fields.length,
    }),
    [fields, itemRenderer, name, remainingFieldArrayProps]
  );
}
