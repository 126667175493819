import { ReactElement } from "react";
import { Path, useFormContext } from "react-hook-form";
import { ExchangeAsset, MFCS } from "Asset_Specification";
import { FormGrid, MfcsInput, OptionalInput } from "@form-components";
import { defaultMfcsValue } from "@utils/mfcsUtils";

export const mfcsName: Path<ExchangeAsset> = "mfcs";

const MfcsTabContent = (): ReactElement => {
  const { watch } = useFormContext();
  const mfcsValue: MFCS | undefined = watch(mfcsName);
  return (
    <FormGrid>
      <OptionalInput<ExchangeAsset>
        name={mfcsName}
        defaultValue={defaultMfcsValue}
        checkboxProps={{ label: "Has MFCS?" }}
        checked={mfcsValue !== undefined}
        heading="MFCS"
      >
        <MfcsInput name={mfcsName} label="MFCS" />
      </OptionalInput>
    </FormGrid>
  );
};

export default MfcsTabContent;
