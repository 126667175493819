import { FieldValues } from "react-hook-form";
import Select, { SelectProps } from "../../basic/Select/Select";
import { ReactElement } from "react";
import { DataType } from "Asset_Specification";

const dataTypeValues: DataType[] = ["real", "int", "bool", "string"];

/**
 * Defines the properties for the {@link SelectDataType}.
 */
interface SelectDataTypeProps<TInputs extends FieldValues>
  extends Omit<SelectProps<TInputs>, "options"> {}

/**
 * A component for selecting an asset id.
 * @param props the properties to inject into the component
 * @returns {ReactElement<SelectDataTypeProps<TInputs>>}
 */
export default function SelectDataType<TInputs extends FieldValues>({
  ...selectProps
}: SelectDataTypeProps<TInputs>): ReactElement<SelectDataTypeProps<TInputs>> {
  return <Select<TInputs> options={dataTypeValues} {...selectProps} />;
}
