import { ReactElement } from "react";
import { RegexpRules } from "../../interfaces/Rules";
import {
  alphanumericWithSeparatorsRegexp,
  alphanumericWithSeparatorsRegexpMessage,
} from "../../utils/regexpUtils";
import TextField, { TextFieldProps } from "../basic/TextField/TextField";
import {
  constructRulesWithRegExp,
  constructRulesWithRequired,
} from "../../utils/rulesUtils";
import { FieldValues } from "react-hook-form";

/**
 * Defines the properties for the {@link RequiredIdTextField} component.
 * @interface
 * @extends TextFieldProps
 */
export interface RequiredIdTextFieldProps<TInputs extends FieldValues>
  extends Omit<TextFieldProps<TInputs, RegexpRules>, "label"> {}

/**
 * A text field component which validates the text to match the "requiredId" group rule.
 * @param {RequiredIdTextFieldProps} props - the properties to pass into the component.
 * @returns {ReactElement}
 */
function RequiredIdTextField<TInputs extends FieldValues>({
  rules,
  ...remainingProps
}: RequiredIdTextFieldProps<TInputs>): ReactElement {
  return (
    <TextField<TInputs>
      label="Id"
      rules={constructRulesWithRequired(
        constructRulesWithRegExp(
          alphanumericWithSeparatorsRegexp,
          alphanumericWithSeparatorsRegexpMessage,
          rules
        )
      )}
      {...remainingProps}
    />
  );
}

export default RequiredIdTextField;
