import { useCallback, useEffect, useState } from "react";
import { TreeNode } from "@umetrics/sartorius-ui-tree";
import { SuiTree } from "../SuiTree";
import { isObject } from "../../utils/objectUtils";

interface ObjectTreeProps {
  object: Object;
}

const getLabel = (key: string, value: any): string => {
  if (isObject(value)) {
    return key;
  }

  if (Array.isArray(value)) {
    return `${key} (${value.length})`;
  }

  return `${key} = ${value}`;
};

const getChildren = (
  label: string,
  key: string,
  value: any,
  getTree: (value: any) => TreeNode[] | undefined
): TreeNode[] | undefined => {
  if (isObject(value)) {
    return getTree(value);
  }

  if (Array.isArray(value)) {
    const children: TreeNode[] = [];
    value.forEach((element: any, index: number) => {
      const elementLabelPrefix: string = `${label}:[${index}]`;
      const elementLabel: string = isObject(element)
        ? elementLabelPrefix
        : getLabel(elementLabelPrefix, element);
      const elementChildren: TreeNode[] | undefined = isObject(element)
        ? getTree(element)
        : undefined;
      const elementTreeNode: TreeNode = {
        label: elementLabel,
        id: `${key}-${index}`,
        children: elementChildren,
        nonSelectable: true,
      };
      children?.push(elementTreeNode);
    });
    return children;
  }

  return undefined;
};

const ObjectTree = (props: ObjectTreeProps) => {
  const { object } = { ...props };
  const [tree, setTree] = useState<TreeNode[]>([]);

  const getTree = useCallback((objectToConvert: Object): TreeNode[] => {
    const tree: TreeNode[] = [];
    Object.entries(objectToConvert).forEach((entry: [string, any]) => {
      const key = entry[0];
      const value = entry[1];
      if (value === undefined) {
        return;
      }

      const label = getLabel(key, value);
      const children: TreeNode[] | undefined = getChildren(
        label,
        key,
        value,
        getTree
      );

      const newTreeNode: TreeNode = {
        label: label,
        id: key,
        children: children,
        nonSelectable: true,
      };
      tree.push(newTreeNode);
    });
    return tree;
  }, []);

  useEffect(() => {
    const newTree = getTree(object);
    setTree(newTree);
  }, [getTree, object]);

  return <SuiTree data={tree} />;
};

export default ObjectTree;
