import { SuiButton } from "../SuiButton";
import { SIcon } from "../SuiIcon";
import editIcon from "@umetrics/sartorius-ui-icon/dist/icons/edit";
import deleteIcon from "@umetrics/sartorius-ui-icon/dist/icons/deleteAction"
import { SuiAccordionItem } from "../SuiAccordion";
import ObjectTree from "../ObjectTree/ObjectTree";

interface EditListAccordionItemProps {
  item: Object;
  label: string;
  onEditItemButtonClick: (event: React.MouseEvent) => void;
  onDeleteItemButtonClick: (event: React.MouseEvent) => void;
}

const EditListAccordionItem = (props: EditListAccordionItemProps) => {
  const { 
    item,
    label,
    onEditItemButtonClick,
    onDeleteItemButtonClick
  } = { ...props };

  return (
    <SuiAccordionItem>
      <SuiButton 
        type="tertiary"
        compact
        slot="action-slot"
        onClick={onEditItemButtonClick}
      >
        <SIcon icon={editIcon} slot="icon-slot" />
      </SuiButton>
      <SuiButton 
        type="tertiary"
        compact
        slot="action-slot"
        onClick={onDeleteItemButtonClick}
      >
        <SIcon icon={deleteIcon} slot="icon-slot" />
      </SuiButton>
      <span slot="label-slot">{label}</span>
      <div className="edit-list-accordion-item-content">
        <ObjectTree object={item} />
      </div>
    </SuiAccordionItem>
  );
}

export default EditListAccordionItem;