import { TabNotification } from "@umetrics/sartorius-ui-tabs";
import { SuiTab } from "../../SuiTabs";
import TabProps from "./interfaces/TabProps";
import EditCalculations from "../../EditCalculations/EditCalculations";

const CalculationsTab = (props: TabProps) => {
  return(
    <SuiTab
      heading="Calculations"
      notificationType={TabNotification.None}
      { ...props }
    >
      <EditCalculations />
    </SuiTab>
  );
}

export default CalculationsTab;