import Flow from "./components/containers/Flow";
import Toolbox from "./components/containers/Toolbox/Toolbox";
import { SuiPanelTemplate } from "../SuiPanelTemplate";
import "./SequentialFlowEditor.css";
import StatesList from "./components/containers/StatesList/StatesList";

const SequentialFlowEditor = () => {
  return (
    <SuiPanelTemplate
      leftPanelWidth={204}
      leftResizeDisabled
      bottomPanelDisabled
    >
      <div slot="main-slot">
        <Flow />
      </div>

      <div slot="left-slot">
        <StatesList />
      </div>

      <div slot="right-slot">
        <Toolbox />
      </div>
    </SuiPanelTemplate>
  );
};

export default SequentialFlowEditor;
