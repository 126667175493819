import { useContext, useEffect, useState } from "react";
import {
  AppDispatchContext,
  AppStateContext,
  ModalContext,
} from "../../state/Context";
import { FaceplateElement } from "../../Asset_Specification";
import EditList from "../EditList/EditList";
import {
  getItemDictionary,
  onDeleteItemCallback,
  onSaveItemCallback,
} from "../EditList/utils/editListUtils";
import EditFaceplateElementModal from "./Modal/EditFaceplateElementModal";

const addFormHeading = "Add Faceplate Element";
const editFormHeading = "Edit Faceplate Element";

const EditFaceplateElements = () => {
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const dispatch = useContext(AppDispatchContext);
  const { specificationService } = useContext(AppStateContext);
  const [faceplateElements, setFaceplateElements] = useState<{
    [key: string]: FaceplateElement;
  }>({});

  const setFaceplateElementsCallback = (newFaceplateElements: {
    [key: string]: FaceplateElement;
  }) => {
    setFaceplateElements(newFaceplateElements);
    dispatch({
      type: "set-faceplate-elements",
      faceplateElements: Object.values(newFaceplateElements),
    });
  };

  const openEditFaceplateElementModal = (
    heading: string,
    faceplateElementEntry?: [string, FaceplateElement]
  ) => {
    dispatchModal({
      type: "show",
      content: (
        <EditFaceplateElementModal
          modalHeading={heading}
          onSaveItemCallback={onSaveFaceplateElement}
          onCancelCallback={() => dispatchModal({ type: "close" })}
          itemEntry={faceplateElementEntry}
        />
      ),
    });
  };

  const onSaveFaceplateElement = (
    faceplateElement: FaceplateElement,
    faceplateElementKey?: string
  ) => {
    onSaveItemCallback(
      faceplateElements,
      faceplateElement,
      setFaceplateElementsCallback,
      dispatchModal,
      faceplateElementKey
    );
  };

  useEffect(() => {
    const faceplateElementsArray: FaceplateElement[] =
      specificationService?.getFaceplateElements() ?? [];
    setFaceplateElements(getItemDictionary(faceplateElementsArray));
  }, [specificationService]);

  return (
    <EditList
      itemDictionary={faceplateElements}
      heading={"Faceplate Elements"}
      onAddItemButtonClick={() => openEditFaceplateElementModal(addFormHeading)}
      onEditItemCallback={(faceplateElementEntryKey) =>
        openEditFaceplateElementModal(editFormHeading, [
          faceplateElementEntryKey,
          faceplateElements[faceplateElementEntryKey],
        ])
      }
      onDeleteItemCallback={(faceplateElementEntryKey) =>
        onDeleteItemCallback(
          faceplateElements,
          faceplateElementEntryKey,
          setFaceplateElementsCallback
        )
      }
      getItemLabel={(item: Object): string | undefined =>
        `For parameter with id: ${(item as FaceplateElement)["@parameterId"]}`
      }
    />
  );
};

export default EditFaceplateElements;
