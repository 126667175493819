import {
  HTMLAttributes,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  RefAttributes,
  useEffect,
  useRef,
} from "react";
import {
  FieldValues,
  Path,
  PathValue,
  useController,
  useFormContext,
} from "react-hook-form";
import { InputProps } from "../../../interfaces/InputProps";
import { SuiTextField } from "../../../../SuiTextField";
import { StringRules } from "../../../interfaces/Rules";
import {
  getRuleValue,
  requiredRuleErrorMessage,
} from "../../../utils/rulesUtils";
import { getStylesFromColumnSpan } from "../../../utils/styleUtils";
import useCustomValidation from "./hooks/useCustomValidation";
import { TextFieldProperties } from "@umetrics/sartorius-ui-text-field";

/**
 * Defines the properties for the {@link TextField} component.
 * @extends InputProps
 * @template {TStringRules} - the {@link StringRules} implementation to use for the input.
 */
export interface TextFieldProps<
  TInputs extends FieldValues,
  TStringRules extends StringRules = StringRules,
> extends InputProps<TInputs, TStringRules> {
  excludeValueFromSubmissionOnDisabled?: boolean; // react-hook-form excludes disabled values from form submission when passed into its hooks, which isn't always the desired behaviour
  setValueOnChange?: boolean; // For inputs which don't need to be updated everytime the underlying input value changes; needed to fix AP-158895
}

/**
 * A simple input component which integrates a umetrics text field with react-hook-form.
 * @template {TStringRules} - the {@link StringRules} implementation to use for the input
 * @param {InputProps} props - the props for the text field component.
 * @returns {ReactElement}
 */
function TextField<
  TInputs extends FieldValues,
  TStringRules extends StringRules = StringRules,
>({
  name,
  label,
  rules,
  disabled,
  excludeValueFromSubmissionOnDisabled,
  columnSpan,
  fullWidth,
  setValueOnChange,
}: TextFieldProps<TInputs, TStringRules>): ReactElement<
  TextFieldProps<TInputs, TStringRules>
> {
  const previousNameValue: MutableRefObject<Path<TInputs>> =
    useRef<Path<TInputs>>(name);
  const { unregister, watch } = useFormContext<TInputs>();
  const {
    field: { onChange, onBlur, value, name: inputName },
    fieldState: { error },
  } = useController({
    name,
    rules,
    disabled: excludeValueFromSubmissionOnDisabled && disabled,
  });
  const { customRegexp, customValidationMessage } = useCustomValidation({
    error,
    name,
  });
  const actualFieldValue: PathValue<TInputs, Path<TInputs>> = watch(inputName);

  const textFieldProps: PropsWithChildren<
    TextFieldProperties & {
      children?: ReactNode;
    } & RefAttributes<HTMLElement> &
      HTMLAttributes<HTMLElement>
  > = {
    id: inputName,
    label,
    value: value ?? "",
    onBlur,
    fullWidth,
    disabled,
    required: !disabled && getRuleValue(rules?.required),
    regexp: customRegexp, // This is purely for displaying the custom validation message and nothing more. A little bit hacky but...it works!
    ...(setValueOnChange ? { onChange } : { onInput: onChange }),
  };

  useEffect(() => {
    // Controlled input state has possibility to get out of sync with the 'actual' state in react-hook-form if setValue is used to set this field
    if (actualFieldValue !== value) {
      onChange(actualFieldValue);
    }
  }, [actualFieldValue, onChange, value]);

  useEffect(() => {
    if (previousNameValue.current === name) {
      return;
    }

    // If the name parameter changes, we need to assign the value to the new name in the form
    unregister(previousNameValue.current);
    previousNameValue.current = name;
    onChange(actualFieldValue);
  }, [actualFieldValue, name, onChange, unregister]);

  return (
    <div style={getStylesFromColumnSpan(columnSpan)}>
      <SuiTextField {...textFieldProps}>
        <span slot="validation-empty">{requiredRuleErrorMessage}</span>
        <span slot="validation-custom">{customValidationMessage}</span>
      </SuiTextField>
    </div>
  );
}

export default TextField;
