import { useContext, useMemo } from "react";
import {
  AppDispatchContext,
  ModalContext,
} from "../../../../../../state/Context";
import useEditNode from "../../../../hooks/useEditNode";
import IEditNodeService from "../../../../services/interfaces/IEditNodeService";
import EditStateLinkNodeDataModalService, {
  IEditStateLinkNodeDataModalService,
} from "../services/EditStateLinkNodeModalService";

const useEditStateLinkNodeDataModal =
  (): IEditStateLinkNodeDataModalService => {
    const { dispatch: modalDispatch } = useContext(ModalContext);
    const appDispatch = useContext(AppDispatchContext);
    const editNodeService: IEditNodeService = useEditNode();
    const editStateLinkNodeModalService: IEditStateLinkNodeDataModalService =
      useMemo(
        () =>
          new EditStateLinkNodeDataModalService(
            appDispatch,
            modalDispatch,
            editNodeService
          ),
        [appDispatch, modalDispatch, editNodeService]
      );
    return editStateLinkNodeModalService;
  };

export default useEditStateLinkNodeDataModal;
