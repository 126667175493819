import { useRef, useState } from "react";
import ModalContent from "@components/ModalContent/ModalContent";
import { SuiButton } from "@components/SuiButton";
import { SubState } from "Asset_Specification";
import EditSubstateForm from "./forms/EditSubstateForm";
import { EditSubstateModalType } from "../enums/EditSubstateModalType";
import { FormSuiButton } from "@form-components";

interface EditSubstateModalProps {
  type: EditSubstateModalType;
  substate: SubState;
  onSaveCallback: (substate: SubState) => void;
  onCancelCallback: () => void;
}

const formId: string = "substate-form";

const EditSubstateModal = ({
  type,
  substate,
  onSaveCallback,
  onCancelCallback,
}: EditSubstateModalProps) => {
  const formRef: React.MutableRefObject<HTMLFormElement | null> =
    useRef<HTMLFormElement>(null);
  const [valid, setValid] = useState<boolean>();
  return (
    <ModalContent
      renderTopSlotContent={() => <h1>{type}</h1>}
      renderMainContent={() => (
        <EditSubstateForm
          id={formId}
          formRef={formRef}
          substate={substate}
          onSubstateSubmitted={(substate: SubState) => onSaveCallback(substate)}
          onValidityChange={setValid}
        />
      )}
      renderBottomRightSlotContent={() => (
        <FormSuiButton
          formRef={formRef}
          label="Save"
          type="primary"
          disabled={!valid}
        />
      )}
      renderBottomLeftSlotContent={() => (
        <SuiButton type="secondary" onClick={onCancelCallback}>
          Cancel
        </SuiButton>
      )}
    />
  );
};

export default EditSubstateModal;
