import { Dispatch } from "react";
import { CaseNodeData } from "../../../../types";
import { ModalReducerAction } from "../../../../../../state/reducers/modalReducer";
import EditNodeModalService from "../../../../services/interfaces/EditNodeModalService";
import { EditCaseNodeDataModal } from "../modals/EditCaseNodeDataModal";
import { CaseNodeHeadings } from "../enums/CaseNodeHeadings";
import IEditNodeService from "../../../../services/interfaces/IEditNodeService";

export default class EditCaseNodeDataModalService
  implements EditNodeModalService<CaseNodeData>
{
  private modalDispatch: Dispatch<ModalReducerAction>;
  private editNodeService: IEditNodeService;

  constructor(
    modalDispatch: Dispatch<ModalReducerAction>,
    editNodeService: IEditNodeService
  ) {
    this.modalDispatch = modalDispatch;
    this.editNodeService = editNodeService;
  }

  public openEditModal(nodeId: string, nodeData: CaseNodeData) {
    this.modalDispatch({
      type: "show",
      content: this.getModalContent(
        CaseNodeHeadings.Edit,
        nodeData,
        (newValue) => this.editNodeService.editNode(nodeId, newValue)
      ),
    });
  }

  private getModalContent(
    heading: string,
    nodeData: CaseNodeData,
    onSaveCallback: (newValue: CaseNodeData) => void
  ): JSX.Element {
    return (
      <EditCaseNodeDataModal
        heading={heading}
        caseNodeData={nodeData}
        onSaveCallback={(newValue) => {
          onSaveCallback(newValue);
          this.modalDispatch({ type: "close" });
        }}
        onCancelCallback={() => this.modalDispatch({ type: "close" })}
      />
    );
  }
}
