import { useContext, useCallback, useEffect, useState } from "react";
import {
  AppDispatchContext,
  AppStateContext,
  ModalContext,
} from "../../state/Context";
import { ExchangeAsset } from "../../Asset_Specification";
import { toArray } from "../../utils";
import ExchangeModal from "./Modal/ExchangeModal";
import EditList from "../EditList/EditList";
import {
  getItemDictionary,
  onDeleteItemCallback,
  onSaveItemCallback,
} from "../EditList/utils/editListUtils";

const addFormHeading = "Add Exchange Asset";
const editFormHeading = "Edit Exchange Asset";
const exchangeAssetUniqueProperty = "@id";

const ExchangeEditor = () => {
  const { specificationService } = useContext(AppStateContext);
  const dispatchApp = useContext(AppDispatchContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const [exchangeAssets, setExchangeAssets] = useState<{
    [key: string]: ExchangeAsset;
  }>({});

  const setExchangeAssetsCallback = useCallback(
    (newExchangeAssets: { [key: string]: ExchangeAsset }) => {
      setExchangeAssets(newExchangeAssets);
      dispatchApp({
        type: "set-exchange",
        exchange: Object.values(newExchangeAssets),
      });
    },
    [dispatchApp]
  );

  const onSaveExchangeAsset = useCallback(
    (exchangeAsset: ExchangeAsset, exchangeAssetId?: string) => {
      onSaveItemCallback(
        exchangeAssets,
        exchangeAsset,
        setExchangeAssetsCallback,
        dispatchModal,
        exchangeAssetId
      );
    },
    [dispatchModal, exchangeAssets, setExchangeAssetsCallback]
  );

  const openEditExchangeAssetModal = useCallback(
    (heading: string, assetEntry?: [string, ExchangeAsset]) =>
      dispatchModal({
        type: "show",
        content: (
          <ExchangeModal
            modalHeading={heading}
            itemEntry={assetEntry}
            onSaveItemCallback={onSaveExchangeAsset}
            onCancelCallback={() => dispatchModal({ type: "close" })}
          />
        ),
      }),
    [dispatchModal, onSaveExchangeAsset]
  );

  useEffect(() => {
    const exchangeAssetsArray: ExchangeAsset[] = toArray(
      specificationService?.Specification.equipmentPhase?.exchange?.asset
    );
    setExchangeAssets(getItemDictionary(exchangeAssetsArray));
  }, [specificationService]);

  return (
    <EditList
      itemDictionary={exchangeAssets}
      heading="Exchange Assets"
      getItemLabel={(item) =>
        (item as ExchangeAsset)[exchangeAssetUniqueProperty]
      }
      onAddItemButtonClick={() => openEditExchangeAssetModal(addFormHeading)}
      onEditItemCallback={(assetId) =>
        openEditExchangeAssetModal(editFormHeading, [
          assetId,
          exchangeAssets[assetId],
        ])
      }
      onDeleteItemCallback={(assetId) =>
        onDeleteItemCallback(exchangeAssets, assetId, setExchangeAssetsCallback)
      }
    />
  );
};

export default ExchangeEditor;
