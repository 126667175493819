import { FormatTemplate } from "../FormatTemplate";

const actionSnapShotTemplate: FormatTemplate = {
  "@snapShotId": {
    type: "string",
    canBeUndefined: false,
  },
};

export default actionSnapShotTemplate;
