import { FormatTemplate } from "../FormatTemplate";
import conditionTemplate from "./conditionTemplate";
import setOperatingSchemeCommandTemplate from "./setOperatingSchemeCommandTemplate";
import setParameterTemplate from "./setParameterTemplate";

const actionOperatingSchemeCommandTemplate: FormatTemplate = {
  setOperatingSchemeCommand: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: setOperatingSchemeCommandTemplate,
  },
  setParameter: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: setParameterTemplate,
  },
  condition: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: conditionTemplate,
  },
  "@controlModuleId": {
    type: "string",
    canBeUndefined: false,
  },
};

export default actionOperatingSchemeCommandTemplate;
