import { File, ProcessDescription } from "../../Asset_Specification";
import { useContext, useEffect, useRef, useState } from "react";
import { AppDispatchContext, AppStateContext } from "../../state/Context";
import { DocumentationPHForm } from "./interfaces/DocumentationForm";
import {
  defaultDocumentationPHForm,
  getDocumentationPHFormFromDocumentationPH,
  getDocumentationPHFromDocumentationPHForm,
  isValidDocumentationPHForm,
  processOptionalFileValue,
} from "./utils/editDocumentationUtils";
import { SuiFormSegment } from "../SuiFormSegment";
import FileInput from "../GenericInputs/FileInput/FileInput";
import FormColumn from "../FormColumn/FormColumn";
import ContainerWithHeader from "../ContainerWithHeader/ContainerWithHeader";
import ProcessDescriptionInput from "./inputs/ProcessDescriptionInput/ProcessDescriptionInput";
import { SuiDivider } from "../SuiDivider";
import { SuiScrollbar } from "../SuiScrollbar";
import "./EditDocumentation.css";
import { toArray } from "../../utils";
import { SuiButton } from "../SuiButton";
import { SuiIcon } from "../SuiIcon";
import addIcon from "@umetrics/sartorius-ui-icon/dist/icons/add";

interface EditDocumentationProps {
  validityCallback: (valid: boolean) => void;
}

const id = "EditDocumentation";
const imageFileExtensions = [".png"];

const EditDocumentation = (props: EditDocumentationProps) => {
  const { validityCallback } = { ...props };

  const processDescriptionRef = useRef<any>();

  const dispatch = useContext(AppDispatchContext);
  const { specificationService } = useContext(AppStateContext);
  const [documentationForm, setDocumentationForm] =
    useState<DocumentationPHForm>(defaultDocumentationPHForm);

  const onAddItemButtonClick = (_: React.MouseEvent): void => {
    processDescriptionRef.current?.onAddItem();
  };

  const onPropertyChanged = (
    propertyName:
      | "processDescription"
      | "wireUp"
      | "sequentialFlowChart"
      | "processFlowDiagram",
    propertyValue: any,
    valid: boolean
  ): void => {
    const documentationFormCopy = {
      ...documentationForm,
      [propertyName]: propertyValue ? [propertyValue, valid] : undefined,
    };
    if (!propertyValue) {
      delete documentationFormCopy[propertyName];
    }

    setDocumentationForm(documentationFormCopy);
    validityCallback(isValidDocumentationPHForm(documentationFormCopy));
    dispatch({
      type: "set-documentation",
      documentation: getDocumentationPHFromDocumentationPHForm(
        documentationFormCopy
      ),
    });
  };

  useEffect(() => {
    const newDocumentationForm = getDocumentationPHFormFromDocumentationPH(
      specificationService?.getDocumentationPH()
    );
    setDocumentationForm(newDocumentationForm);
    validityCallback(isValidDocumentationPHForm(newDocumentationForm));
  }, [specificationService, validityCallback]);

  return (
    <ContainerWithHeader
      heading="Process Description"
      additionalHeading={
        <h1 className="edit-list-heading-count">
          ({toArray(documentationForm.processDescription[0].textBlock).length})
        </h1>
      }
      headingButtons={
        <SuiButton
          type="primary"
          onClick={onAddItemButtonClick}
          fullHeight
          fullWidth
        >
          <SuiIcon icon={addIcon} slot="icon-slot" size="large" />
        </SuiButton>
      }
    >
      <SuiFormSegment className="edit-documentation-form-segment">
        <FormColumn
          columnSpanDesktop={12}
          columnSpanTablet={12}
          columnSpanMobile={12}
        >
          <SuiScrollbar
            className="edit-documentation-process-description-scrollbar"
            horiHide
          >
            <ProcessDescriptionInput
              id={`${id}-ProcessDescription`}
              ref={processDescriptionRef}
              processDescription={documentationForm.processDescription[0]}
              onChangeCallback={(
                newValue: ProcessDescription,
                valid: boolean
              ) => onPropertyChanged("processDescription", newValue, valid)}
            />
          </SuiScrollbar>
        </FormColumn>

        <SuiDivider />

        <FormColumn
          columnSpanDesktop={4}
          columnSpanTablet={4}
          columnSpanMobile={12}
        >
          <FileInput
            id={`${id}-SequentialFlowChart`}
            file={documentationForm.sequentialFlowChart[0]}
            label="Sequential Flowchart"
            acceptedFileExtensions={imageFileExtensions}
            required
            onChangeCallback={(newValue: File, valid: boolean) =>
              onPropertyChanged("sequentialFlowChart", newValue, valid)
            }
          />
        </FormColumn>

        <FormColumn
          columnSpanDesktop={4}
          columnSpanTablet={4}
          columnSpanMobile={12}
        >
          <FileInput
            id={`${id}-WireUp`}
            file={documentationForm.wireUp?.[0]}
            label="Wire Up"
            acceptedFileExtensions={imageFileExtensions}
            onChangeCallback={(newValue: File, valid: boolean) =>
              onPropertyChanged(
                "wireUp",
                processOptionalFileValue(newValue),
                valid
              )
            }
          />
        </FormColumn>

        <FormColumn
          columnSpanDesktop={4}
          columnSpanTablet={4}
          columnSpanMobile={12}
        >
          <FileInput
            id={`${id}-ProcessFlowDiagram`}
            file={documentationForm.processFlowDiagram?.[0]}
            label="Process Flow Diagram"
            acceptedFileExtensions={imageFileExtensions}
            onChangeCallback={(newValue: File, valid: boolean) =>
              onPropertyChanged(
                "processFlowDiagram",
                processOptionalFileValue(newValue),
                valid
              )
            }
          />
        </FormColumn>
      </SuiFormSegment>
    </ContainerWithHeader>
  );
};

export default EditDocumentation;
