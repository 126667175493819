import { ReactElement, useRef, useState } from "react";
import ModalContent from "../../../../../ModalContent/ModalContent";
import { SuiButton } from "../../../../../SuiButton";
import { CaseNodeData } from "../../../../types";
import CaseEditForm from "./forms/CaseEditForm";
import { FormSuiButton } from "@form-components";
import { Case } from "Asset_Specification";

interface CaseNodeModalProps {
  heading: string;
  caseNodeData: CaseNodeData;
  onSaveCallback: (newValue: CaseNodeData) => void;
  onCancelCallback: () => void;
}

const formId: string = "case-form";

export const EditCaseNodeDataModal = ({
  heading: label,
  caseNodeData,
  onSaveCallback,
  onCancelCallback,
}: CaseNodeModalProps): ReactElement<CaseNodeModalProps> => {
  const formRef: React.MutableRefObject<HTMLFormElement | null> =
    useRef<HTMLFormElement>(null);
  const [valid, setValid] = useState<boolean>(false);

  return (
    <ModalContent
      renderMainContent={() => (
        <CaseEditForm
          id={formId}
          formRef={formRef}
          caseValue={caseNodeData.case}
          onCaseSubmitted={(newCase: Case) =>
            onSaveCallback({ ...caseNodeData, case: newCase })
          }
          onValidityChange={setValid}
        />
      )}
      renderTopSlotContent={() => <h1>{label}</h1>}
      renderBottomRightSlotContent={() => (
        <FormSuiButton
          formRef={formRef}
          label="Save"
          type="primary"
          disabled={!valid}
        />
      )}
      renderBottomLeftSlotContent={() => (
        <SuiButton type="secondary" onClick={onCancelCallback}>
          Cancel
        </SuiButton>
      )}
    />
  );
};
