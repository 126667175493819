import { ReactElement, useEffect, useState } from "react";
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import AddConditionButton from "./buttons/AddConditionButton";
import ConditionInputEntry from "./ConditionInputEntry";
import { Condition, ConditionOperator } from "Asset_Specification";
import { defaultCondition } from "@utils/conditionUtils";
import CollapseSegment from "@components/GenericInputs/CollapseSegment/CollapseSegment";
import { formatConditionToHumanReadableLabel } from "@utils/conditionLabelUtils";

/**
 * Defines the properties for the {@link ConditionInputContent} component
 * @template TInputs - the value type for the input.
 * @see InputPropsWithoutRules<TInputs>
 */
interface ConditionInputContentProps<TInputs extends FieldValues>
  extends Omit<
    InputPropsWithoutRules<TInputs>,
    "label" | "columnSpan" | "fullWidth"
  > {
  operatorsToExclude?: ConditionOperator[];
}

/**
 * The content of the input component for Conditions in an asset specification.
 *
 * @param props the properties to inject into the component
 * @returns {ReactElement<ConditionInputContentProps<TInputs>>}
 */
export default function ConditionInputContent<TInputs extends FieldValues>({
  name,
  disabled,
  operatorsToExclude,
}: ConditionInputContentProps<TInputs>): ReactElement<
  ConditionInputContentProps<TInputs>
> {
  const { unregister, setValue, trigger, watch } = useFormContext<TInputs>();
  const [humanReadableCondition, setHumanReadableCondition] =
    useState<string>("");

  // Watch everything for the human readable label otherwise it won't update when each condition field is updated (condition only gets updated via watch when the field value is set via name)
  const watchAll = watch();
  const condition: Condition | undefined = watch(name);

  const onDelete = () => {
    if (!condition) {
      return;
    }

    unregister(name);
  };

  useEffect(() => {
    trigger(name);
  }, [condition, name, trigger]);

  useEffect(() => {
    setHumanReadableCondition(
      condition ? formatConditionToHumanReadableLabel(condition) : ""
    );
  }, [condition, watchAll]);

  return (
    <div className="condition-content">
      {!condition ? (
        <AddConditionButton
          onAdd={() =>
            setValue(name, { ...defaultCondition } as PathValue<
              TInputs,
              Path<TInputs>
            >)
          }
          disabled={!!disabled}
        />
      ) : (
        <>
          <CollapseSegment buttonLabel="Human Readable Label">
            <p className="condition-human-readable black-text">
              {humanReadableCondition}
            </p>
          </CollapseSegment>
          <ConditionInputEntry
            name={name}
            disabled={disabled}
            onDelete={onDelete}
            operatorsToExclude={operatorsToExclude}
          />
        </>
      )}
    </div>
  );
}
