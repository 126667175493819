import { Label } from "../Asset_Specification";

export const defaultLabel: [Label, Label, ...Label[]] = [
  {
    "@lang": "en_US.utf8",
    "@value": ""
  },
  {
    "@lang": "de_DE.utf8",
    "@value": ""
  }
]

export const getLabels = (label: Label | Label[]): Label | [Label, Label, ...Label[]] => {
  if (!Array.isArray(label)) {
    return label;
  }

  if (label.length < 1) {
    return defaultLabel;
  }

  if (label.length < 2) {
    return label[0];
  }
  
  return getLabelTuple(label);
}

export const getLabelTuple = (label: Label[]): [Label, Label, ...Label[]] => {
  if (label.length < 1) {
    return defaultLabel;
  }

  if (label.length < 2) {
    const labelTupleToReturn: [Label, Label, ...Label[]]  = [ ...defaultLabel ]
    const labelToModifyIndex: number = labelTupleToReturn.findIndex(element => element["@lang"] === label[0]["@lang"]);
    labelTupleToReturn[labelToModifyIndex] = label[0];
    return labelTupleToReturn;
  }

  if (label.length === 2) {
    return [label[0], label[1]];
  }

  return [label[0], label[1], ...label.slice(3)];
}

export const labelObjectsAreEqual = (firstLabel: Label, secondLabel: Label): boolean => {
  return firstLabel["@lang"] === secondLabel["@lang"] && firstLabel["@value"] === secondLabel["@value"];
}

export const isLabelInArray = (label: Label, array: Label[]) => {
  for (const arrayLabel of array) {
    const labelIsInArray = labelObjectsAreEqual(label, arrayLabel);
    if(labelIsInArray) {
      return true;
    }
  }

  return false;
}

export const labelArraysAreEqual = (firstLabelArray: Label[], secondLabelArray: Label[]) => {
  if (firstLabelArray.length !== secondLabelArray.length) {
    return false;
  }

  for (const label of firstLabelArray) {
    const isLabelInOtherArray = isLabelInArray(label, secondLabelArray);
    if (!isLabelInOtherArray) {
      return false;
    }
  }

  return true;
}

export const labelsAreEqual = (firstLabel: Label | [Label, Label, ...Label[]], secondLabel: Label | [Label, Label, ...Label[]]): boolean => {
  if (!Array.isArray(firstLabel) && !Array.isArray(secondLabel)) {
    return labelObjectsAreEqual(firstLabel, secondLabel);
  }

  if (Array.isArray(firstLabel) && Array.isArray(secondLabel)) {
    if (firstLabel.length !== secondLabel.length) {
      return false;
    }

    for (const label of firstLabel) {
      const isLabelInOtherArray = isLabelInArray(label, secondLabel);
      if (!isLabelInOtherArray) {
        return false;
      }
    }

    return true;
  }

  return false;
}
