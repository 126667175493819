import { ReactElement } from "react";
import { RegexpRules } from "../../../interfaces/Rules";
import { TextFieldProps } from "../../basic/TextField/TextField";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import AnsiTextField from "../../basic/AnsiTextField";
import useLabelFromLang from "./hooks/useLabelFromLang";
import { Lang } from "types";

/**
 * Defines the properties for the {@link SingleLabelInput} component.
 * @interface
 * @extends TextFieldProps
 */
export interface SingleLabelInputProps<TInputs extends FieldValues>
  extends Omit<TextFieldProps<TInputs, RegexpRules>, "label"> {}

/**
 * A text field component for individual labels as defined in the specification.
 * @param {SingleLabelInputProps} props - the properties to pass into the component.
 * @returns {ReactElement}
 */
function SingleLabelInput<TInputs extends FieldValues>({
  name,
  rules,
  disabled,
  columnSpan,
  fullWidth,
}: SingleLabelInputProps<TInputs>): ReactElement<
  SingleLabelInputProps<TInputs>
> {
  const { watch } = useFormContext<TInputs>();
  const lang: Lang = watch(`${name}.@lang` as Path<TInputs>);
  const label: string = useLabelFromLang(lang);

  return (
    <AnsiTextField<TInputs>
      name={`${name}.@value` as Path<TInputs>}
      label={label}
      rules={rules}
      disabled={disabled}
      columnSpan={columnSpan}
      fullWidth={fullWidth}
    />
  );
}

export default SingleLabelInput;
