import { FieldValues, Path, useFormContext } from "react-hook-form";
import { ConditionOperator } from "Asset_Specification";
import { OperatorOption, getOperatorOptions } from "../utils/operatorOptions";
import { useEffect, useState } from "react";

/**
 * Defines the properties to inject into the {@link useSelectedConditionOperator} hook.
 */
interface UseSelectedConditionOperatorProps<TInputs extends FieldValues> {
  name: Path<TInputs>;
  operatorsToExclude: ConditionOperator[] | undefined;
}

/**
 * Defines the return value of the {@link useSelectedConditionOperator} hook.
 */
interface UseSelectedConditionOperatorReturn {
  selectedOperatorOption: OperatorOption;
  isGroupCondition: boolean;
}

const findSelectedOperatorOption = (
  selectedConditionOperator: ConditionOperator,
  operatorsToExclude: ConditionOperator[] | undefined
): OperatorOption => {
  const selectedOperatorOption: OperatorOption | undefined = getOperatorOptions(
    operatorsToExclude
  ).find(
    (operatorOption: OperatorOption) =>
      operatorOption.value === selectedConditionOperator
  );
  if (!selectedOperatorOption) {
    throw new Error("The selected condition operator is not valid!");
  }

  return selectedOperatorOption;
};

const getIsGroupCondition = (
  selectedConditionOperator: ConditionOperator
): boolean => {
  return (
    selectedConditionOperator === "and" || selectedConditionOperator === "or"
  );
};

/**
 * The hook for retrieving the current {@link Condition} value and related metadata.
 * @param props the properties to inject into the hook
 * @returns {UseSelectedConditionOperatorReturn}
 */
export default function useSelectedConditionOperator<
  TInputs extends FieldValues,
>({
  name,
  operatorsToExclude,
}: UseSelectedConditionOperatorProps<TInputs>): UseSelectedConditionOperatorReturn {
  const { watch } = useFormContext<TInputs>();
  const selectedConditionOperator: ConditionOperator = watch(
    `${name}.@operator` as Path<TInputs>
  );

  const [selectedOperatorOption, setSelectedOperatorOption] =
    useState<OperatorOption>(() =>
      findSelectedOperatorOption(selectedConditionOperator, operatorsToExclude)
    );
  const [isGroupCondition, setIsGroupCondition] = useState<boolean>(() =>
    getIsGroupCondition(selectedConditionOperator)
  );

  useEffect(() => {
    setSelectedOperatorOption(
      findSelectedOperatorOption(selectedConditionOperator, operatorsToExclude)
    );
    setIsGroupCondition(getIsGroupCondition(selectedConditionOperator));
  }, [operatorsToExclude, selectedConditionOperator]);

  return { selectedOperatorOption, isGroupCondition };
}
