import { FormatTemplate } from "../FormatTemplate";
import presetValuesTemplate from "./presetValuesTemplate";
import returnValuesTemplate from "./returnValuesTemplate";
import viewValuesTemplate from "./viewValuesTemplate";

const actionOperatorMessageTemplate: FormatTemplate = {
  viewValues: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: viewValuesTemplate,
  },
  presetValues: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: presetValuesTemplate,
  },
  returnValues: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: returnValuesTemplate,
  },
  "@messageId": {
    type: "string",
    canBeUndefined: false,
  },
};

export default actionOperatorMessageTemplate;
