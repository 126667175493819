import { ReactElement } from "react";
import { Path } from "react-hook-form";
import { ExchangeAsset } from "Asset_Specification";
import { ConditionInput, FormGrid } from "@form-components";

export const conditionName: Path<ExchangeAsset> = "condition";

const ConditionTabContent = (): ReactElement => {
  return (
    <FormGrid>
      <ConditionInput name={conditionName} label="Condition" />
    </FormGrid>
  );
};

export default ConditionTabContent;
