import { CSSProperties, PropsWithChildren, ReactElement } from "react";
import { SuiFormSegment } from "../../SuiFormSegment";

/**
 * Defines the properties for the {@link FormGrid} component.
 * @interface
 * @param {string} heading - the heading for the grid
 * @param children - the child input components of the grid.
 */
export interface FormGridProps extends PropsWithChildren {
  heading?: string;
  style?: CSSProperties | undefined;
  className?: string | undefined;
}

/**
 * A component for providing a container for rows and columns of form inputs
 * @param props - the properties to pass into the component
 * @returns {ReactElement}
 */
const FormGrid = ({
  heading,
  children,
  style,
  className,
}: FormGridProps): ReactElement<FormGridProps> => {
  const segmentClassName: string = `black-text ${className ?? ""}`;
  return (
    <SuiFormSegment
      heading={heading}
      style={style ?? {}}
      className={segmentClassName}
    >
      {children}
    </SuiFormSegment>
  );
};

export default FormGrid;
