import { ReactElement } from "react";
import { Path, UseFormGetValues } from "react-hook-form";
import { FaceplateElement } from "Asset_Specification";
import { AccordionInput, ValidationRuleInput } from "@form-components";
import { defaultValidationRule } from "@utils/validationRuleUtils";
import { isUndefinedOrEmptyOrWhitespace } from "@utils/stringUtils";
import { inequalities } from "@utils/operatorUtils";

export const valueValidationName: Path<FaceplateElement> =
  "valueValidation.validationRule";

const ValueValidationTabContent = (): ReactElement => {
  return (
    <AccordionInput<FaceplateElement, "valueValidation.validationRule">
      heading="Value Validation Rules"
      name={valueValidationName}
      defaultValue={{ ...defaultValidationRule }}
      getItemLabel={(
        index: number,
        getValues: UseFormGetValues<FaceplateElement>
      ) => {
        const defaultLabel: string = `Validation Rule ${index}`;
        const operator: string | undefined = getValues(
          `${valueValidationName}.${index}.@operator`
        );
        const parameterId: string | undefined = getValues(
          `${valueValidationName}.${index}.@parameterId`
        );
        if (
          isUndefinedOrEmptyOrWhitespace(operator) ||
          isUndefinedOrEmptyOrWhitespace(parameterId)
        ) {
          return defaultLabel;
        }

        const operatorLabel: string | undefined = inequalities.find(
          (inequality) => inequality.value === operator
        )?.label;
        return !isUndefinedOrEmptyOrWhitespace(operatorLabel)
          ? `${operatorLabel} ${parameterId}`
          : defaultLabel;
      }}
      itemRenderer={({ itemKey, name }) => (
        <ValidationRuleInput key={itemKey} name={name} />
      )}
    />
  );
};

export default ValueValidationTabContent;
