import { useRef, useState } from "react";
import { BranchingNodeData } from "../../../../types";
import { SuiButton } from "../../../../../SuiButton";
import ModalContent from "../../../../../ModalContent/ModalContent";
import EditBranchingNodeDataForm from "./forms/EditBranchingNodeDataForm";
import { EditBranchingNodeDataModalType } from "../enums/EditBranchingNodeDataModalType";
import { FormSuiButton } from "@form-components";

interface EditBranchingNodeDataModalProps {
  type: EditBranchingNodeDataModalType;
  branchingNodeData: BranchingNodeData;
  onSaveCallback: (branchingNodeData: BranchingNodeData) => void;
  onCancelCallback: () => void;
}

const formId: string = "edit-branching-node-data-form";

const EditBranchingNodeDataModal = (props: EditBranchingNodeDataModalProps) => {
  const { type, branchingNodeData, onSaveCallback, onCancelCallback } = {
    ...props,
  };
  const formRef: React.MutableRefObject<HTMLFormElement | null> =
    useRef<HTMLFormElement>(null);
  const [valid, setValid] = useState<boolean>(true);

  return (
    <ModalContent
      renderTopSlotContent={() => <h1>{type}</h1>}
      renderMainContent={() => (
        <EditBranchingNodeDataForm
          id={formId}
          formRef={formRef}
          branchingNodeData={branchingNodeData}
          onBranchingNodeDataSubmitted={onSaveCallback}
          onValidityChange={setValid}
        />
      )}
      renderBottomRightSlotContent={() => (
        <FormSuiButton
          formRef={formRef}
          label="Save"
          type="primary"
          disabled={!valid}
        />
      )}
      renderBottomLeftSlotContent={() => (
        <SuiButton type="secondary" onClick={onCancelCallback}>
          Cancel
        </SuiButton>
      )}
    />
  );
};

export default EditBranchingNodeDataModal;
