import { ReactElement } from "react";
import { SuiTabs } from "../../SuiTabs";
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- used in doc string
import FormTab, { FormTabProps } from "./FormTab";
import { FieldValues } from "react-hook-form";

/**
 * Defines the properties for the {@link FormTabs} component.
 * @interface
 * @param children - the child {@link FormTab } components.
 */
export interface FormTabsProps<TInputs extends FieldValues> {
  children:
    | ReactElement<FormTabProps<TInputs>>
    | ReactElement<FormTabProps<TInputs>>[];
}

/**
 * A component which allows for spreading form data across different tabs
 * @param {FormTabProps} props - the properties to pass into the component.
 * @returns {ReactElement}
 */
function FormTabs<TInputs extends FieldValues>({
  children,
}: FormTabsProps<TInputs>): ReactElement<FormTabsProps<TInputs>> {
  return (
    <SuiTabs navigation fullHeight>
      {children}
    </SuiTabs>
  );
}

export default FormTabs;

