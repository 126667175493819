import { forwardRef } from "react";
import { ProcessDescription } from "../../../../Asset_Specification";
import TextBlockInput from "../../../GenericInputs/TextBlockInput/TextBlockInput";
import { getProcessDescriptionToSetFromArray } from "./utils/processDescriptionInputUtils";

interface ProcessDescriptionInputProps {
  id: string;
  processDescription: ProcessDescription;
  onChangeCallback: (newValue: ProcessDescription, valid: boolean) => void;
}

const ProcessDescriptionInput = forwardRef(
  (props: ProcessDescriptionInputProps, ref) => {
    const { id, processDescription, onChangeCallback } = { ...props };

    const onProcessDescriptionChanged = (
      newTextBlocks: string | string[] | undefined,
      valid: boolean
    ) => {
      onChangeCallback(
        getProcessDescriptionToSetFromArray(newTextBlocks),
        valid
      );
    };

    return (
      <TextBlockInput
        id={id}
        ref={ref}
        textBlock={processDescription.textBlock}
        onChangeCallback={onProcessDescriptionChanged}
        hideAddButton
        regexp=""
        setValueOnChange
      />
    );
  }
);

export default ProcessDescriptionInput;
