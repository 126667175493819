import { ReactElement } from "react";
import { FieldValues } from "react-hook-form";
import Select, { SelectProps } from "../../basic/Select/Select";
import useCalculationIds from "./hooks/useCalculationIds";

/**
 * Defines the properties for the {@link SelectParameterId}.
 */
interface SelectCalculationIdProps<TInputs extends FieldValues>
  extends Omit<SelectProps<TInputs>, "options"> {}

/**
 * The component for selecting calculation ids from those defined in the underlying specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<SelectCalculationIdProps<TInputs>>}
 */
export default function SelectCalculationId<TInputs extends FieldValues>(
  props: SelectCalculationIdProps<TInputs>
): ReactElement<SelectCalculationIdProps<TInputs>> {
  const { calculationIdOptions } = useCalculationIds();
  return <Select<TInputs> options={calculationIdOptions} {...props} />;
}
