import { FormatTemplate } from "../FormatTemplate";
import labelTemplate from "./labelTemplate";

const operatorMessageDefinitionTemplate: FormatTemplate = {
  label: {
    type: "array",
    canBeUndefined: false,
    propertyTemplate: labelTemplate,
  },
  "@id": {
    type: "string",
    canBeUndefined: false,
  },
  "@messageNumber": {
    type: "string",
    canBeUndefined: false,
  },
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@messageType": {
    type: "string",
    canBeUndefined: false,
  },
  "@buttonSet": {
    type: "string",
    canBeUndefined: false,
  },
  "@viewValues": {
    type: "string",
    canBeUndefined: false,
  },
  "@presetValues": {
    type: "string",
    canBeUndefined: false,
  },
};

export default operatorMessageDefinitionTemplate;
