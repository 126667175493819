import { Dispatch } from "react";
import { MessageNodeData, StepNodeData } from "../../../../types";
import { AppReducerAction } from "../../../../../../state/reducers/appReducer";
import { ModalReducerAction } from "../../../../../../state/reducers/modalReducer";
import EditOperatorMessageNodeDataModal from "../modals/EditOperatorMessageNodeDataModal";
import {
  Case,
  OperatorMessageButtonSet,
  OperatorMessageDefinition,
} from "../../../../../../Asset_Specification";
import { EditOperatorMessageNodeDataModalType } from "../enums/OperatorMessageModalType";
import EditNodeModalService from "../../../../services/interfaces/EditNodeModalService";
import { Node } from "reactflow";
import {
  OperatorMessageCase,
  OperatorMessageCaseWithSubstateId,
} from "@utils/branchingUtils";
import { MessageNodeDataForm } from "../modals/form/interfaces";
import { isStepNode } from "../../nodeUtils";
import { toArray } from "utils";
import AddNodeModalService from "@components/SequentialFlowEditor/services/interfaces/AddNodeModalService";

export interface IEditOperatorMessageNodeDataModalService
  extends EditNodeModalService<MessageNodeData>,
    AddNodeModalService<MessageNodeData> {}

export class EditOperatorMessageNodeDataModalService
  implements IEditOperatorMessageNodeDataModalService
{
  private readonly appDispatch: Dispatch<AppReducerAction>;
  private readonly modalDispatch: Dispatch<ModalReducerAction>;
  private readonly nodes: Node[];

  constructor(
    appDispatch: Dispatch<AppReducerAction>,
    modalDispatch: Dispatch<ModalReducerAction>,
    nodes: Node[]
  ) {
    this.appDispatch = appDispatch;
    this.modalDispatch = modalDispatch;
    this.nodes = nodes;
  }

  public openAddModal(node: Node<MessageNodeData>): void {
    this.modalDispatch({
      type: "show",
      content: this.getModalContent(
        EditOperatorMessageNodeDataModalType.Add,
        node.id,
        node.data
      ),
    });
  }

  public openEditModal(nodeId: string, nodeData: MessageNodeData): void {
    this.modalDispatch({
      type: "show",
      content: this.getModalContent(
        EditOperatorMessageNodeDataModalType.Edit,
        nodeId,
        nodeData
      ),
    });
  }

  private getModalContent(
    type: EditOperatorMessageNodeDataModalType,
    nodeId: string,
    messageNodeData: MessageNodeData
  ): JSX.Element {
    const messageNodeDataForm: MessageNodeDataForm =
      this.getMessageNodeDataForm(messageNodeData);
    return (
      <EditOperatorMessageNodeDataModal
        type={type}
        messageNodeDataForm={messageNodeDataForm}
        onSaveCallback={(newMessageNodeDataForm: MessageNodeDataForm) => {
          type === EditOperatorMessageNodeDataModalType.Edit
            ? this.saveOnEdit(
                nodeId,
                messageNodeData.stepNodeId,
                newMessageNodeDataForm,
                messageNodeData.messageDefinition["@id"]
              )
            : this.saveOnAdd(
                messageNodeData.stepNodeId,
                newMessageNodeDataForm
              );
          this.modalDispatch({ type: "close" });
        }}
        onCancelCallback={() => this.modalDispatch({ type: "close" })}
      />
    );
  }

  private getMessageNodeDataForm(
    messageNodeData: MessageNodeData
  ): MessageNodeDataForm {
    const operatorMessageCases: OperatorMessageCase[] =
      this.getOperatorMessageCasesFromMessageDefinition(
        messageNodeData.messageDefinition
      );
    const stepNode: Node<StepNodeData> | undefined = this.nodes.find(
      (node: Node) => isStepNode(node) && node.id === messageNodeData.stepNodeId
    );
    if (!stepNode) {
      throw new Error(
        `Could not find a step node with the id '${messageNodeData.stepNodeId}'!`
      );
    }

    const cases: Case[] = toArray(stepNode.data.step.branching?.case);
    const caseSubstateIds: OperatorMessageCaseWithSubstateId[] =
      operatorMessageCases.map((operatorMessageCase: OperatorMessageCase) => {
        const substateId: string =
          cases.find(
            (caseValue: Case) =>
              caseValue.condition["@operator"] === "OM" &&
              caseValue.condition["@op1"]?.toUpperCase() === operatorMessageCase
          )?.["@subStateId"] ?? "";
        return { operatorMessageCase, substateId };
      });
    return {
      caseSubstateIds,
      messageDefinition: {
        ...messageNodeData.messageDefinition,
      },
      actionMessage: messageNodeData.actionMessage
        ? {
            ...messageNodeData.actionMessage,
          }
        : undefined,
    };
  }

  private getOperatorMessageCasesFromMessageDefinition(
    messageDefinition: OperatorMessageDefinition
  ): OperatorMessageCase[] {
    const buttonSet: OperatorMessageButtonSet = messageDefinition["@buttonSet"];
    switch (buttonSet) {
      case "OK":
        return [OperatorMessageCase.Ok];
      case "Save":
        return [OperatorMessageCase.Save];
      case "YesNo":
      case "YesNoDefaultYes":
      case "YesNoDefaultNo":
        return [OperatorMessageCase.Yes, OperatorMessageCase.No];
      case "Empty":
        return [];
    }
  }

  private saveOnAdd(
    stepNodeId: string,
    messageNodeDataForm: MessageNodeDataForm
  ): void {
    this.appDispatch({
      type: "add-operator-message",
      stepNodeId: stepNodeId,
      operatorMessageDefinition: messageNodeDataForm.messageDefinition,
      actionOperatorMessage: messageNodeDataForm.actionMessage ?? {
        "@messageId": "",
      },
      addNode: true,
    });
    this.updateBranching(stepNodeId, messageNodeDataForm);
  }

  private saveOnEdit(
    nodeId: string,
    stepNodeId: string,
    messageNodeDataForm: MessageNodeDataForm,
    oldMessageDefinitionId: string
  ): void {
    const messageId: string = this.addOrUpdateOperatorMessageDefinition(
      stepNodeId,
      messageNodeDataForm,
      oldMessageDefinitionId
    );
    const messageNodeDataToSet: MessageNodeData = {
      stepNodeId,
      messageDefinition: {
        ...messageNodeDataForm.messageDefinition,
        "@id": messageId,
      },
      actionMessage: {
        ...messageNodeDataForm.actionMessage,
        "@messageId": messageId,
      },
    };

    this.appDispatch({
      type: "update-node",
      payload: {
        id: nodeId,
        data: messageNodeDataToSet,
      },
    });
    if (messageNodeDataForm.actionMessage) {
      this.appDispatch({
        type: "set-action-operator-message",
        operatorMessage: messageNodeDataToSet.actionMessage ?? {
          "@messageId": messageId,
        },
        stepNodeId,
      });
    }
    this.updateBranching(stepNodeId, messageNodeDataForm);
  }

  private addOrUpdateOperatorMessageDefinition(
    stepNodeId: string,
    messageNodeDataForm: MessageNodeDataForm,
    oldMessageDefinitionId: string
  ): string {
    const messageDefinition: OperatorMessageDefinition =
      messageNodeDataForm.messageDefinition;
    const newId: string | undefined = messageDefinition["@id"];
    // react-hook-form returns undefined value for id if we don't edit it
    if (newId === undefined || newId === oldMessageDefinitionId) {
      this.appDispatch({
        type: "update-operator-message-definition",
        messageId: oldMessageDefinitionId,
        newValue: {
          ...messageDefinition,
          "@id": oldMessageDefinitionId,
        },
      });
      return oldMessageDefinitionId;
    }

    this.appDispatch({
      type: "add-operator-message",
      stepNodeId,
      operatorMessageDefinition: messageNodeDataForm.messageDefinition,
      actionOperatorMessage: messageNodeDataForm.actionMessage ?? {
        "@messageId": newId,
      },
    });
    this.appDispatch({
      type: "remove-operator-message",
      messageId: oldMessageDefinitionId,
    });
    return newId;
  }

  private updateBranching(
    stepNodeId: string,
    messageNodeDataForm: MessageNodeDataForm
  ): void {
    const buttonSet = messageNodeDataForm.messageDefinition["@buttonSet"];
    const isEmptyButtonSet = buttonSet === "Empty";

    if (isEmptyButtonSet) {
      this.appDispatch({
        type: "remove-operator-message-branching",
        stepNodeId,
      });

      return;
    }

    this.appDispatch({
      type: "set-operator-message-branching",
      stepNodeId,
      operatorMessages: messageNodeDataForm.caseSubstateIds,
    });
  }
}
