import { CSSProperties } from "react";
import { EdgeLabelRenderer, EdgeProps } from "reactflow";
import DefaultEdge from "../DefaultEdge/DefaultEdge";
import useCaseToStepEdgeLineColor from "./hooks/useCaseToStepEdgeLineColor";
import { caseXOffset } from "../../../../../utils/nodeGenerationUtils";
import useMaxNumberOfLines from "./hooks/useMaxNumberOfLines";

const lineHeight: number = 1.25;
const fontSize: number = 9;

const CaseToStepEdge = (props: EdgeProps) => {
  const { source, sourceX, sourceY, targetY, label } = props;
  const lineColor: string = useCaseToStepEdgeLineColor(source);
  const { maxNumberOfLines } = useMaxNumberOfLines({
    lineHeight,
    fontSize,
    sourceY,
    targetY,
  });
  const style: CSSProperties = {
    ...props.style,
    stroke: lineColor,
  };
  const propsForEdgeComponent: EdgeProps = {
    ...props,
    label: undefined,
    style: style,
  };
  return (
    <>
      <DefaultEdge {...propsForEdgeComponent} />
      <EdgeLabelRenderer>
        <p
          style={{
            lineHeight: lineHeight,
            position: "absolute",
            background: "rgba(255,255,255,0.5)",
            fontSize: fontSize,
            fontWeight: 700,
            transform: `translate(-50%, 0%) translate(${sourceX}px,${sourceY}px)`,
            width: caseXOffset * 0.98,
            textAlign: "center",
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: maxNumberOfLines,
            WebkitBoxOrient: "vertical",
          }}
        >
          {label}
        </p>
      </EdgeLabelRenderer>
    </>
  );
};

export default CaseToStepEdge;
