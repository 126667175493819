import { FileSelectorChangeEvent } from "@umetrics/sartorius-ui-file-selector";
import { SuiFileSelector } from "../SuiFileSelector";
import { SuiButton } from "../SuiButton";
import { useState } from "react";
import PhaseInfoForm from "./PhaseInfoForm/PhaseInfoForm";
import "./TopPanel.css";
interface TopPanelProps {
  onFileLoaded: (event: FileSelectorChangeEvent) => void;
  onExportButtonClick: (event: React.MouseEvent) => void;
  onCreateNew: (event: React.MouseEvent) => void;
}

const TopPanel = (props: TopPanelProps) => {
  const { onFileLoaded, onExportButtonClick, onCreateNew } = {
    ...props,
  };

  const [isValid, setIsValid] = useState(true);

  return (
    <div slot="top-slot" className="top-panel-content">
      <PhaseInfoForm isValidCallback={setIsValid} />
      <div className="top-panel-buttons">
        <div className="top-panel-button">
          <SuiButton onClick={onCreateNew}>Create New</SuiButton>
        </div>
        <div className="top-panel-button">
          <SuiFileSelector
            multiple={false}
            dragDropLabel=""
            browseButtonLabel="Import"
            acceptedFileExtensions={["xml"]}
            onFileSelectionChanged={onFileLoaded}
            hideLabel
          />
        </div>
        <div className="top-panel-button">
          <SuiButton onClick={onExportButtonClick} disabled={!isValid}>
            Export
          </SuiButton>
        </div>
      </div>
    </div>
  );
};

export default TopPanel;
