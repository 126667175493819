import { ReactElement } from "react";
import { BaseInputProps } from "../../interfaces/InputProps";
import { ArrayPath, FieldArrayWithId, FieldValues } from "react-hook-form";

/**
 * Defines the properties for the {@link ArrayInput} component.
 * @template TInputs - the value type for the input.
 * @extends {BaseInputProps<ArrayPath<TInputs>, TInputs>}
 */
export interface ArrayInputProps<
  TInputs extends FieldValues,
  TArrayPath extends ArrayPath<TInputs>,
> extends BaseInputProps<TArrayPath>,
    ArrayItemRendererProps<TInputs, TArrayPath> {
  fields: FieldArrayWithId<TInputs, TArrayPath, "id">[];
  minLength?: number;
}

/**
 * Defines the properties for each indidual item in a {@link ArrayInput}
 * @template TInputs - the value type for the input.
 * @extends {BaseInputProps<ArrayPath<TInputs>, TInputs>}
 */
export interface ArrayInputItemProps<
  TInputs extends FieldValues,
  TArrayPath extends ArrayPath<TInputs>,
> extends BaseInputProps<TArrayPath> {
  itemKey: string;
  index: number;
}

/**
 * Defines the properties needed for rendering an array item
 * @template TInputs - the value type for the input.
 */
export interface ArrayItemRendererProps<
  TInputs extends FieldValues,
  TArrayPath extends ArrayPath<TInputs>,
> {
  itemRenderer: (
    itemProps: ArrayInputItemProps<TInputs, TArrayPath>
  ) => ReactElement;
}

/**
 * The input component for form fields which are arrays.
 * @param props the properties to inject into this component
 * @returns {ReactElement<ArrayInputProps<TInputs>>}
 */
export default function ArrayInput<
  TInputs extends FieldValues,
  TArrayPath extends ArrayPath<TInputs>,
>({
  fields,
  name,
  itemRenderer,
}: ArrayInputProps<TInputs, TArrayPath>): ReactElement<
  ArrayInputProps<TInputs, TArrayPath>
> {
  return (
    <>
      {fields.map(
        (field: FieldArrayWithId<TInputs, TArrayPath, "id">, index: number) =>
          itemRenderer({
            itemKey: field.id,
            name: `${name}.${index}` as TArrayPath,
            index,
          })
      )}
    </>
  );
}
