import { FormatTemplate } from "../../../FormatTemplate";
import commonSpecificationTemplate from "../commonSpecificationTemplate";
import equipmentPhaseTemplate from "./equipmentPhaseTemplate";

const equipmentPhaseSpecificationTemplate: FormatTemplate = {
  ...commonSpecificationTemplate,
  equipmentPhase: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: equipmentPhaseTemplate,
  },
};

export default equipmentPhaseSpecificationTemplate;
