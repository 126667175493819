import { ReactElement } from "react";
import { Path, UseFormGetValues } from "react-hook-form";
import { TransitionConditions } from "./types/TransitionConditions";
import { AccordionInput } from "@form-components";
import TransitionConditionInput from "./TransitionConditionInput";
import { defaultTransitionCondition } from "./utils/defaultValues";

interface TransitionConditionsFormContentProps {
  name: Path<TransitionConditions>;
}

const TransitionConditionsFormContent = ({
  name,
}: TransitionConditionsFormContentProps): ReactElement<TransitionConditionsFormContentProps> => {
  return (
    <AccordionInput<TransitionConditions>
      heading={`${name} Transition Conditions`}
      name={name}
      defaultValue={{ ...defaultTransitionCondition }}
      getItemLabel={(
        index: number,
        getValues: UseFormGetValues<TransitionConditions>
      ) => `${getValues(`${name}.${index}.id`)}`}
      itemRenderer={({ itemKey, name }) => (
        <TransitionConditionInput key={itemKey} name={name} />
      )}
      maxLength={8}
    />
  );
};

export default TransitionConditionsFormContent;
