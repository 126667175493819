import { useMemo } from "react";
import { Lang } from "types";

/**
 * A hook for getting a human readable label given a language type.
 * @param lang the language type
 * @returns a human readable label
 */
const useLabelFromLang = (lang: Lang): string => {
  return useMemo(() => {
    switch (lang) {
      case "en_US.utf8":
        return "English Label";
      case "de_DE.utf8":
        return "German Label";
      default:
        throw new Error(
          `Undefined language value! Possible values are: ${Object.values(
            lang
          ).join(", ")}`
        );
    }
  }, [lang]);
};

export default useLabelFromLang;
