import { FormatTemplate } from "../FormatTemplate";
import fileTemplate from "./fileTemplate";
import processDescriptionTemplate from "./processDescriptionTemplate";

const documentationCoreTemplate: FormatTemplate = {
  processDescription: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: processDescriptionTemplate,
  },
  wireUp: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: fileTemplate,
  },
};

export default documentationCoreTemplate;
