import { NonEmptyString } from "../Asset_Specification";

export const isEmptyOrWhitespace = (input: string): boolean => {
  const matches = String(input).match("^\\s*$");
  return matches !== null;
};

export const isUndefinedOrEmptyOrWhitespace = (input: string | undefined) => {
  return input === undefined ? true : isEmptyOrWhitespace(input);
};

export const isString = (input: any | undefined): input is string => {
  return input !== undefined && typeof input === "string";
};

export const nonEmptyString = (str: string) => {
  if (str === "") return undefined;

  return str as NonEmptyString;
};
