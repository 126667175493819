import { SuiSelect, SuiSelectOption } from "../../../SuiSelect";
import PLCParameterPropertyInputProps from "../interfaces/PLCParameterPropertyInputProps";

interface TypeInputProps extends PLCParameterPropertyInputProps {
  value: "static" | "temp" | "functionBlock";
}

const options: string[] = ["static", "temp", "functionBlock"];

const isValid = (value: string): boolean => {
  return options.includes(value);
}

const TypeInput = (props: TypeInputProps) => {
  const { 
    id, 
    value, 
    onPropertyChanged 
  } = { ...props };

  const typeId: string = `${id}-DateType`;

  return(
    <SuiSelect
      id={typeId}
      required
      label="Type"
      value={value}
      onChange={({detail: {option: {value}}} : any) => onPropertyChanged(value, isValid(value))}
    >
      {options.map((option, index) => 
        <SuiSelectOption 
          id={`${typeId}-${option}`}
          key={`${typeId}-${index}`}
          label={option}
          value={option}
        />
      )}
    </SuiSelect>
  );
}

export default TypeInput;