import { FormatTemplate } from "../FormatTemplate";
import dialogContentTemplate from "./dialogContentTemplate";
import displayInformationTemplate from "./displayInformationTemplate";
import parameterListTemplate from "./parameterListTemplate";
import reportListTemplate from "./reportListTemplate";

const assetTemplate: FormatTemplate = {
  displayInformation: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: displayInformationTemplate,
  },
  parameterList: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: parameterListTemplate,
  },
  reportList: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: reportListTemplate,
  },
  dialogContent: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: dialogContentTemplate,
  },
  "@xmlns": {
    type: "string",
    canBeUndefined: true,
  },
};

export default assetTemplate;
