import { ReactElement } from "react";
import ModalState from "../interfaces/ModalState";

export type ModalReducerAction =
  | { type: "show"; content: ReactElement }
  | { type: "close" }
  | { type: "set-on-close"; func: () => void };

export const initialModalState: ModalState = {
  props: {
    hide: true,
    clickOutsideClose: false,
    escapeClose: false,
    innerPadding: true,
    modalSize: "large",
  },
};

export function modalReducer(
  state: ModalState,
  action: ModalReducerAction
): ModalState {
  switch (action.type) {
    case "show":
      return {
        ...state,
        content: action.content,
        props: {
          ...state.props,
          hide: false,
        },
      };

    case "close":
      return {
        ...state,
        content: undefined,
        props: {
          ...state.props,
          hide: true,
        },
      };

    case "set-on-close":
      return {
        ...state,
        props: {
          ...state.props,
          onModalClose: action.func,
        },
      };
  }
}
