import { ReactElement } from "react";
import { Path, UseFormGetValues } from "react-hook-form";
import { ExchangeAsset } from "Asset_Specification";
import { AccordionInput, BypassInput, FormGrid } from "@form-components";
import { defaultBypass } from "@utils/bypassUtils";

export const bypassesName: Path<ExchangeAsset> = "byPass";

const BypassesTabContent = (): ReactElement => {
  return (
    <FormGrid>
      <AccordionInput<ExchangeAsset, "byPass">
        heading="Bypasses"
        name={bypassesName}
        defaultValue={{ ...defaultBypass }}
        getItemLabel={(
          index: number,
          getValues: UseFormGetValues<ExchangeAsset>
        ) => getValues(`${bypassesName}.${index}.@id`) ?? ""}
        itemRenderer={({ itemKey, name }) => (
          <BypassInput key={itemKey} name={name} />
        )}
      />
    </FormGrid>
  );
};

export default BypassesTabContent;
