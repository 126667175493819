import { SubState } from "Asset_Specification";
import { Path, UseFormGetValues } from "react-hook-form";
import {
  AccordionInput,
  FormGrid,
  ActionAlarmingWarningInput,
} from "@form-components";
import { defaultActionAlarmingWarning } from "@utils/actionAlarmingWarningUtils";

export const actionAlarmingWarningName: Path<SubState> =
  "actionAlarmingWarning";

const ActionAlarmWarningTabContent = () => {
  return (
    <FormGrid>
      <AccordionInput<SubState, "actionAlarmingWarning">
        heading="Action Alarming Warnings"
        name={actionAlarmingWarningName}
        defaultValue={{ ...defaultActionAlarmingWarning }}
        getItemLabel={(index: number, getValues: UseFormGetValues<SubState>) =>
          getValues(`${actionAlarmingWarningName}.${index}.@assetId`) ?? ""
        }
        itemRenderer={({ itemKey, name }) => (
          <ActionAlarmingWarningInput<SubState> key={itemKey} name={name} />
        )}
      />
    </FormGrid>
  );
};

export default ActionAlarmWarningTabContent;
