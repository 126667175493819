import { useEffect, useState } from "react";
import { HeaderSlot } from "@umetrics/sartorius-ui-header";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { callMsGraph } from "../graph";
import EnviromentBanner from "../components/EnviromentBanner/EnviromentBanner";
import { SuiHeader } from "@components/SuiHeader";

export const AppHeader = () => {
  return (
    <SuiHeader>
      <span className="app-header-product-slot" slot={HeaderSlot.PRODUCT_SLOT}>
        <div>Asset Generator</div>
        <EnviromentBanner />
      </span>
      <span className="app-header-user-slot" slot={HeaderSlot.USER_SLOT}>
        <ProfileContent />
        <div>{process.env.REACT_APP_VERSION}</div>
      </span>
    </SuiHeader>
  );
};

function ProfileContent() {
  const { instance, accounts } = useMsal();
  // eslint-disable-next-line
  const [_, setGraphData] = useState(null);
  const [name, setName] = useState<string | undefined>();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      // Do we need to obtain user's data from ms graph? Do we want to store this data across whole app?

      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          callMsGraph(response.accessToken).then((response) =>
            setGraphData(response)
          );
        })
        .catch((_) => {
          instance.acquireTokenPopup(request).then((response) => {
            callMsGraph(response.accessToken).then((response) =>
              setGraphData(response)
            );
          });
        });

      setName(accounts[0].name);
    }
  }, [accounts, instance, isAuthenticated]);

  return <div>{name}</div>;
}

