import { useContext, useMemo } from "react";
import { Edge, Node } from "reactflow";
import { AppDispatchContext } from "../../../state/Context";
import IEditNodeService from "../services/interfaces/IEditNodeService";
import EditNodeService from "../services/EditNodeService";
import useSelectedStateNodes from "./useSelectedStateNodes";
import useSelectedStateEdges from "./useSelectedStateEdges";

const useEditNode = (): IEditNodeService => {
  const selectedStateNodes: Node[] = useSelectedStateNodes();
  const selectedStateEdges: Edge[] = useSelectedStateEdges();
  const appDispatch = useContext(AppDispatchContext);

  const nodeDataChange = useMemo(
    () =>
      new EditNodeService(appDispatch, selectedStateNodes, selectedStateEdges),
    [appDispatch, selectedStateNodes, selectedStateEdges]
  );

  return nodeDataChange;
};

export default useEditNode;
