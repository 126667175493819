import { ReactElement } from "react";
import { SubState } from "Asset_Specification";
import {
  AccordionInput,
  ActionCalculationInput,
  FormGrid,
} from "@form-components";
import { Path, UseFormGetValues } from "react-hook-form";
import { defaultActionCalculation } from "@utils/actionCalculationUtils";

export const actionCalculationName: Path<SubState> = "actionCalculation";

const ActionCalculationTabContent = (): ReactElement => {
  return (
    <FormGrid>
      <AccordionInput<SubState, "actionCalculation">
        heading="Action Calculations"
        name={actionCalculationName}
        defaultValue={{ ...defaultActionCalculation }}
        getItemLabel={(index: number, getValues: UseFormGetValues<SubState>) =>
          getValues(`${actionCalculationName}.${index}.@calculationId`) ?? ""
        }
        itemRenderer={({ itemKey, name }) => (
          <ActionCalculationInput key={itemKey} name={name} />
        )}
      />
    </FormGrid>
  );
};

export default ActionCalculationTabContent;
