import { ReactElement } from "react";
import { Path, UseFormGetValues, useFormContext } from "react-hook-form";
import {
  FaceplateElement,
  FaceplateElementProperty,
} from "Asset_Specification";
import {
  AccordionInput,
  FaceplateElementPropertyInput,
} from "@form-components";
import { getDefaultFaceplateElementProperty } from "@utils/faceplateElementUtils";

export const propertyName: Path<FaceplateElement> = "property";

const ValueValidationTabContent = (): ReactElement => {
  const { watch } = useFormContext<FaceplateElement>();
  const selectedNames: string[] =
    watch(propertyName)?.map(
      (faceplateElementProperty: FaceplateElementProperty) =>
        faceplateElementProperty["@name"]
    ) ?? [];
  const defaultValue: FaceplateElementProperty =
    getDefaultFaceplateElementProperty(selectedNames);
  return (
    <AccordionInput<FaceplateElement, "property">
      heading="Properties"
      name={propertyName}
      defaultValue={{ ...defaultValue }}
      getItemLabel={(
        index: number,
        getValues: UseFormGetValues<FaceplateElement>
      ) => getValues(`${propertyName}.${index}.@name`) ?? ""}
      itemRenderer={({ itemKey, name, index }) => {
        const namesToExclude: string[] = selectedNames.filter(
          (_: string, selectedNamesIndex: number) =>
            selectedNamesIndex !== index
        );
        return (
          <FaceplateElementPropertyInput
            key={itemKey}
            name={name}
            namesToExclude={namesToExclude}
          />
        );
      }}
      maxLength={3}
    />
  );
};

export default ValueValidationTabContent;
