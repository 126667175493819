import { BranchingNodeData } from "../../../../../types";
import "./EditBranchingNodeDataForm.css";
import { Form, TextField } from "@form-components";

interface EditBranchingNodeDataFormProps {
  id: string;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  branchingNodeData: BranchingNodeData;
  onBranchingNodeDataSubmitted: (branchingNodeData: BranchingNodeData) => void;
  onValidityChange: (isValid: boolean) => void;
}

const EditBranchingNodeDataForm = ({
  id,
  formRef,
  branchingNodeData,
  onBranchingNodeDataSubmitted,
  onValidityChange,
}: EditBranchingNodeDataFormProps) => {
  return (
    <Form<BranchingNodeData>
      id={id}
      formRef={formRef}
      onSubmit={onBranchingNodeDataSubmitted}
      defaultValues={branchingNodeData}
      onValidityChange={onValidityChange}
    >
      <TextField
        name="transitionORStepId"
        label="Transition or Step Id"
        fullWidth
      />
    </Form>
  );
};

export default EditBranchingNodeDataForm;
