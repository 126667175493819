import { ReactElement } from "react";
import { FieldValues } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import ConditionInputGroup from "./ConditionInputGroup";
import { ConditionOperator } from "Asset_Specification";
import ConditionInputNonGroup from "./ConditionInputNonGroup";
import useSelectedConditionOperator from "../hooks/useSelectedConditionOperator";

/**
 * Defines the properties for the {@link ConditionInputEntry} component.
 */
export interface ConditionInputEntryProps<TInputs extends FieldValues>
  extends Omit<
    InputPropsWithoutRules<TInputs>,
    "label" | "columnSpan" | "fullWidth"
  > {
  onDelete: () => void;
  deleteBtnDisabled?: boolean;
  operatorsToExclude?: ConditionOperator[];
}

/**
 * A component representing a single Condition "entry" in the specification. This could either be a condition group or a non-group
 * (i.e. no embedded conditions in spec)
 * @template TInputs the type of the form object
 * @param props the properties injected into the component
 * @returns {ReactElement<ConditionInputEntryProps<TInputs>>}
 */
export default function ConditionInputEntry<TInputs extends FieldValues>({
  name,
  disabled,
  onDelete,
  deleteBtnDisabled,
  operatorsToExclude,
}: ConditionInputEntryProps<TInputs>): ReactElement<
  ConditionInputEntryProps<TInputs>
> {
  const { selectedOperatorOption, isGroupCondition } =
    useSelectedConditionOperator({
      name,
      operatorsToExclude,
    });

  return isGroupCondition ? (
    <ConditionInputGroup
      name={name}
      disabled={disabled}
      deleteBtnDisabled={deleteBtnDisabled}
      onDelete={onDelete}
      logicalOperator={selectedOperatorOption.value as "and" | "or"}
      operatorsToExclude={operatorsToExclude}
    />
  ) : (
    <ConditionInputNonGroup
      name={name}
      disabled={disabled}
      onDelete={onDelete}
      deleteBtnDisabled={deleteBtnDisabled}
      operatorsToExclude={operatorsToExclude}
      selectedOperatorOption={selectedOperatorOption}
    />
  );
}
