/**
 * @deprecated use the equivalent from form-components
 */
interface ArrayInputProps<T> {
  id: string;
  items: { key: string; value: T; valid: boolean }[];
  itemRenderer: (itemProps: ArrayInputItemProps<T>) => JSX.Element;
  onChangeCallback: (
    newValue: { key: string; value: T; valid: boolean }[]
  ) => void;
}

/**
 * @deprecated use the equivalent from form-components
 */
export interface ArrayInputItemProps<T> {
  id: string;
  value: T;
  key: string;
  valid?: boolean;
  onItemChangeCallback: (newValue: T, valid: boolean) => void;
  onDeleteItem?: () => void;
  index?: number;
}

/**
 * @deprecated use the equivalent from form-components
 */
function ArrayInput<T>(props: ArrayInputProps<T>) {
  const { id, items, onChangeCallback, itemRenderer } = props;

  const onItemChange = (
    key: string,
    index: number,
    newValue: T,
    valid: boolean
  ) => {
    let newItemsArray = [...items];

    if (index < 0 || index >= newItemsArray.length) {
      throw Error("ArrayInput: index out of scope!");
    }

    newItemsArray[index] = { key: key, value: newValue, valid };
    onChangeCallback(newItemsArray);
  };

  const onDeleteItem = (index: number) => {
    const newValueArray = [...items];
    newValueArray.splice(index, 1);

    onChangeCallback(newValueArray);
  };

  return (
    <>
      {items.map((item, index) =>
        itemRenderer({
          id: `${id}-${index}`,
          key: item.key,
          value: item.value,
          valid: item.valid,
          onItemChangeCallback: (newValue: T, valid: boolean) =>
            onItemChange(item.key, index, newValue, valid),
          onDeleteItem: () => onDeleteItem(index),
          index: index,
        })
      )}
    </>
  );
}

export default ArrayInput;
