import { SuiTabs } from "../../SuiTabs";
import ParametersTab from "./ParametersTab";
import SequentialFlowEditorTab from "./SequentialFlowEditorTab";
import ExchangeTab from "./ExchangeTab";
import CalculationsTab from "./CalculationsTab";
import SnapshotsTab from "./SnapshotsTab";
import DocumentationTab from "./DocumentationTab";
import FaceplatesTab from "./FaceplatesTab";

const HomePageTabs = () => {
  return (
    <SuiTabs navigation fullHeight>
      <SequentialFlowEditorTab />
      <ParametersTab />
      <ExchangeTab />
      <CalculationsTab />
      <SnapshotsTab />
      <DocumentationTab />
      <FaceplatesTab />
    </SuiTabs>
  );
};

export default HomePageTabs;
