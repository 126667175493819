import { AWParameter, ActionAlarmingWarning } from "Asset_Specification";

const defaultAwParameter: AWParameter = {
  "@value": "",
  "@enable": false,
};

export const defaultActionAlarmingWarning: ActionAlarmingWarning = {
  "@assetId": "",
  limitH: defaultAwParameter,
  limitHH: defaultAwParameter,
  limitL: defaultAwParameter,
  limitLL: defaultAwParameter,
};
