import { SnapShotDefinition } from "Asset_Specification";
import { useContext, useMemo } from "react";
import { AppStateContext } from "state/Context";

/**
 * Defines the return value from the {@link useSnapShotIds} hook.
 */
interface UseSnapShotIdsReturn {
  snapShotIdOptions: { label: string; value: string }[];
}

/**
 * A hook for fetching the ids of snapshots for the select snapshot id component
 * @returns {UseSnapShotIdsReturn}
 */
const useSnapShotIds = (): UseSnapShotIdsReturn => {
  const { specificationService } = useContext(AppStateContext);
  const snapShotIdOptions: { label: string; value: string }[] = useMemo(
    () =>
      Object.values(specificationService?.getSnapshotDefinitions() ?? {}).map(
        (snapshotDefinition: SnapShotDefinition) => ({
          label: snapshotDefinition["@id"],
          value: snapshotDefinition["@id"],
        })
      ),
    [specificationService]
  );

  return { snapShotIdOptions };
};

export default useSnapShotIds;
