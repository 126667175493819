import { FormGrid, FormTab, MfcsInput, OptionalInput } from "@form-components";
import { defaultMfcsValue } from "@utils/mfcsUtils";
import { MFCS, Parameter } from "Asset_Specification";
import { Path, useFormContext } from "react-hook-form";

const mfcsNames: Path<Parameter>[] = ["mfcs.@name", "mfcs.@max", "mfcs.@min"];
export const mfcsName: Path<Parameter> = "mfcs";

const MfcsTab = () => {
  const { watch } = useFormContext();
  const mfcsValue: MFCS | undefined = watch(mfcsName);

  return (
    <FormTab heading="MFCS" names={mfcsNames}>
      <FormGrid>
        <OptionalInput<Parameter>
          name={mfcsName}
          defaultValue={defaultMfcsValue}
          checkboxProps={{ label: "Has MFCS?" }}
          checked={mfcsValue !== undefined}
          heading="MFCS"
        >
          <MfcsInput name={mfcsName} label="MFCS" />
        </OptionalInput>
      </FormGrid>
    </FormTab>
  );
};

export default MfcsTab;
