import {
  AnsiTextField,
  FormGrid,
  FormTab,
  NumericField,
  RequiredIdTextField,
  getAlreadyExistingValueRule,
} from "@form-components";
import { SnapShotDefinition } from "Asset_Specification";
import { Path } from "react-hook-form";

const attributesNames: Path<SnapShotDefinition>[] = [
  "@id",
  "@snapShotNumber",
  "@textValue",
  "@value",
];

interface attributesTabProps {
  excludeIds: string[];
}

const AttributesTab = ({ excludeIds }: attributesTabProps) => {
  return (
    <FormTab heading="Attributes" names={attributesNames}>
      <FormGrid style={{ marginBottom: "1.5rem" }}>
        <RequiredIdTextField
          name="@id"
          fullWidth
          rules={{
            validate: {
              ...getAlreadyExistingValueRule(
                excludeIds,
                "Value cannot be used as an Id since it's already being used by another snapshot!"
              ),
            },
          }}
        />
        <NumericField
          name="@snapShotNumber"
          label="Snapshot Number"
          isInteger
          rules={{ required: true }}
          fullWidth
        />
        <AnsiTextField
          name="@value"
          label="Value"
          rules={{ required: true }}
          fullWidth
        />
        <AnsiTextField name="@textValue" label="Text Value" fullWidth />
      </FormGrid>
    </FormTab>
  );
};

export default AttributesTab;
