import { Dispatch } from "react";
import { SubState } from "../../../../../../Asset_Specification";
import EditSubstateModal from "../modals/EditSubstateModal";
import { EditSubstateModalType } from "../enums/EditSubstateModalType";
import { AppReducerAction } from "../../../../../../state/reducers/appReducer";
import { ModalReducerAction } from "../../../../../../state/reducers/modalReducer";
import { StepNodeData } from "../../../../types";
import { Node } from "reactflow";
import EditNodeModalService from "../../../../services/interfaces/EditNodeModalService";
import IEditNodeService from "../../../../services/interfaces/IEditNodeService";
import AddNodeModalService from "@components/SequentialFlowEditor/services/interfaces/AddNodeModalService";

export interface IEditStepNodeDataModalService
  extends EditNodeModalService<StepNodeData>,
    AddNodeModalService<StepNodeData> {}

export default class EditStepNodeDataModalService
  implements IEditStepNodeDataModalService
{
  private appDispatch: Dispatch<AppReducerAction>;
  private modalDispatch: Dispatch<ModalReducerAction>;
  private editNodeService: IEditNodeService;

  constructor(
    appDispatch: Dispatch<AppReducerAction>,
    modalDispatch: Dispatch<ModalReducerAction>,
    editNodeService: IEditNodeService
  ) {
    this.appDispatch = appDispatch;
    this.modalDispatch = modalDispatch;
    this.editNodeService = editNodeService;
  }

  public openAddModal(node: Node<StepNodeData>): void {
    this.modalDispatch({
      type: "show",
      content: this.getModalContent(
        EditSubstateModalType.Add,
        node.data,
        (substate) => this.saveOnAdd(node, substate)
      ),
    });
  }

  public openEditModal(nodeId: string, stepNodeData: StepNodeData): void {
    this.modalDispatch({
      type: "show",
      content: this.getModalContent(
        EditSubstateModalType.Edit,
        stepNodeData,
        (substate) => this.saveOnEdit(nodeId, stepNodeData, substate)
      ),
    });
  }

  private getModalContent(
    type: EditSubstateModalType,
    stepNodeData: StepNodeData,
    onSaveCallback: (substate: SubState) => void
  ): JSX.Element {
    return (
      <EditSubstateModal
        type={type}
        substate={stepNodeData.step}
        onSaveCallback={(substate: SubState) => {
          onSaveCallback(substate);
          this.modalDispatch({ type: "close" });
        }}
        onCancelCallback={() => this.modalDispatch({ type: "close" })}
      />
    );
  }

  private saveOnAdd(node: Node, newSubstate: SubState): void {
    const nodeToAdd: Node<StepNodeData> = {
      ...node,
      data: {
        ...node.data,
        step: {
          stepId: node.data.step.stepId,
          ...newSubstate,
        },
      },
    };
    this.appDispatch({ type: "add-node", node: nodeToAdd });
    this.appDispatch({
      type: "add-step",
      step: nodeToAdd.data.step,
    });
  }

  private saveOnEdit(
    nodeId: string,
    oldStepNodeData: StepNodeData,
    substate: SubState
  ): void {
    const newData: StepNodeData = {
      ...oldStepNodeData,
      step: {
        stepId: oldStepNodeData.step.stepId,
        ...substate,
      },
    };
    this.editNodeService.editNode(nodeId, newData);
  }
}
