import { MutableRefObject, ReactElement, useEffect, useRef } from "react";
import { NumericRules } from "../../../interfaces/Rules";
import { InputProps } from "../../../interfaces/InputProps";
import { getStylesFromColumnSpan } from "../../../utils/styleUtils";
import { getRuleValue } from "../../../utils/rulesUtils";
import { SuiFeedbackMessage } from "../../../../SuiFeedbackMessage";
import { FeedbackMessageType } from "@umetrics/sartorius-ui-feedback-message";
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form";
import "./NumericField.css";
import useErrorMessage from "./hooks/useErrorMessage";

/**
 * Defines the properties for the {@link NumericField} component.
 * @template TInputs type must extend {@link FieldValues}
 * @extends {InputProps<TInputs, NumericRules>}
 * @param isInteger whether the input should be for integers or decimal values
 */
export interface NumericFieldProps<TInputs extends FieldValues>
  extends InputProps<TInputs, NumericRules> {
  isInteger?: boolean;
}

/**
 * A numeric basic input component
 * @template TInputs type must extend {@link FieldValues}
 * @param props the properties to pass into the component
 * @returns {ReactElement<NumericFieldProps<TInputs>>}
 */
function NumericField<TInputs extends FieldValues>({
  name,
  label,
  rules,
  disabled,
  columnSpan,
  fullWidth,
  isInteger,
}: NumericFieldProps<TInputs>): ReactElement<NumericFieldProps<TInputs>> {
  const inputRef: MutableRefObject<HTMLInputElement | null> =
    useRef<HTMLInputElement | null>(null);

  const required: boolean = getRuleValue(rules?.required) ?? false;
  const { getFieldState, register, trigger, formState } =
    useFormContext<TInputs>();
  const { invalid, error } = getFieldState(name, formState);
  const { errorMessage } = useErrorMessage({ name, required, error });
  const { ref, ...remainingRegisterReturn } = register(name, {
    ...rules,
    validate: {
      ...rules?.validate,
      isInteger: (value: PathValue<TInputs, Path<TInputs>>) => {
        if (isInteger && !Number.isInteger(Number(value))) {
          return "Provided number must be an integer.";
        }

        return true;
      },
    },
  });

  // Prevents mouse scrolling; can't be prevented via callback method in input JSX Element!
  useEffect(() => {
    const input: HTMLInputElement | null = inputRef.current;
    const handleWheel = (event: Event) => event.preventDefault();
    input?.addEventListener("wheel", handleWheel, {
      passive: false,
    });
    return () => input?.removeEventListener("wheel", handleWheel);
  }, []);

  useEffect(() => {
    trigger(name);
  }, [isInteger, name, trigger]);

  return (
    <div
      className={`numeric-input ${fullWidth ? "numeric-input-full-width" : ""}`}
      style={getStylesFromColumnSpan(columnSpan)}
    >
      <div className="numeric-input-label-wrapper">
        <label className="numeric-input-label" htmlFor={name}>
          {`${label} ${required ? "*" : ""}`}
        </label>
      </div>
      <div
        className={`numeric-input-input-field-wrapper ${
          fullWidth ? "numeric-input-input-field-wrapper-full-width" : ""
        }`}
      >
        <div
          className={`numeric-input-input-field-border ${
            invalid ? "numeric-input-input-field-border-invalid" : ""
          } ${disabled ? "numeric-input-input-field-border-disabled" : ""}`}
        >
          <input
            className="numeric-input-input-field"
            id={name}
            type="number"
            disabled={disabled}
            ref={(element: HTMLInputElement | null) => {
              ref(element);
              inputRef.current = element;
            }}
            {...remainingRegisterReturn}
          />
        </div>
      </div>
      <div className="numeric-input-feedback-wrapper">
        <SuiFeedbackMessage
          className="numeric-input-feedback-message"
          feedbackType={FeedbackMessageType.Failure}
        >
          {errorMessage}
        </SuiFeedbackMessage>
      </div>
    </div>
  );
}

export default NumericField;
