import { ReactElement } from "react";
import { Form, FormTab, FormTabs } from "@form-components";
import { ExchangeAsset } from "Asset_Specification";
import AttributesTabContent, {
  attributeNames,
} from "./TabContent/AttributesTabContent";
import ConditionTabContent, {
  conditionName,
} from "./TabContent/ConditionTabContent";
import MfcsTabContent, { mfcsName } from "./TabContent/MfcsTabContent";
import BypassesTabContent, {
  bypassesName,
} from "./TabContent/BypassesTabContent";
import EnumerationTabContent, {
  enumerationName,
} from "./TabContent/EnumerationTabContent";

interface ExchangeEditorFormProps {
  id: string;
  exchangeAsset: ExchangeAsset;
  onExchangeAssetSubmitted: (exchangeAsset: ExchangeAsset) => void;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onValidityChange: (isValid: boolean) => void;
}

const ExchangeEditorForm = ({
  id,
  exchangeAsset,
  onExchangeAssetSubmitted,
  formRef,
  onValidityChange,
}: ExchangeEditorFormProps): ReactElement<ExchangeEditorFormProps> => {
  return (
    <Form<ExchangeAsset>
      id={id}
      formRef={formRef}
      onSubmit={onExchangeAssetSubmitted}
      defaultValues={exchangeAsset}
      mode="onChange"
      onValidityChange={onValidityChange}
    >
      <FormTabs>
        <FormTab heading="Attributes" names={attributeNames}>
          <AttributesTabContent />
        </FormTab>
        <FormTab heading="Enumerations" names={enumerationName}>
          <EnumerationTabContent />
        </FormTab>
        <FormTab heading="MFCS" names={mfcsName}>
          <MfcsTabContent />
        </FormTab>
        <FormTab heading="Bypasses" names={bypassesName}>
          <BypassesTabContent />
        </FormTab>
        <FormTab heading="Conditions" names={conditionName}>
          <ConditionTabContent />
        </FormTab>
      </FormTabs>
    </Form>
  );
};

export default ExchangeEditorForm;
