import { FormEvent, ReactElement } from "react";
import { FieldValues, useController } from "react-hook-form";
import { SuiCheckbox } from "@components/SuiCheckbox";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";

/**
 * The properties for the {@link Checkbox} component.
 */
interface CheckboxProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "fullWidth"> {}

/**
 * A checkbox component which can be used for boolean field values in a form.
 * @param props the properties to inject into the component
 * @returns {ReactElement<CheckboxProps<TInputs>>}
 */
export default function Checkbox<TInputs extends FieldValues>({
  name,
  label,
  disabled,
  columnSpan,
}: CheckboxProps<TInputs>): ReactElement<CheckboxProps<TInputs>> {
  const {
    field: { onChange, value, name: inputName },
  } = useController({ name });
  return (
    <div style={{ ...getStylesFromColumnSpan(columnSpan), color: "black" }}>
      <SuiCheckbox
        id={inputName}
        label={label}
        checked={value}
        onChange={(event: FormEvent<HTMLElement>) =>
          // Negation is not a mistake here!!! When the checkbox is checked, currentTarget has no "checked" attribute.
          onChange(!event.currentTarget.hasAttribute("checked"))
        }
        disabled={disabled}
      />
    </div>
  );
}
