import { TabNotification } from "@umetrics/sartorius-ui-tabs";
import { SuiTab } from "../../SuiTabs";
import TabProps from "./interfaces/TabProps";
import EditParameters from "../../EditParameters/EditParameters";

const ParametersTab = (props: TabProps) => {
  return(
    <SuiTab
      heading="Parameters"
      notificationType={TabNotification.None}
      { ...props }
    >
      <EditParameters />
    </SuiTab>
  );
}

export default ParametersTab;