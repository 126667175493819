import AlphanumericInput from "../../../GenericInputs/AlphanumericInput";
import CalculationFormProps from "../interfaces/CalculationFormProps";

interface IdInputProps extends CalculationFormProps {
  id: string;
}

const IdInput = (props: IdInputProps) => {
  const { id, formId, setCalculationFormCallback } = { ...props };

  return (
    <AlphanumericInput
      id={`${formId}-Id`}
      label="Id"
      value={id}
      maxLength={47}
      onChangeCallback={(value: string, valid: boolean) =>
        setCalculationFormCallback({
          type: "set-prop",
          payload: {
            key: "@id",
            value: [value, valid],
          },
        })
      }
      fullWidth
      required
      additionalCharactersRegex={"_-"}
      additionalErrorMessage="or '-', '_' characters"
    />
  );
};

export default IdInput;
