import PLCParameterForm from "../interfaces/PLCParameterForm";

export interface PLCParameterFormReducer {
  field: string;
  payload: [any, boolean];
}

export const plcParameterFormReducer = (
  state: PLCParameterForm, 
  action: PLCParameterFormReducer
) => {
  return {
    ...state,
    [action.field]: action.payload
  };
}