import { FormatTemplate } from "../FormatTemplate";
import validationRuleTemplate from "./validationRuleTemplate";

const validationRuleListTemplate: FormatTemplate = {
  validationRule: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: validationRuleTemplate,
  },
};

export default validationRuleListTemplate;
