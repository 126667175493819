import AnsiCharInput from "../../../GenericInputs/AnsiCharInput";
import CalculationFormProps from "../interfaces/CalculationFormProps";

interface NameInputProps extends CalculationFormProps {
  name: string;
}

const NameInput = (props: NameInputProps) => {
  const { name, formId, setCalculationFormCallback } = { ...props };

  return (
    <AnsiCharInput
      id={`${formId}-Name`}
      label="Name"
      value={name}
      maxLength={47}
      onChangeCallback={(value: string, valid: boolean) =>
        setCalculationFormCallback({
          type: "set-prop",
          payload: { key: "@name", value: [value, valid] },
        })
      }
      fullWidth
      required
    />
  );
};

export default NameInput;

