import { FormatTemplate } from "../FormatTemplate";
import conditionTemplate from "./conditionTemplate";
import labelTemplate from "./labelTemplate";

const startHoldSuspendTemplate: FormatTemplate = {
  label: {
    type: "array",
    canBeUndefined: false,
    propertyTemplate: labelTemplate,
  },
  condition: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: conditionTemplate,
  },
  "@id": {
    type: "string",
    canBeUndefined: false,
  },
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@description": {
    type: "string",
    canBeUndefined: true,
  },
};

export default startHoldSuspendTemplate;
