import { EditSnapshotFormProps } from "./interfaces/EditShapshotFormProps";
import { Form, FormTabs } from "@form-components";
import { SnapShotDefinition } from "../../../Asset_Specification";
import "./EditSnapshotsForm.css";
import AttributesTab from "./tabs/AttributesTab";
import DescriptionValueTab from "./tabs/DescriptionValueTab";
import LabelTab from "./tabs/LabelTab";

const EditSnapshotsForm = ({
  id,
  excludeIds,
  formRef,
  onSnapshotSubmitted,
  onValidityChange,
  snapshot,
}: EditSnapshotFormProps) => {
  return (
    <Form<SnapShotDefinition>
      id={id}
      defaultValues={snapshot}
      formRef={formRef}
      onSubmit={onSnapshotSubmitted}
      onValidityChange={onValidityChange}
    >
      <FormTabs>
        <AttributesTab excludeIds={excludeIds} />
        <DescriptionValueTab name="displayFormat" heading="Display Format" />
        <DescriptionValueTab
          name="engineeringUnit"
          heading="Engineering Unit"
        />
        <LabelTab />
      </FormTabs>
    </Form>
  );
};

export default EditSnapshotsForm;
