import RenderError from "@components/RenderError/RenderError";
import App from "App";
import { HomePage } from "pages/HomePage";
import { createBrowserRouter } from "react-router-dom";
import { errorPagePath, homePath } from "./routePaths";
import RouterErrorPage from "./RouterErrorPage";

const router = createBrowserRouter([
  {
    path: homePath,
    element: <App />,
    errorElement: <RouterErrorPage />,
    children: [
      {
        path: homePath,
        element: <HomePage />,
      },
      {
        path: errorPagePath,
        element: <RenderError />,
      },
    ],
  },
]);

export default router;
