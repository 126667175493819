import { CSSProperties } from "react";
import { ColumnSpan } from "../interfaces/InputProps";

/**
 * Returns a {@link CSSProperties} object corresponding to a
 * @param columnSpan - encapsulates the column spanning details.
 * @returns {CSSProperties}
 */
export const getStylesFromColumnSpan = (
  columnSpan?: ColumnSpan
): CSSProperties => {
  return columnSpan === undefined
    ? {}
    : ({
        "--column-span": `span ${columnSpan.columnSpanDesktop ?? 12}`,
        "--column-span-tablet": `span ${columnSpan.columnSpanTablet ?? 12}`,
        "--column-span-mobile": `span ${columnSpan.columnSpanMobile ?? 12}`,
        "grid-column": columnSpan.gridColumnSpan
          ? `auto / span ${columnSpan.gridColumnSpan}`
          : "auto",
      } as CSSProperties);
};

/**
 * The {@link ColumnSpan} for occupying half the space of a full row.
 */
export const twoColumnsSpan: ColumnSpan = {
  columnSpanDesktop: 6,
  columnSpanTablet: 6,
  columnSpanMobile: 6,
  gridColumnSpan: 6,
};

/**
 * The {@link ColumnSpan} for occupying a third of the space of a full row.
 */
export const threeColumnsSpan: ColumnSpan = {
  columnSpanDesktop: 4,
  columnSpanTablet: 4,
  columnSpanMobile: 4,
  gridColumnSpan: 4,
};
