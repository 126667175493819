import { useRef, useState } from "react";
import { Parameter } from "../../../Asset_Specification";
import EditParametersForm from "../Form/EditParametersForm";
import { defaultParameter } from "../Form/utils/parameterFormUtils";
import EditModalProps from "../../EditModal/interfaces/EditModalProps";
import EditModal from "../../EditModal/EditModal";

const formId: string = "parameter-form";

const EditParametersModal = ({
  modalHeading,
  onSaveItemCallback,
  onCancelCallback,
  itemEntry,
}: EditModalProps<Parameter>) => {
  const formRef: React.MutableRefObject<HTMLFormElement | null> =
    useRef<HTMLFormElement>(null);
  const [valid, setValid] = useState<boolean>(itemEntry !== undefined);

  return (
    <EditModal
      modalHeading={modalHeading}
      valid={valid}
      onCancelCallback={onCancelCallback}
      formRef={formRef}
    >
      <EditParametersForm
        id={formId}
        formRef={formRef}
        parameter={itemEntry?.[1] ?? defaultParameter}
        onParameterSubmitted={(parameter: Parameter) =>
          onSaveItemCallback(parameter, itemEntry?.[0])
        }
        onValidityChange={setValid}
      />
    </EditModal>
  );
};

export default EditParametersModal;
