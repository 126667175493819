import { SuiAccordion } from "../SuiAccordion";
import EditListAccordionItem from "./EditListAccordionItem";

interface EditListAccordionProps {
  itemDictionary: {[type: string]: Object};
  getItemLabel: (item: Object) => string | undefined;
  onEditItemCallback: (key: string) => void;
  onDeleteItemCallback: (key: string) => void;
}

const EditListAccordion = (props: EditListAccordionProps) => {
  const { 
    itemDictionary,
    getItemLabel,
    onEditItemCallback,
    onDeleteItemCallback
  } = { ...props };

  return (
    <SuiAccordion
      singleOpen
    >
      {Object.entries(itemDictionary).map(([key, item], index) => (
        <EditListAccordionItem
          key={key}
          item={item}
          label={getItemLabel(item) ?? `Item-${index}`}
          onEditItemButtonClick={(_) => onEditItemCallback(key)}
          onDeleteItemButtonClick={(_) => onDeleteItemCallback(key)}
        />
      ))}
    </SuiAccordion>
  );
}

export default EditListAccordion;