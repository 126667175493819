import { useRef, useState } from "react";
import { SnapShotDefinition } from "../../../Asset_Specification";
import EditModalProps from "../../EditModal/interfaces/EditModalProps";
import { defaultSnapshotDefinition } from "../Form/utils/utils";
import EditModal from "../../EditModal/EditModal";
import EditSnapshotsForm from "../Form/EditSnapshotsForm";

interface EditSnapshotModalProps extends EditModalProps<SnapShotDefinition> {
  snapshotIds: string[];
}

const EditSnapshotsModal = ({
  modalHeading,
  onSaveItemCallback,
  onCancelCallback,
  itemEntry,
  snapshotIds,
}: EditSnapshotModalProps) => {
  const formRef: React.MutableRefObject<HTMLFormElement | null> =
    useRef<HTMLFormElement>(null);
  const [valid, setValid] = useState<boolean>(itemEntry !== undefined);

  return (
    <EditModal
      formRef={formRef}
      modalHeading={modalHeading}
      valid={valid}
      onCancelCallback={onCancelCallback}
    >
      <EditSnapshotsForm
        id="snapshot-form"
        formRef={formRef}
        snapshot={itemEntry?.[1] ?? defaultSnapshotDefinition}
        onSnapshotSubmitted={(snapshot) => {
          onSaveItemCallback(snapshot, itemEntry?.[0]);
        }}
        onValidityChange={setValid}
        excludeIds={snapshotIds}
      />
    </EditModal>
  );
};

export default EditSnapshotsModal;
