import { Node } from "reactflow";
import { BranchingNodeData, CaseNodeData } from "../../../../types";
import { isBranchNode, isCaseNode } from "../../../nodes/nodeUtils";

export const defaulCaseToStepEdgeColor: string = "black";

export const caseToStepEdgeColorWheel: string[] = [
  "maroon",
  "red",
  "purple",
  "fuchsia",
  "green",
  "lime",
  "olive",
  "darkgoldenrod",
  "navy",
  "blue",
  "teal",
  "aqua",
];

export const getColourWheelIndex = (
  caseNodeId: string,
  nodes: Node[]
): number => {
  const defaultIndex: number = -1;
  const caseNode: Node<CaseNodeData> | undefined = nodes.find(
    (node: Node) => isCaseNode(node) && node.id === caseNodeId
  );
  if (!caseNode) {
    return defaultIndex;
  }

  const branchingNodeId: string = caseNode.data.branchingNodeId;
  const branchingNode: Node<BranchingNodeData> | undefined = nodes.find(
    (node: Node) => isBranchNode(node) && node.id === branchingNodeId
  );
  if (!branchingNode) {
    return defaultIndex;
  }

  const caseNodeIndex: number = branchingNode.data.caseNodeIds.findIndex(
    (caseNodeIdInBranchingNode) => caseNodeIdInBranchingNode === caseNodeId
  );
  return caseNodeIndex % caseToStepEdgeColorWheel.length;
};
