import { FieldError } from "react-hook-form";
import { maybeWarnAboutMissingErrorMessage } from "../../utils/errorUtils";

/**
 * Defines the properties for the {@link useCustomValidation} hook.
 */
interface UseCustomValidationProps {
  error: FieldError | undefined;
  name: string;
}

/**
 * Defines the return value for the {@link useCustomValidation} hook.
 */
interface UseCustomValidationReturn {
  customValidationMessage: string;
  customRegexp: string | undefined;
}

const captureEverythingRegexp: string = "^[*]$";

/**
 * A hook for getting the "custom" validation message and regexp to display
 * the correct validation errors in the text field input.
 * @param props the properties to inject into the hook
 * @returns {UseCustomValidationReturn}
 */
const useCustomValidation = ({
  error,
  name,
}: UseCustomValidationProps): UseCustomValidationReturn => {
  maybeWarnAboutMissingErrorMessage(name, error);
  const customRegexp: string | undefined = error
    ? captureEverythingRegexp
    : undefined;
  const customValidationMessage: string = error?.message ?? "";
  return { customRegexp, customValidationMessage };
};

export default useCustomValidation;
