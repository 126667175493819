import { CalculationDefinition } from "../../../../Asset_Specification";
import { toArray } from "../../../../utils";
import { isEmptyOrWhitespace } from "../../../../utils/stringUtils";
import { isValidPlcParameter } from "../../../GenericInputs/PLCParameterInput/utils/plcParameterInputUtils";
import CalculationForm from "../interfaces/CalculationForm";

export const defaultCalculationForm: CalculationForm = {
  documentation: [{}, true],
  plcParameter: [undefined, true],
  code: ["", false],
  "@parameterId": [undefined, true],
  "@parameter": [undefined, true],
  "@id": ["", true],
  "@name": ["", false],
  "@description": ["", false],
};

export const getCalculationFormFromCalculationDefinition = (
  calculationDefinition?: CalculationDefinition
): CalculationForm => {
  return calculationDefinition !== undefined
    ? {
        documentation: [
          calculationDefinition.documentation,
          toArray(calculationDefinition.documentation.textBlock).every(
            (textBlock) => !isEmptyOrWhitespace(textBlock)
          ),
        ],
        plcParameter: [
          calculationDefinition.plcParameter,
          toArray(calculationDefinition.plcParameter).every(
            isValidPlcParameter
          ),
        ],
        code: [
          calculationDefinition.code,
          calculationDefinition.code.length > 0,
        ],
        "@parameterId": [calculationDefinition["@parameterId"], true],
        "@parameter": [calculationDefinition["@parameter"], true],
        "@id": [calculationDefinition["@id"], true],
        "@name": [
          calculationDefinition["@name"],
          calculationDefinition["@name"].length > 0,
        ],
        "@description": [
          calculationDefinition["@description"],
          calculationDefinition["@description"].length > 0,
        ],
      }
    : defaultCalculationForm;
};

export const getCalculationDefinitionFromCalculationForm = (
  calculationForm: CalculationForm
): CalculationDefinition => {
  return {
    documentation: calculationForm.documentation[0],
    plcParameter: calculationForm.plcParameter[0],
    code: calculationForm.code[0],
    "@parameterId": calculationForm["@parameterId"][0],
    "@parameter": calculationForm["@parameter"][0],
    "@id": calculationForm["@id"][0],
    "@name": calculationForm["@name"][0],
    "@description": calculationForm["@description"][0],
  };
};
