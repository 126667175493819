import { SuiPanelTemplate } from "../components/SuiPanelTemplate";
import { useCallback, useContext } from "react";
import {
  AppDispatchContext,
  AppStateContext,
  ModalContext,
} from "../state/Context";
import {
  GenerateXml,
  Import,
  CreateNewEquipmentPhase,
  SpecificationService,
} from "../services/SpecificationService";
import { FileSelectorChangeEvent } from "@umetrics/sartorius-ui-file-selector";
import TopPanel from "../components/TopPanel/TopPanel";
import { SuiModal } from "../components/SuiModal";
import HomePageTabs from "../components/Tabs/HomePageTabs/SectionHomeTabs";
import { AssetFormatterService } from "../services/AssetFormatterService/AssetFormatterService";
import FlowchartGenerator from "../services/FlowchartGenerator";
import { useErrorBoundary } from "react-error-boundary";
import { AssetSpecification } from "Asset_Specification";
import { selectAssetTemplate } from "@utils/assetTemplateUtils";
import { FormatTemplate } from "services/AssetFormatterService/FormatTemplate";

export const HomePage = () => {
  const dispatch = useContext(AppDispatchContext);
  const { specificationService, assetFormatterService } =
    useContext(AppStateContext);
  const { state: modal } = useContext(ModalContext);
  const { showBoundary } = useErrorBoundary();

  const unknownSpecificationTypeForFormatTemplateAlert = useCallback(() => {
    alert("Unknown specification type! No matching format template was found.");
  }, []);

  const importAndLoad = useCallback(
    function (file: File) {
      Import(file).then(
        (specification) => {
          if (!specification?.equipmentPhase?.actionTable) {
            alert("File is not a valid Equipment Phase Specification!");
            return;
          }

          const assetTemplate: FormatTemplate | undefined =
            selectAssetTemplate(specification);
          if (!assetTemplate) {
            unknownSpecificationTypeForFormatTemplateAlert();
            return;
          }

          const assetFormatterService = new AssetFormatterService(
            assetTemplate
          );

          const formattedSpecification: AssetSpecification =
            assetFormatterService.format(specification);

          const specService = new SpecificationService(formattedSpecification);
          const flowchartGenerator = new FlowchartGenerator(specService);
          dispatch({ type: "set-selected-state", selectedState: undefined });
          dispatch({
            type: "set-asset-formatter-service",
            service: assetFormatterService,
          });
          dispatch({
            type: "set-flowchart-generator",
            flowchartGenerator: flowchartGenerator,
          });
          dispatch({ type: "set-spec-service", service: specService });
          dispatch({ type: "clear-nodes-and-edges" });
        },
        (error) => showBoundary(error)
      );
    },
    [dispatch, showBoundary, unknownSpecificationTypeForFormatTemplateAlert]
  );

  const onFileLoaded = useCallback(
    (event: FileSelectorChangeEvent) => {
      const files = event.target.selectedFiles;
      if (!files || files.length === 0) {
        alert("No files selected!");
        return;
      }
      const file = files[files.length - 1];
      event.target.selectedFiles = [];

      if (file.type !== "text/xml") {
        alert(
          "Invalid file format. Please select a valid Specification file (xml)."
        );
        return;
      }

      importAndLoad(file);
    },
    [importAndLoad]
  );

  const onExportButtonClick = useCallback(
    async (_: React.MouseEvent) => {
      if (!specificationService?.Specification || !assetFormatterService) {
        return;
      }

      try {
        const assetData = await GenerateXml(
          specificationService.Specification,
          assetFormatterService
        );
        const blob = new Blob([assetData], { type: "text/xml" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = `${specificationService.getAssetType()}_${specificationService.getAssetName()}_Specification`;
        link.href = url;
        link.click();
      } catch (error) {
        showBoundary(error);
      }
    },
    [specificationService, assetFormatterService, showBoundary]
  );

  const onCreateNewClick = useCallback(
    async (_: React.MouseEvent) => {
      CreateNewEquipmentPhase().then(
        (specification) => {
          const specService = new SpecificationService(specification);
          const assetTemplate: FormatTemplate | undefined = selectAssetTemplate(
            specService.Specification
          );
          if (!assetTemplate) {
            unknownSpecificationTypeForFormatTemplateAlert();
            return;
          }

          const assetFormatterService = new AssetFormatterService(
            assetTemplate
          );
          const flowchartGenerator = new FlowchartGenerator(specService);
          dispatch({ type: "set-selected-state", selectedState: undefined });
          dispatch({ type: "set-spec-service", service: specService });
          dispatch({
            type: "set-asset-formatter-service",
            service: assetFormatterService,
          });
          dispatch({
            type: "set-flowchart-generator",
            flowchartGenerator: flowchartGenerator,
          });
          dispatch({ type: "clear-nodes-and-edges" });
        },
        (error) => showBoundary(error)
      );
    },
    [dispatch, showBoundary, unknownSpecificationTypeForFormatTemplateAlert]
  );

  return (
    <>
      <TopPanel
        onFileLoaded={onFileLoaded}
        onExportButtonClick={onExportButtonClick}
        onCreateNew={onCreateNewClick}
      />
      {specificationService && (
        <SuiPanelTemplate
          rightPanelDisabled
          bottomPanelDisabled
          leftPanelDisabled
        >
          <div slot="main-slot">
            <HomePageTabs />
          </div>
        </SuiPanelTemplate>
      )}
      <SuiModal {...modal.props}>{modal.content}</SuiModal>
    </>
  );
};
