import { Node } from "reactflow";
import {
  caseXInitialOffset,
  caseXOffset,
} from "../../../utils/nodeGenerationUtils";

export const moveNodes = (
  nodes: Node[],
  vector: { x?: number; y?: number }
) => {
  const movedNodes = [];
  for (const node of nodes) {
    const nodeCopy = { ...node };
    nodeCopy.position = {
      x: nodeCopy.position.x + (vector.x ?? 0),
      y: nodeCopy.position.y + (vector.y ?? 0),
    };
    movedNodes.push(nodeCopy);
  }

  return movedNodes;
};

export const moveNode = (node: Node, vector: { x?: number; y?: number }) => {
  return {
    ...node,
    position: {
      x: node.position.x + (vector.x ?? 0),
      y: node.position.y + (vector.y ?? 0),
    },
  };
};

export const findStepNodeIndex = (stepId: string, allNodes: Node[]) =>
  allNodes.findIndex((stepNode) => stepNode.data.step?.stepId === stepId);

export const recalculateCaseNodePositions = (
  allNodes: Node[],
  caseNodeIds: string[]
): Node[] => {
  const nodes = [...allNodes];
  const caseNodes = nodes.filter((node) => caseNodeIds.includes(node.id));

  for (let i = 0; i < caseNodes.length; i++) {
    const caseNode = caseNodes[i];
    caseNode.position.x = caseXInitialOffset + caseXOffset * i;
  }

  return nodes;
};
