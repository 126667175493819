import {
  AnsiTextField,
  FormGrid,
  Select,
  threeColumnsSpan,
} from "@form-components";
import { PhaseSubtype } from "types";

enum EphInfoFormFields {
  name = "name",
  subtype = "subtype",
  description = "description",
}

const PhaseInfoFormContent = () => {
  return (
    <FormGrid>
      <AnsiTextField
        name={EphInfoFormFields.name}
        label="Phase Name"
        fullWidth
        columnSpan={threeColumnsSpan}
        rules={{
          required: true,
        }}
        setValueOnChange
      />
      <Select
        name={EphInfoFormFields.subtype}
        label="Phase Subtype"
        options={Object.keys(PhaseSubtype)}
        fullWidth
        columnSpan={threeColumnsSpan}
      />
      <AnsiTextField
        name={EphInfoFormFields.description}
        label="Phase Description"
        fullWidth
        columnSpan={threeColumnsSpan}
        setValueOnChange
      />
    </FormGrid>
  );
};

export default PhaseInfoFormContent;
