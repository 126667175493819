import { TabNotification } from "@umetrics/sartorius-ui-tabs";
import EditFaceplateElements from "../../EditFaceplateElements/EditFaceplateElements";
import { SuiTab } from "../../SuiTabs";
import TabProps from "./interfaces/TabProps";

const FaceplatesTab = (props: TabProps) => {
  return (
    <SuiTab
      heading="Faceplates"
      notificationType={TabNotification.None}
      {...props}
    >
      <EditFaceplateElements />
    </SuiTab>
  );
};

export default FaceplatesTab;
