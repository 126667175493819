import { TransitionCondition } from "types";
import { TransitionConditions } from "../types/TransitionConditions";

export const defaultTransitionConditions: TransitionConditions = {};
export const defaultTransitionCondition: TransitionCondition = {
  id: "",
  name: "",
  english: "",
  german: "",
};
