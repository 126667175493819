import { FormatTemplate } from "../FormatTemplate";
import operatorMessageDefinitionTemplate from "./operatorMessageDefinitionTemplate";

const operatorMessagesTemplate: FormatTemplate = {
  operatorMessageDefinition: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: operatorMessageDefinitionTemplate,
  },
};

export default operatorMessagesTemplate;
