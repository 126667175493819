import { FormatTemplate } from "../FormatTemplate";

const labelTemplate: FormatTemplate = {
  "@lang": {
    type: "string",
    canBeUndefined: false,
  },
  "@value": {
    type: "string",
    canBeUndefined: false,
  },
};

export default labelTemplate;
