import { MutableRefObject, ReactElement, useEffect, useRef } from "react";
import {
  DeepPartial,
  DefaultValues,
  FieldValues,
  FormProvider,
  Mode,
  UseFormProps,
  useForm,
} from "react-hook-form";
import { SuiFormSegment } from "../SuiFormSegment";
import { Rules } from "./interfaces/Rules";
import { FormGridProps } from "./grid/FormGrid";
import { InputProps } from "./interfaces/InputProps";
import { DevTool } from "@hookform/devtools";
import { debugReactHookForm } from "./utils/envUtils";
import useFormSubmit from "./hooks/useFormSubmit";
import "./Form.css";
import { generateChecksum } from "@utils/checksumUtils";

/**
 * Defines the props for a form component.
 * @template TInputs - the type defining the inputs for the form.
 */
export interface FormProps<TInputs extends FieldValues>
  extends Omit<UseFormProps<TInputs>, "defaultValues"> {
  id: string;
  heading?: string;
  onSubmit: (data: TInputs) => void;
  children:
    | ReactElement<
        InputProps<TInputs, Rules> | FormProps<TInputs> | FormGridProps
      >
    | ReactElement<
        InputProps<TInputs, Rules> | FormProps<TInputs> | FormGridProps
      >[];
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onValidityChange?: (isValid: boolean) => void;
  defaultValues?: DefaultValues<TInputs>;
  hideDevTools?: boolean;
  submitOnChange?: boolean;
}

/**
 * A form component which wraps a {@link HTMLFormElement} in a {@link SuiFormSegment} and integrates it
 * with react-hook-form.
 * @param {FormProps}} props - the props for the form component.
 * @returns {ReactElement}
 */
export default function Form<TInputs extends FieldValues>({
  id,
  heading,
  onSubmit,
  children,
  formRef,
  mode,
  onValidityChange,
  defaultValues,
  hideDevTools,
  submitOnChange,
  ...useFormProps
}: FormProps<TInputs>): ReactElement<FormProps<TInputs>> {
  const modeToUse: Mode = mode ?? "onChange";
  const firstRender: MutableRefObject<boolean> = useRef<boolean>(true);
  const currentValidity: MutableRefObject<boolean> = useRef<boolean>(true);
  const { dispatchSubmitEvent } = useFormSubmit();
  const {
    handleSubmit,
    trigger,
    formState,
    reset,
    watch,
    getValues,
    ...remainingProps
  } = useForm<TInputs>({
    ...useFormProps,
    defaultValues: defaultValues,
    mode: modeToUse,
  });
  const valuesChecksum: MutableRefObject<number> = useRef<number>(
    generateChecksum(defaultValues)
  );

  useEffect(() => {
    if (!firstRender.current || modeToUse === "onSubmit") {
      return;
    }

    firstRender.current = false;
    trigger();
  }, [trigger, modeToUse]);

  useEffect(() => {
    if (!onValidityChange || currentValidity.current === formState.isValid) {
      return;
    }

    currentValidity.current = formState.isValid;
    onValidityChange(formState.isValid);
  }, [formState, onValidityChange]);

  useEffect(() => {
    if (!submitOnChange) {
      reset(defaultValues);
      return;
    }

    // Forms which submit on change tend to have defaultValues which match the current form values anyway
    // If they get out of sync, an external change occurred and so therfore must be reset.
    const defaultValuesChecksum: number = generateChecksum(defaultValues);
    if (defaultValuesChecksum !== valuesChecksum.current) {
      reset(defaultValues);
    }
  }, [defaultValues, getValues, reset, submitOnChange]);

  useEffect(() => {
    if (!submitOnChange || !formRef.current) {
      return;
    }

    const subscription = watch((data: DeepPartial<TInputs>) => {
      valuesChecksum.current = generateChecksum(data);
      dispatchSubmitEvent(formRef);
    });
    return () => subscription.unsubscribe();
  }, [dispatchSubmitEvent, formRef, submitOnChange, watch]);

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      formState={formState}
      trigger={trigger}
      reset={reset}
      watch={watch}
      getValues={getValues}
      {...remainingProps}
    >
      <SuiFormSegment heading={heading}>
        <form
          id={id}
          ref={formRef}
          className="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {children}
        </form>
      </SuiFormSegment>
      {!hideDevTools && debugReactHookForm && (
        <DevTool control={remainingProps.control} />
      )}
    </FormProvider>
  );
}
