import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { AnsiTextField, FormGrid, twoColumnsSpan } from "@form-components";
import { DescriptionValueEnhanced } from "Asset_Specification";
import { FieldValues, Path } from "react-hook-form";
import ValueEnumsInput from "./ValueEnumInput/ValueEnumsInput";

/**
 * Defines the properties for the {@link DescriptionValueEnhancedInput} component.
 */
interface DescriptionValueEnhancedInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {
  heading: string;
}

export const DescriptionValueEnhancedInputNames: Path<DescriptionValueEnhanced>[] =
  ["valueEnum", "@description", "@defaultValue", "@min", "@max"];

/**
 * The input component for specifying DescriptionValueEnhanced values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<DescriptionValueEnhancedInput<TInputs>>}
 */
function DescriptionValueEnhancedInput<TInputs extends FieldValues>({
  name,
  heading,
}: DescriptionValueEnhancedInputProps<TInputs>) {
  return (
    <FormGrid heading={heading}>
      <AnsiTextField
        name={`${name}.@description`}
        label="Description"
        rules={{ required: true }}
        fullWidth
        columnSpan={twoColumnsSpan}
      />
      <AnsiTextField
        name={`${name}.@min`}
        label="Min"
        fullWidth
        columnSpan={twoColumnsSpan}
      />
      <AnsiTextField
        name={`${name}.@defaultValue`}
        label="Default Value"
        rules={{
          required: true,
        }}
        fullWidth
        columnSpan={twoColumnsSpan}
      />
      <AnsiTextField
        name={`${name}.@max`}
        label="Max"
        fullWidth
        columnSpan={twoColumnsSpan}
      />

      <ValueEnumsInput name={`${name}.valueEnum`} />
    </FormGrid>
  );
}

export default DescriptionValueEnhancedInput;
