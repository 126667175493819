import { Connection, Edge, EdgeTypes, MarkerType, Node } from "reactflow";
import { HandleTypes } from "../components/SequentialFlowEditor/utils/types";
import { NodeType } from "../components/SequentialFlowEditor/types";
import {
  isStepNode,
  isStateLinkNode,
} from "../components/SequentialFlowEditor/components/nodes/nodeUtils";
import { CaseNodeData } from "../components/SequentialFlowEditor/types";
import { formatConditionToHumanReadableLabel } from "./conditionLabelUtils";
import CaseToStepEdge from "../components/SequentialFlowEditor/components/edges/CaseToStepEdge/CaseToStepEdge";
import DefaultEdge from "../components/SequentialFlowEditor/components/edges/DefaultEdge/DefaultEdge";

export const edgeMarkerType: MarkerType = MarkerType.ArrowClosed;
export const edgeTypes: EdgeTypes = {
  default: DefaultEdge,
  caseToStep: CaseToStepEdge,
};
export enum EdgeType {
  default = "default",
  caseToStep = "caseToStep",
}

export interface ValidEdgeConnection {
  sourceHandle: HandleTypes;
  targetHandles: HandleTypes[];
  addIfTargetAlreadyHasEdgeConnections: boolean;
}

const validEdgeConnections: ValidEdgeConnection[] = [
  {
    sourceHandle: HandleTypes.startSource,
    targetHandles: [HandleTypes.stepTarget],
    addIfTargetAlreadyHasEdgeConnections: true,
  },
  {
    sourceHandle: HandleTypes.stepSource,
    targetHandles: [HandleTypes.branchingTarget],
    addIfTargetAlreadyHasEdgeConnections: false,
  },
  {
    sourceHandle: HandleTypes.branchingCaseSource,
    targetHandles: [HandleTypes.stepTarget, HandleTypes.stateLinkTarget],
    addIfTargetAlreadyHasEdgeConnections: true,
  },
];

export const isValidEdgeConnection = (
  allEdges: Edge[],
  connection: Connection,
  isNewConnection: boolean
): boolean => {
  const validEdgeConnection: ValidEdgeConnection | undefined =
    validEdgeConnections.find(
      (validEdgeConnection: ValidEdgeConnection) =>
        validEdgeConnection.sourceHandle === connection.sourceHandle
    );
  const noValidTargetHandle: boolean =
    !validEdgeConnection ||
    !validEdgeConnection.targetHandles.some(
      (targetHandle: HandleTypes) => targetHandle === connection.targetHandle
    );
  if (!validEdgeConnection || noValidTargetHandle) {
    return false;
  }

  const sourceEdgeAlreadyExists: boolean = allEdges.some(
    (edge: Edge) =>
      edge.source === connection.source &&
      edge.sourceHandle === connection.sourceHandle
  );
  const exactEdgeAlreadyExists: boolean = allEdges.some(
    (edge: Edge) =>
      edge.sourceHandle === connection.sourceHandle &&
      edge.source === connection.source &&
      edge.target === connection.target
  );

  if (sourceEdgeAlreadyExists || exactEdgeAlreadyExists) {
    return !isNewConnection; // Valid if we're modifying an edge target for an existing edge
  }

  const numberOfExistingTargets: number = allEdges.filter(
    (edge: Edge) => edge.target === connection.target
  ).length;
  return (
    numberOfExistingTargets < 1 ||
    validEdgeConnection.addIfTargetAlreadyHasEdgeConnections
  );
};

export const createEdgeFromConnection = (
  nodes: Node[],
  connection: Connection
): Edge | undefined => {
  const sourceNode: Node | undefined = nodes.find(
    (node: Node) => node.id === connection.source
  );
  const targetNode: Node | undefined = nodes.find(
    (node: Node) => node.id === connection.target
  );
  if (!sourceNode || !targetNode) {
    return undefined;
  }

  return connectWithEdge(sourceNode, targetNode);
};

const createEdge = (
  source: Node,
  sourceHandle: HandleTypes,
  target: Node,
  targetHandle: HandleTypes,
  label?: string,
  type?: EdgeType
): Edge => ({
  id: createEdgeId(source, target),
  source: source.id,
  sourceHandle: sourceHandle,
  target: target.id,
  targetHandle: targetHandle,
  label: label,
  type: type ?? EdgeType.default,
});

export const createEdgeId = (source: Node, target: Node): string => {
  return `${source.id}-${target.id}`;
};

export const connectWithEdge = (
  sourceNode: Node,
  targetNode: Node
): Edge | undefined => {
  switch (sourceNode.type as NodeType) {
    case NodeType.start:
      return createStartNodeEdge(sourceNode, targetNode);
    case NodeType.step: {
      return createStepNodeEdge(sourceNode, targetNode);
    }
    case NodeType.case: {
      return createCaseNodeEdge(sourceNode, targetNode);
    }

    default:
      return undefined;
  }
};

const createEdgeWhenTargetIsStepNode = (
  sourceNode: Node,
  sourceHandle: HandleTypes,
  targetNode: Node,
  label?: string,
  type?: EdgeType
): Edge | undefined => {
  return isStepNode(targetNode)
    ? createEdge(
        sourceNode,
        sourceHandle,
        targetNode,
        HandleTypes.stepTarget,
        label,
        type
      )
    : undefined;
};

const createStartNodeEdge = (
  startNode: Node,
  targetNode: Node
): Edge | undefined => {
  return createEdgeWhenTargetIsStepNode(
    startNode,
    HandleTypes.startSource,
    targetNode
  );
};

const createCaseNodeEdge = (
  caseNode: Node<CaseNodeData>,
  targetNode: Node
): Edge | undefined => {
  const conditionLabel: string = formatConditionToHumanReadableLabel(
    caseNode.data.case.condition
  );
  if (isStepNode(targetNode)) {
    return createEdgeWhenTargetIsStepNode(
      caseNode,
      HandleTypes.branchingCaseSource,
      targetNode,
      conditionLabel,
      EdgeType.caseToStep
    );
  }

  if (isStateLinkNode(targetNode)) {
    return createEdge(
      caseNode,
      HandleTypes.branchingCaseSource,
      targetNode,
      HandleTypes.stateLinkTarget,
      conditionLabel,
      EdgeType.caseToStep
    );
  }

  return undefined;
};

const createStepNodeEdge = (sourceNode: Node, targetNode: Node): Edge => {
  let sourceHandleType: HandleTypes = HandleTypes.stepSource;
  let targetHandleType: HandleTypes = HandleTypes.stepTarget;

  switch (targetNode.type as NodeType) {
    case NodeType.branching:
      targetHandleType = HandleTypes.branchingTarget;
      break;
    case NodeType.message:
      sourceHandleType = HandleTypes.operatorMessage;
      targetHandleType = HandleTypes.operatorMessage;
      break;
  }

  return createEdge(sourceNode, sourceHandleType, targetNode, targetHandleType);
};
