import { FeedbackMessageType } from "@umetrics/sartorius-ui-feedback-message";
import { SuiSelect, SuiSelectOption } from "../../SuiSelect";
import { CommonInputProps } from "../InputProps";
import { SuiFeedbackMessage } from "../../SuiFeedbackMessage";
import "./RequiredSelectInput.css";

interface RequiredSelectInputProps extends CommonInputProps<string> {
  value: string;
  options: string[] | { label: string; value: string }[];
  label: string;
  required?: boolean;
  fixedDropdown?: boolean;
}

const getLabelAndValueForOption = (
  option: string | { label: string; value: string }
): { label: string; value: string } => {
  if (typeof option === "string") {
    return { label: option, value: option };
  }

  return option;
};

/**
 * @deprecated use the equivalent Select input from form-components
 */
const RequiredSelectInput = (props: RequiredSelectInputProps) => {
  const {
    id,
    value,
    options,
    label,
    required,
    fixedDropdown,
    onChangeCallback,
  } = {
    ...props,
  };
  return (
    <div className="required-select-input-container">
      <SuiSelect
        id={id}
        label={label}
        value={value}
        required={required}
        fullWidth
        onChange={({
          detail: {
            option: { value },
          },
        }: any) => onChangeCallback?.(value, true)}
        fixedDropdown={fixedDropdown}
      >
        {options.map((option) => {
          const optionAsObj: { label: string; value: string } =
            getLabelAndValueForOption(option);
          return (
            <SuiSelectOption
              key={`${id}-${option}`}
              label={optionAsObj.label}
              value={optionAsObj.value}
            />
          );
        })}
      </SuiSelect>
      {!value && required && (
        <SuiFeedbackMessage feedbackType={FeedbackMessageType.Failure}>
          This is a mandatory field!
        </SuiFeedbackMessage>
      )}
    </div>
  );
};

export default RequiredSelectInput;
