import { ValueEnum } from "../Asset_Specification";
import { defaultLabel, labelsAreEqual } from "./labelUtils";

export const defaultValueEnum: ValueEnum = {
  label: defaultLabel,
  "@value": "",
  "@id": "",
  "@name": "",
};

export const valueEnumsAreEqual = (
  firstValueEnum: ValueEnum,
  secondValueEnum: ValueEnum
): boolean => {
  return (
    firstValueEnum["@description"] === secondValueEnum["@description"] &&
    firstValueEnum["@id"] === secondValueEnum["@id"] &&
    firstValueEnum["@name"] === secondValueEnum["@name"] &&
    firstValueEnum["@value"] === secondValueEnum["@value"] &&
    labelsAreEqual(firstValueEnum.label, secondValueEnum.label)
  );
};
