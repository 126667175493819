import { useContext, useMemo } from "react";
import { AppStateContext } from "state/Context";

/**
 * Defines the return value from the {@link useParameterIds} hook.
 */
interface UseParameterIdsReturn {
  parameterIdOptions: { label: string; value: string }[];
}

/**
 * A hook for fetching the ids of parameters for the select parameter id component
 * @returns {UseParameterIdsReturn}
 */
const useParameterIds = (): UseParameterIdsReturn => {
  const { specificationService } = useContext(AppStateContext);
  const parameterIdOptions: { label: string; value: string }[] = useMemo(
    () =>
      Object.values(specificationService?.getParameters() ?? {}).map(
        (parameter) => ({
          label: `${parameter["@name"]} (${parameter["@id"]})`,
          value: parameter["@id"],
        })
      ),
    [specificationService]
  );

  return { parameterIdOptions };
};

export default useParameterIds;
