import AnsiCharInput from "../../../GenericInputs/AnsiCharInput";
import CalculationFormProps from "../interfaces/CalculationFormProps";

interface DescriptionInputProps extends CalculationFormProps {
  description: string;
}

const DescriptionInput = (props: DescriptionInputProps) => {
  const { description, formId, setCalculationFormCallback } = { ...props };

  return (
    <AnsiCharInput
      id={`${formId}-Description`}
      label="Description"
      value={description}
      maxLength={47}
      onChangeCallback={(value: string, valid: boolean) =>
        setCalculationFormCallback({
          type: "set-prop",
          payload: { key: "@description", value: [value, valid] },
        })
      }
      fullWidth
      required
    />
  );
};

export default DescriptionInput;

