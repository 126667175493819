import { TabNotification } from "@umetrics/sartorius-ui-tabs";
import { SuiTab } from "../../SuiTabs";
import SequentialFlowEditor from "../../SequentialFlowEditor/SequentialFlowEditor";
import TabProps from "./interfaces/TabProps";

interface SequentialFlowEditorTabProps extends TabProps {}

const SequentialFlowEditorTab = (props: SequentialFlowEditorTabProps) => {
  return (
    <SuiTab
      heading="Sequential Flow Editor"
      notificationType={TabNotification.None}
      {...props}
    >
      <SequentialFlowEditor />
    </SuiTab>
  );
};

export default SequentialFlowEditorTab;
