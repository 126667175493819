import { ConditionType, TransitionCondition, lang } from "../types";
import {
  StartConditions,
  HoldConditions,
  SuspendConditions,
  StartHoldSuspend,
  SuspendCondition,
  Condition,
} from "../Asset_Specification";
import { toArray } from "../utils";

function AssetConditionsToTransitionConditions(
  conditions: StartConditions | HoldConditions | SuspendConditions
): TransitionCondition[] {
  let cons: StartHoldSuspend | StartHoldSuspend[] | undefined = undefined;
  const paramKeys = Object.keys(conditions);
  if (paramKeys.find((key) => key === "startCondition")) {
    cons = copyStartHoldConditions(
      (conditions as StartConditions)["startCondition"]
    );
  } else if (paramKeys.find((key) => key === "holdCondition")) {
    cons = copyStartHoldConditions(
      (conditions as HoldConditions)["holdCondition"]
    );
  } else if (paramKeys.find((key) => key === "suspendCondition")) {
    let suspendCons = copySuspendConditions(
      (conditions as SuspendConditions)["suspendCondition"]
    );

    return toArray(suspendCons).map((con) => suspendToTransConditions(con));
  }
  if (!cons) {
    return [];
  }

  return toArray(cons).map((con) => startHoldSuspendToTransConditions(con));
}

function startHoldSuspendToTransConditions(con: StartHoldSuspend) {
  return {
    id: con["@id"] ?? "",
    name: con["@name"] ?? "",
    english:
      toArray(con.label).find((value) => value["@lang"] === lang.eng)?.[
        "@value"
      ] ?? "",
    german:
      toArray(con.label).find((value) => value["@lang"] === lang.ger)?.[
        "@value"
      ] ?? "",
    conditions: con.condition,
  };
}

function suspendToTransConditions(con: SuspendCondition) {
  return {
    id: con["@id"] ?? "",
    name: con["@name"] ?? "",
    english:
      toArray(con.label).find((value) => value["@lang"] === lang.eng)?.[
        "@value"
      ] ?? "",
    german:
      toArray(con.label).find((value) => value["@lang"] === lang.ger)?.[
        "@value"
      ] ?? "",
    conditions: con.suspend,
    transBackConditions: con.unSuspend,
  };
}

function copyStartHoldConditions(
  cons: StartHoldSuspend | StartHoldSuspend[] | undefined
): StartHoldSuspend | StartHoldSuspend[] | undefined {
  if (!cons) {
    return undefined;
  }

  if (Array.isArray(cons)) {
    return cons.map((con) => copyStartHoldConditions(con) as StartHoldSuspend);
  } else {
    return {
      ...cons,
      condition: deepCopyConditions(cons.condition),
    };
  }
}

function copySuspendConditions(
  cons: SuspendCondition | SuspendCondition[] | undefined
): SuspendCondition | SuspendCondition[] | undefined {
  if (!cons) {
    return undefined;
  }

  if (Array.isArray(cons)) {
    return cons.map((con) => copySuspendConditions(con) as SuspendCondition);
  } else {
    return {
      ...cons,
      suspend: deepCopyConditions(cons.suspend),
      unSuspend: deepCopyConditions(cons.unSuspend),
    };
  }
}

function deepCopyConditions(condition?: Condition): Condition | undefined {
  if (!condition) {
    return undefined;
  }

  let cons: Condition[] | undefined = undefined;
  if (Array.isArray(condition.condition)) {
    cons = condition.condition
      .filter((con) => con)
      .map((con) => deepCopyConditions(con) as Condition);
  }

  return {
    ...condition,
    condition: cons,
  };
}

function TransitionConditionsToAssetConditions(
  conditions: TransitionCondition[],
  type: ConditionType
): StartConditions | HoldConditions | SuspendConditions {
  const cons: StartHoldSuspend[] = conditions.map((condition) => ({
    "@id": condition.id,
    "@name": condition.name,
    label: [
      { "@lang": lang.eng, "@value": condition.english },
      { "@lang": lang.ger, "@value": condition.german },
    ],
    condition: deepCopyConditions(condition.conditions),
  }));

  switch (type) {
    case "START":
      return { startCondition: cons };
    case "HOLD":
      return { holdCondition: cons };
    case "SUSPEND":
      const suspendCons: SuspendCondition[] = conditions.map((condition) => ({
        "@id": condition.id,
        "@name": condition.name,
        label: [
          { "@lang": lang.eng, "@value": condition.english },
          { "@lang": lang.ger, "@value": condition.german },
        ],
        suspend: deepCopyConditions(condition.conditions),
        unSuspend: deepCopyConditions(condition.transBackConditions),
      }));
      return { suspendCondition: suspendCons };
  }
}

export {
  AssetConditionsToTransitionConditions,
  TransitionConditionsToAssetConditions,
};
