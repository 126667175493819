import React from "react";

import root from "react-shadow";

const umetricsStyleString: string = `@import ~@umetrics/sartorius-ui-style/dist/css/style.css`;

export const withShadowDOM =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: React.PropsWithChildren<P>) => {
    return (
      <root.div>
        <style type="text/css">{umetricsStyleString}</style>
        <Component {...props} />
      </root.div>
    );
  };
