import { ReactElement } from "react";
import { SuiTabs } from "../../SuiTabs";
import { FormTabProps } from "./FormTab";

/**
 * @deprecated use {@link ../../FormComponents/Tabs/FormTabs} instead
 */
interface FormTabsProps {
  children: ReactElement<FormTabProps> | ReactElement<FormTabProps>[];
}

/**
 * @deprecated use {@link ../../FormComponents/Tabs/FormTabs} instead
 */
const FormTabs = (props: FormTabsProps) => {
  const { children } = { ...props };
  return (
    <SuiTabs navigation fullHeight>
      {children}
    </SuiTabs>
  );
};

export default FormTabs;
