import { Dispatch } from "react";
import { Node, XYPosition } from "reactflow";
import IAddNodeService from "./interfaces/IAddNodeService";
import {
  BranchingNodeData,
  NodeType,
  StateLinkNodeData,
  StepNodeData,
} from "../types";
import { AppReducerAction } from "../../../state/reducers/appReducer";
import ISpecificationService from "../../../services/interfaces/ISpecificationService";
import AddNodeData from "./interfaces/AddNodeData";
import { isAddBranchingNodeData } from "../utils/utils";
import {
  createBranchingNodeWithPosition,
  createNewNode,
  createNewNodeId,
  createStateLinkNode,
  createStepNode,
} from "../../../utils/nodeGenerationUtils";
import { Step } from "types";
import AddNodeModalService from "./interfaces/AddNodeModalService";

export default class AddNodeService implements IAddNodeService {
  private readonly appDispatch: Dispatch<AppReducerAction>;
  private readonly addBranchingNodeDataModalService: AddNodeModalService<BranchingNodeData>;
  private readonly addStepNodeModalService: AddNodeModalService<StepNodeData>;
  private readonly addStateLinkNodeModalService: AddNodeModalService<StateLinkNodeData>;
  private readonly nodes: Node[];
  private readonly specificationService: ISpecificationService | undefined;

  constructor(
    appDispatch: Dispatch<AppReducerAction>,
    addBranchingNodeDataModalService: AddNodeModalService<BranchingNodeData>,
    addStepNodeModalService: AddNodeModalService<StepNodeData>,
    addStateLinkNodeModalService: AddNodeModalService<StateLinkNodeData>,
    nodes: Node[],
    specificationService: ISpecificationService | undefined
  ) {
    this.appDispatch = appDispatch;
    this.addBranchingNodeDataModalService = addBranchingNodeDataModalService;
    this.addStepNodeModalService = addStepNodeModalService;
    this.addStateLinkNodeModalService = addStateLinkNodeModalService;
    this.nodes = nodes;
    this.specificationService = specificationService;
  }

  public addNode(
    type: NodeType,
    position?: XYPosition,
    addNodeData?: AddNodeData
  ): void {
    if (this.specificationService === undefined) {
      return;
    }

    if (!this.specificationService.isStateSelected()) {
      alert("Please select a state to add element");
      return;
    }

    if (
      type === NodeType.case &&
      addNodeData !== undefined &&
      isAddBranchingNodeData(addNodeData)
    ) {
      this.addCaseNode(addNodeData.branchingNodeId);
      return;
    }

    const newNodeId: string = createNewNodeId(this.nodes).toString();
    switch (type) {
      case NodeType.step:
        this.addStepNode(newNodeId, position);
        return;

      case NodeType.branching:
        this.addBranchingNode(newNodeId, position);
        return;

      case NodeType.stateLink:
        this.addStateLinkNode(newNodeId, position);
        return;

      default:
        this.addDefaultNode(newNodeId, type, position);
        return;
    }
  }

  private addDefaultNode(
    id: string,
    type: NodeType,
    position: XYPosition | undefined
  ): void {
    const newNode: Node = createNewNode(id, type, position);
    newNode.data = {
      label: "node",
    };
    this.appDispatch({ type: "add-node", node: newNode });
  }

  private addBranchingNode(id: string, position: XYPosition | undefined): void {
    const branchingNode: Node<BranchingNodeData> =
      createBranchingNodeWithPosition(id, position);
    this.addBranchingNodeDataModalService.openAddModal(
      branchingNode as Node<BranchingNodeData>
    );
  }

  private addCaseNode(branchingNodeId: string): void {
    this.appDispatch({
      type: "add-branching-node-case",
      branchingNodeId: branchingNodeId,
    });
  }

  private addStepNode(id: string, position: XYPosition | undefined): void {
    if (this.specificationService === undefined) {
      console.warn(
        "Tried to add a step node when no specification service has been initialized!"
      );
      return;
    }

    const step: Step = this.specificationService.createStep();
    const stepNode: Node<StepNodeData> = createStepNode(id, step, position);
    this.addStepNodeModalService.openAddModal(stepNode);
  }

  private addStateLinkNode(id: string, position: XYPosition | undefined): void {
    const stateLinkNode: Node<StateLinkNodeData> = createStateLinkNode(
      id,
      position
    );
    this.addStateLinkNodeModalService.openAddModal(stateLinkNode);
  }
}
