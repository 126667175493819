import {
  MouseEvent,
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ArrayPath,
  FieldValues,
  Path,
  UseFormGetValues,
  useFormContext,
} from "react-hook-form";
import { SuiAccordionItem } from "@components/SuiAccordion";
import { SIcon } from "@components/SuiIcon";
import { SuiButton } from "@components/SuiButton";
import { ArrayInputItemProps, ArrayItemRendererProps } from "../ArrayInput";
import { isUndefinedOrEmptyOrWhitespace } from "@utils/stringUtils";
import deleteIcon from "@umetrics/sartorius-ui-icon/dist/icons/deleteAction";
import errorIcon from "@umetrics/sartorius-ui-icon/dist/icons/error";
import { SuiScrollbar } from "@components/SuiScrollbar";
import { Subscription } from "react-hook-form/dist/utils/createSubject";

/**
 * Defines the properties for the {@link AccordionInputItem}
 */
export interface AccordionInputItemProps<
  TInputs extends FieldValues,
  TArrayPath extends ArrayPath<TInputs>,
> extends ArrayInputItemProps<TInputs, TArrayPath>,
    ArrayItemRendererProps<TInputs, TArrayPath> {
  getItemLabel: (getValues: UseFormGetValues<TInputs>) => string;
  open: boolean;
  onDeleteItem: (event: MouseEvent) => void;
}

/**
 * The input component the individual items in the accordion input.
 * @param props the properties to inject into the component
 * @returns {ReactElement<AccordionInputItemProps<TInputs>>}
 */
export default function AccordionInputItem<
  TInputs extends FieldValues,
  TArrayPath extends ArrayPath<TInputs>,
>({
  itemKey,
  getItemLabel,
  name,
  open,
  index,
  onDeleteItem,
  itemRenderer,
}: AccordionInputItemProps<TInputs, TArrayPath>): ReactElement<
  AccordionInputItemProps<TInputs, TArrayPath>
> {
  const firstRender: MutableRefObject<boolean> = useRef<boolean>(true);
  const { getFieldState, getValues, trigger, watch } =
    useFormContext<TInputs>();
  const valid: boolean = !getFieldState(name as Path<TInputs>).invalid;

  const [label, setLabel] = useState<string>(() => getItemLabel(getValues));
  const accordionItemClassName: string | undefined = valid
    ? "accordion-item-valid"
    : "input-error";

  useEffect(() => {
    const valueSubscription: Subscription = watch(() => {
      setLabel(getItemLabel(getValues));
    });

    return () => valueSubscription.unsubscribe();
  }, [getItemLabel, getValues, watch]);

  useEffect(() => {
    if (!firstRender.current) {
      return;
    }

    firstRender.current = false;
    trigger(name as Path<TInputs>);
  }, [name, trigger]);

  return (
    <SuiAccordionItem
      className={accordionItemClassName}
      key={itemKey}
      open={open}
    >
      <span slot="label-slot">
        {!valid && isUndefinedOrEmptyOrWhitespace(label) && (
          <SIcon icon={errorIcon} />
        )}
        {label}
      </span>
      <SuiButton
        type="tertiary"
        compact
        slot="action-slot"
        onClick={onDeleteItem}
      >
        <SIcon
          className={accordionItemClassName}
          icon={deleteIcon}
          slot="icon-slot"
        />
      </SuiButton>
      <SuiScrollbar horiHide>
        {itemRenderer({
          itemKey,
          index,
          name,
        })}
      </SuiScrollbar>
    </SuiAccordionItem>
  );
}
