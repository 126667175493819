import SelectParameterIdInput from "../../../GenericInputs/SelectParameterIdInput/SelectParameterIdInput";
import {
  processChangedOptionalValue,
  processOptionalValue,
} from "../../../GenericInputs/inputUtils";
import CalculationFormProps from "../interfaces/CalculationFormProps";

interface ParameterIdInputProps extends CalculationFormProps {
  parameterId: string | undefined;
}

const ParameterIdInput = (props: ParameterIdInputProps) => {
  const { parameterId, formId, setCalculationFormCallback } = { ...props };

  return (
    <SelectParameterIdInput
      id={`${formId}-ParameterId`}
      label="Parameter Id"
      parameterId={processOptionalValue(parameterId)}
      onChangeCallback={(value: string, valid: boolean) =>
        setCalculationFormCallback({
          type: "set-prop",
          payload: {
            key: "@parameterId",
            value: [processChangedOptionalValue(value), valid],
          },
        })
      }
    />
  );
};

export default ParameterIdInput;
