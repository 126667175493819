import { AssetSpecification } from "Asset_Specification";
import { FormatTemplate } from "services/AssetFormatterService/FormatTemplate";
import equipmentPhaseSpecificationTemplate from "services/AssetFormatterService/FormatTemplates/specificationTemplates/equipmentPhase/equipmentPhaseSpecificationTemplate";

export const selectAssetTemplate = (
  specification: AssetSpecification
): FormatTemplate | undefined => {
  if (specification.equipmentPhase) {
    return equipmentPhaseSpecificationTemplate;
  }

  return undefined;
};
