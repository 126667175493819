import { StateLinkNodeData } from "@components/SequentialFlowEditor/types";
import { Form } from "@form-components";
import { ReactElement } from "react";
import EditStateLinkNodeDataFormContent from "./EditStateLinkNodeDataFormContent";

interface EditStateLinkNodeDataFormProps {
  id: string;
  data: StateLinkNodeData;
  onDataSubmitted: (data: StateLinkNodeData) => void;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onValidityChange: (isValid: boolean) => void;
}

const EditStateLinkNodeDataForm = ({
  id,
  data,
  onDataSubmitted,
  formRef,
  onValidityChange,
}: EditStateLinkNodeDataFormProps): ReactElement<EditStateLinkNodeDataFormProps> => {
  return (
    <Form<StateLinkNodeData>
      id={id}
      formRef={formRef}
      onSubmit={onDataSubmitted}
      defaultValues={data}
      mode="onChange"
      onValidityChange={onValidityChange}
    >
      <EditStateLinkNodeDataFormContent />
    </Form>
  );
};

export default EditStateLinkNodeDataForm;
