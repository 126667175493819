import { useContext, useState, MutableRefObject, useRef } from "react";
import { SuiButton } from "../SuiButton";
import { AppDispatchContext, ModalContext } from "../../state/Context";
import ModalContent from "../ModalContent/ModalContent";
import TransitionConditionsForm from "./TransitionConditionsForm";
import { TransitionConditions } from "./types/TransitionConditions";
import { FormSuiButton } from "@form-components";

const formId: string = "transition-conditions-form";

const TransitionConditionsModal = () => {
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const dispatch = useContext(AppDispatchContext);
  const [isValid, setIsValid] = useState<boolean>(false);
  const formRef: MutableRefObject<HTMLFormElement | null> =
    useRef<HTMLFormElement | null>(null);

  return (
    <ModalContent
      renderTopSlotContent={() => <h1>Transition Conditions</h1>}
      renderMainContent={() => (
        <TransitionConditionsForm
          id={formId}
          onTransitionConditionSubmitted={(
            transitionConditions: TransitionConditions
          ): void => {
            dispatch({
              type: "set-transition-conditions",
              transitionConditions: transitionConditions,
            });
            dispatchModal({ type: "close" });
          }}
          formRef={formRef}
          onValidityChange={setIsValid}
        />
      )}
      renderBottomRightSlotContent={() => (
        <FormSuiButton formRef={formRef} label="Save" disabled={!isValid} />
      )}
      renderBottomLeftSlotContent={() => (
        <SuiButton
          type="secondary"
          onClick={(_) => dispatchModal({ type: "close" })}
        >
          Cancel
        </SuiButton>
      )}
    />
  );
};

export default TransitionConditionsModal;
