import { useContext, useMemo } from "react";
import { Edge, Node } from "reactflow";
import { AppDispatchContext } from "../../../state/Context";
import IDeleteNodeService from "../services/interfaces/IDeleteNodeService";
import DeleteNodeService from "../services/DeleteNodeService";
import useSelectedStateNodes from "./useSelectedStateNodes";
import useSelectedStateEdges from "./useSelectedStateEdges";

const useDeleteNode = (): IDeleteNodeService => {
  const selectedStateNodes: Node[] = useSelectedStateNodes();
  const selectedStateEdges: Edge[] = useSelectedStateEdges();

  const appDispatch = useContext(AppDispatchContext);

  const deleteNodeService = useMemo(() => {
    return new DeleteNodeService(
      appDispatch,
      selectedStateEdges,
      selectedStateNodes
    );
  }, [appDispatch, selectedStateEdges, selectedStateNodes]);

  return deleteNodeService;
};

export default useDeleteNode;
