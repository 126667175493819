import { FormatTemplate } from "../FormatTemplate";
import omValueTemplate from "./omValueTemplate";

const returnValuesTemplate: FormatTemplate = {
  returnValue: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: omValueTemplate,
  },
};

export default returnValuesTemplate;
