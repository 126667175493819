import { FormatTemplate } from "../FormatTemplate";
import descriptionValueTemplate from "./descriptionValueTemplate";
import labelTemplate from "./labelTemplate";

const snapShotDefinitionTemplate: FormatTemplate = {
  label: {
    type: "array",
    canBeUndefined: false,
    propertyTemplate: labelTemplate,
  },
  engineeringUnit: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: descriptionValueTemplate,
  },
  displayFormat: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: descriptionValueTemplate,
  },
  "@id": {
    type: "string",
    canBeUndefined: false,
  },
  "@snapShotNumber": {
    type: "string",
    canBeUndefined: false,
  },
  "@value": {
    type: "string",
    canBeUndefined: false,
  },
  "@textValue": {
    type: "string",
    canBeUndefined: false,
  },
};

export default snapShotDefinitionTemplate;
