import { ReactElement } from "react";
import { Path, UseFormGetValues } from "react-hook-form";
import { Case } from "Asset_Specification";
import {
  AccordionInput,
  FormGrid,
  FormTab,
  SetParameterBypassInput,
} from "@form-components";
import { defaultSetParameterBypass } from "@utils/setParameterBypassUtils";

const setAssetParameterName: Path<Case> = "setAssetParameter";

const SetAssetParameterTab = (): ReactElement => {
  return (
    <FormTab heading="Set Asset Parameters" names={setAssetParameterName}>
      <FormGrid>
        <AccordionInput<Case, "setAssetParameter">
          heading="Set Asset Parameters"
          name={setAssetParameterName}
          defaultValue={{ ...defaultSetParameterBypass }}
          getItemLabel={(index: number, getValues: UseFormGetValues<Case>) =>
            getValues(`${setAssetParameterName}.${index}.@assetId`)
          }
          itemRenderer={({ itemKey, name }) => (
            <SetParameterBypassInput key={itemKey} name={name} />
          )}
        />
      </FormGrid>
    </FormTab>
  );
};

export default SetAssetParameterTab;
