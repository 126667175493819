import { useState } from "react";
import { TabNotification } from "@umetrics/sartorius-ui-tabs";
import { SuiTab } from "../../SuiTabs";
import TabProps from "./interfaces/TabProps";
import EditDocumentation from "../../EditDocumentation/EditDocumentation";

const heading = "Documentation";

const DocumentationTab = (props: Omit<TabProps, "error">) => {
  const [documentationValid, setDocumentationValid] = useState<boolean>(true);
  const error = !documentationValid;
  const internalHeading: string = !error ? heading : `${heading} \u26a0`;
  return (
    <SuiTab
      heading={internalHeading}
      notificationType={TabNotification.None}
      error={error}
      {...props}
    >
      <EditDocumentation validityCallback={setDocumentationValid} />
    </SuiTab>
  );
};

export default DocumentationTab;
