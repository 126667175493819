import { FormatTemplate } from "../FormatTemplate";
import isa88StateTemplate from "./isa88StateTemplate";

const actionTableTemplate: FormatTemplate = {
  isa88State: {
    type: "array",
    canBeUndefined: false,
    propertyTemplate: isa88StateTemplate,
  },
};

export default actionTableTemplate;
