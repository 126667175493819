import { ReactElement } from "react";
import { SuiButton } from "@components/SuiButton";
import { SuiIcon } from "@components/SuiIcon";
import deleteIcon from "@umetrics/sartorius-ui-icon/dist/icons/deleteAction";

/**
 * Defines the properties for the {@link DeleteConditionButton} component.
 */
interface DeleteConditionButtonProps {
  onDelete: () => void;
  disabled?: boolean;
}

/**
 * The button component used for deleting conditions.
 * @param props The properties to inject into the component
 * @returns {ReactElement<DeleteConditionButtonProps>}
 */
const DeleteConditionButton = ({
  onDelete,
  disabled,
}: DeleteConditionButtonProps): ReactElement<DeleteConditionButtonProps> => {
  return (
    <SuiButton type="secondary" onClick={onDelete} disabled={disabled}>
      <SuiIcon icon={deleteIcon} />
    </SuiButton>
  );
};

export default DeleteConditionButton;
