import { FormatTemplate } from "../FormatTemplate";
import conditionTemplate from "./conditionTemplate";

const actionSetAssetParameterTemplate: FormatTemplate = {
  condition: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: conditionTemplate,
  },
  "@assetId": {
    type: "string",
    canBeUndefined: false,
  },
  "@parameter": {
    type: "string",
    canBeUndefined: false,
  },
  "@value": {
    type: "string",
    canBeUndefined: false,
  },
};

export default actionSetAssetParameterTemplate;
