import { CSSProperties } from "react";
import { EdgeProps } from "reactflow";
import { SmartStepEdge } from "@tisoap/react-flow-smart-edge";

const DefaultEdge = (props: EdgeProps) => {
  const { selected } = { ...props };
  const style: CSSProperties = {
    ...props.style,
    strokeWidth: selected ? 2 : 1,
    strokeDasharray: selected ? "5" : undefined,
    animation: selected ? "dashdraw 0.5s linear infinite" : undefined,
  };
  const propsForEdgeComponent: EdgeProps = {
    ...props,
    style: style,
  };
  return <SmartStepEdge {...propsForEdgeComponent} />;
};

export default DefaultEdge;
