const defaultErrorCode = "501";
const isError = (error: any): error is Error => "message" in error && "stack" in error;

export enum ErrorSlots {
  headerBig = "header-big", //Big text in header. Used for displaying error code or "Coming soon" text.
  header = "header", //Regular text in header.
  description = "description", //Error description.
  errorCode = "error-code", //Error code in the extra section.
  information = "information", //Extra error information.
  button = "button", //Button for retry or return action.
}

export const asErrorWithCode = (
  error: Error | { error: Error; errorCode?: string }
): { error: Error; errorCode?: string } => {
  return isError(error)
    ? { error: error, errorCode: defaultErrorCode }
    : error;
};
