import { useRef, useState } from "react";
import { SuiButton } from "../../../../../SuiButton";
import ModalContent from "../../../../../ModalContent/ModalContent";
import { EditOperatorMessageNodeDataModalType } from "../enums/OperatorMessageModalType";
import EditOperatorMessageNodeDateForm from "./form/EditOperatorMessageNodeDateForm";
import { FormSuiButton } from "@form-components";
import { MessageNodeDataForm } from "./form/interfaces";

interface OperatorMessageProps {
  type: EditOperatorMessageNodeDataModalType;
  messageNodeDataForm: MessageNodeDataForm;
  onSaveCallback: (newMessageNodeDataForm: MessageNodeDataForm) => void;
  onCancelCallback: () => void;
}

const formId: string = "message-node-data-form";

const EditOperatorMessageNodeDataModal = ({
  type,
  messageNodeDataForm,
  onSaveCallback,
  onCancelCallback,
}: OperatorMessageProps) => {
  const formRef: React.MutableRefObject<HTMLFormElement | null> =
    useRef<HTMLFormElement>(null);
  const [valid, setValid] = useState<boolean>();

  return (
    <ModalContent
      renderTopSlotContent={() => <h1>{type}</h1>}
      renderMainContent={() => (
        <EditOperatorMessageNodeDateForm
          id={formId}
          formRef={formRef}
          messageNodeDataForm={messageNodeDataForm}
          onSubmitted={(newMessageNodeDataForm: MessageNodeDataForm) =>
            onSaveCallback({
              ...messageNodeDataForm,
              ...newMessageNodeDataForm,
            })
          }
          onValidityChange={setValid}
        />
      )}
      renderBottomRightSlotContent={() => (
        <FormSuiButton
          formRef={formRef}
          label="Save"
          type="primary"
          disabled={!valid}
        />
      )}
      renderBottomLeftSlotContent={() => (
        <SuiButton type="secondary" onClick={onCancelCallback}>
          Cancel
        </SuiButton>
      )}
    />
  );
};

export default EditOperatorMessageNodeDataModal;
