import {
  BranchingNodeData,
  CaseNodeData,
  MessageNodeData,
  NodeData,
  NodeType,
  StateLinkNodeData,
  StepNodeData,
} from "../components/SequentialFlowEditor/types";
import { Edge, Node, XYPosition } from "reactflow";
import { Step } from "../types";
import {
  ActionOperatorMessage,
  Branching,
  OperatorMessageDefinition,
} from "../Asset_Specification";
import {
  isBranchNode,
  isCaseNode,
  isStateLinkNode,
  isStepNode,
} from "../components/SequentialFlowEditor/components/nodes/nodeUtils";
import IFlowchartGenerator from "./interfaces/IFlowchartGenerator";
import { connectWithEdge } from "../utils/edgeUtils";
import {
  BranchingNodes,
  caseXInitialOffset,
  caseXOffset,
  caseYInitialOffset,
  createBranchingAndCaseNodes,
  createMessageNode,
  createNewNodeId,
  createStartNode,
  createStateLinkNode,
  createStepNode,
  getAbsoluteCaseNodePosition,
  unconnectedNodeXPosition,
  ySpaceBetweenStartAndStep,
  ySpaceBetweenStepAndBranch,
  ySpaceBetweenSteps,
} from "../utils/nodeGenerationUtils";
import ISpecificationService from "./interfaces/ISpecificationService";

interface OperatorMessageNodeAndEdge {
  operatorMessageNode: Node<MessageNodeData>;
  operatorMessageEdge: Edge;
}

export default class FlowchartGenerator implements IFlowchartGenerator {
  private readonly specificationService: ISpecificationService;
  private readonly defaultNodesAndEdges: { nodes: Node[]; edges: Edge[] } = {
    nodes: [],
    edges: [],
  };

  constructor(specificationService: ISpecificationService) {
    this.specificationService = specificationService;
  }

  public generateFlowchart(): { nodes: Node[]; edges: Edge[] } {
    const stateName: string | undefined =
      this.specificationService.getSelectedStateName();
    if (!stateName) {
      console.warn("No state has been selected so cannot generate flowchart!");
      return { ...this.defaultNodesAndEdges };
    }

    const steps: Step[] = this.specificationService.getSelectedStateSteps();
    const startNode: Node<NodeData> = createStartNode(
      createNewNodeId([]).toString(),
      stateName
    );
    return this.generateNodesAndEdgesFromSteps(steps ?? [], startNode);
  }

  private generateNodesAndEdgesFromSteps(
    steps: Step[],
    startNode: Node<NodeData>
  ): { nodes: Node[]; edges: Edge[] } {
    const stepNodes: Node<StepNodeData>[] =
      this.generateAllStepNodesVerticallySpaced(steps, startNode);
    const generatedNodesAndEdges: { nodes: Node[]; edges: Edge[] } =
      this.traverseStepTreeAndGenerateRemainingNodesAndEdges(
        stepNodes,
        startNode
      );
    return this.adjustUnconnectedNodes(generatedNodesAndEdges);
  }

  private generateAllStepNodesVerticallySpaced(
    steps: Step[],
    startNode: Node<NodeData>
  ): Node<StepNodeData>[] {
    const stepNodes: Node<StepNodeData>[] = [];
    let previousNode: Node<NodeData> = { ...startNode };
    const sortedSteps: Step[] = this.sortStepsByValue(steps);
    for (const step of sortedSteps) {
      const stepNode: Node<StepNodeData> = this.generateStepNode(
        step,
        previousNode.position,
        () => createNewNodeId([startNode, ...stepNodes]).toString(),
        previousNode.type
      );
      stepNodes.push(stepNode);
      previousNode = { ...stepNode };
    }

    return stepNodes;
  }

  private sortStepsByValue(steps: Step[]): Step[] {
    return [...steps].sort(
      (stepA: Step, stepB: Step) =>
        parseInt(stepA["@value"].toString()) -
        parseInt(stepB["@value"].toString())
    );
  }

  private generateStepNode(
    step: Step,
    previousNodePosition: XYPosition,
    generateNewId: () => string,
    previousNodeType?: string
  ): Node<StepNodeData> {
    const position: XYPosition = this.getStepNodePositionFromPrevious(
      previousNodePosition,
      previousNodeType
    );
    return createStepNode(generateNewId(), step, position);
  }

  private getStepNodePositionFromPrevious(
    previousNodePosition: XYPosition,
    previousNodeType?: string
  ): XYPosition {
    const ySpace: number = this.getYSpaceForStepNode(previousNodeType);
    return {
      x: previousNodePosition.x,
      y: previousNodePosition.y + ySpace,
    };
  }

  private getYSpaceForStepNode(previousNodeType?: string): number {
    if (previousNodeType === NodeType.start) {
      return ySpaceBetweenStartAndStep;
    }

    if (previousNodeType === NodeType.step) {
      return (
        ySpaceBetweenSteps + ySpaceBetweenStepAndBranch + caseYInitialOffset
      );
    }

    return ySpaceBetweenSteps;
  }

  private traverseStepTreeAndGenerateRemainingNodesAndEdges(
    stepNodes: Node<StepNodeData>[],
    startNode: Node<NodeData>
  ): { nodes: Node[]; edges: Edge[] } {
    const nodesAndEdges: { nodes: Node[]; edges: Edge[] } = {
      nodes: [startNode, ...stepNodes],
      edges: [],
    };
    const previousNodesStack: Node<NodeData>[] = [startNode];
    const visitedNodes: (Node<StepNodeData> | Node<StateLinkNodeData>)[] = [];
    while (
      visitedNodes.length < stepNodes.length ||
      previousNodesStack.length > 0
    ) {
      const previousNode: Node<NodeData> | undefined = previousNodesStack.pop();
      const { currentNode, isNewNode } = this.getCurrentNodeToProcess(
        stepNodes,
        visitedNodes,
        nodesAndEdges.nodes,
        previousNode
      );

      if (!currentNode) {
        continue;
      }

      if (previousNode) {
        const previousNodeEdge: Edge = this.createEdge(
          previousNode,
          currentNode
        );
        nodesAndEdges.edges.push(previousNodeEdge);
      }

      if (visitedNodes.includes(currentNode)) {
        continue;
      }

      if (isNewNode) {
        nodesAndEdges.nodes.push(currentNode);
      }

      currentNode.position = this.adjustCurrentNodePosition(
        currentNode,
        previousNode,
        [...nodesAndEdges.nodes]
      );

      visitedNodes.push(currentNode);

      if (!isStepNode(currentNode)) {
        continue;
      }

      const operatorMessageNodeAndEdge: OperatorMessageNodeAndEdge | undefined =
        this.createOperatorMessageNodeAndEdge(
          createNewNodeId([...nodesAndEdges.nodes]).toString(),
          currentNode
        );
      if (operatorMessageNodeAndEdge) {
        nodesAndEdges.nodes.push(
          operatorMessageNodeAndEdge.operatorMessageNode
        );
        nodesAndEdges.edges.push(
          operatorMessageNodeAndEdge.operatorMessageEdge
        );
      }

      const branchingNodes: BranchingNodes | undefined =
        this.createBranchingNodeWithCases(currentNode, [
          ...nodesAndEdges.nodes,
        ]);

      if (!branchingNodes) {
        continue;
      }

      const branchingNode: Node<BranchingNodeData> =
        this.adjustBranchingNodePosition(
          [...nodesAndEdges.nodes],
          branchingNodes.branchingNode
        );
      const caseNodes: Node<CaseNodeData>[] = branchingNodes.caseNodes;
      nodesAndEdges.nodes.push(branchingNode, ...caseNodes);
      const stepToBranchNodeEdge: Edge = this.createEdge(
        currentNode,
        branchingNode
      );
      nodesAndEdges.edges.push(stepToBranchNodeEdge);

      // Process the left most branch first
      const caseNodesProcessingOrder: Node<CaseNodeData>[] = [
        ...caseNodes,
      ].reverse();
      previousNodesStack.push(...caseNodesProcessingOrder);
    }

    return nodesAndEdges;
  }

  private getCurrentNodeToProcess(
    allStepNodes: Node<StepNodeData>[],
    visitedNodes: (Node<StepNodeData> | Node<StateLinkNodeData>)[],
    allNodes: Node[],
    previousNode?: Node<NodeData>
  ): {
    currentNode: Node<StepNodeData> | Node<StateLinkNodeData> | undefined;
    isNewNode: boolean;
  } {
    const unvisitedStepNodes: Node<StepNodeData>[] = allStepNodes.filter(
      (stepNode: Node<StepNodeData>) => !visitedNodes.includes(stepNode)
    );
    const defaultStepNodeToProcess: Node<StepNodeData> | undefined =
      unvisitedStepNodes[0];
    if (
      !previousNode ||
      !isCaseNode(previousNode) ||
      !previousNode.data.case["@subStateId"]
    ) {
      return { currentNode: defaultStepNodeToProcess, isNewNode: false };
    }

    const substateId: string = previousNode.data.case["@subStateId"];
    const stepFollowingCaseNode: Node<StepNodeData> | undefined =
      allStepNodes.find(
        (stepNode: Node<StepNodeData>) =>
          stepNode.data.step["@id"] === substateId
      );
    if (stepFollowingCaseNode) {
      return { currentNode: stepFollowingCaseNode, isNewNode: false };
    }

    const existingStateLinkNode: Node<StateLinkNodeData> | undefined =
      visitedNodes
        .filter(isStateLinkNode)
        .find(
          (node: Node<StateLinkNodeData>) => node.data.substateId === substateId
        );
    if (existingStateLinkNode) {
      return { currentNode: existingStateLinkNode, isNewNode: false };
    }

    const stateOfSubstate: string | undefined =
      this.specificationService.getStateOfSubstate(substateId);
    if (!stateOfSubstate) {
      return { currentNode: defaultStepNodeToProcess, isNewNode: false };
    }

    const newNodeId: string = createNewNodeId([...allNodes]).toString();
    const newStateLinkNode: Node<StateLinkNodeData> = createStateLinkNode(
      newNodeId,
      undefined,
      substateId,
      stateOfSubstate
    );
    return { currentNode: newStateLinkNode, isNewNode: true };
  }

  private createEdge(sourceNode: Node, targetNode: Node): Edge {
    const edge: Edge | undefined = connectWithEdge(sourceNode, targetNode);
    if (!edge) {
      throw new Error(
        `Could not connect source node of type ${sourceNode.type} to target node of type ${targetNode.type}!`
      );
    }

    return edge;
  }

  private adjustCurrentNodePosition(
    currentNode: Node<StepNodeData> | Node<StateLinkNodeData>,
    previousNode: Node<NodeData> | undefined,
    nodes: Node[]
  ): XYPosition {
    if (!previousNode) {
      return { ...currentNode.position };
    }

    const previousNodePosition: XYPosition = this.getPreviousPosition(
      previousNode,
      nodes
    );
    if (
      isStepNode(currentNode) &&
      previousNodePosition.x === currentNode.position.x
    ) {
      return { ...currentNode.position };
    }

    return this.getStepNodePositionFromPrevious(
      previousNodePosition,
      previousNode.type
    );
  }

  private getPreviousPosition(
    previousNode: Node<NodeData>,
    nodes: Node[]
  ): XYPosition {
    if (!isCaseNode(previousNode)) {
      return { ...previousNode.position };
    }

    // Case node positions are relative to the parent so require adding to the branch node position
    const previousCaseNodePosition: XYPosition = previousNode.position;
    const previousBranchNodeId: string = previousNode.data.branchingNodeId;
    const previousBranchNode: Node | undefined = nodes.find(
      (node: Node) => node.id === previousBranchNodeId
    );
    if (!previousBranchNode) {
      throw new Error(
        `Branching node with id ${previousBranchNodeId} has not been added to the generated nodes array!`
      );
    }

    const previousBranchNodePosition: XYPosition = previousBranchNode.position;
    return getAbsoluteCaseNodePosition(
      previousCaseNodePosition,
      previousBranchNodePosition
    );
  }

  private createOperatorMessageNodeAndEdge(
    id: string,
    stepNode: Node<StepNodeData>
  ): OperatorMessageNodeAndEdge | undefined {
    const actionOperatorMessage: ActionOperatorMessage | undefined =
      stepNode.data.step.actionOperatorMessage;
    const messageDefinition: OperatorMessageDefinition | undefined =
      this.specificationService
        .getOperatorMessageDefinitions()
        .find(
          (message) => message["@id"] === actionOperatorMessage?.["@messageId"]
        );
    if (!actionOperatorMessage || !messageDefinition) {
      return undefined;
    }

    const operatorMessageNode: Node<MessageNodeData> = createMessageNode(
      id,
      stepNode,
      actionOperatorMessage,
      messageDefinition
    );
    const operatorMessageEdge: Edge = this.createEdge(
      stepNode,
      operatorMessageNode
    );
    return { operatorMessageNode, operatorMessageEdge };
  }

  private createBranchingNodeWithCases(
    stepNode: Node<StepNodeData>,
    nodes: Node[]
  ): BranchingNodes | undefined {
    const branching: Branching | undefined = stepNode.data.step.branching;
    return branching
      ? createBranchingAndCaseNodes(stepNode, branching, nodes)
      : undefined;
  }

  private adjustBranchingNodePosition(
    generatedNodes: Node[],
    branchingNode: Node<BranchingNodeData>
  ): Node<BranchingNodeData> {
    const previousBranchNodeOnSameY: Node<BranchingNodeData> | undefined =
      generatedNodes
        .filter(
          (node: Node) =>
            isBranchNode(node) && node.position.y === branchingNode.position.y
        )
        .reverse()
        .find((node: Node) => node);
    if (
      !previousBranchNodeOnSameY ||
      !this.branchingNodeOverlaps(previousBranchNodeOnSameY, branchingNode)
    ) {
      return branchingNode;
    }

    const adjustedBranchingNode: Node<BranchingNodeData> = { ...branchingNode };
    const lastCaseXPositionInBranchingNodeA: number =
      this.getLastCaseXPositionInBranchingNode(previousBranchNodeOnSameY);
    adjustedBranchingNode.position.x =
      lastCaseXPositionInBranchingNodeA + caseXOffset;
    return adjustedBranchingNode;
  }

  private branchingNodeOverlaps(
    branchingNodeA: Node<BranchingNodeData>,
    branchingNodeB: Node<BranchingNodeData>
  ): boolean {
    if (branchingNodeA.position.y !== branchingNodeB.position.y) {
      return false;
    }

    const lastCaseXPositionInBranchingNodeA: number =
      this.getLastCaseXPositionInBranchingNode(branchingNodeA);
    return lastCaseXPositionInBranchingNodeA - branchingNodeB.position.x >= 0;
  }

  private getLastCaseXPositionInBranchingNode(
    branchingNode: Node<BranchingNodeData>
  ): number {
    return (
      branchingNode.position.x +
      caseXInitialOffset +
      caseXOffset * (branchingNode.data.caseNodeIds.length - 1)
    );
  }

  private adjustUnconnectedNodes(generatedNodesAndEdges: {
    nodes: Node[];
    edges: Edge[];
  }): { nodes: Node[]; edges: Edge[] } {
    const unconnectedNodes: Node[] = generatedNodesAndEdges.nodes.filter(
      (node: Node) =>
        !isCaseNode(node) &&
        !generatedNodesAndEdges.edges.some(
          (edge: Edge) => edge.source === node.id || edge.target === node.id
        )
    );
    unconnectedNodes.forEach((node: Node, index: number) => {
      const adjustedPosition: XYPosition = {
        x: unconnectedNodeXPosition,
        y:
          index > 0
            ? unconnectedNodes[index - 1].position.y + ySpaceBetweenSteps
            : 0,
      };
      node.position = adjustedPosition;
    });

    return generatedNodesAndEdges;
  }
}
