import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import { FormGrid, SelectSnapShotId } from "@form-components";

/**
 * Defines the properties for the {@link ActionSnapShotInput} component.
 */
interface ActionSnapShotInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {}

/**
 * The input component for specifying actionSnapShot values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<ActionSnapShotInputProps<TInputs>>}
 */
export default function ActionSnapShotInput<TInputs extends FieldValues>({
  disabled,
  columnSpan,
  name,
}: ActionSnapShotInputProps<TInputs>): ReactElement<
  ActionSnapShotInputProps<TInputs>
> {
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <SelectSnapShotId
        name={`${name}.@snapShotId` as Path<TInputs>}
        label="Snapshot Id"
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
        fixedDropdown
      />
    </FormGrid>
  );
}
