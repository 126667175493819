import { Edge, Node, XYPosition } from "reactflow";
import { Branching, Case, Condition } from "../Asset_Specification";
import {
  BranchingNodeData,
  CaseNodeData,
  NodeType,
  StepNodeData,
} from "../components/SequentialFlowEditor/types";
import { HandleTypes } from "../components/SequentialFlowEditor/utils/types";
import { isEmptyOrWhitespace } from "./stringUtils";
import {
  moveNode,
  recalculateCaseNodePositions,
} from "../components/SequentialFlowEditor/utils/editorFunctions";
import {
  isBranchNode,
  isBranchingNodeData,
  isCaseNode,
  isStepNodeData,
} from "../components/SequentialFlowEditor/components/nodes/nodeUtils";
import { toArray } from "../utils";
import {
  createBranchingNode,
  createCaseNode,
  createCaseNodes,
  ySpaceBetweenStepAndBranch,
} from "../utils/nodeGenerationUtils";
import { connectWithEdge } from "./edgeUtils";
import { toObjectOrArray } from "./objectUtils";

export enum OperatorMessageCase {
  Yes = "YES",
  No = "NO",
  Save = "SAVE",
  Ok = "OK",
}
const allOperatorMessageCases: OperatorMessageCase[] = [
  OperatorMessageCase.Ok,
  OperatorMessageCase.Save,
  OperatorMessageCase.Yes,
  OperatorMessageCase.No,
];

export interface OperatorMessageCaseWithSubstateId {
  operatorMessageCase: OperatorMessageCase;
  substateId: string;
}

export const updateNodesWithBranchingCase = (
  branchingNode: Node<BranchingNodeData>,
  allNodes: Node[],
  caseValue?: Case
): {
  newCaseNode: Node<CaseNodeData>;
  newBranchingNode: Node<BranchingNodeData>;
  nodes: Node[];
} => {
  const branchingNodeIndex = allNodes.findIndex(
    (node) => node.id === branchingNode.id && isBranchingNodeData(node.data)
  );

  if (branchingNodeIndex === -1) {
    throw new Error("Branching not found in the nodes array!");
  }

  const newCaseValue: Case = caseValue ?? {
    "@subStateId": "",
    condition: { "@operator": "true" },
  };

  const caseNode = createCaseNode(branchingNode.id, newCaseValue, allNodes);
  const newBranchingNodeValue: Node<BranchingNodeData> = {
    ...branchingNode,
    data: {
      ...branchingNode.data,
      caseNodeIds: [...branchingNode.data.caseNodeIds, caseNode.id],
    },
  };
  const nodes = [...allNodes];
  nodes[branchingNodeIndex] = newBranchingNodeValue;

  return {
    newCaseNode: caseNode,
    newBranchingNode: newBranchingNodeValue,
    nodes: [...nodes, caseNode],
  };
};

export const replaceMessageBranchingInStep = (
  stepNode: Node<StepNodeData>,
  operatorMessages: OperatorMessageCaseWithSubstateId[],
  allNodes: Node[],
  allEdges: Edge[]
): {
  newStepNodeValue: Node<StepNodeData>;
  nodes: Node[];
  edges: Edge[];
  caseNodesUpdated: {
    id: string;
    data: CaseNodeData;
  }[];
} => {
  const operatorMessageCases = updateOperatorMessageCases(
    stepNode,
    operatorMessages
  );
  const { nodes, edges, branchingNode } = updateBranchingNodesAndEdges(
    stepNode,
    [...allNodes],
    [...allEdges],
    operatorMessages.map(
      (operatorMessage: OperatorMessageCaseWithSubstateId) =>
        operatorMessage.operatorMessageCase
    ),
    operatorMessageCases
  );
  const {
    updatedBranchingNode,
    caseNodesUpdated,
    updatedNodes: nodesWithUpdatedBranching,
  } = updateBranchingOperatorMessageCases(
    branchingNode,
    operatorMessageCases,
    nodes
  );
  const { newStepNodeValue, updatedNodes } = updateStepNodeWithBranching(
    stepNode,
    {
      "@transitionORStepId": updatedBranchingNode.data.transitionORStepId,
      case: [
        ...toArray(stepNode.data.step.branching?.case).filter(
          (caseValue) =>
            !isOperatorMessageCase(caseValue, [...allOperatorMessageCases])
        ),
        ...operatorMessageCases,
      ],
    },
    nodesWithUpdatedBranching
  );

  return { newStepNodeValue, nodes: updatedNodes, edges, caseNodesUpdated };
};

const updateBranchingOperatorMessageCases = (
  branchingNode: Node<BranchingNodeData>,
  operatorMessageCases: Case[],
  allNodes: Node[]
): {
  updatedBranchingNode: Node<BranchingNodeData>;
  caseNodesUpdated: { id: string; data: CaseNodeData }[];
  updatedNodes: Node[];
} => {
  const updatedNodes = [...allNodes];
  const branchingNodeIndex = updatedNodes.findIndex(
    (node) => node.id === branchingNode.id
  );
  if (branchingNodeIndex < 0) {
    throw new Error(
      "addCasesToBranchingNode: branching node with provided Id is not found!"
    );
  }
  const updatedBranchingNode: Node<BranchingNodeData> = {
    ...branchingNode,
    data: {
      ...branchingNode.data,
      caseNodeIds: [...branchingNode.data.caseNodeIds],
    },
  };

  const existingCaseNodes: { caseNode: Node<CaseNodeData>; index: number }[] =
    [];
  updatedNodes.forEach((node: Node, index: number) => {
    if (
      isCaseNode(node) &&
      updatedBranchingNode.data.caseNodeIds.includes(node.id)
    ) {
      existingCaseNodes.push({ caseNode: node, index });
    }
  });
  const caseNodesUpdated: { id: string; data: CaseNodeData }[] = [];
  const newOperatorMessageCases: Case[] = [];
  operatorMessageCases.forEach((operatorMessageCase: Case) => {
    const existingCaseNode:
      | { caseNode: Node<CaseNodeData>; index: number }
      | undefined = existingCaseNodes.find(
      (existingCaseNode: { caseNode: Node<CaseNodeData>; index: number }) => {
        const operatorMessageCaseCondition: Condition =
          operatorMessageCase.condition;
        const existingCaseNodeCondition: Condition =
          existingCaseNode.caseNode.data.case.condition;
        return (
          existingCaseNodeCondition["@op1"] &&
          operatorMessageCaseCondition["@op1"] &&
          existingCaseNodeCondition["@operator"] === "OM" &&
          existingCaseNodeCondition["@op1"].toUpperCase() ===
            operatorMessageCaseCondition["@op1"].toUpperCase()
        );
      }
    );
    if (!existingCaseNode) {
      newOperatorMessageCases.push(operatorMessageCase);
      return;
    }

    const newCaseNode: Node<CaseNodeData> = {
      ...existingCaseNode.caseNode,
      data: {
        ...existingCaseNode.caseNode.data,
        case: operatorMessageCase,
      },
    };
    updatedNodes[existingCaseNode.index] = newCaseNode;
    caseNodesUpdated.push({ id: newCaseNode.id, data: newCaseNode.data });
  });

  const newCaseNodes: Node<CaseNodeData>[] = createCaseNodes(
    updatedBranchingNode.id,
    newOperatorMessageCases,
    allNodes
  );
  caseNodesUpdated.push(
    ...newCaseNodes.map((node: Node<CaseNodeData>) => ({
      id: node.id,
      data: node.data,
    }))
  );

  updatedBranchingNode.data.caseNodeIds.push(
    ...newCaseNodes.map((caseNode) => caseNode.id)
  );
  updatedNodes[branchingNodeIndex] = updatedBranchingNode;
  updatedNodes.push(...newCaseNodes);

  return {
    updatedBranchingNode,
    caseNodesUpdated,
    updatedNodes,
  };
};

const updateBranchingNodesAndEdges = (
  stepNode: Node<StepNodeData>,
  nodes: Node[],
  edges: Edge[],
  operatorMessagesToSet: OperatorMessageCase[],
  operatorMessageCases: Case[]
): { nodes: Node[]; edges: Edge[]; branchingNode: Node<BranchingNodeData> } => {
  const existingBranchingNode: Node<BranchingNodeData> | undefined = nodes.find(
    (node: Node) => isBranchNode(node) && node.data.stepNodeId === stepNode.id
  );

  if (existingBranchingNode) {
    const {
      nodesWithoutCases: updatedNodes,
      edges: updatedEdges,
      updatedBranchingNode,
    } = removeOperatorMessageCaseNodes(
      existingBranchingNode,
      nodes,
      edges,
      allOperatorMessageCases.filter(
        (omCase: OperatorMessageCase) => !operatorMessagesToSet.includes(omCase)
      )
    );

    return {
      nodes: updatedNodes,
      edges: updatedEdges,
      branchingNode: updatedBranchingNode,
    };
  }

  const newNodes: Node[] = makeSpaceForNodes(nodes, stepNode.position);
  const transitionOrStepId: string | undefined = operatorMessageCases.find(
    (caseValue: Case) => caseValue["@subStateId"]
  )?.["@subStateId"];
  const newBranchingNode: Node<BranchingNodeData> = createBranchingNode(
    stepNode,
    transitionOrStepId,
    newNodes
  );
  newNodes.push(newBranchingNode);
  const stepToBranchNodeEdge: Edge | undefined = connectWithEdge(
    stepNode,
    newBranchingNode
  );
  const newEdges: Edge[] = [...edges];
  if (stepToBranchNodeEdge) {
    newEdges.push(stepToBranchNodeEdge);
  }

  return { nodes: newNodes, edges: newEdges, branchingNode: newBranchingNode };
};

export const getBranchingCases = (
  caseNodeIds: string[],
  allNodes: Node[]
): Case[] =>
  allNodes
    .filter(
      (node: Node) => caseNodeIds.find((id) => node.id === id) && node.data.case
    )
    .map((node: Node<CaseNodeData>) => node.data.case);

export const hasOperatorMessageBranching = (node: Node): boolean => {
  if (!isStepNodeData(node.data)) {
    return false;
  }

  return toArray(node.data.step.branching?.case).some(
    (caseValue) =>
      caseValue.condition?.["@operator"] === "OM" &&
      (caseValue.condition["@op1"]?.toLowerCase() === "yes" ||
        caseValue.condition["@op1"]?.toLowerCase() === "no")
  );
};

export const removeMessageBranchingFromStep = (
  stepNode: Node<StepNodeData>,
  allNodes: Node[],
  allEdges: Edge[]
): { newStepNodeValue: Node<StepNodeData>; nodes: Node[]; edges: Edge[] } => {
  const newCases = toArray(stepNode.data.step.branching?.case).filter(
    (caseValue) => !isOperatorMessageCase(caseValue)
  );

  let { newStepNodeValue, updatedNodes: nodes } = updateStepNodeWithBranching(
    stepNode,
    {
      ...stepNode.data.step.branching,
      case: toObjectOrArray(newCases),
    },
    allNodes
  );

  const branchingNode: Node<BranchingNodeData> | undefined = findConnectedNode(
    stepNode,
    HandleTypes.stepSource,
    HandleTypes.branchingTarget,
    allNodes,
    allEdges
  );

  if (!branchingNode) {
    return {
      newStepNodeValue,
      nodes,
      edges: [...allEdges],
    };
  }
  const { nodesWithoutCases, edges } = removeOperatorMessageCaseNodes(
    branchingNode,
    nodes,
    allEdges
  );

  return {
    newStepNodeValue,
    nodes: nodesWithoutCases,
    edges,
  };
};

const updateOperatorMessageCases = (
  stepNode: Node<StepNodeData>,
  operatorMessages: OperatorMessageCaseWithSubstateId[]
): Case[] => {
  const stepCases: Case[] = toArray(stepNode.data.step.branching?.case);
  const operatorCases: Case[] = operatorMessages.map(
    (operatorMessage: OperatorMessageCaseWithSubstateId) => {
      const existingCase: Case | undefined = stepCases.find(
        (caseValue: Case) =>
          caseValue.condition?.["@operator"] === "OM" &&
          caseValue?.condition?.["@op1"]?.toUpperCase() ===
            operatorMessage.operatorMessageCase
      );
      const updatedCase: Case = existingCase
        ? { ...existingCase, "@subStateId": operatorMessage.substateId }
        : {
            condition: {
              "@operator": "OM",
              "@op1": operatorMessage.operatorMessageCase,
            },
            "@subStateId": operatorMessage.substateId,
          };
      return updatedCase;
    }
  );

  return operatorCases;
};

/**
 * Checks whether case is operator message case.
 * @param caseValue Case that is checked against being operator message case.
 * @param withMessage If offMessage provided, case is checked whether it is operator message case with message equal to any in the provided array.
 * @returns
 */
const isOperatorMessageCase = (
  caseValue: Case | undefined,
  withMessage?: OperatorMessageCase[]
) =>
  caseValue?.condition?.["@operator"] === "OM" &&
  (!withMessage ||
    withMessage.some(
      (message) => message === caseValue?.condition?.["@op1"]?.toUpperCase()
    ));

const findConnectedNode = (
  node: Node,
  sourceHandle: HandleTypes,
  targetHandle: HandleTypes,
  allNodes: Node[],
  allEdges: Edge[]
): Node | undefined => {
  const connectedNodeId = allEdges.find(
    (edge) =>
      edge.source === node.id &&
      edge.sourceHandle === sourceHandle &&
      edge.targetHandle === targetHandle
  )?.target;

  if (!connectedNodeId || isEmptyOrWhitespace(connectedNodeId)) {
    return undefined;
  }

  const connectedNode = allNodes.find((node) => node.id === connectedNodeId);
  return !connectedNode ? undefined : { ...connectedNode };
};

const makeSpaceForNodes = (nodes: Node[], stepNodePosition: XYPosition) => {
  const { lowerNodes } = splitNodesByYPosition(nodes, stepNodePosition.y);

  return nodes.map((node) => {
    if (lowerNodes.includes(node.id)) {
      return moveNode(node, { y: ySpaceBetweenStepAndBranch });
    }
    return node;
  });
};

const removeOperatorMessageCaseNodes = (
  branchingNode: Node<BranchingNodeData>,
  allNodes: Node[],
  allEdges: Edge[],
  operatorMessagesToRemove?: OperatorMessageCase[]
): {
  nodesWithoutCases: Node[];
  edges: Edge[];
  updatedBranchingNode: Node<BranchingNodeData>;
} => {
  const nodes: Node[] = [...allNodes];
  const caseNodeIds: string[] = branchingNode.data.caseNodeIds;
  const operatorMessageCaseNodeIds: string[] = nodes
    .filter(
      (node: Node) =>
        caseNodeIds.includes(node.id) &&
        isCaseNode(node) &&
        isOperatorMessageCase(node.data.case, operatorMessagesToRemove)
    )
    .map((node) => node.id);

  const caseNodeIdsWithoutOMCases: string[] = caseNodeIds.filter(
    (caseNodeId) => !operatorMessageCaseNodeIds.includes(caseNodeId)
  );
  const nodesWithoutCases: Node[] = recalculateCaseNodePositions(
    nodes.filter((node) => !operatorMessageCaseNodeIds.includes(node.id)),
    caseNodeIdsWithoutOMCases
  );

  const transitionOrStepId: string | undefined = nodesWithoutCases
    .filter(isCaseNode)
    .find((node: Node<CaseNodeData>) =>
      caseNodeIdsWithoutOMCases.includes(node.id)
    )?.data.case["@subStateId"];
  const updatedBranchingNode: Node<BranchingNodeData> = {
    ...branchingNode,
    data: {
      ...branchingNode.data,
      caseNodeIds: caseNodeIdsWithoutOMCases,
      transitionORStepId: transitionOrStepId,
    },
  };
  const branchingNodeIndex: number = nodesWithoutCases.findIndex(
    (node) => node.id === branchingNode.id
  );
  nodesWithoutCases[branchingNodeIndex] = updatedBranchingNode;

  const edges: Edge[] = [
    ...allEdges.filter(
      (edge) => !operatorMessageCaseNodeIds.includes(edge.source)
    ),
  ];
  return {
    nodesWithoutCases,
    edges,
    updatedBranchingNode,
  };
};

const splitNodesByYPosition = (allNodes: Node[], yPosition: number) => {
  const higherOrEqualNodes = [];
  const lowerNodes = [];

  for (let node of allNodes) {
    // omit nodes within another nodes
    if (node.parentNode) {
      continue;
    }

    if (node.position.y <= yPosition) {
      higherOrEqualNodes.push(node.id);
    } else {
      lowerNodes.push(node.id);
    }
  }

  return {
    higherOrEqualNodes,
    lowerNodes,
  };
};

const updateStepNodeWithBranching = (
  stepNode: Node<StepNodeData>,
  branching: Branching | undefined,
  allNodes: Node[]
) => {
  const stepNodeIndex = allNodes.findIndex(
    (node) => node.type === NodeType.step && node.id === stepNode.id
  );
  if (stepNodeIndex === -1) {
    throw new Error("There is no step node with provided id in allNodes!");
  }

  let nodes = [...allNodes.map((node) => ({ ...node }))];
  const newStepNodeValue = {
    ...stepNode,
    data: {
      ...stepNode.data,
      step: {
        ...stepNode.data.step,
        branching: branching,
      },
    },
  };
  nodes[stepNodeIndex] = newStepNodeValue;

  return { newStepNodeValue, updatedNodes: nodes };
};
