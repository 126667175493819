import { OperatorMessageDefinition } from "Asset_Specification";
import { useCallback, useContext, useEffect, useState } from "react";
import ISpecificationService from "services/interfaces/ISpecificationService";
import { AppStateContext } from "state/Context";

interface UseOperatorMessageDefinitionValidationRulesReturn {
  existingOperatorMessageIds: string[];
  validateOperatorMessageIdUniqueness: (id: string) => boolean;
}

interface UseOperatorMessageDefinitionValidationRulesProps {
  defaultId: string | undefined;
}

const getOperatorMessageIds = (
  defaultId: string | undefined,
  specificationService: ISpecificationService | undefined
): string[] => {
  const operatorMessageIds: string[] =
    specificationService
      ?.getOperatorMessageDefinitions()
      .map(
        (operatorMessageDefinition: OperatorMessageDefinition) =>
          operatorMessageDefinition["@id"]
      )
      .filter((existingId: string) => existingId !== defaultId) ?? [];
  return operatorMessageIds;
};

const useOperatorMessageDefinitionValidationRules = ({
  defaultId,
}: UseOperatorMessageDefinitionValidationRulesProps): UseOperatorMessageDefinitionValidationRulesReturn => {
  const { specificationService } = useContext(AppStateContext);
  const [existingOperatorMessageIds, setExistingOperatorMessageIds] = useState<
    string[]
  >(() => getOperatorMessageIds(defaultId, specificationService));
  const validateOperatorMessageIdUniqueness: (id: string) => boolean =
    useCallback(
      (id: string) => {
        return !existingOperatorMessageIds.some(
          (existingId: string) => existingId === id
        );
      },
      [existingOperatorMessageIds]
    );

  useEffect(() => {
    const operatorMessageIds: string[] = getOperatorMessageIds(
      defaultId,
      specificationService
    );
    setExistingOperatorMessageIds(operatorMessageIds);
  }, [defaultId, specificationService]);

  return { existingOperatorMessageIds, validateOperatorMessageIdUniqueness };
};

export default useOperatorMessageDefinitionValidationRules;
