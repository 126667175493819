import { ReactElement, useEffect, useRef } from "react";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import {
  ArrayPath,
  FieldArray,
  FieldValues,
  Path,
  UseFormGetValues,
  useFormContext,
} from "react-hook-form";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import {
  AccordionInput,
  ConditionInput,
  FormGrid,
  SelectAssetId,
  SetParameterInput,
} from "@form-components";
import { defaultSetParameter } from "@utils/setParameterUtils";
import { defaultSetParameterBypass } from "@utils/setParameterBypassUtils";
import SetParameterBypassInput from "./SetParameterBypassInput";
import { defaultSetOperatingScheme } from "@utils/setOperatingSchemeUtils";
import SetOperatingSchemeInput from "./SetOperatingSchemeInput";
import CollapseSegment from "@components/GenericInputs/CollapseSegment/CollapseSegment";
import useError from "@components/form-components/hooks/useError";

/**
 * Defines the properties for the {@link ActionOperatingSchemeInput} component
 */
interface ActionOperatingSchemeInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {}

/**
 * The input component for actionOperatingScheme values.
 * @param props the properties to inject into the component
 * @returns {ReactElement<ActionOperatingSchemeInputProps>}
 */
export default function ActionOperatingSchemeInput<
  TInputs extends FieldValues,
>({
  disabled,
  columnSpan,
  name,
}: ActionOperatingSchemeInputProps<TInputs>): ReactElement<
  ActionOperatingSchemeInputProps<TInputs>
> {
  const conditionName: Path<TInputs> = `${name}.condition` as Path<TInputs>;
  const conditionError: boolean = useError({ names: conditionName });
  const setParameterName: Path<TInputs> =
    `${name}.setParameter` as Path<TInputs>;
  const setParameterError: boolean = useError({ names: setParameterName });
  const setParameterBypassName: Path<TInputs> =
    `${name}.setParameterBypass` as Path<TInputs>;
  const setParameterBypassError: boolean = useError({
    names: setParameterBypassName,
  });
  const setOperatingSchemeName: Path<TInputs> =
    `${name}.setOperatingScheme` as Path<TInputs>;
  const setOperatingSchemeError: boolean = useError({
    names: setOperatingSchemeName,
  });

  const { trigger } = useFormContext<TInputs>();
  const firstRender = useRef<boolean>(true);

  useEffect(() => {
    if (!firstRender.current) {
      return;
    }
    // if we won't trigger the validation on the first render, then the SetOperatingScheme won't be validated
    firstRender.current = false;
    trigger(setOperatingSchemeName);
  }, [trigger, setOperatingSchemeName]);

  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <SelectAssetId
        name={`${name}.@equipmentModuleId` as Path<TInputs>}
        label={"Equipment Module Id"}
        rules={{ required: true }}
        disabled={disabled}
        fixedDropdown
        fullWidth
      />

      <CollapseSegment
        buttonLabel="Set Operating Schemes"
        error={setOperatingSchemeError}
      >
        <AccordionInput<TInputs, ArrayPath<TInputs>>
          heading="Set Operating Schemes"
          name={setOperatingSchemeName as ArrayPath<TInputs>}
          defaultValue={
            { ...defaultSetOperatingScheme } as FieldArray<
              TInputs,
              ArrayPath<TInputs>
            >
          }
          getItemLabel={(index: number, getValues: UseFormGetValues<TInputs>) =>
            getValues(
              `${name}.setOperatingScheme.${index}.@operatingScheme` as Path<TInputs>
            ) ?? ""
          }
          itemRenderer={({ itemKey, name }) => (
            <SetOperatingSchemeInput
              key={itemKey}
              name={name as Path<TInputs>}
              disabled={disabled}
            />
          )}
          minLength={1}
          shouldUnregister
        />
      </CollapseSegment>

      <CollapseSegment buttonLabel="Set Parameters" error={setParameterError}>
        <AccordionInput<TInputs, ArrayPath<TInputs>>
          heading="Set Parameters"
          name={setParameterName as ArrayPath<TInputs>}
          defaultValue={
            { ...defaultSetParameter } as FieldArray<
              TInputs,
              ArrayPath<TInputs>
            >
          }
          getItemLabel={(index: number, getValues: UseFormGetValues<TInputs>) =>
            getValues(
              `${name}.setParameter.${index}.@parameter` as Path<TInputs>
            ) ?? ""
          }
          itemRenderer={({ itemKey, name }) => (
            <SetParameterInput
              key={itemKey}
              name={name as Path<TInputs>}
              disabled={disabled}
            />
          )}
        />
      </CollapseSegment>

      <CollapseSegment
        buttonLabel="Set Parameter Bypasses"
        error={setParameterBypassError}
      >
        <AccordionInput<TInputs, ArrayPath<TInputs>>
          heading="Set Parameter Bypasses"
          name={setParameterBypassName as ArrayPath<TInputs>}
          defaultValue={
            { ...defaultSetParameterBypass } as FieldArray<
              TInputs,
              ArrayPath<TInputs>
            >
          }
          getItemLabel={(index: number, getValues: UseFormGetValues<TInputs>) =>
            getValues(
              `${name}.setParameterBypass.${index}.@assetId` as Path<TInputs>
            ) ?? ""
          }
          itemRenderer={({ itemKey, name }) => (
            <SetParameterBypassInput
              key={itemKey}
              name={name as Path<TInputs>}
              disabled={disabled}
            />
          )}
        />
      </CollapseSegment>

      <CollapseSegment buttonLabel="Condition" error={conditionError}>
        <ConditionInput
          name={conditionName as Path<TInputs>}
          disabled={disabled}
        />
      </CollapseSegment>
    </FormGrid>
  );
}
