import { createContext } from "react";
import { AppReducerAction } from "./reducers/appReducer";
import { ModalReducerAction, initialModalState } from "./reducers/modalReducer";
import AppState from "./interfaces/AppState";
import ModalState from "./interfaces/ModalState";
import { initialState } from "./utils";

export const AppStateContext = createContext<AppState>(initialState);

export const AppDispatchContext = createContext<
  React.Dispatch<AppReducerAction>
>((value: AppReducerAction) => {});

export const ModalContext = createContext<{
  state: ModalState;
  dispatch: React.Dispatch<ModalReducerAction>;
}>({
  state: initialModalState,
  dispatch: (value: ModalReducerAction) => {},
});
