import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import {
  getStylesFromColumnSpan,
  twoColumnsSpan,
} from "@components/form-components/utils/styleUtils";
import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import {
  ConditionInput,
  FormGrid,
  SelectAssetId,
  AwParameterInput,
} from "@form-components";

/**
 * Defines the properties for the {@link ActionAlarmingWarningInput} component.
 */
interface ActionAlarmingWarningInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {}

/**
 * The input component for specifying actionAlarmingWarning values in the substate.
 * @param props the properties to inject into the component
 * @returns {ReactElement<ActionAlarmingWarningInput<TInputs>>}
 */
export default function ActionAlarmingWarningInput<
  TInputs extends FieldValues,
>({
  disabled,
  columnSpan,
  name,
}: ActionAlarmingWarningInputProps<TInputs>): ReactElement<
  ActionAlarmingWarningInputProps<TInputs>
> {
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <SelectAssetId
        name={`${name}.@assetId` as Path<TInputs>}
        label="Asset Id"
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
      />
      <AwParameterInput
        name={`${name}.limitHH` as Path<TInputs>}
        label="Limit HH"
        disabled={disabled}
        columnSpan={twoColumnsSpan}
      />
      <AwParameterInput
        name={`${name}.limitLL` as Path<TInputs>}
        label="Limit LL"
        disabled={disabled}
        columnSpan={twoColumnsSpan}
      />
      <AwParameterInput
        name={`${name}.limitH` as Path<TInputs>}
        label="Limit H"
        disabled={disabled}
        columnSpan={twoColumnsSpan}
      />
      <AwParameterInput
        name={`${name}.limitL` as Path<TInputs>}
        label="Limit L"
        disabled={disabled}
        columnSpan={twoColumnsSpan}
      />
      <ConditionInput
        name={`${name}.condition` as Path<TInputs>}
        label="Condition"
        disabled={disabled}
      />
    </FormGrid>
  );
}
