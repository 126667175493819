import { SubState } from "Asset_Specification";
import { Path, UseFormGetValues } from "react-hook-form";
import {
  AccordionInput,
  ActionOperatingSchemeInput,
  FormGrid,
} from "@form-components";
import { defaultActionOperatingScheme } from "@utils/actionOperatingSchemeUtils";

export const actionOperatingSchemeName: Path<SubState> =
  "actionOperatingScheme";

const ActionOperatingSchemeTabContent = () => {
  return (
    <FormGrid>
      <AccordionInput<SubState, "actionOperatingScheme">
        heading="Action Operating Schemes"
        name={actionOperatingSchemeName}
        defaultValue={{ ...defaultActionOperatingScheme }}
        getItemLabel={(index: number, getValues: UseFormGetValues<SubState>) =>
          getValues(
            `${actionOperatingSchemeName}.${index}.@equipmentModuleId`
          ) ?? ""
        }
        itemRenderer={({ itemKey, name }) => (
          <ActionOperatingSchemeInput<SubState> key={itemKey} name={name} />
        )}
      />
    </FormGrid>
  );
};

export default ActionOperatingSchemeTabContent;
