import { MouseEvent } from "react";
import { SuiIcon } from "../../../SuiIcon";
import pencilIcon from "@umetrics/sartorius-ui-icon/dist/icons/edit";
import deleteIcon from "@umetrics/sartorius-ui-icon/dist/icons/deleteAction";
import { Node } from "reactflow";
import useDeleteNode from "../../hooks/useDeleteNode";
import "./NodeButtonContainer.css";
import IDeleteNodeService from "../../services/interfaces/IDeleteNodeService";
import useSelectedStateNodes from "../../hooks/useSelectedStateNodes";

interface ButtonContainerProps {
  className: string;
  nodeId: string;
  onEditCallback?: () => void;
  additionalBtns?: {
    icon: string;
    onClick: (event: MouseEvent) => void;
    title: string;
  }[];
}

const NodeButtonContainer = (props: ButtonContainerProps) => {
  const { className, nodeId, onEditCallback, additionalBtns } = { ...props };
  const selectedStateNodes: Node[] = useSelectedStateNodes();
  const deleteNodeService: IDeleteNodeService = useDeleteNode();

  const onDeleteNodeBtnClick = () => {
    const nodeToDelete: Node | undefined = selectedStateNodes.find(
      (node) => node.id === nodeId
    );

    if (nodeToDelete === undefined) {
      alert(`Cannot delete node with id ${nodeId} because it does not exist!`);
      return;
    }

    deleteNodeService.deleteNode(nodeToDelete);
  };

  const onAdditionalBtnClick = (
    event: MouseEvent,
    additionalBtnOnClick: (event: MouseEvent) => void
  ) => {
    event.stopPropagation();
    additionalBtnOnClick(event);
  };

  return (
    <div className={`node-button-container ${className} nodrag`}>
      {additionalBtns &&
        additionalBtns.map((additionalBtn) => (
          <button
            onClick={(event) =>
              onAdditionalBtnClick(event, additionalBtn.onClick)
            }
            className="node-button"
            title={additionalBtn.title}
            key={additionalBtn.title}
          >
            <SuiIcon icon={additionalBtn.icon} size="tiny" />
          </button>
        ))}

      {onEditCallback && (
        <button
          onClick={onEditCallback}
          className="node-button"
          title="Edit Node"
        >
          <SuiIcon icon={pencilIcon} size="tiny" />
        </button>
      )}

      <button
        onClick={onDeleteNodeBtnClick}
        className="node-button"
        title="Delete Node"
      >
        <SuiIcon icon={deleteIcon} size="tiny" />
      </button>
    </div>
  );
};

export default NodeButtonContainer;
