import AttributesTab from "./Tabs/AttributesTab";
import { Form, FormTabs } from "@form-components";
import { Parameter } from "Asset_Specification";
import LabelTab from "./Tabs/LabelTab";
import ValueTab from "./Tabs/ValueTab";
import EngineeringUnitTab from "./Tabs/EngineeringUnitTab";
import DisplayFormatTab from "./Tabs/DisplayFormatTab";
import MfcsTab from "./Tabs/MfcsTab";

interface EditParametersFormProps {
  id: string;
  parameter: Parameter;
  onParameterSubmitted: (parameter: Parameter) => void;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onValidityChange: (isValid: boolean) => void;
}

const EditParametersForm = ({
  id,
  parameter,
  onParameterSubmitted,
  formRef,
  onValidityChange,
}: EditParametersFormProps) => {
  return (
    <Form<Parameter>
      id={id}
      formRef={formRef}
      onSubmit={onParameterSubmitted}
      defaultValues={parameter}
      onValidityChange={onValidityChange}
    >
      <FormTabs>
        <AttributesTab />
        <LabelTab />
        <ValueTab />
        <EngineeringUnitTab />
        <DisplayFormatTab />
        <MfcsTab />
      </FormTabs>
    </Form>
  );
};

export default EditParametersForm;
