import { ReactElement } from "react";
import { FieldValues, useController } from "react-hook-form";
import { StringRules } from "@components/form-components/interfaces/Rules";
import { InputProps } from "@components/form-components/interfaces/InputProps";
import {
  getRuleValue,
  requiredRuleErrorMessage,
} from "@components/form-components/utils/rulesUtils";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import { SuiSelect, SuiSelectOption } from "@components/SuiSelect";
import { SuiFeedbackMessage } from "@components/SuiFeedbackMessage";
import { FeedbackMessageType } from "@umetrics/sartorius-ui-feedback-message";
import { getLabelAndValueForOption } from "./utils";
import "./Select.css";

/**
 * Defines the properties for the {@link Select} component.
 * @extends InputProps
 * @template {TStringRules} - the {@link StringRules} implementation to use for the input.
 */
export interface SelectProps<
  TInputs extends FieldValues,
  TStringRules extends StringRules = StringRules,
> extends InputProps<TInputs, TStringRules> {
  options: string[] | { label: string; value: string }[];
  fixedDropdown?: boolean;
}

/**
 * A simple input component which wraps a umetrics select component in a {@link Controller} so that
 * it can be integrated into react-hook-form.
 * @template {TStringRules} - the {@link StringRules} implementation to use for the input
 * @param {InputProps} props - the props for the text field component.
 * @returns {ReactElement<SelectProps<TInputs>>}
 */
function Select<TInputs extends FieldValues>({
  name,
  label,
  rules,
  disabled,
  columnSpan,
  fullWidth,
  fixedDropdown,
  options,
}: SelectProps<TInputs>): ReactElement<SelectProps<TInputs>> {
  const {
    field: { onChange, onBlur, ref, value, name: inputName },
    fieldState: { error },
  } = useController({ name, rules });
  return (
    <div
      className="required-select-input-container"
      style={getStylesFromColumnSpan(columnSpan)}
    >
      <SuiSelect
        ref={ref}
        id={inputName}
        value={value}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        fullWidth={fullWidth}
        required={getRuleValue(rules?.required)}
        fixedDropdown={fixedDropdown}
        disabled={disabled}
      >
        {options.map((option) => {
          const optionAsObj: { label: string; value: string } =
            getLabelAndValueForOption(option);
          return (
            <SuiSelectOption
              key={optionAsObj.value}
              label={optionAsObj.label}
              value={optionAsObj.value}
            />
          );
        })}
      </SuiSelect>
      {error && (
        <SuiFeedbackMessage feedbackType={FeedbackMessageType.Failure}>
          {error.type === "required" ? requiredRuleErrorMessage : error.message}
        </SuiFeedbackMessage>
      )}
    </div>
  );
}

export default Select;
