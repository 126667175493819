import { Form, FormTab, FormTabs } from "@form-components";
import { MutableRefObject, ReactElement } from "react";
import DefinitionTabContent, {
  definitionName,
} from "./tab-content/DefinitionTabContent";
import ActionTabContent, {
  actionName,
} from "./tab-content/ActionTabContent/ActionTabContent";
import { MessageNodeDataForm } from "./interfaces";

interface EditOperatorMessageNodeDateFormProps {
  id: string;
  messageNodeDataForm: MessageNodeDataForm;
  onSubmitted: (messageNodeData: MessageNodeDataForm) => void;
  formRef: MutableRefObject<HTMLFormElement | null>;
  onValidityChange: (isValid: boolean) => void;
}

const EditOperatorMessageNodeDateForm = ({
  id,
  messageNodeDataForm,
  onSubmitted,
  formRef,
  onValidityChange,
}: EditOperatorMessageNodeDateFormProps): ReactElement<EditOperatorMessageNodeDateFormProps> => {
  return (
    <Form<MessageNodeDataForm>
      id={id}
      formRef={formRef}
      onSubmit={onSubmitted}
      defaultValues={messageNodeDataForm}
      mode="onChange"
      onValidityChange={onValidityChange}
    >
      <FormTabs<MessageNodeDataForm>>
        <FormTab<MessageNodeDataForm>
          heading="Definition"
          names={definitionName}
        >
          <DefinitionTabContent
            defaultId={
              messageNodeDataForm.messageDefinition["@id"]
                ? messageNodeDataForm.messageDefinition["@id"]
                : undefined
            }
          />
        </FormTab>
        <FormTab<MessageNodeDataForm> heading="Action" names={actionName}>
          <ActionTabContent />
        </FormTab>
      </FormTabs>
    </Form>
  );
};

export default EditOperatorMessageNodeDateForm;
