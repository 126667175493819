import { ReactElement } from "react";
import { RegexpRules } from "../../interfaces/Rules";
import {
  alphanumericWithSeparatorsAndWhitespaceRegexp,
  alphanumericWithSeparatorsAndWhitespaceRegexpMessage,
  alphanumericWithSeparatorsRegexp,
  alphanumericWithSeparatorsRegexpMessage,
} from "../../utils/regexpUtils";
import TextField, { TextFieldProps } from "../basic/TextField/TextField";
import {
  constructRulesWithRegExp,
  constructRulesWithRequired,
} from "../../utils/rulesUtils";
import { FieldValues } from "react-hook-form";

/**
 * Defines the properties for the {@link RequiredNonEmptyNameTextField} component.
 * @interface
 * @extends TextFieldProps
 */
export interface RequiredNonEmptyNameTextFieldProps<TInputs extends FieldValues>
  extends Omit<TextFieldProps<TInputs, RegexpRules>, "label"> {
  includeWhitespace?: boolean;
}

/**
 * A text field component which validates the text to match the "requiredId" group rule.
 * @param {RequiredNonEmptyNameTextFieldProps} props - the properties to pass into the component.
 * @returns {ReactElement}
 */
function RequiredNonEmptyNameTextField<TInputs extends FieldValues>({
  rules,
  includeWhitespace,
  ...remainingProps
}: RequiredNonEmptyNameTextFieldProps<TInputs>): ReactElement<
  RequiredNonEmptyNameTextFieldProps<TInputs>
> {
  const { regexp, regexpMessage } = !includeWhitespace
    ? {
        regexp: alphanumericWithSeparatorsRegexp,
        regexpMessage: alphanumericWithSeparatorsRegexpMessage,
      }
    : {
        regexp: alphanumericWithSeparatorsAndWhitespaceRegexp,
        regexpMessage: alphanumericWithSeparatorsAndWhitespaceRegexpMessage,
      };
  return (
    <TextField<TInputs>
      label="Name"
      rules={constructRulesWithRequired(
        constructRulesWithRegExp(regexp, regexpMessage, rules)
      )}
      {...remainingProps}
    />
  );
}

export default RequiredNonEmptyNameTextField;
