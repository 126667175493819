import { FormatTemplate } from "../FormatTemplate";
import startHoldSuspendTemplate from "./startHoldSuspendTemplate";

const startConditionsTemplate: FormatTemplate = {
  startCondition: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: startHoldSuspendTemplate,
  },
};

export default startConditionsTemplate;
