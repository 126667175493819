import { ReactElement } from "react";
import { Path } from "react-hook-form";
import { TransitionConditions } from "./types/TransitionConditions";
import {
  AnsiTextField,
  ConditionInput,
  FormGrid,
  RequiredIdTextField,
  RequiredNonEmptyNameTextField,
  useError,
} from "@form-components";
import { ConditionType } from "types";
import CollapseSegment from "@components/GenericInputs/CollapseSegment/CollapseSegment";

interface TransitionConditionInputProps {
  name: Path<TransitionConditions>;
}

const TransitionConditionInput = ({
  name,
}: TransitionConditionInputProps): ReactElement<TransitionConditionInputProps> => {
  const conditionType: ConditionType = `${name.split(".")[0]}` as ConditionType;
  const conditionsName: Path<TransitionConditions> = `${name}.conditions`;
  const conditionsError: boolean = useError({ names: conditionsName });
  const conditionsLabel: string = `${conditionType} Conditions`;
  const backConditionsName: Path<TransitionConditions> = `${name}.transBackConditions`;
  const backConditionsError: boolean = useError({ names: backConditionsName });
  const backConditionsLabel: string | undefined =
    conditionType === "SUSPEND" ? "UNSUSPEND Conditions" : undefined;
  return (
    <FormGrid>
      <RequiredIdTextField name={`${name}.id`} fullWidth />
      <RequiredNonEmptyNameTextField name={`${name}.name`} fullWidth />
      <AnsiTextField
        name={`${name}.description`}
        label="Description"
        fullWidth
      />
      <AnsiTextField
        name={`${name}.english`}
        label="English Label"
        rules={{ required: true }}
        fullWidth
      />
      <AnsiTextField
        name={`${name}.german`}
        label="German Label"
        rules={{ required: true }}
        fullWidth
      />
      <CollapseSegment buttonLabel={conditionsLabel} error={conditionsError}>
        <ConditionInput name={conditionsName} label={conditionsLabel} />
      </CollapseSegment>
      {backConditionsLabel ? (
        <CollapseSegment
          buttonLabel={backConditionsLabel}
          error={backConditionsError}
        >
          <ConditionInput
            name={backConditionsName}
            label={backConditionsLabel}
          />
        </CollapseSegment>
      ) : undefined}
    </FormGrid>
  );
};

export default TransitionConditionInput;
