import { useCallback, useContext } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { SIcon as SuiIcon } from "../../../../SuiIcon";
import add from "@umetrics/sartorius-ui-icon/dist/icons/add";
import { AppStateContext } from "../../../../../state/Context";
import { StepNodeData } from "../../../types";
import useEditOperatorMessageNodeDataModal from "../MessageNode/hooks/useEditOperatorMessageNodeDataModal";
import { getNewMessageNodeData } from "./utils/stepNodeUtils";
import { HandleTypes } from "../../../utils/types";
import NodeButtonContainer from "../../NodeButtonContainer/NodeButtonContainer";
import useEditStepNodeDataModal from "./hooks/useEditStepNodeDataModal";
import { IEditOperatorMessageNodeDataModalService } from "../MessageNode/services/EditOperatorMessageNodeDataModalService";
import { IEditStepNodeDataModalService } from "./services/EditStepNodeDataModalService";
import "./StepNode.css";

function StepNode(props: NodeProps<StepNodeData>) {
  const { "@name": name, "@id": id, actionOperatorMessage } = props.data.step;
  const { specificationService } = useContext(AppStateContext);
  const operatorMessageModalService: IEditOperatorMessageNodeDataModalService =
    useEditOperatorMessageNodeDataModal();
  const editStepNodeModalService: IEditStepNodeDataModalService =
    useEditStepNodeDataModal();

  const onAddOperatorMessage = useCallback(() => {
    if (!specificationService) {
      return;
    }

    operatorMessageModalService.openAddModal({
      id: "new-id",
      position: { x: 0, y: 0 },
      data: getNewMessageNodeData(props.id, specificationService),
    });
  }, [operatorMessageModalService, props.id, specificationService]);

  const onEditCallback = useCallback(() => {
    editStepNodeModalService.openEditModal(props.id, props.data);
  }, [editStepNodeModalService, props.id, props.data]);

  return (
    <div className="regular-node">
      <Handle
        position={Position.Top}
        type="target"
        id={HandleTypes.stepTarget}
      />
      <div className="regular-top-row">
        <label className="regular-label-top">{id}</label>
      </div>
      <label className="regular-label-bottom">{name}</label>
      <NodeButtonContainer
        className="regular-button-container"
        nodeId={props.id}
        onEditCallback={onEditCallback}
      />
      <Handle
        position={Position.Bottom}
        type="source"
        id={HandleTypes.stepSource}
      />

      <Handle
        position={Position.Right}
        style={{ top: "25%" }}
        type="source"
        id={HandleTypes.operatorMessage}
      >
        {!actionOperatorMessage && (
          <div>
            <button
              title="add operator message"
              className="regular-node-message-button"
              type="button"
              onClick={onAddOperatorMessage}
            >
              <SuiIcon icon={add} size="tiny" />
            </button>
          </div>
        )}
      </Handle>
    </div>
  );
}

export default StepNode;
