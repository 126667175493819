import { ReactElement } from "react";
import { FieldValues, Path } from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import {
  ConditionInput,
  FormGrid,
  getStylesFromColumnSpan,
  twoColumnsSpan,
} from "@form-components";
import AnsiTextField from "../basic/AnsiTextField";

/**
 * Defines the properties for the {@link SetParameterInputProps} component.
 */
interface SetParameterInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {}

/**
 * The input component for specifying SetParameter values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<SetParameterInputProps<TInputs>>}
 */
export default function SetParameterInput<TInputs extends FieldValues>({
  disabled,
  columnSpan,
  name,
}: SetParameterInputProps<TInputs>): ReactElement<
  SetParameterInputProps<TInputs>
> {
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <AnsiTextField
        name={`${name}.@parameter` as Path<TInputs>}
        label="Parameter"
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
        columnSpan={twoColumnsSpan}
      />

      <AnsiTextField
        name={`${name}.@value` as Path<TInputs>}
        label="Value"
        rules={{ required: true }}
        disabled={disabled}
        fullWidth
        columnSpan={twoColumnsSpan}
      />

      <ConditionInput
        name={`${name}.condition` as Path<TInputs>}
        label="Condition"
        disabled={disabled}
      />
    </FormGrid>
  );
}
