import { Node } from "reactflow";
import {
  BranchingNodeData,
  CaseNodeData,
  NodeData,
  StepNodeData,
  StateLinkNodeData,
} from "../../types";

export const isStepNodeData = (data: any): data is StepNodeData => {
  return "step" in data;
};

export const isBranchingNodeData = (data: any): data is BranchingNodeData => {
  return "caseNodeIds" in data;
};

export const isCaseNodeData = (data: any): data is CaseNodeData => {
  return "branchingNodeId" in data && "case" in data;
};

export const isStateLinkNodeData = (data: any): data is StateLinkNodeData => {
  return "state" in data && "substateId" in data;
};

export const isStepNode = (
  node: Node<NodeData>
): node is Node<StepNodeData> => {
  return isStepNodeData(node.data);
};

export const isBranchNode = (
  node: Node<NodeData>
): node is Node<BranchingNodeData> => {
  return isBranchingNodeData(node.data);
};

export const isCaseNode = (
  node: Node<NodeData>
): node is Node<CaseNodeData> => {
  return isCaseNodeData(node.data);
};

export const isStateLinkNode = (
  node: Node<NodeData>
): node is Node<StateLinkNodeData> => {
  return isStateLinkNodeData(node.data);
};
