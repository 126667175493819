import { Dispatch } from "react";
import { BranchingNodeData } from "../../../../types";
import { AppReducerAction } from "../../../../../../state/reducers/appReducer";
import { ModalReducerAction } from "../../../../../../state/reducers/modalReducer";
import EditNodeModalService from "../../../../services/interfaces/EditNodeModalService";
import { Node } from "reactflow";
import { EditBranchingNodeDataModalType } from "../enums/EditBranchingNodeDataModalType";
import EditBranchingNodeDataModal from "../modals/EditBranchingNodeDataModal";
import IEditNodeService from "../../../../services/interfaces/IEditNodeService";
import AddNodeModalService from "@components/SequentialFlowEditor/services/interfaces/AddNodeModalService";

export interface IEditBranchingNodeDataModalService
  extends EditNodeModalService<BranchingNodeData>,
    AddNodeModalService<BranchingNodeData> {}

export default class EditBranchingNodeDataModalService
  implements EditNodeModalService<BranchingNodeData>
{
  private appDispatch: Dispatch<AppReducerAction>;
  private modalDispatch: Dispatch<ModalReducerAction>;
  private editNodeService: IEditNodeService;

  constructor(
    appDispatch: Dispatch<AppReducerAction>,
    modalDispatch: Dispatch<ModalReducerAction>,
    editNodeService: IEditNodeService
  ) {
    this.appDispatch = appDispatch;
    this.modalDispatch = modalDispatch;
    this.editNodeService = editNodeService;
  }

  public openAddModal(node: Node<BranchingNodeData>): void {
    this.modalDispatch({
      type: "show",
      content: this.getModalContent(
        EditBranchingNodeDataModalType.Add,
        node.data,
        (substate) => this.saveOnAdd(node, substate)
      ),
    });
  }

  public openEditModal(
    nodeId: string,
    branchingNodeData: BranchingNodeData
  ): void {
    this.modalDispatch({
      type: "show",
      content: this.getModalContent(
        EditBranchingNodeDataModalType.Edit,
        branchingNodeData,
        (newBranchingNodeData) => this.saveOnEdit(nodeId, newBranchingNodeData)
      ),
    });
  }

  private getModalContent(
    type: EditBranchingNodeDataModalType,
    branchingNodeData: BranchingNodeData,
    onSaveCallback: (branchingNodeData: BranchingNodeData) => void
  ): JSX.Element {
    return (
      <EditBranchingNodeDataModal
        type={type}
        branchingNodeData={branchingNodeData}
        onSaveCallback={(branchingNodeData: BranchingNodeData) => {
          onSaveCallback(branchingNodeData);
          this.modalDispatch({ type: "close" });
        }}
        onCancelCallback={() => this.modalDispatch({ type: "close" })}
      />
    );
  }

  private saveOnAdd(node: Node, newBranchingNodeData: BranchingNodeData): void {
    const nodeToAdd: Node<BranchingNodeData> = {
      ...node,
      data: newBranchingNodeData,
    };
    this.appDispatch({ type: "add-node", node: nodeToAdd });
  }

  private saveOnEdit(
    nodeId: string,
    branchingNodeData: BranchingNodeData
  ): void {
    this.editNodeService.editNode(nodeId, branchingNodeData);
  }
}

