import { useReducer } from "react";
import { PLCParameter } from "../../../Asset_Specification"
import { CommonInputProps } from "../InputProps"
import { plcParameterFormReducer } from "./reducers/plcParameterFormReducer";
import { getPLCParameterFormFromPLCParameter, getPLCParameterFromPLCParameterForm } from "./utils/plcParameterInputUtils";
import { SuiFormSegment } from "../../SuiFormSegment";
import IdInput from "./inputs/IdInput";
import TypeInput from "./inputs/TypeInput";
import NameInput from "./inputs/NameInput";
import BlockInput from "./inputs/BlockInput";
import DescriptionInput from "./inputs/DescriptionInput";

interface PLCParameterInputProps extends CommonInputProps<PLCParameter> {
  plcParameter?: PLCParameter;
}

const PLCParameterInput = (props: PLCParameterInputProps) => {
  const {
    id,
    plcParameter,
    onChangeCallback
  } = { ...props };

  const [plcParameterForm, setPlcParameterForm] = useReducer(
    plcParameterFormReducer,
    plcParameter,
    getPLCParameterFormFromPLCParameter
  );

  const onPropertyChanged = (propertyName: "@type" | "@block" | "@description" | "@id" | "@name", propertyValue: any, propertyValid: boolean) => {
    const plcParameterFormCopy = { ...plcParameterForm };
    plcParameterFormCopy[propertyName] = [propertyValue, propertyValid];
    const newPlcParameter = getPLCParameterFromPLCParameterForm(plcParameterFormCopy);
    const valid = Object.values(plcParameterFormCopy).every(propertyPair => propertyPair[1]);
    setPlcParameterForm(
      {
        field: propertyName,
        payload: [propertyValue, propertyValid]
      }
    );
    onChangeCallback?.(newPlcParameter, valid);
  }

  return(
    <SuiFormSegment>
      <IdInput 
        id={id}
        value={plcParameterForm["@id"][0]}
        onPropertyChanged={(propertyValue: any, propertyValid: boolean) => onPropertyChanged("@id", propertyValue, propertyValid)}
      />
      <NameInput 
        id={id}
        value={plcParameterForm["@name"][0]}
        onPropertyChanged={(propertyValue: any, propertyValid: boolean) => onPropertyChanged("@name", propertyValue, propertyValid)}
      />
      <TypeInput 
        id={id}
        value={plcParameterForm["@type"][0]}
        onPropertyChanged={(propertyValue: any, propertyValid: boolean) => onPropertyChanged("@type", propertyValue, propertyValid)}
      />
      <BlockInput 
        id={id}
        value={plcParameterForm["@block"][0]}
        onPropertyChanged={(propertyValue: any, propertyValid: boolean) => onPropertyChanged("@block", propertyValue, propertyValid)}
      />
      <DescriptionInput 
        id={id}
        value={plcParameterForm["@description"][0]}
        onPropertyChanged={(propertyValue: any, propertyValid: boolean) => onPropertyChanged("@description", propertyValue, propertyValid)}
      />
    </SuiFormSegment>
  );
}

export default PLCParameterInput;