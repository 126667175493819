import { Case } from "Asset_Specification";
import SubstateIdAndConditionTab from "./tabs/SubstateIdAndConditionTab";
import RefreshParameterTab from "./tabs/RefreshParameterTab";
import SetAssetParameterTab from "./tabs/SetAssetParameterTab";
import { Form, FormTabs } from "@form-components";
import { ReactElement } from "react";

interface CaseNodeEditFormProps {
  id: string;
  caseValue: Case;
  onCaseSubmitted: (newCase: Case) => void;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onValidityChange: (isValid: boolean) => void;
}

const CaseEditForm = ({
  id,
  caseValue,
  onCaseSubmitted,
  formRef,
  onValidityChange,
}: CaseNodeEditFormProps): ReactElement<CaseNodeEditFormProps> => {
  return (
    <Form<Case>
      id={id}
      formRef={formRef}
      onSubmit={onCaseSubmitted}
      defaultValues={caseValue}
      mode="onChange"
      onValidityChange={onValidityChange}
    >
      <FormTabs>
        <SubstateIdAndConditionTab />
        <SetAssetParameterTab />
        <RefreshParameterTab />
      </FormTabs>
    </Form>
  );
};

export default CaseEditForm;
