import { ReactElement } from "react";
import {
  ArrayPath,
  FieldArray,
  FieldValues,
  Path,
  UseFormGetValues,
} from "react-hook-form";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import {
  AccordionInput,
  AnsiTextField,
  FormGrid,
  Select,
  ValueEnumInput,
} from "@form-components";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import { defaultValueEnum } from "@utils/valueEnumUtils";
import CollapseSegment from "@components/GenericInputs/CollapseSegment/CollapseSegment";
import useError from "@components/form-components/hooks/useError";

/**
 * Defines the properties for the {@link EnumerationInput} component.
 */
interface EnumerationInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "label" | "fullWidth"> {}

/**
 * The input component for specifying Enumeration values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<EnumerationInputProps<TInputs>>}
 */
export default function EnumerationInput<TInputs extends FieldValues>({
  disabled,
  columnSpan,
  name,
}: EnumerationInputProps<TInputs>): ReactElement<
  EnumerationInputProps<TInputs>
> {
  const dataTypeName: Path<TInputs> = `${name}.@dataType` as Path<TInputs>;
  const valueEnumName: ArrayPath<TInputs> =
    `${name}.valueEnum` as ArrayPath<TInputs>;
  const valueEnumsError: boolean = useError({
    names: valueEnumName as Path<TInputs>,
  });
  return (
    <FormGrid style={getStylesFromColumnSpan(columnSpan)}>
      <Select
        name={dataTypeName}
        label="Data Type"
        options={["real", "int"]}
        rules={{ required: true }}
        fullWidth
        fixedDropdown
        disabled={disabled}
      />

      <AnsiTextField
        name={`${name}.@description` as Path<TInputs>}
        label="Description"
        fullWidth
        disabled={disabled}
      />

      <CollapseSegment buttonLabel="Value Enums" error={valueEnumsError}>
        <AccordionInput<TInputs, ArrayPath<TInputs>>
          heading="Value Enums"
          name={valueEnumName}
          defaultValue={
            { ...defaultValueEnum } as FieldArray<TInputs, ArrayPath<TInputs>>
          }
          getItemLabel={(index: number, getValues: UseFormGetValues<TInputs>) =>
            getValues(`${valueEnumName}.${index}.@id` as Path<TInputs>) ?? ""
          }
          itemRenderer={({ itemKey, name }) => (
            <ValueEnumInput key={itemKey} name={name as Path<TInputs>} />
          )}
        />
      </CollapseSegment>
    </FormGrid>
  );
}
