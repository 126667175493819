import { FormatTemplate } from "../FormatTemplate";
import actionAlarmingWarningTemplate from "./actionAlarmingWarningTemplate";
import actionCalculationTemplate from "./actionCalculationTemplate";
import actionOperatingSchemeCommandTemplate from "./actionOperatingSchemeCommandTemplate";
import actionOperatingSchemeTemplate from "./actionOperatingSchemeTemplate";
import actionOperatorMessageTemplate from "./actionOperatorMessageTemplate";
import actionRefreshParameterTemplate from "./actionRefreshParameterTemplate";
import actionSetAssetParameterTemplate from "./actionSetAssetParameterTemplate";
import actionSnapShotTemplate from "./actionSnapShotTemplate";
import branchingTemplate from "./branchingTemplate";
import setParameterTemplate from "./setParameterTemplate";

const subStateTemplate: FormatTemplate = {
  actionOperatingScheme: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: actionOperatingSchemeTemplate,
  },
  actionOperatingSchemeCommand: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: actionOperatingSchemeCommandTemplate,
  },
  actionSetAssetParameter: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: actionSetAssetParameterTemplate,
  },
  actionSetParameter: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: setParameterTemplate,
  },
  actionOperatorMessage: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: actionOperatorMessageTemplate,
  },
  actionSnapShot: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: actionSnapShotTemplate,
  },
  actionAlarmingWarning: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: actionAlarmingWarningTemplate,
  },
  actionRefreshParameter: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: actionRefreshParameterTemplate,
  },
  actionCalculation: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: actionCalculationTemplate,
  },
  branching: {
    type: "object",
    canBeUndefined: true,
    propertyTemplate: branchingTemplate,
  },
  "@id": {
    type: "string",
    canBeUndefined: false,
  },
  "@name": {
    type: "string",
    canBeUndefined: false,
  },
  "@value": {
    type: "string",
    canBeUndefined: false,
  },
  "@description": {
    type: "string",
    canBeUndefined: false,
  },
  "@pauseStep": {
    type: "string",
    canBeUndefined: true,
  },
};

export default subStateTemplate;
