import { useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactFlow, {
  Controls,
  Background,
  Edge,
  applyEdgeChanges,
  NodeChange,
  EdgeChange,
  addEdge,
  Connection,
  SelectionMode,
  ReactFlowInstance,
  XYPosition,
  Node,
} from "reactflow";
import "reactflow/dist/style.css";
import { NodeType, nodeTypes } from "../../types";
import { AppDispatchContext } from "../../../../state/Context";
import useAddNode from "../../hooks/useAddNode";
import IAddNodeService from "../../services/interfaces/IAddNodeService";
import {
  EdgeType,
  createEdgeFromConnection,
  edgeMarkerType,
  edgeTypes,
  isValidEdgeConnection,
} from "@utils/edgeUtils";
import useSelectedStateNodes from "../../hooks/useSelectedStateNodes";
import useSelectedStateEdges from "../../hooks/useSelectedStateEdges";
import { HandleTypes } from "../../utils/types";
import {
  isValidBranchingCaseConnection,
  isValidStepBranchingConnection,
  updateBranchingCase,
  updateStepBranching,
} from "../../utils/updateConnectionUtils";
import {
  removeBranchingCase,
  removeStepBranching,
} from "../../utils/deleteConnectionUtils";

const proOptions = { hideAttribution: true };

const Flow = () => {
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance<
    any,
    any
  > | null>(null);
  const edgeUpdateSuccessful = useRef(false);
  const reactflowWrapper: React.LegacyRef<HTMLDivElement> | undefined =
    useRef(null);
  const dispatch = useContext(AppDispatchContext);
  const addNodeService: IAddNodeService = useAddNode();

  const selectedStateNodes: Node[] = useSelectedStateNodes();
  const selectedStateEdges: Edge[] = useSelectedStateEdges();

  const onConnection = useCallback(
    (connection: Connection | Edge, oldEdge: Edge | undefined) => {
      if (isValidStepBranchingConnection(connection)) {
        updateStepBranching(connection, selectedStateNodes, dispatch);
        return;
      }

      if (!isValidBranchingCaseConnection(connection)) {
        return;
      }

      updateBranchingCase(oldEdge, connection, selectedStateNodes, dispatch);
    },
    [dispatch, selectedStateNodes]
  );

  const onNewConnection = useCallback(onConnection, [onConnection]);

  const onUpdateConnection = useCallback(onConnection, [onConnection]);

  const onRemoveConnection = useCallback(
    (edge: Edge) => {
      if (edge.sourceHandle === HandleTypes.operatorMessage) {
        alert("There should be no unconnected operator messages!");
      }

      if (isValidStepBranchingConnection(edge)) {
        removeStepBranching(edge, selectedStateNodes, dispatch);
        return;
      }

      if (!isValidBranchingCaseConnection(edge)) {
        return;
      }

      removeBranchingCase(edge, selectedStateNodes, dispatch);
    },
    [dispatch, selectedStateNodes]
  );

  const isValidConnection = useCallback(
    (connection: Connection, isNewConnection: boolean): boolean => {
      return isValidEdgeConnection(
        selectedStateEdges,
        connection,
        isNewConnection
      );
    },
    [selectedStateEdges]
  );

  const setEdges = useCallback(
    (edges: Edge[]) => {
      dispatch({ type: "set-edges", edges });
    },
    [dispatch]
  );

  const isValidEdgeRemoval = useCallback((edge: Edge) => {
    // Operator message nodes must be connected to any step
    return edge.sourceHandle !== HandleTypes.operatorMessage;
  }, []);

  // manual nodes and edges changes handling
  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      dispatch({ type: "apply-node-changes", nodeChanges: changes });
    },
    [dispatch]
  );

  // handling updating edges //
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) =>
      setEdges(applyEdgeChanges(changes, selectedStateEdges)),
    [setEdges, selectedStateEdges]
  );

  const onConnect = useCallback(
    (connection: Connection): void => {
      if (!isValidConnection(connection, true)) {
        return;
      }

      const edge: Edge | undefined = createEdgeFromConnection(
        selectedStateNodes,
        connection
      );
      if (!edge) {
        return;
      }

      setEdges(addEdge(edge, selectedStateEdges));
      onNewConnection(connection, undefined);
    },
    [
      isValidConnection,
      selectedStateNodes,
      setEdges,
      selectedStateEdges,
      onNewConnection,
    ]
  );

  const onEdgeUpdateStart = useCallback(
    () => (edgeUpdateSuccessful.current = false),
    []
  );

  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection): void => {
      if (!isValidConnection(newConnection, false)) {
        return;
      }

      const newEdge: Edge | undefined = createEdgeFromConnection(
        selectedStateNodes,
        newConnection
      );
      if (!newEdge) {
        return;
      }

      const newEdges: Edge[] = selectedStateEdges.filter(
        (edge) => edge.id !== oldEdge.id
      );
      newEdges.push(newEdge);
      edgeUpdateSuccessful.current = true;
      setEdges(newEdges);
      onUpdateConnection(newConnection, oldEdge);
    },
    [
      isValidConnection,
      selectedStateNodes,
      selectedStateEdges,
      setEdges,
      onUpdateConnection,
    ]
  );

  const onEdgeUpdateEnd = useCallback(
    (_: globalThis.MouseEvent | TouchEvent, edge: Edge) => {
      if (
        edgeUpdateSuccessful.current ||
        !isValidEdgeRemoval(edge) ||
        !window.confirm("Are you sure you want to delete this edge?")
      ) {
        return;
      }

      setEdges(selectedStateEdges.filter((e) => e.id !== edge.id));
      onRemoveConnection(edge);
    },
    [selectedStateEdges, setEdges, onRemoveConnection, isValidEdgeRemoval]
  );

  ////

  // Drag and drop handling
  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onNodeDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const data = event.dataTransfer.getData("text/plain");
      const zoneRect = reactflowWrapper.current?.getBoundingClientRect();

      const position: XYPosition =
        zoneRect && reactFlowInstance
          ? reactFlowInstance.project({
              x: event.clientX - zoneRect.left,
              y: event.clientY - zoneRect.top,
            })
          : { x: event.clientX, y: event.clientY };
      addNodeService.addNode(data as NodeType, position);
    },
    [reactFlowInstance, addNodeService]
  );
  ////

  useEffect(() => {
    const width = (reactflowWrapper.current?.clientWidth ?? 300) / 2;
    const height = (reactflowWrapper.current?.clientHeight ?? 300) / 2;
    const horizontalOffset = -0.75 * width;
    const verticalOffset = 0.15 * height;

    reactFlowInstance?.fitBounds(
      {
        x: horizontalOffset,
        y: verticalOffset,
        width: width,
        height: height,
      },
      {
        padding: 0.5,
      }
    );
  }, [
    reactFlowInstance,
    reactflowWrapper.current?.clientWidth,
    reactflowWrapper.current?.clientHeight,
  ]);

  return (
    <div style={{ height: "80vh" }} ref={reactflowWrapper}>
      <ReactFlow
        onInit={setReactFlowInstance}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        nodes={selectedStateNodes}
        edges={selectedStateEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        onConnect={onConnect}
        onDragOver={onDragOver}
        onDrop={onNodeDrop}
        proOptions={proOptions}
        selectionMode={SelectionMode.Partial}
        snapToGrid
        defaultEdgeOptions={{
          type: EdgeType.default,
          markerEnd: { type: edgeMarkerType },
          deletable: false,
        }}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default Flow;
