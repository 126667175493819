import { SnapShotDefinition } from "../../../../Asset_Specification";

export const defaultSnapshotDefinition: SnapShotDefinition = {
  "@id": "",
  "@snapShotNumber": 0,
  "@value": "",
  "@textValue": "",
  engineeringUnit: { "@description": "Engineering Unit", "@value": "" },
  displayFormat: { "@description": "Display Format", "@value": "" },
  label: undefined,
};
