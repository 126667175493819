import { useCallback, useState } from "react";
import { SuiButton } from "../SuiButton";
import "./CopyToClipboardButton.css";

interface CopyToClipboardButtonProps {
  textToCopy: string;
}

const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => {
  const { textToCopy } = props;
  const [copied, setCopied] = useState(false);

  const onClick = useCallback(() => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
  }, [textToCopy]);

  const onMouseLeave = useCallback(() => {
    setCopied(false);
  }, []);

  return (
    <div onMouseLeave={onMouseLeave} className="copy-button-container">
      <SuiButton type="secondary" onClick={onClick} fullWidth>
        {copied ? "Copied!" : "Copy to Clipboard"}
      </SuiButton>
    </div>
  );
};

export default CopyToClipboardButton;
