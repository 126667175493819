import { Dispatch } from "react";
import { Edge, Node } from "reactflow";
import IDeleteNodeService from "./interfaces/IDeleteNodeService";
import { AppReducerAction } from "../../../state/reducers/appReducer";
import {
  deleteBranchingNode,
  deleteCaseNode,
  deleteMessageOperatorNode,
  deleteStepNode,
  deleteStateLinkNode,
} from "../utils/deleteNodeUtils";
import { NodeType } from "../types";
import { isBranchNode } from "../components/nodes/nodeUtils";

export default class DeleteNodeService implements IDeleteNodeService {
  private appDispatch: Dispatch<AppReducerAction>;
  private edges: Edge[];
  private nodes: Node[];

  constructor(
    appDispatch: Dispatch<AppReducerAction>,
    edges: Edge[],
    nodes: Node[]
  ) {
    this.appDispatch = appDispatch;
    this.edges = edges;
    this.nodes = nodes;
  }

  public deleteNode(node: Node): void {
    this.deleteNodeWithType(node);
    this.deleteConnectedEdges(node);
  }

  private deleteNodeWithType(node: Node): void {
    switch (node.type) {
      case NodeType.step: {
        return deleteStepNode(node, this.nodes, this.edges, this.appDispatch);
      }
      case NodeType.message: {
        return deleteMessageOperatorNode(
          node,
          this.nodes,
          this.edges,
          this.appDispatch
        );
      }
      case NodeType.branching: {
        return deleteBranchingNode(node, this.nodes, this.appDispatch);
      }
      case NodeType.case: {
        return deleteCaseNode(node, this.nodes, this.appDispatch);
      }
      case NodeType.stateLink: {
        return deleteStateLinkNode(
          node,
          this.nodes,
          this.edges,
          this.appDispatch
        );
      }
      default: {
        throw new Error(
          `Cannot delete node because type '${node.type}' is unrecognized!`
        );
      }
    }
  }

  private deleteConnectedEdges(node: Node): void {
    let newEdges = [
      ...this.edges.filter(
        (edge: Edge) => edge.source !== node.id && edge.target !== node.id
      ),
    ];
    if (isBranchNode(node)) {
      const caseNodeIds: string[] = node.data.caseNodeIds;
      newEdges = newEdges.filter(
        (edge: Edge) =>
          !caseNodeIds.includes(edge.source) &&
          !caseNodeIds.includes(edge.target)
      );
    }

    this.appDispatch({ type: "set-edges", edges: newEdges });
  }
}
