const maxLabelLengthBeforeTruncation = 100;

/**
 * @deprecated use the form-components equivalent
 */
export const maybeTruncateLabel = (
  label: string | undefined
): string | undefined => {
  if (label === undefined || label.length < maxLabelLengthBeforeTruncation) {
    return label;
  }

  return `${label.slice(0, maxLabelLengthBeforeTruncation)}...`;
};
