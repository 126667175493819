import { useMemo } from "react";

interface UseMaxNumberOfLinesProps {
  lineHeight: number;
  fontSize: number;
  sourceY: number;
  targetY: number;
}

interface UseMaxNumberOfLinesReturn {
  maxNumberOfLines: number;
}

const labelPaddingSpace: number = -20;

const useMaxNumberOfLines = ({
  lineHeight,
  fontSize,
  sourceY,
  targetY,
}: UseMaxNumberOfLinesProps): UseMaxNumberOfLinesReturn => {
  return useMemo(() => {
    const allowedSpace: number =
      labelPaddingSpace + Math.abs(targetY - sourceY);
    const maxNumberOfLines: number = Math.floor(
      allowedSpace / (lineHeight * fontSize)
    );
    return { maxNumberOfLines };
  }, [fontSize, lineHeight, sourceY, targetY]);
};

export default useMaxNumberOfLines;
