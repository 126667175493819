import { ReactElement } from "react";
import { RegexpRules } from "../../interfaces/Rules";
import {
  alphanumericWithSeparatorsRegexp,
  alphanumericWithSeparatorsRegexpMessage,
} from "../../utils/regexpUtils";
import TextField, { TextFieldProps } from "../basic/TextField/TextField";
import { constructRulesWithRegExp } from "../../utils/rulesUtils";
import { FieldValues } from "react-hook-form";

/**
 * Defines the properties for the {@link AllocNameTextField} component.
 * @interface
 * @extends TextFieldProps
 */
export interface AllocNameTextFieldProps<TInputs extends FieldValues>
  extends Omit<TextFieldProps<TInputs, RegexpRules>, "label"> {}

/**
 * A text field component which validates the text to match the "alloc name" group rule.
 * @param {AllocNameTextFieldProps} props - the properties to pass into the component.
 * @returns {ReactElement}
 */
function AllocNameTextField<TInputs extends FieldValues>({
  rules,
  ...remainingProps
}: AllocNameTextFieldProps<TInputs>): ReactElement {
  return (
    <TextField<TInputs>
      label="Alloc Name"
      rules={constructRulesWithRegExp(
        alphanumericWithSeparatorsRegexp,
        alphanumericWithSeparatorsRegexpMessage,
        rules
      )}
      {...remainingProps}
    />
  );
}

export default AllocNameTextField;
