import {
  ActionOperatorMessage,
  Branching,
  CalculationDefinition,
  Case,
  DocumentationPH,
  ExchangeAsset,
  FaceplateElement,
  OperatorMessageDefinition,
  Parameter,
  SnapShotDefinition,
} from "../../Asset_Specification";
import {
  BranchingNodeData,
  CaseNodeData,
  MessageNodeData,
  StepNodeData,
} from "../../components/SequentialFlowEditor/types";
import FlowchartGenerator from "../../services/FlowchartGenerator";
import { SpecificationService } from "../../services/SpecificationService";
import { EPHInfo, Step, TransitionCondition } from "../../types";
import { Edge, Node, NodeChange, applyNodeChanges } from "reactflow";
import { AssetFormatterService } from "../../services/AssetFormatterService/AssetFormatterService";
import {
  updateNodesWithBranchingCase,
  replaceMessageBranchingInStep,
  getBranchingCases,
  hasOperatorMessageBranching,
  removeMessageBranchingFromStep,
  OperatorMessageCaseWithSubstateId,
} from "../../utils/branchingUtils";
import { IAssetFormatterService } from "../../services/AssetFormatterService/IAssetFormatterService";
import { FormatTemplate } from "../../services/AssetFormatterService/FormatTemplate";
import AppState from "../interfaces/AppState";
import IFlowchartGenerator from "../../services/interfaces/IFlowchartGenerator";
import applyNodeAndEdgeMigrations from "../migrations/applyNodeAndEdgeMigrations";
import { isStepNode } from "../../components/SequentialFlowEditor/components/nodes/nodeUtils";
import {
  createMessageNode,
  createNewNodeId,
} from "../../utils/nodeGenerationUtils";
import { connectWithEdge } from "../../utils/edgeUtils";
import { copyAppState } from "@utils/copyUtils";
import { selectAssetTemplate } from "@utils/assetTemplateUtils";
import { initialState } from "state/utils";
import { onUpdateCaseNode } from "@utils/caseToSubstateUtils";

export type AppReducerAction =
  | { type: "add-node"; node: Node }
  | { type: "update-node"; payload: { id: string; data: any } }
  | { type: "delete-node"; id: string }
  | { type: "set-nodes"; nodes: Node[] }
  | { type: "set-edges"; edges: Edge[] }
  | { type: "set-spec-service"; service: SpecificationService | undefined }
  | {
      type: "set-asset-formatter-service";
      service: IAssetFormatterService | undefined;
    }
  | { type: "set-eph-info"; ephInfo: EPHInfo }
  | { type: "remove-step"; step: Step }
  | {
      type: "set-transition-conditions";
      transitionConditions: { [key: string]: TransitionCondition[] };
    }
  | {
      type: "set-action-operator-message";
      step?: Step;
      stepNodeId?: string;
      operatorMessage: ActionOperatorMessage;
    }
  | {
      type: "add-operator-message";
      stepNodeId: string;
      operatorMessageDefinition: OperatorMessageDefinition;
      actionOperatorMessage: ActionOperatorMessage;
      addNode?: boolean;
    }
  | {
      type: "update-operator-message-definition";
      messageId: string;
      newValue: OperatorMessageDefinition;
    }
  | { type: "remove-operator-message"; messageId: string }
  | { type: "set-parameters"; parameters: Parameter[] }
  | { type: "set-exchange"; exchange: ExchangeAsset[] }
  | { type: "set-calculations"; calculations: CalculationDefinition[] }
  | { type: "set-snapshots"; snapshots: SnapShotDefinition[] }
  | { type: "set-documentation"; documentation: DocumentationPH }
  | { type: "set-faceplate-elements"; faceplateElements: FaceplateElement[] }
  | {
      type: "set-operator-message-branching";
      stepNodeId: string;
      operatorMessages: OperatorMessageCaseWithSubstateId[];
    }
  | { type: "remove-operator-message-branching"; stepNodeId: string }
  | { type: "add-branching-node-case"; branchingNodeId: string; case?: Case }
  | {
      type: "update-step";
      newStepValue: Step;
    }
  | { type: "apply-node-changes"; nodeChanges: NodeChange[] }
  | { type: "add-step"; step: Step }
  | { type: "set-selected-state"; selectedState: string | undefined }
  | { type: "clear-nodes-and-edges" }
  | {
      type: "set-flowchart-generator";
      flowchartGenerator: IFlowchartGenerator | undefined;
    }
  | {
      type: "update-edge";
      newEdge: Edge;
    }
  | {
      type: "update-case-node";
      nodeId: string;
      data: CaseNodeData;
    };

export const initializer = (
  initialValue: AppState = initialState
): AppState => {
  const stateString = localStorage.getItem("localState");
  if (stateString) {
    const state = JSON.parse(stateString);
    const specificationService: SpecificationService | undefined =
      state.specification !== undefined
        ? new SpecificationService(state.specification, state.selectedState)
        : undefined;
    const assetTemplate: FormatTemplate | undefined =
      state.specification !== undefined
        ? selectAssetTemplate(state.specification)
        : undefined;
    if (!assetTemplate && state.specification) {
      console.warn(
        "Unknown specification type for stored specification! No matching format template was found. AssetFormatterService not created."
      );
    }

    return {
      nodesAndEdges: applyNodeAndEdgeMigrations(
        state.nodesAndEdges,
        state.selectedState,
        state.nodes,
        state.edges
      ),
      specificationService: specificationService,
      assetFormatterService: assetTemplate
        ? new AssetFormatterService(assetTemplate)
        : undefined,
      flowchartGenerator: specificationService
        ? new FlowchartGenerator(specificationService)
        : undefined,
    };
  }

  return initialValue;
};

export function appReducer(
  state: AppState,
  action: AppReducerAction
): AppState {
  switch (action.type) {
    case "add-node":
      if (state.selectedState === undefined) {
        return { ...state };
      }

      return {
        ...state,
        nodesAndEdges: {
          ...state.nodesAndEdges,
          [state.selectedState]: {
            ...state.nodesAndEdges[state.selectedState],
            nodes: [
              ...state.nodesAndEdges[state.selectedState].nodes,
              action.node,
            ],
          },
        },
      };
    case "delete-node":
      if (state.selectedState === undefined) {
        return { ...state };
      }

      return {
        ...state,
        nodesAndEdges: {
          ...state.nodesAndEdges,
          [state.selectedState]: {
            ...state.nodesAndEdges[state.selectedState],
            nodes: state.nodesAndEdges[state.selectedState].nodes.filter(
              (nd) => nd.id !== action.id
            ),
          },
        },
      };
    case "set-nodes":
      if (state.selectedState === undefined) {
        return { ...state };
      }

      return {
        ...state,
        nodesAndEdges: {
          ...state.nodesAndEdges,
          [state.selectedState]: {
            ...state.nodesAndEdges[state.selectedState],
            nodes: action.nodes,
          },
        },
      };
    case "update-node":
      if (state.selectedState === undefined) {
        return { ...state };
      }

      const selectedStateNodes: Node[] = state.nodesAndEdges[
        state.selectedState
      ].nodes.map((node) => {
        if (node.id === action.payload.id) {
          node.data = {
            ...node.data,
            ...action.payload.data,
          };
        }
        return node;
      });
      return {
        ...state,
        nodesAndEdges: {
          ...state.nodesAndEdges,
          [state.selectedState]: {
            ...state.nodesAndEdges[state.selectedState],
            nodes: selectedStateNodes,
          },
        },
      };
    case "set-edges":
      if (state.selectedState === undefined) {
        return { ...state };
      }

      return {
        ...state,
        nodesAndEdges: {
          ...state.nodesAndEdges,
          [state.selectedState]: {
            ...state.nodesAndEdges[state.selectedState],
            edges: action.edges,
          },
        },
      };
    case "set-spec-service":
      return {
        ...state,
        specificationService: action.service,
      };
    case "set-asset-formatter-service":
      return {
        ...state,
        assetFormatterService: action.service,
      };
    case "remove-step": {
      const newState = copyAppState(state);
      newState.specificationService?.removeStep(action.step);
      return newState;
    }
    case "set-eph-info": {
      const newState = copyAppState(state);
      newState.specificationService?.updateEphInfo(action.ephInfo);
      return newState;
    }
    case "set-transition-conditions": {
      const newState = copyAppState(state);
      newState.specificationService?.updateTransitionConditions(
        action.transitionConditions
      );
      return newState;
    }
    case "set-action-operator-message": {
      if (state.selectedState === undefined) {
        return { ...state };
      }

      const newState: AppState = copyAppState(state);
      const messageId: string = action.operatorMessage["@messageId"];
      const stepToUpdate: Step | undefined = action.step
        ? action.step
        : newState.specificationService?.getStepWithOperatorMessageId(
            messageId
          );
      if (!stepToUpdate) {
        console.error(
          "Cannot update action operator message as there is no step linked to the operator message!"
        );

        return newState;
      }

      newState.specificationService?.removeActionOperatorMessage(messageId);
      const updatedStep =
        newState.specificationService?.setActionOperatorMessage(
          stepToUpdate,
          action.operatorMessage
        );

      const selectedNodes: Node[] = newState.nodesAndEdges[
        state.selectedState
      ].nodes.map((node) => {
        if (node.data.step?.stepId === updatedStep?.stepId) {
          return {
            ...node,
            data: {
              ...node.data,
              step: updatedStep,
            },
          };
        }

        return node;
      });

      return {
        ...newState,
        nodesAndEdges: {
          ...newState.nodesAndEdges,
          [state.selectedState]: {
            ...newState.nodesAndEdges[state.selectedState],
            nodes: selectedNodes,
          },
        },
      };
    }
    case "add-operator-message": {
      if (state.selectedState === undefined) {
        return { ...state };
      }

      const newState = copyAppState(state);
      const nodes: Node[] = [
        ...newState.nodesAndEdges[state.selectedState].nodes,
      ];
      const stepNode: Node<StepNodeData> | undefined = nodes.find(
        (node) => isStepNode(node) && node.id === action.stepNodeId
      );
      if (!newState.specificationService || !stepNode) {
        return { ...newState };
      }

      const messageDefinition: OperatorMessageDefinition | undefined =
        newState.specificationService.addOperatorMessageDefinition(
          action.operatorMessageDefinition
        );
      if (!messageDefinition) {
        return { ...state };
      }

      const actionOperatorMessage: ActionOperatorMessage = {
        ...action.actionOperatorMessage,
        "@messageId": messageDefinition["@id"],
      };
      const step: Step = newState.specificationService.setActionOperatorMessage(
        stepNode.data.step,
        actionOperatorMessage
      );
      stepNode.data = { step: { ...step } };
      if (!action.addNode) {
        return { ...newState };
      }

      const newMessageNode: Node<MessageNodeData> = createMessageNode(
        createNewNodeId(nodes).toString(),
        stepNode,
        actionOperatorMessage,
        messageDefinition
      );
      const newMessageEdge: Edge | undefined = connectWithEdge(
        stepNode,
        newMessageNode
      );

      const newNodes = [...nodes, newMessageNode];
      const newEdges = [...newState.nodesAndEdges[state.selectedState].edges];

      if (newMessageEdge) {
        newEdges.push(newMessageEdge);
      }

      return {
        ...newState,
        nodesAndEdges: {
          ...newState.nodesAndEdges,
          [state.selectedState]: {
            nodes: newNodes,
            edges: newEdges,
          },
        },
      };
    }
    case "update-operator-message-definition": {
      const newState = copyAppState(state);
      newState.specificationService?.updateOperatorMessageDefinition(
        action.messageId,
        action.newValue
      );
      return newState;
    }
    case "remove-operator-message": {
      const newState = copyAppState(state);
      newState.specificationService?.removeActionOperatorMessage(
        action.messageId
      );
      newState.specificationService?.removeOperatorMessageDefinition(
        action.messageId
      );
      return newState;
    }
    case "set-parameters": {
      const newState = copyAppState(state);
      newState.specificationService?.setParameters(action.parameters);
      return newState;
    }
    case "set-exchange": {
      const newState = copyAppState(state);
      newState.specificationService?.setExchange(action.exchange);
      return newState;
    }
    case "set-calculations": {
      const newState = copyAppState(state);
      newState.specificationService?.setCalculations(action.calculations);
      return newState;
    }
    case "set-snapshots": {
      const newState = copyAppState(state);
      newState.specificationService?.setSnapshotDefinitions(action.snapshots);
      return newState;
    }
    case "set-documentation": {
      const newState = copyAppState(state);
      newState.specificationService?.setDocumentationPH(action.documentation);
      return newState;
    }
    case "set-faceplate-elements": {
      const newState = copyAppState(state);
      newState.specificationService?.setFaceplateElements(
        action.faceplateElements
      );
      return newState;
    }
    case "set-operator-message-branching": {
      const newState = copyAppState(state);
      if (
        newState.selectedState === undefined ||
        !newState.specificationService
      ) {
        return { ...state };
      }

      const stepNode: Node<StepNodeData> | undefined = newState.nodesAndEdges[
        newState.selectedState
      ].nodes.find((step: Node<StepNodeData>) => step.id === action.stepNodeId);

      if (!stepNode) {
        return newState;
      }

      const { newStepNodeValue, nodes, edges, caseNodesUpdated } =
        replaceMessageBranchingInStep(
          stepNode,
          action.operatorMessages,
          newState.nodesAndEdges[newState.selectedState].nodes,
          newState.nodesAndEdges[newState.selectedState].edges
        );
      newState.specificationService?.updateStep(newStepNodeValue.data.step);

      let nodesAndEdgesOfSelectedState: { nodes: Node[]; edges: Edge[] } = {
        nodes: [...nodes],
        edges: [...edges],
      };
      for (const caseNodeUpdated of caseNodesUpdated) {
        nodesAndEdgesOfSelectedState = onUpdateCaseNode(
          caseNodeUpdated.id,
          caseNodeUpdated.data,
          nodesAndEdgesOfSelectedState,
          newState.specificationService
        );
      }

      return {
        ...newState,
        nodesAndEdges: {
          ...newState.nodesAndEdges,
          [newState.selectedState]: { ...nodesAndEdgesOfSelectedState },
        },
      };
    }
    case "remove-operator-message-branching": {
      if (state.selectedState === undefined || !state.specificationService) {
        return { ...state };
      }

      const newState = copyAppState(state);
      const stepNode: Node<StepNodeData> | undefined = newState.nodesAndEdges[
        state.selectedState
      ].nodes.find((step) => step.id === action.stepNodeId);
      if (!stepNode || !hasOperatorMessageBranching(stepNode)) {
        return newState;
      }
      const { newStepNodeValue, nodes, edges } = removeMessageBranchingFromStep(
        stepNode,
        newState.nodesAndEdges[state.selectedState].nodes,
        newState.nodesAndEdges[state.selectedState].edges
      );
      newState.specificationService?.updateStep(newStepNodeValue.data.step);

      return {
        ...newState,
        nodesAndEdges: {
          ...newState.nodesAndEdges,
          [state.selectedState]: {
            nodes: nodes,
            edges: edges,
          },
        },
      };
    }
    case "add-branching-node-case": {
      if (state.selectedState === undefined) {
        return { ...state };
      }

      const newState = copyAppState(state);
      const branchingNode: Node<BranchingNodeData> | undefined =
        newState.nodesAndEdges[state.selectedState].nodes.find(
          (node) => node.id === action.branchingNodeId
        );
      if (!branchingNode) {
        return newState;
      }

      const {
        newCaseNode,
        newBranchingNode,
        nodes: updatedNodes,
      } = updateNodesWithBranchingCase(
        branchingNode,
        newState.nodesAndEdges[state.selectedState].nodes,
        action.case
      );

      const stepNode: Node<StepNodeData> | undefined = newState.nodesAndEdges[
        state.selectedState
      ].nodes.find((node) => node.id === branchingNode.data.stepNodeId);

      if (stepNode) {
        const branchingCases = getBranchingCases(
          [...newBranchingNode.data.caseNodeIds, newCaseNode.id],
          updatedNodes
        );
        const newBranchingValue: Branching = {
          "@transitionORStepId": newBranchingNode.data.transitionORStepId,
          case: branchingCases,
        };
        stepNode.data = {
          ...stepNode.data,
          step: {
            ...stepNode.data.step,
            branching: newBranchingValue,
          },
        };
        newState.specificationService?.setStepBranching(
          stepNode.data.step,
          newBranchingValue
        );
      }

      return {
        ...newState,
        nodesAndEdges: {
          ...newState.nodesAndEdges,
          [state.selectedState]: {
            ...newState.nodesAndEdges[state.selectedState],
            nodes: [...updatedNodes],
          },
        },
      };
    }
    case "update-step": {
      const newState = copyAppState(state);
      newState.specificationService?.updateStep(action.newStepValue);
      return newState;
    }
    case "apply-node-changes": {
      if (state.selectedState === undefined) {
        return { ...state };
      }

      const selectedNodes: Node[] =
        state.nodesAndEdges[state.selectedState].nodes;
      const validChanges: NodeChange[] = action.nodeChanges.filter(
        (nodeChange) => {
          if (nodeChange.type === "add" || nodeChange.type === "reset") {
            return true;
          }

          return selectedNodes.some((node) => node.id === nodeChange.id);
        }
      );
      return {
        ...state,
        nodesAndEdges: {
          ...state.nodesAndEdges,
          [state.selectedState]: {
            ...state.nodesAndEdges[state.selectedState],
            nodes: applyNodeChanges(validChanges, selectedNodes),
          },
        },
      };
    }
    case "add-step": {
      const newState = copyAppState(state);
      newState.specificationService?.addStep(action.step);
      return newState;
    }
    case "set-selected-state": {
      const newState: AppState = copyAppState({
        ...state,
        selectedState: action.selectedState,
      });
      if (!newState.specificationService) {
        return newState;
      }

      newState.specificationService.setSelectedState(action.selectedState);
      if (
        !newState.flowchartGenerator ||
        !action.selectedState ||
        action.selectedState in newState.nodesAndEdges
      ) {
        return newState;
      }

      const { nodes, edges }: { nodes: Node[]; edges: Edge[] } =
        newState.flowchartGenerator.generateFlowchart();
      newState.nodesAndEdges[action.selectedState] = {
        nodes: nodes,
        edges: edges,
      };

      return newState;
    }
    case "clear-nodes-and-edges": {
      return {
        ...state,
        nodesAndEdges: {},
      };
    }
    case "set-flowchart-generator": {
      return {
        ...state,
        flowchartGenerator: action.flowchartGenerator,
      };
    }
    case "update-edge": {
      const newState = copyAppState(state);
      if (newState.selectedState === undefined) {
        return newState;
      }

      const selectedStateEdges: Edge[] =
        newState.nodesAndEdges[newState.selectedState].edges;
      const newSelectedStateEdges: Edge[] = selectedStateEdges.filter(
        (edge: Edge) => edge.id !== action.newEdge.id
      );
      newSelectedStateEdges.push(action.newEdge);
      newState.nodesAndEdges[newState.selectedState] = {
        nodes: [...newState.nodesAndEdges[newState.selectedState].nodes],
        edges: [...newSelectedStateEdges],
      };
      return newState;
    }
    case "update-case-node": {
      const newState = copyAppState(state);
      if (
        newState.selectedState === undefined ||
        !newState.specificationService
      ) {
        return newState;
      }

      const newNodesAndEdgesOfSelectedState: { nodes: Node[]; edges: Edge[] } =
        onUpdateCaseNode(
          action.nodeId,
          action.data,
          newState.nodesAndEdges[newState.selectedState],
          newState.specificationService
        );
      return {
        ...newState,
        nodesAndEdges: {
          ...newState.nodesAndEdges,
          [newState.selectedState]: { ...newNodesAndEdgesOfSelectedState },
        },
      };
    }
    default:
      return { ...state };
  }
}
