import { Handle, NodeProps, Position } from "reactflow";
import { NodeData } from "../../../types";
import { SuiIcon } from "../../../../SuiIcon";
import cogs from "@umetrics/sartorius-ui-icon/dist/icons/settings";
import "./StartNode.css";
import { useContext, MouseEvent } from "react";
import { ModalContext } from "../../../../../state/Context";
import TransitionConditionsModal from "../../../../TransitionConditions/TransitionConditionsModal";
import { HandleTypes } from "../../../utils/types";

function StartNode(props: NodeProps<NodeData>) {
  const { dispatch } = useContext(ModalContext);
  const onClick = (_: MouseEvent<HTMLDivElement>) => {
    dispatch({
      type: "show",
      content: <TransitionConditionsModal />,
    });
  };

  return (
    <div className="start-node">
      <label className="start-node-label">{props.data.label}</label>
      <div className="start-node-button-container">
        <SuiIcon
          icon={cogs}
          slot="icon-slot"
          onClick={onClick}
          style={{ cursor: "pointer" }}
          title="Transition Conditions"
        />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id={HandleTypes.startSource}
      />
    </div>
  );
}

export default StartNode;
