import { MutableRefObject, ReactElement } from "react";
import { SuiButton } from "@components/SuiButton";
import { ButtonProperties } from "@umetrics/sartorius-ui-button";
import useFormSubmit from "../hooks/useFormSubmit";

/**
 * Defines the properties for the {@link FormSuiButtonProps}.
 */
interface FormSuiButtonProps
  extends Omit<ButtonProperties, "buttonType" | "onClick"> {
  formRef: MutableRefObject<HTMLFormElement | null>;
  label: string;
}

/**
 * The {@link SuiButton} to use when submitting a form using a button which sits OUTSIDE of the form component it is submitting.
 * @param props the properties to inject into the component
 * @returns {ReactElement<FormSuiButtonProps>}
 */
const FormSuiButton = ({
  formRef,
  label,
  ...buttonProps
}: FormSuiButtonProps): ReactElement<FormSuiButtonProps> => {
  const { dispatchSubmitEvent } = useFormSubmit();
  return (
    <SuiButton
      type="primary"
      onClick={() => dispatchSubmitEvent(formRef)}
      {...buttonProps}
    >
      {label}
    </SuiButton>
  );
};

export default FormSuiButton;
