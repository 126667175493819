import { isEmptyOrWhitespace } from "../../utils/stringUtils";
import { SuiTextField } from "../SuiTextField";
import { RegexpInputProps } from "./InputProps";
import {
  ansiRegexCharacters,
  ansiRegexValidationMessage,
  getRegexpFromCharacters,
  validateRegexInput,
} from "./inputUtils";

const regexp = `${getRegexpFromCharacters(ansiRegexCharacters)}|^(?!\\s\\S)$`; // Matches all ANSI characters or empty strings

/**
 * @deprecated use {@link ../FormComponents/Inputs/Basic/AnsiTextField} instead with the newer form API.
 */
const AnsiCharInput = (props: RegexpInputProps) => {
  const { onChangeCallback, required, maxLength, ...remainingProps } = {
    ...props,
  };

  const onInput = ({ detail: { value } }: any) => {
    let valid = validateRegexInput(value, regexp);
    if (required && isEmptyOrWhitespace(value)) {
      valid = false;
    }

    onChangeCallback?.(value, valid);
  };

  return (
    <SuiTextField
      {...remainingProps}
      onInput={onInput}
      required={required}
      regexp={regexp}
      maxLength={maxLength}
    >
      <span slot="validation-empty">This is a mandatory field!</span>
      <span slot="validation-custom">
        {ansiRegexValidationMessage}
        {maxLength ? ` and up to ${maxLength} characters!` : "!"}
      </span>
    </SuiTextField>
  );
};

export default AnsiCharInput;
