import { ReactElement } from "react";
import { SubState } from "Asset_Specification";
import {
  AccordionInput,
  ActionRefreshParameterInput,
  FormGrid,
} from "@form-components";
import { Path, UseFormGetValues } from "react-hook-form";
import { defaultActionRefreshParameter } from "@utils/actionRefreshParameterUtils";

export const actionRefreshParameterName: Path<SubState> =
  "actionRefreshParameter";

const ActionRefreshParameterTabContent = (): ReactElement => {
  return (
    <FormGrid>
      <AccordionInput<SubState, "actionRefreshParameter">
        heading="Action Refresh Parameters"
        name={actionRefreshParameterName}
        defaultValue={{ ...defaultActionRefreshParameter }}
        getItemLabel={(index: number, getValues: UseFormGetValues<SubState>) =>
          getValues(`${actionRefreshParameterName}.${index}.@parameterId`) ?? ""
        }
        itemRenderer={({ itemKey, name }) => (
          <ActionRefreshParameterInput key={itemKey} name={name} />
        )}
      />
    </FormGrid>
  );
};

export default ActionRefreshParameterTabContent;
