import { FormTab } from "@form-components";
import LabelInput from "../inputs/LabelInput";
import { SnapShotDefinition } from "Asset_Specification";
import { Path } from "react-hook-form";

const labelNames: Path<SnapShotDefinition>[] = ["label", "label.0", "label.1"];

const LabelTab = () => {
  return (
    <FormTab heading="Labels" names={labelNames}>
      <LabelInput />
    </FormTab>
  );
};

export default LabelTab;
