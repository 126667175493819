import { SubState } from "Asset_Specification";
import { defaultSetParameter } from "@utils/setParameterUtils";
import { AccordionInput, FormGrid, SetParameterInput } from "@form-components";
import { ReactElement } from "react";
import { Path, UseFormGetValues } from "react-hook-form";

export const actionSetParameterName: Path<SubState> = "actionSetParameter";

const ActionSetParametersTabContent = (): ReactElement => {
  return (
    <FormGrid>
      <AccordionInput<SubState, "actionSetParameter">
        heading="Action Set Parameters"
        name={actionSetParameterName}
        defaultValue={{ ...defaultSetParameter }}
        getItemLabel={(index: number, getValues: UseFormGetValues<SubState>) =>
          getValues(`${actionSetParameterName}.${index}.@parameter`) ?? ""
        }
        itemRenderer={({ itemKey, name }) => (
          <SetParameterInput key={itemKey} name={name} />
        )}
      />
    </FormGrid>
  );
};

export default ActionSetParametersTabContent;
