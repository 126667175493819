import { FormatTemplate } from "../FormatTemplate";
import actionRefreshParameterTemplate from "./actionRefreshParameterTemplate";
import conditionTemplate from "./conditionTemplate";
import setParameterBypassTemplate from "./setParameterBypassTemplate";

const caseTemplate: FormatTemplate = {
  condition: {
    type: "object",
    canBeUndefined: false,
    propertyTemplate: conditionTemplate,
  },
  setAssetParameter: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: setParameterBypassTemplate,
  },
  refreshParameter: {
    type: "array",
    canBeUndefined: true,
    propertyTemplate: actionRefreshParameterTemplate,
  },
  "@subStateId": {
    type: "string",
    canBeUndefined: false,
  },
};

export default caseTemplate;
