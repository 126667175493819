import {
  DescriptionValueInput,
  FormGrid,
  FormTab,
  descriptionValueInputNames,
} from "@form-components";

interface DescriptionValueTabProps {
  name: string;
  heading: string;
}

const DescriptionValueTab = ({ name, heading }: DescriptionValueTabProps) => {
  return (
    <FormTab
      heading={heading}
      names={[
        name,
        ...descriptionValueInputNames.map(
          (descValueName) => `${name}.${descValueName}`
        ),
      ]}
    >
      <FormGrid>
        <DescriptionValueInput name={name} hideLabel />
      </FormGrid>
    </FormTab>
  );
};

export default DescriptionValueTab;
