import { ReactElement } from "react";
import "./EditFrom.css";

interface EditFormProps {
  children: ReactElement | ReactElement[];
}

/**
 * @deprecated use the Form component from form-components
 */
const EditForm = (props: EditFormProps) => {
  const { children } = { ...props };
  return <div className="edit-form">{children}</div>;
};

export default EditForm;
