import { Node, Connection, Edge } from "reactflow";
import { updateStepCasesForBranchingNode } from "./utils";
import { HandleTypes } from "./types";
import { AppReducerAction } from "../../../state/reducers/appReducer";
import {
  BranchingNodeData,
  CaseNodeData,
  NodeType,
  StepNodeData,
} from "../types";
import { getBranchingCases } from "../../../utils/branchingUtils";
import {
  isStepNode,
  isStateLinkNode,
  isCaseNode,
} from "../components/nodes/nodeUtils";

export const isValidStepBranchingConnection = (
  connection: Connection | Edge
): boolean =>
  connection.sourceHandle === HandleTypes.stepSource &&
  connection.targetHandle === HandleTypes.branchingTarget &&
  connection.source !== undefined &&
  connection.target !== undefined;

export const updateStepBranching = (
  connection: Connection | Edge,
  allNodes: Node[],
  dispatch: (action: AppReducerAction) => void
) => {
  const nodes = allNodes.map((node) => ({ ...node }));
  const branchingNode: Node<BranchingNodeData> | undefined = nodes.find(
    (node) => node.id === connection.target
  );
  const stepNode: Node<StepNodeData> | undefined = nodes.find(
    (node) => node.id === connection.source
  );

  if (!branchingNode || !stepNode) {
    return;
  }

  const previousBranchingNodeConnectedToStep = nodes.find(
    (node) =>
      node.type === NodeType.branching && node.data.stepNodeId === stepNode.id
  );
  if (previousBranchingNodeConnectedToStep) {
    previousBranchingNodeConnectedToStep.data = {
      ...previousBranchingNodeConnectedToStep.data,
      stepNodeId: undefined,
    };
  }

  const previousStepNodeConnectedToBranching = nodes.find(
    (node) => node.id === branchingNode.data.stepNodeId
  );
  if (previousStepNodeConnectedToBranching) {
    previousStepNodeConnectedToBranching.data = {
      ...previousStepNodeConnectedToBranching.data,
      step: {
        ...previousStepNodeConnectedToBranching.data.step,
        branching: undefined,
      },
    };

    dispatch({
      type: "update-step",
      newStepValue: previousStepNodeConnectedToBranching.data.step,
    });
  }

  branchingNode.data = {
    ...branchingNode.data,
    stepNodeId: stepNode.id,
  };

  const { updatedStepNode, updatedNodes } = updateStepCasesForBranchingNode(
    branchingNode,
    nodes
  );
  if (updatedStepNode) {
    dispatch({ type: "update-step", newStepValue: updatedStepNode.data.step });
  }

  dispatch({
    type: "set-nodes",
    nodes: updatedNodes,
  });
};

export const isValidBranchingCaseConnection = (
  connection: Connection | Edge
): boolean =>
  connection.sourceHandle === HandleTypes.branchingCaseSource &&
  (connection.targetHandle === HandleTypes.stepTarget ||
    connection.targetHandle === HandleTypes.stateLinkTarget) &&
  connection.source !== undefined &&
  connection.target !== undefined;

export const updateBranchingCase = (
  oldEdge: Edge | undefined,
  connection: Connection | Edge,
  allNodes: Node[],
  dispatch: (value: AppReducerAction) => void
): void => {
  const nodes = allNodes.map((node) => ({ ...node }));
  const {
    caseNodeUpdated: oldCaseNodeUpdated,
    stepNodeIdToUpdate: stepNodeIdToUpdateFromOldCaseNodeConnection,
  } = updateOldConnectedCaseNode(oldEdge, nodes);
  const {
    caseNodeUpdated: newCaseNodeUpdated,
    stepNodeIdToUpdate: stepNodeIdToUpdateFromNewCaseNodeConnection,
  } = updateNewConnectedCaseNode(connection, nodes);
  if (!oldCaseNodeUpdated && !newCaseNodeUpdated) {
    return;
  }

  dispatch({ type: "set-nodes", nodes });

  if (stepNodeIdToUpdateFromOldCaseNodeConnection) {
    updateStep(stepNodeIdToUpdateFromOldCaseNodeConnection, nodes, dispatch);
  }

  if (
    stepNodeIdToUpdateFromNewCaseNodeConnection &&
    stepNodeIdToUpdateFromNewCaseNodeConnection !==
      stepNodeIdToUpdateFromOldCaseNodeConnection
  ) {
    updateStep(stepNodeIdToUpdateFromNewCaseNodeConnection, nodes, dispatch);
  }
};

const updateStep = (
  stepNodeId: string,
  nodes: Node[],
  dispatch: (value: AppReducerAction) => void
): void => {
  const stepNode: Node<StepNodeData> | undefined = nodes.find(
    (node: Node) => isStepNode(node) && node.id === stepNodeId
  );
  if (stepNode) {
    dispatch({
      type: "update-step",
      newStepValue: stepNode.data.step,
    });
  }
};

const updateOldConnectedCaseNode = (
  oldEdge: Edge | undefined,
  nodes: Node[]
): { caseNodeUpdated: boolean; stepNodeIdToUpdate: string | undefined } => {
  if (!oldEdge) {
    return { caseNodeUpdated: false, stepNodeIdToUpdate: undefined };
  }

  return updateConnectedCaseNode(oldEdge, nodes, "");
};

const updateNewConnectedCaseNode = (
  connection: Connection | Edge,
  nodes: Node[]
): { caseNodeUpdated: boolean; stepNodeIdToUpdate: string | undefined } => {
  const connectedSubstateId: string | undefined = getConnectedSubstateId(
    connection.target,
    nodes
  );
  if (connectedSubstateId === undefined) {
    return { caseNodeUpdated: false, stepNodeIdToUpdate: undefined };
  }

  return updateConnectedCaseNode(connection, nodes, connectedSubstateId);
};

const updateConnectedCaseNode = (
  connection: Connection | Edge,
  nodes: Node[],
  newSubstateId: string
): { caseNodeUpdated: boolean; stepNodeIdToUpdate: string | undefined } => {
  const caseNode: Node<CaseNodeData> | undefined = nodes.find(
    (node) => isCaseNode(node) && node.id === connection.source
  );
  if (!caseNode) {
    return { caseNodeUpdated: false, stepNodeIdToUpdate: undefined };
  }

  caseNode.data = {
    ...caseNode.data,
    case: {
      ...caseNode.data.case,
      "@subStateId": newSubstateId,
    },
  };

  const branchingNode: Node<BranchingNodeData> | undefined = nodes.find(
    (node) => node.id === caseNode?.data.branchingNodeId
  );
  const branchingStepNode: Node<StepNodeData> | undefined = nodes.find(
    (node) => node.id === branchingNode?.data.stepNodeId
  );

  if (!branchingNode || !branchingStepNode?.data.step.branching) {
    return { caseNodeUpdated: true, stepNodeIdToUpdate: undefined };
  }

  branchingStepNode.data.step.branching.case = getBranchingCases(
    branchingNode.data.caseNodeIds,
    nodes
  );
  return { caseNodeUpdated: true, stepNodeIdToUpdate: branchingStepNode.id };
};

const getConnectedSubstateId = (
  target: string | null,
  nodes: Node[]
): string | undefined => {
  const connectedNode: Node | undefined = nodes.find(
    (node: Node) => node.id === target
  );
  if (!connectedNode) {
    return undefined;
  }

  if (isStepNode(connectedNode)) {
    return connectedNode.data.step["@id"];
  }

  if (isStateLinkNode(connectedNode)) {
    return connectedNode.data.substateId;
  }

  return undefined;
};
