import {
  MultiLabelInput,
  OptionalInput,
  defaultMultiLangValue,
} from "@form-components";
import { SnapShotDefinition } from "Asset_Specification";
import { useFormContext } from "react-hook-form";

const multiLangLabelName = "label";

export const LabelInput = () => {
  const { watch } = useFormContext();
  const langLabelValue = watch(multiLangLabelName);

  return (
    <OptionalInput<SnapShotDefinition>
      name={multiLangLabelName}
      defaultValue={defaultMultiLangValue}
      checked={langLabelValue !== undefined}
      checkboxProps={{ label: "Has label?" }}
    >
      <MultiLabelInput name={multiLangLabelName} required />
    </OptionalInput>
  );
};

export default LabelInput;
