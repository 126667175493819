import {
  X2jOptions,
  XMLBuilder,
  XMLParser,
  XmlBuilderOptions,
} from "fast-xml-parser";
import {
  ActionOperatorMessage,
  AssetSpecification,
  Branching,
  CalculationDefinition,
  Calculations,
  DocumentationPH,
  EquipmentPhase,
  ExchangeAsset,
  ExchangeAssetSubtype,
  FaceplateElement,
  HoldConditions,
  Isa88State,
  OperatorMessageDefinition,
  Parameter,
  ParameterList,
  SnapShotDefinition,
  SnapShots,
  StartConditions,
  SubState,
  SuspendConditions,
} from "../Asset_Specification";
import { toArray } from "../utils";
import {
  ConditionType,
  EPHInfo,
  Step,
  TransitionCondition,
  lang,
} from "../types";
import transitionConditionTypeFactory from "./TransitionConditionFactory";
import {
  AssetConditionsToTransitionConditions,
  TransitionConditionsToAssetConditions,
} from "./TransitionConditionsService";
import { IAssetFormatterService } from "./AssetFormatterService/IAssetFormatterService";
import { swapKeyValues } from "../utils/objectUtils";
import ISpecificationService from "./interfaces/ISpecificationService";

const mapForCharactersToEscape = {
  "&": "&amp;", // THIS NEEDS TO BE FIRST IN PARSING ORDER!!!
  '"': "&quot;",
  "'": "&apos;",
  "<": "&lt;",
  ">": "&gt;",
};

const mapForCharactersToUnescape = swapKeyValues(mapForCharactersToEscape);

const replaceCharacters = (
  value: string,
  map: { [key: string]: any }
): string => {
  let newValue = value;
  Object.entries(map).forEach(([characterToUnescape, unescapedCharacter]) => {
    newValue = newValue.replaceAll(characterToUnescape, unescapedCharacter);
  });
  return newValue;
};

const maybeReplaceCharacters = (
  value: unknown,
  map: { [key: string]: any }
): string => {
  if (typeof value === "string") {
    return replaceCharacters(value, map);
  }

  return value as string;
};

const readOptions: Partial<X2jOptions> = {
  processEntities: false,
  ignoreAttributes: false,
  attributeNamePrefix: "@",
  allowBooleanAttributes: true,
  numberParseOptions: {
    leadingZeros: true,
    hex: false,
  },
  tagValueProcessor: (
    _tagName: string,
    tagValue: string,
    _jPath: string,
    _hasAttributes: boolean,
    _isLeafNode: boolean
  ) => replaceCharacters(tagValue, mapForCharactersToUnescape),
  attributeValueProcessor: (
    _attrName: string,
    attrValue: string,
    _jPath: string
  ) => replaceCharacters(attrValue, mapForCharactersToUnescape),
};

const writeOptions: Partial<XmlBuilderOptions> = {
  processEntities: false,
  format: true,
  ignoreAttributes: false,
  suppressBooleanAttributes: false,
  suppressEmptyNode: true,
  attributeNamePrefix: "@",
  tagValueProcessor: (_name: string, value: unknown) =>
    maybeReplaceCharacters(value, mapForCharactersToEscape),
  attributeValueProcessor: (_name: string, value: unknown) =>
    maybeReplaceCharacters(value, mapForCharactersToEscape),
};

export class SpecificationService implements ISpecificationService {
  private specification: AssetSpecification;

  public get Specification(): AssetSpecification {
    return this.specification;
  }

  private selectedIsaState: Isa88State | undefined;
  private selectedStateSteps: Step[] | undefined;
  public get SelectedState(): Isa88State | undefined {
    return this.selectedIsaState;
  }

  constructor(specification: AssetSpecification, selectedState?: string) {
    this.specification = specification;
    if (selectedState) {
      this.setSelectedState(selectedState);
    }
  }

  public getAssetType(): string {
    return this.specification.equipmentPhase?.["@typeIdentifier"] ?? "";
  }

  public getAssetName(): string {
    return this.specification.equipmentPhase?.["@name"] ?? "";
  }

  ///////////////////////// Steps and states ////////////////////////////////////////////

  public addStep(step: Step): Step | undefined {
    if (this.selectedIsaState === undefined) {
      alert('Please select a state to add "Step"');
      return;
    }

    if (this.selectedStateSteps === undefined) {
      this.selectedStateSteps = [];
    }

    // Because of strict mode, sometimes this function gets called twice and adds the step twice when it should just be the once
    if (this.stepAlreadyAdded(step)) {
      return step;
    }

    const newStep = {
      ...step,
      stepId: this.selectedStateSteps.length.toString(),
    };

    this.selectedStateSteps.push(newStep);
    this.updateSelectedState();
    return newStep;
  }

  public createStep(data?: {
    id?: string;
    name?: string;
    description?: string;
  }): Step {
    const newId: string = data?.id ?? this.generateSubStateId();
    if (data?.id) {
      this.assertSubstateIdIsUnique(newId);
    }

    return {
      "@id": newId,
      "@name": data?.name ?? "",
      "@value": newId.slice(1),
      "@description": data?.description ?? "",
      stepId: this.selectedStateSteps?.length.toString() ?? "",
    };
  }

  private assertSubstateIdIsUnique = (id: string): void => {
    const allIds: string[] = toArray(
      this.specification.equipmentPhase?.actionTable.isa88State
    )
      .map((isa88State: Isa88State) =>
        toArray(isa88State.subState).map(
          (substate: SubState) => substate["@id"]
        )
      )
      .flat();
    if (allIds.includes(id)) {
      throw new Error(`The id provided '${id}' is not unique!`);
    }
  };

  public updateStep(step: Step): void {
    const { stepId, ...substate } = step;

    if (!this.selectedStateSteps) {
      alert("Update error. No substates in selected State");
      return;
    }
    const indexOfSubstateToUpdate = this.selectedStateSteps.findIndex(
      (step) => step.stepId === stepId
    );

    if (indexOfSubstateToUpdate < 0) {
      return;
    }

    this.selectedStateSteps[indexOfSubstateToUpdate] = {
      ...this.selectedStateSteps[indexOfSubstateToUpdate],
      ...substate,
    };

    this.updateSelectedState();
  }

  public removeStep(step: Step): void {
    this.selectedStateSteps = this.selectedStateSteps?.filter(
      (s) => s.stepId !== step.stepId
    );
    this.updateSelectedState();
  }

  public setSelectedState(stateName: string | undefined): void {
    if (!stateName) {
      this.selectedIsaState = undefined;
      this.selectedStateSteps = undefined;
      return;
    }

    const isaStates = toArray(
      this.specification.equipmentPhase?.actionTable?.isa88State
    );
    this.selectedIsaState = isaStates.find(
      (isaState) => isaState["@name"] === stateName
    );

    this.selectedStateSteps = toArray(this.selectedIsaState?.subState).map(
      (subState, index) => {
        return {
          stepId: index.toString(),
          ...subState,
        };
      }
    );
  }

  /**
   * Generates a new id for a substate based on the max value of a substate in the specification.
   */
  private generateSubStateId(): string {
    const defaultSubstateId: number = 1;
    const allSubstates: SubState[] = toArray(
      this.specification.equipmentPhase?.actionTable.isa88State
    )
      .map((isa88State: Isa88State) => toArray(isa88State.subState))
      .flat();
    const ids: number[] = allSubstates.map((state) => Number(state["@value"]));
    const maxId: number = Math.max(...ids);
    const newId: number = isFinite(maxId) ? maxId + 1 : defaultSubstateId;
    return `S${newId.toString().padStart(3, "0")}`;
  }

  public isStateSelected(): boolean {
    return this.SelectedState !== undefined;
  }

  public getSelectedStateSteps(): Step[] {
    return this.selectedStateSteps ?? [];
  }

  public getStateNames(): string[] {
    return toArray(this.specification.equipmentPhase?.actionTable.isa88State)
      .map((isa88State: Isa88State) => isa88State["@name"] ?? "")
      .filter((name: string) => name);
  }

  public getSelectedStateName(): string | undefined {
    return this.selectedIsaState?.["@name"];
  }

  public getSubstateIdsForState(state: string): string[] {
    const isa88State: Isa88State | undefined = toArray(
      this.specification.equipmentPhase?.actionTable.isa88State
    ).find((isa88State: Isa88State) => isa88State["@name"] === state);
    return toArray(isa88State?.subState).map(
      (substate: SubState) => substate["@id"]
    );
  }

  public getStateOfSubstate(substateId: string): string | undefined {
    const isa88States: Isa88State[] = toArray(
      this.specification.equipmentPhase?.actionTable.isa88State
    );
    for (const isa88State of isa88States) {
      const subState: SubState | undefined = toArray(isa88State.subState).find(
        (substate: SubState) => substate["@id"] === substateId
      );
      if (subState) {
        return isa88State["@name"];
      }
    }

    return undefined;
  }

  ///////////////////////////////////////////////////////////////////////////////////////

  public updateEphInfo(ephInfo: EPHInfo): void {
    if (!this.specification.equipmentPhase) {
      alert("There is no equipment phase in this specification!");
      return;
    }

    this.specification.equipmentPhase["@name"] = ephInfo.name;
    this.specification.equipmentPhase["@subtype"] = ephInfo.subtype;
    this.specification.equipmentPhase.displayInformation = {
      "@name": ephInfo.name,
      "@description": ephInfo.description,
    };
  }

  ///////////////////////// Operator Messages ///////////////////////////////////////////

  public getOperatorMessageDefinitions(): OperatorMessageDefinition[] {
    return toArray(
      this.specification.equipmentPhase?.operatorMessages
        ?.operatorMessageDefinition
    );
  }

  public addOperatorMessageDefinition(
    message: OperatorMessageDefinition
  ): OperatorMessageDefinition | undefined {
    if (!this.specification.equipmentPhase) {
      alert(
        "There is no equipmentPhase in the current asset. Please import valid specification or create new one."
      );
      return;
    }

    if (!this.specification.equipmentPhase.operatorMessages) {
      this.specification.equipmentPhase.operatorMessages = {};
    }

    const messageDefinitions = toArray(
      this.specification.equipmentPhase.operatorMessages
        .operatorMessageDefinition
    );
    const newMessage = {
      ...message,
    };

    if (!newMessage["@id"]) {
      newMessage["@id"] = (
        (messageDefinitions.length > 0
          ? Math.max(
              ...messageDefinitions.map((def) => {
                const numId = Number(def["@id"]);
                return isNaN(numId) ? 0 : numId;
              })
            )
          : 0) + 1
      ).toString();
    }

    messageDefinitions.push(newMessage);
    this.specification.equipmentPhase.operatorMessages.operatorMessageDefinition =
      messageDefinitions;
    return newMessage;
  }

  public getEmptyOperatorMessageDefinition(): OperatorMessageDefinition {
    return {
      "@id": "",
      "@buttonSet": "Empty",
      "@messageNumber": "",
      "@messageType": "PlainView",
      "@name": "",
      "@presetValues": "",
      "@viewValues": "",
      label: [
        { "@lang": lang.eng, "@value": "" },
        { "@lang": lang.ger, "@value": "" },
      ],
    };
  }

  public addEmptyOperatorMessageDefinition():
    | OperatorMessageDefinition
    | undefined {
    const newMessage: OperatorMessageDefinition =
      this.getEmptyOperatorMessageDefinition();
    return this.addOperatorMessageDefinition(newMessage);
  }

  /** Update OperatorMessageDefinition with messageId using newValue data - method allows to update ID  */
  public updateOperatorMessageDefinition(
    messageId: string,
    newValue: OperatorMessageDefinition
  ): void {
    const messageDefinitions =
      this.specification.equipmentPhase?.operatorMessages
        ?.operatorMessageDefinition;

    if (!messageDefinitions) {
      alert(
        "UpdateOperatorMessageDefinition: There is no OperatorMessageDefinition with provided id!"
      );
      return;
    }

    if (Array.isArray(messageDefinitions)) {
      const definitionIndex = messageDefinitions.findIndex(
        (message) => message["@id"] === messageId
      );

      if (definitionIndex === -1) {
        alert(
          "UpdateOperatorMessageDefinition: There is no OperatorMessageDefinition with provided id!"
        );
        return;
      }

      messageDefinitions[definitionIndex] = {
        ...newValue,
      };
    } else {
      this.specification.equipmentPhase!.operatorMessages!.operatorMessageDefinition =
        [newValue];
    }
  }

  /** Remove ActionOperatorMessage with provided messageId from all steps of selectedIsaState.
   * One of the Isa88States must be selected (via SetSelectedState method or constructor) before running this method
   */
  public removeActionOperatorMessage(messageId: string): void {
    if (!this.selectedIsaState) {
      alert("RemoveActionOperatorMessage: There is no selected State!");
      return;
    }
    this.selectedStateSteps?.forEach((subState) => {
      if (subState.actionOperatorMessage?.["@messageId"] === messageId) {
        subState.actionOperatorMessage = undefined;
      }
    });
    this.updateSelectedState();
  }

  public removeOperatorMessageDefinition(messageId: string): void {
    if (!this.specification.equipmentPhase?.operatorMessages) {
      alert(
        "RemoveOperatorMessageDefinition: There is no operatorMessages in imported Specification!"
      );
      return;
    }
    const messageDefinitions = toArray(
      this.specification.equipmentPhase.operatorMessages
        ?.operatorMessageDefinition
    ).filter((messageDefinition) => messageDefinition["@id"] !== messageId);

    this.specification.equipmentPhase.operatorMessages.operatorMessageDefinition =
      messageDefinitions;
  }

  public setActionOperatorMessage(
    step: Step,
    message: ActionOperatorMessage
  ): Step {
    const newStepValue = { ...step };
    newStepValue.actionOperatorMessage = {
      ...message,
    };

    this.updateStep(newStepValue);
    return newStepValue;
  }

  public getStepWithOperatorMessageId(messageId: string): Step | undefined {
    if (!this.selectedIsaState) {
      alert("GetStepWithOperatorMessageId: There is no selected State!");
      return;
    }

    const substates: SubState[] = toArray(this.selectedIsaState.subState);
    const substateIndex: number = substates.findIndex(
      (subState) => subState.actionOperatorMessage?.["@messageId"] === messageId
    );
    if (substateIndex < 0) {
      alert(
        `GetStepWithOperatorMessageId: No substate was found with the message id '${messageId}'!`
      );
      return;
    }

    return {
      stepId: substateIndex.toString(),
      ...substates[substateIndex],
    };
  }

  ///////////////////////// Operator Messages ///////////////////////////////////////////

  ///////////////////////// Transition Conditions ///////////////////////////////////////

  /**
   * Get transition conditions of the provided Isa88State
   */
  public getTransitionConditions(state: Isa88State): {
    [type: string]: TransitionCondition[];
  } {
    const selectedState = state["@name"];

    if (!selectedState) {
      return {};
    }

    const conditionTypes: { [type: string]: TransitionCondition[] } = {};
    transitionConditionTypeFactory(selectedState).forEach((type) => {
      const cons = this.conditionsSelector(
        type,
        this.specification?.equipmentPhase
      );
      if (!cons) {
        conditionTypes[type] = [];
      } else {
        conditionTypes[type] = AssetConditionsToTransitionConditions(cons);
      }
    });

    return conditionTypes;
  }

  /**
   * Updates the transition condtions in asset.
   * Not provided types will be unaffected by the update.
   */
  public updateTransitionConditions(transitionConditions: {
    [type: string]: TransitionCondition[];
  }): void {
    if (!this.specification?.equipmentPhase) {
      alert(
        "UpdateTransitionConditions: There is no equipment phase in this specification!"
      );
      return;
    }

    const startConditions = transitionConditions["START"];
    if (startConditions) {
      const assetCons = TransitionConditionsToAssetConditions(
        startConditions,
        "START"
      ) as StartConditions;
      this.specification.equipmentPhase.startConditions = assetCons;
    }

    const holdConditions = transitionConditions["HOLD"];
    if (holdConditions) {
      const assetCons = TransitionConditionsToAssetConditions(
        holdConditions,
        "HOLD"
      ) as HoldConditions;
      this.specification.equipmentPhase.holdConditions = assetCons;
    }

    const suspendConditions = transitionConditions["SUSPEND"];
    if (suspendConditions) {
      const assetCons = TransitionConditionsToAssetConditions(
        suspendConditions,
        "SUSPEND"
      ) as SuspendConditions;
      this.specification.equipmentPhase.suspendConditions = assetCons;
    }
  }

  ///////////////////////// Transition Conditions ///////////////////////////////////////

  ///////////////////////// Parameters ///////////////////////////////////////

  public getParameters(): { [type: string]: Parameter } {
    const parameterArray: Parameter[] = toArray(
      this.specification.equipmentPhase?.parameterList?.parameter
    );
    return Object.fromEntries(
      parameterArray.map((parameter) => [parameter["@id"], parameter])
    );
  }

  public setParameters(parameters: Parameter[]): void {
    if (!this.specification.equipmentPhase) {
      alert(
        "SetParameters: There is no equipment phase in this specification!"
      );
      return;
    }

    if (parameters.length < 1) {
      this.specification.equipmentPhase.parameterList = {};
      return;
    }

    this.specification.equipmentPhase.parameterList = {
      parameter: parameters.length > 1 ? parameters : parameters[0],
    } as ParameterList;
  }

  ///////////////////////// Parameters ///////////////////////////////////////

  ///////////////////////// Exchange Asset ///////////////////////////////////////////

  public getExchangeAssets(subtype?: ExchangeAssetSubtype): ExchangeAsset[] {
    const exchangeAssets: ExchangeAsset[] = toArray(
      this.specification.equipmentPhase?.exchange?.asset
    );
    return !subtype
      ? exchangeAssets
      : exchangeAssets.filter(
          (exchangeAsset) => exchangeAsset["@subtype"] === subtype
        );
  }

  public setExchange(exchange: ExchangeAsset[]): void {
    if (!this.specification.equipmentPhase) {
      alert("setExchange: There is no equipment phase in this specification!");
      return;
    }

    if (exchange.length < 1) {
      this.specification.equipmentPhase.exchange = {};
      return;
    }

    this.specification.equipmentPhase.exchange = { asset: exchange };
  }

  ///////////////////////// Exchange Asset ///////////////////////////////////////////

  ///////////////////////// Calculations ///////////////////////////////////////

  public getCalculations(): CalculationDefinition[] {
    return toArray(
      this.specification.equipmentPhase?.calculations?.calculationDefinition
    );
  }

  public setCalculations(calculations: CalculationDefinition[]): void {
    if (!this.specification.equipmentPhase) {
      alert(
        "SetCalculations: There is no equipment phase in this specification!"
      );
      return;
    }

    if (calculations.length < 1) {
      this.specification.equipmentPhase.calculations = undefined;
      return;
    }

    this.specification.equipmentPhase.calculations = {
      calculationDefinition:
        calculations.length > 1 ? calculations : calculations[0],
    } as Calculations;
  }

  ///////////////////////// Calculations ///////////////////////////////////////

  ///////////////////////// SnapshotDefinitions ///////////////////////////////////////

  public getSnapshotDefinitions(): SnapShotDefinition[] {
    return toArray(
      this.Specification.equipmentPhase?.snapShots?.snapShotDefinition
    );
  }

  public setSnapshotDefinitions(snapshots: SnapShotDefinition[]): void {
    if (!this.Specification.equipmentPhase) {
      alert(
        "SetSnapshotDefinitions: There is no equipment phase in this specification!"
      );
      return;
    }

    if (snapshots.length < 1) {
      this.Specification.equipmentPhase.snapShots = undefined;
      return;
    }

    this.Specification.equipmentPhase.snapShots = {
      snapShotDefinition: snapshots.length > 1 ? snapshots : snapshots[0],
    } as SnapShots;
  }

  ///////////////////////// SnapshotDefinitions ///////////////////////////////////////

  ///////////////////////// DocumentationPH ///////////////////////////////////////

  public getDocumentationPH(): DocumentationPH | undefined {
    if (!this.specification.equipmentPhase) {
      alert(
        "GetDocumentationPH: There is no equipment phase in this specification!"
      );
    }

    return this.specification.equipmentPhase?.documentation;
  }

  public setDocumentationPH(documentation: DocumentationPH): void {
    if (!this.specification.equipmentPhase) {
      alert(
        "SetDocumentationPH: There is no equipment phase in this specification!"
      );
      return;
    }

    this.specification.equipmentPhase.documentation = documentation;
  }

  ///////////////////////// DocumentationPH ///////////////////////////////////////

  ///////////////////////// DialogContent ///////////////////////////////////////

  public getFaceplateElements(): FaceplateElement[] {
    return toArray(
      this.Specification.equipmentPhase?.dialogContent?.faceplateElement
    );
  }

  public setFaceplateElements(faceplateElements: FaceplateElement[]) {
    if (!this.Specification.equipmentPhase) {
      alert(
        "SetFaceplateElements: There is no equipment phase in this specification!"
      );
      return;
    }

    if (!this.Specification.equipmentPhase.dialogContent) {
      this.Specification.equipmentPhase.dialogContent = {};
    }

    this.Specification.equipmentPhase.dialogContent.faceplateElement =
      faceplateElements.length > 0 ? faceplateElements : undefined;
  }

  ///////////////////////// DialogContent ///////////////////////////////////////

  ///////////////////////// Step branching //////////////////////////////////////

  public setStepBranching(step: Step, branching: Branching | undefined): void {
    this.updateStep({
      ...step,
      branching,
    });
  }

  ///////////////////////// Step branching //////////////////////////////////////

  private updateSelectedState(): void {
    if (!this.selectedStateSteps || !this.selectedIsaState) {
      return;
    }

    const substates = this.selectedStateSteps.map((step) => {
      const { stepId, ...substate } = step;
      return substate;
    });
    this.selectedIsaState.subState = substates;
  }

  private conditionsSelector(
    type: ConditionType,
    equipmentPhase: EquipmentPhase | undefined
  ): StartConditions | HoldConditions | SuspendConditions | undefined {
    if (!equipmentPhase) {
      throw new Error("equipment phase is undefined!");
    }
    switch (type) {
      case "START":
        return equipmentPhase.startConditions;
      case "HOLD":
        return equipmentPhase.holdConditions;
      case "SUSPEND":
        return equipmentPhase.suspendConditions;
    }
  }

  private stepAlreadyAdded(step: Step): boolean {
    if (this.selectedStateSteps === undefined) {
      return false;
    }

    return (
      this.selectedStateSteps.find(
        (selectedStateStep) => selectedStateStep.stepId === step.stepId
      ) !== undefined
    );
  }
}

export async function Import(file: File) {
  return new Promise<AssetSpecification>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (ev) => {
      const fileXml = ev.target == null ? "" : (ev.target.result as string);

      const parser = new XMLParser(readOptions);
      const fileJson = parser.parse(fileXml);
      parseEmptyStringsToUndefined(fileJson);
      correctFields(fileJson);
      resolve(fileJson as AssetSpecification);
    };
    fileReader.onerror = reject;
    fileReader.readAsText(file, "UTF8");
  });
}

export async function CreateNewEquipmentPhase() {
  return new Promise<AssetSpecification>((resolve, reject) => {
    const fileXml =
      '<?xml version="1.0" encoding="utf-8"?>\r\n<equipmentPhase name="AssetName" typeIdentifier="PH" subtype="Control" typeId="1000" xmlns="urn:Sartorius.NewAP.Schemas.Specification:v1">\r\n  <displayInformation name="AssetName" description="description" />\r\n  <parameterList></parameterList>\r\n  <reportList></reportList>\r\n  <dialogContent></dialogContent>\r\n  <exchange></exchange>\r\n  <startConditions></startConditions>\r\n  <holdConditions></holdConditions>\r\n  <suspendConditions></suspendConditions>\r\n  <actionTable>\r\n    <isa88State name="IDLE" description="EPH State IDLE"/>\r\n    <isa88State name="STARTING" description="EPH State STARTING"/>\r\n    <isa88State name="RUNNING" description="EPH State RUNNING" />\r\n    <isa88State name="COMPLETING" description="EPH State COMPLETING" />\r\n    <isa88State name="COMPLETED" description="EPH State COMPLETED" />\r\n    <isa88State name="RESETTING" description="EPH State RESETTING" />\t\r\n\t<isa88State name="SUSPENDING" description="EPH State SUSPENDING" />\r\n\t<isa88State name="SUSPENDED" description="EPH State SUSPENDED" />\r\n\t<isa88State name="UNSUSPENDING" description="EPH State UNSUSPENDING" />\r\n    <isa88State name="HOLDING" description="EPH State HOLDING" />\r\n    <isa88State name="HELD" description="EPH State HELD" />\r\n    <isa88State name="UNHOLDING" description="EPH State UNHOLDING" />\t\r\n    <isa88State name="STOPPING" description="EPH State STOPPING" />\t\r\n    <isa88State name="STOPPED" description="EPH State STOPPED" />\t\r\n    <isa88State name="ABORTING" description="EPH State ABORTING" />\t\r\n    <isa88State name="ABORTED" description="EPH State ABORTED" />\r\n  </actionTable>\r\n  <operatorMessages />\r\n  <snapShots />\r\n  <calculations />\r\n  <documentation>\r\n    <processDescription />\r\n    <sequentalFlowChart fileName="" />\r\n  </documentation>\r\n</equipmentPhase>';

    try {
      const parser = new XMLParser(readOptions);
      const fileJson = parser.parse(fileXml);
      parseEmptyStringsToUndefined(fileJson, [
        "documentation",
        "dialogContent",
      ]);
      fileJson.equipmentPhase.dialogContent = {};
      resolve(fileJson as AssetSpecification);
    } catch (error) {
      reject(error);
    }
  });
}

export async function GenerateXml(
  asset: AssetSpecification,
  assetFormatterService: IAssetFormatterService
) {
  return new Promise<string>((resolve, reject) => {
    const builder = new XMLBuilder(writeOptions);
    try {
      const formattedAsset: AssetSpecification =
        assetFormatterService.format(asset);
      resolve(builder.build(formattedAsset));
    } catch (error) {
      reject(error);
    }
  });
}

function parseEmptyStringsToUndefined(node: any, keysToIgnore?: string[]) {
  const keys = Object.keys(node).filter((key) => !keysToIgnore?.includes(key));
  keys.forEach((key) => {
    const value = node[key];
    if (Array.isArray(value)) {
      value.forEach((element) =>
        parseEmptyStringsToUndefined(element, keysToIgnore)
      );
    } else if (typeof value === "object") {
      parseEmptyStringsToUndefined(value, keysToIgnore);
    } else if (value === "") node[key] = undefined;
  });
}

function correctFields(asset: AssetSpecification) {
  if (asset.equipmentPhase?.displayInformation["@description"]) {
    asset.equipmentPhase.displayInformation["@description"] =
      asset.equipmentPhase?.displayInformation["@description"].slice(0, 80);
  }

  if (asset.equipmentPhase?.displayInformation["@name"]) {
    asset.equipmentPhase.displayInformation["@name"] =
      asset.equipmentPhase?.displayInformation["@name"].slice(0, 47);
  }

  if (asset.equipmentPhase?.["@name"]) {
    asset.equipmentPhase["@name"] = asset.equipmentPhase["@name"].slice(0, 47);
  }
}
