import {
  DocumentationCore,
  DocumentationPH,
  File,
  ProcessDescription,
} from "../../../Asset_Specification";
import { toArray } from "../../../utils";
import { defaultFile, isValidFile } from "../../../utils/fileUtils";
import { isEmptyOrWhitespace } from "../../../utils/stringUtils";
import {
  DocumentationCoreForm,
  DocumentationPHForm,
} from "../interfaces/DocumentationForm";

const defaultDocumentationCoreForm: DocumentationCoreForm = {
  processDescription: [{}, true],
};

export const defaultDocumentationPHForm: DocumentationPHForm = {
  ...defaultDocumentationCoreForm,
  sequentialFlowChart: [defaultFile, isValidFile(defaultFile)],
};

const isValidProcessDescription = (processDescription: ProcessDescription) => {
  const textBlocks = toArray(processDescription.textBlock);
  return textBlocks.every((textBlock) => !isEmptyOrWhitespace(textBlock));
};

export const getDocumentationPHFormFromDocumentationPH = (
  documentationPH?: DocumentationPH
): DocumentationPHForm => {
  if (!documentationPH) {
    return defaultDocumentationPHForm;
  }

  const documentationPHForm: DocumentationPHForm = {
    ...getDocumentationCoreFormFromDocumentationCore(documentationPH),
    sequentialFlowChart: documentationPH.sequentalFlowChart
      ? [
          documentationPH.sequentalFlowChart,
          isValidFile(documentationPH.sequentalFlowChart),
        ]
      : defaultDocumentationPHForm.sequentialFlowChart,
  };
  if (documentationPH.processFlowDiagram) {
    documentationPHForm.processFlowDiagram = [
      documentationPH.processFlowDiagram,
      isValidFile(documentationPH.processFlowDiagram),
    ];
  }

  return documentationPHForm;
};

export const getDocumentationCoreFormFromDocumentationCore = (
  documentationCore?: DocumentationCore
): DocumentationCoreForm => {
  if (!documentationCore) {
    return defaultDocumentationCoreForm;
  }

  const documentationCoreForm: DocumentationCoreForm = {
    processDescription: documentationCore.processDescription
      ? [
          documentationCore.processDescription,
          isValidProcessDescription(documentationCore.processDescription),
        ]
      : defaultDocumentationCoreForm.processDescription,
  };
  if (documentationCore.wireUp) {
    documentationCoreForm.wireUp = [
      documentationCore.wireUp,
      isValidFile(documentationCore.wireUp),
    ];
  }

  return documentationCoreForm;
};

export const getDocumentationPHFromDocumentationPHForm = (
  documentationPHForm: DocumentationPHForm
): DocumentationPH => {
  const documentationPH: DocumentationPH = {
    ...getDocumentationCoreFromDocumentationCoreForm(documentationPHForm),
    sequentalFlowChart: documentationPHForm.sequentialFlowChart[0],
  };
  if (documentationPHForm.processFlowDiagram) {
    documentationPH.processFlowDiagram =
      documentationPHForm.processFlowDiagram[0];
  }
  return documentationPH;
};

export const getDocumentationCoreFromDocumentationCoreForm = (
  documentationCoreForm: DocumentationCoreForm
): DocumentationCore => {
  const documentationCore: DocumentationCore = {
    processDescription: documentationCoreForm.processDescription[0],
  };
  if (documentationCoreForm.wireUp) {
    documentationCore.wireUp = documentationCoreForm.wireUp[0];
  }

  return documentationCore;
};

export const isValidDocumentationPHForm = (
  documentationPHForm: DocumentationPHForm
) => {
  return Object.values(documentationPHForm).every((property) => property[1]);
};

export const processOptionalFileValue = (file: File): File | undefined => {
  return isEmptyOrWhitespace(file["@fileName"]) ? undefined : file;
};
