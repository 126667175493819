import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppDispatchContext } from "../../../state/Context";
import { EPHInfo } from "../../../types";
import { GeneratePhaseInfo } from "../../../utils";
import { defaultEPHInfo } from "./utils/phaseInfoFormUtils";
import "./PhaseInfoForm.css";
import useAssetSpecification from "./hooks/useAssetSpecification";
import { Form } from "@form-components";
import PhaseInfoFormContent from "./PhaseInfoFormContent";
import { hideDevToolForPhaseInfo } from "@components/form-components/utils/envUtils";

const id: string = "PhaseInfo";

interface PhaseInfoFormProps {
  isValidCallback?: (valid: boolean) => void;
}

const PhaseInfoForm = ({ isValidCallback }: PhaseInfoFormProps) => {
  const { asset } = useAssetSpecification();
  const formRef: React.MutableRefObject<HTMLFormElement | null> =
    useRef<HTMLFormElement>(null);
  const dispatch = useContext(AppDispatchContext);

  const [phaseInfo, setPhaseInfo] = useState<EPHInfo>(defaultEPHInfo);

  const onPhaseInfoSubmitted = useCallback(
    (newPhaseInfo: EPHInfo) => {
      if (asset) {
        dispatch({ type: "set-eph-info", ephInfo: newPhaseInfo });
      }
    },
    [asset, dispatch]
  );

  useEffect(() => {
    const phaseInfo: EPHInfo = GeneratePhaseInfo(asset);
    setPhaseInfo(phaseInfo);
  }, [asset]);

  return (
    <div className="phase-info-form">
      <Form<EPHInfo>
        id={id}
        formRef={formRef}
        defaultValues={phaseInfo}
        onSubmit={onPhaseInfoSubmitted}
        onValidityChange={isValidCallback}
        mode="onChange"
        hideDevTools={hideDevToolForPhaseInfo}
        submitOnChange
      >
        <PhaseInfoFormContent />
      </Form>
    </div>
  );
};

export default PhaseInfoForm;
