import { useContext } from "react";
import { CommonInputProps } from "../InputProps";
import { AppStateContext } from "../../../state/Context";
import RequiredSelectInput from "../RequiredSelectInput/RequiredSelectInput";

interface SelectParameterIdInputProps extends CommonInputProps<string> {
  parameterId: string;
  label: string;
  required?: boolean;
  fixedDropdown?: boolean;
}

/**
 * @deprecated use the equivalent component from form-components
 */
const SelectParameterIdInput = (props: SelectParameterIdInputProps) => {
  const { id, parameterId, label, onChangeCallback, required, fixedDropdown } =
    {
      ...props,
    };

  const { specificationService } = useContext(AppStateContext);
  const parameterNameIdPairs: { name: string; id: string }[] = Object.values(
    specificationService?.getParameters() ?? {}
  ).map((parameter) => ({
    name: parameter["@name"],
    id: parameter["@id"],
  }));

  return (
    <RequiredSelectInput
      id={id}
      label={label}
      value={parameterId}
      options={parameterNameIdPairs.map((parameterNameIdPair) => ({
        label: `${parameterNameIdPair.name} (${parameterNameIdPair.id})`,
        value: parameterNameIdPair.id,
      }))}
      required={required}
      onChangeCallback={onChangeCallback}
      fixedDropdown={fixedDropdown}
    />
  );
};

export default SelectParameterIdInput;
