import FormGrid from "@components/form-components/grid/FormGrid";
import { InputPropsWithoutRules } from "@components/form-components/interfaces/InputProps";
import { getStylesFromColumnSpan } from "@components/form-components/utils/styleUtils";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import AnsiTextField from "../basic/AnsiTextField";
import { DescriptionValue } from "Asset_Specification";

/**
 * Defines the properties for the {@link DescriptionValueInput} component.
 */
interface DescriptionValueInputProps<TInputs extends FieldValues>
  extends Omit<InputPropsWithoutRules<TInputs>, "fullWidth" | "label"> {
  hideLabel?: boolean;
}

/**
 * The input component for specifying descriptionValueInput values in the specification.
 * @param props the properties to inject into the component
 * @returns {ReactElement<DescriptionValueInput<TInputs>>}
 */
export default function DescriptionValueInput<TInputs extends FieldValues>({
  columnSpan,
  disabled,
  name,
  hideLabel,
}: DescriptionValueInputProps<TInputs>) {
  const { getValues } = useFormContext();
  const description = getValues(`${name}.@description`);

  return (
    <FormGrid
      heading={hideLabel ? undefined : description}
      style={getStylesFromColumnSpan(columnSpan)}
    >
      <AnsiTextField
        name={`${name}.@value`}
        label="Value"
        disabled={disabled}
        rules={{ required: true }}
        fullWidth
      />
    </FormGrid>
  );
}

export const descriptionValueInputNames: Path<DescriptionValue>[] = [
  "@description",
  "@value",
];
