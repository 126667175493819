import { SuiButton } from "../SuiButton";
import { SuiIcon } from "../SuiIcon";
import { SuiScrollbar } from "../SuiScrollbar";
import addIcon from "@umetrics/sartorius-ui-icon/dist/icons/add";
import "./EditList.css";
import EditListAccordion from "./EditListAccordion";
import ContainerWithHeader from "../ContainerWithHeader/ContainerWithHeader";

interface EditListProps {
  itemDictionary: { [type: string]: Object };
  heading: string;
  getItemLabel: (item: Object) => string | undefined;
  onAddItemButtonClick: (event: React.MouseEvent) => void;
  onEditItemCallback: (key: string) => void;
  onDeleteItemCallback: (key: string) => void;
}

const EditList = (props: EditListProps) => {
  const {
    itemDictionary,
    heading,
    getItemLabel,
    onAddItemButtonClick,
    onEditItemCallback,
    onDeleteItemCallback,
  } = { ...props };

  return (
    <SuiScrollbar className="edit-list-scrollbar" horiHide>
      <ContainerWithHeader
        heading={heading}
        additionalHeading={
          <h1 className="edit-list-heading-count">
            ({Object.entries(itemDictionary).length})
          </h1>
        }
        headingButtons={
          <SuiButton
            type="primary"
            onClick={onAddItemButtonClick}
            fullHeight
            fullWidth
          >
            <SuiIcon icon={addIcon} slot="icon-slot" size="large" />
          </SuiButton>
        }
      >
        <EditListAccordion
          itemDictionary={itemDictionary}
          getItemLabel={getItemLabel}
          onEditItemCallback={onEditItemCallback}
          onDeleteItemCallback={onDeleteItemCallback}
        />
      </ContainerWithHeader>
    </SuiScrollbar>
  );
};

export default EditList;
