import { ReactElement } from "react";
import "./ModalContent.css";

/**
 * Defines the properties for the {@link ModalContent} component.
 * @param renderMainContent function which renders the main content of the modal.
 * @param renderTopSlotContent function which renders the top-slot content of the modal.
 * @param renderBottomLeftSlotContent function which renders the bottom-left-slot content of the modal.
 * @param renderBottomCenterSlotContent function which renders the bottom-center-slot content of the modal.
 * @param renderBottomRightSlotContent function which renders the bottom-right-slot content of the modal.
 */
interface ModalContentProps {
  renderMainContent: () => ReactElement | ReactElement[];
  renderTopSlotContent?: () => ReactElement;
  renderBottomLeftSlotContent?: () => ReactElement;
  renderBottomCenterSlotContent?: () => ReactElement;
  renderBottomRightSlotContent?: () => ReactElement;
}

const renderSlotContent = (
  slot: string,
  renderContent?: () => ReactElement
) => {
  if (renderContent) {
    return (
      <div className="modal-content-slot" slot={slot}>
        {renderContent()}
      </div>
    );
  }

  return null;
};

/**
 * A component which defines the content for a SuiModal.
 * @param props the properties for the component; see {@link ModalContentProps} for details on the individual properties.
 * @returns A react component of type {@link ModalContent}
 *
 * @remarks This exists to enforce a max size of the modal content in the SuiModal.
 */
const ModalContent = (props: ModalContentProps) => {
  const {
    renderMainContent,
    renderTopSlotContent,
    renderBottomLeftSlotContent,
    renderBottomCenterSlotContent,
    renderBottomRightSlotContent,
  } = { ...props };

  return (
    <>
      {renderSlotContent("top-slot", renderTopSlotContent)}
      <div className="modal-content-main">{renderMainContent()}</div>
      {renderSlotContent("bottom-left-slot", renderBottomLeftSlotContent)}
      {renderSlotContent("bottom-center-slot", renderBottomCenterSlotContent)}
      {renderSlotContent("bottom-right-slot", renderBottomRightSlotContent)}
    </>
  );
};

export default ModalContent;
