import { SuiCheckbox } from "@components/SuiCheckbox";
import FormGrid from "@components/form-components/grid/FormGrid";
import { CheckboxProperties } from "@umetrics/sartorius-ui-checkbox";
import {
  FormEvent,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form";

/**
 * The properties to inject into the {@link OptionalInput<TInputs>} component
 */
interface OptionalInputProps<TInputs extends FieldValues>
  extends PropsWithChildren {
  name: Path<TInputs>;
  defaultValue: PathValue<TInputs, Path<TInputs>>;
  checkboxProps: Omit<CheckboxProperties, "id" | "checked" | "onChange">;
  checked: boolean;
  heading?: string;
  onCheckedChangeCallback?: (isChecked: boolean) => void;
}

/**
 * A component for defining (complex) optional values.
 * @param props the properties of the component
 * @returns a {@link ReactElement<OptionalInputProps<TInputs>>} component.
 */
export default function OptionalInput<TInputs extends FieldValues>({
  name,
  defaultValue,
  children,
  checkboxProps,
  checked,
  heading,
  onCheckedChangeCallback,
}: OptionalInputProps<TInputs>): ReactElement<OptionalInputProps<TInputs>> {
  const { unregister, setValue, trigger } = useFormContext<TInputs>();
  const triggerValidation: MutableRefObject<boolean> = useRef<boolean>(checked);

  const onCheckCallback = useCallback(
    (event: FormEvent<HTMLElement>) => {
      // Negation is not a mistake here!!! When the checkbox is checked, currentTarget has no "checked" attribute.
      const isChecked: boolean = !event.currentTarget.hasAttribute("checked");
      onCheckedChangeCallback?.(isChecked);
      if (!isChecked) {
        unregister(name);
        return;
      }

      setValue(name, defaultValue);
      triggerValidation.current = true;
    },
    [defaultValue, name, onCheckedChangeCallback, setValue, unregister]
  );

  useEffect(() => {
    if (checked && triggerValidation.current) {
      trigger(name);
      triggerValidation.current = false;
    }
  }, [checked, name, trigger]);

  return (
    <FormGrid heading={heading}>
      <SuiCheckbox
        checked={checked}
        onChange={onCheckCallback}
        {...checkboxProps}
      />
      {checked && children}
    </FormGrid>
  );
}
