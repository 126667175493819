import AnsiCharInput from "../../AnsiCharInput";
import {
  processChangedOptionalValue,
  processOptionalValue,
} from "../../inputUtils";
import { PLCParameterOptionalPropertyInputProps } from "../interfaces/PLCParameterPropertyInputProps";

const DescriptionInput = (props: PLCParameterOptionalPropertyInputProps) => {
  const { id, value, onPropertyChanged } = { ...props };

  return (
    <AnsiCharInput
      id={`${id}-Description`}
      label="Description"
      value={processOptionalValue(value)}
      maxLength={47}
      onChangeCallback={(value: string, valid: boolean) =>
        onPropertyChanged(processChangedOptionalValue(value), valid)
      }
      fullWidth
    />
  );
};

export default DescriptionInput;

