import { useContext, useMemo } from "react";
import EditStepNodeDataModalService, {
  IEditStepNodeDataModalService,
} from "../services/EditStepNodeDataModalService";
import {
  AppDispatchContext,
  ModalContext,
} from "../../../../../../state/Context";
import useEditNode from "../../../../hooks/useEditNode";
import IEditNodeService from "../../../../services/interfaces/IEditNodeService";

const useEditStepNodeDataModal = (): IEditStepNodeDataModalService => {
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const appDispatch = useContext(AppDispatchContext);
  const editNodeService: IEditNodeService = useEditNode();
  const editStepNodeModalService: IEditStepNodeDataModalService = useMemo(
    () =>
      new EditStepNodeDataModalService(
        appDispatch,
        modalDispatch,
        editNodeService
      ),
    [appDispatch, modalDispatch, editNodeService]
  );
  return editStepNodeModalService;
};

export default useEditStepNodeDataModal;

