import { useContext, useEffect, useState } from "react";
import { TransitionConditions } from "../types/TransitionConditions";
import { AppStateContext } from "state/Context";
import { defaultTransitionConditions } from "../utils/defaultValues";

const useTransitionConditions = (): TransitionConditions => {
  const { specificationService } = useContext(AppStateContext);
  const [transitionConditions, setTransitionConditions] =
    useState<TransitionConditions>(defaultTransitionConditions);

  useEffect(() => {
    const selectedState = specificationService?.SelectedState;
    if (!selectedState) {
      setTransitionConditions(defaultTransitionConditions);
      return;
    }
    const conditionTypes: TransitionConditions =
      specificationService.getTransitionConditions(selectedState);
    setTransitionConditions(conditionTypes);
  }, [specificationService]);

  return transitionConditions;
};

export default useTransitionConditions;
