import AddTool from "../../addTool/AddTool";
import { SuiAccordion, SuiAccordionItem } from "../../../../SuiAccordion";
import { AccordionItemSlot } from "@umetrics/sartorius-ui-accordion";
import { SuiScrollbar } from "../../../../SuiScrollbar";
import "./Toolbox.css";

const Toolbox = () => {
  return (
    <SuiScrollbar horiHide className="flow-editor-toolbox">
      <SuiAccordion singleOpen>
        <SuiAccordionItem>
          <span slot={AccordionItemSlot.LABEL}>Elements</span>
          <AddTool />
        </SuiAccordionItem>
      </SuiAccordion>
    </SuiScrollbar>
  );
};

export default Toolbox;
