import { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { TransitionConditions } from "./types/TransitionConditions";
import { FormTab, FormTabs } from "@form-components";
import TransitionConditionsFormContent from "./TransitionConditionsFormContent";

const TransitionConditionsFormTabs = (): ReactElement => {
  const { watch } = useFormContext<TransitionConditions>();

  const transitionConditions: TransitionConditions = watch();

  return (
    <FormTabs>
      {Object.keys(transitionConditions).map((transitionConditionType) => (
        <FormTab
          key={transitionConditionType}
          heading={transitionConditionType}
          names={transitionConditionType}
        >
          <TransitionConditionsFormContent name={transitionConditionType} />
        </FormTab>
      ))}
    </FormTabs>
  );
};

export default TransitionConditionsFormTabs;
